import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteWorkExperience } from "../../redux/actions/workExpActions";
import {useTranslation} from "react-i18next";

export default function Delete(props) {
   const dispatch = useDispatch();
   const wexp = useSelector((state) => state.workExpReducers);
   let { uuid, status, closeModal } = props;
   const [hidden, setHidden] = useState(false);
   const [filterRecords, setFilterRecords] = useState(undefined);
   const effectBG = status ? "fadeIn" : "fadeOut";
   const effectModal = status ? "fadeInDown" : "fadeOutUp";
	const { t } = useTranslation('common');

   useEffect(() => {
      if (status) {
         var filter = wexp.records.filter((item) => item.uuid === uuid);
         setFilterRecords(filter[0]);
      }
   }, [status, uuid, wexp.records]);

   useEffect(() => {
      if (status) {
         setHidden(true);
      } else {
         setTimeout(() => {
            setHidden(false);
         }, 200);
      }
   }, [status]);

   useEffect(() => {
      if (wexp.success) closeModal();
   }, [wexp.success, closeModal]);

   const handleDelete = () => {
      dispatch(deleteWorkExperience(filterRecords.uuid));
   };

   return (
      <>
         {hidden && (
            <>
               <div
                  className={`fixed bg-black w-full h-full top-0 left-0 z-20 w-full animate__animated animate__${effectBG} animate__faster`}
                  style={{ backgroundColor: "rgba(0,0,0,.5)" }}
                  onClick={closeModal}
               ></div>
               <div className="flex justify-center">
                  <div
                     className={`rounded-md fixed top-0 left-0 mt-64 w-11/12 center-11-12 md:w-4/12 bg-white z-30 animate__animated animate__${effectModal} animate__faster`}
                  >
                     <div className=" bg-red-500 bg-opacity-80 text-white font-bold">
                     <div className="flex justify-between p-3">
                              <label >{t('experience.deltitle')}</label>
                              <svg onClick={closeModal} xmlns="http://www.w3.org/2000/svg" className="h-5 w-5  border-2 rounded-md hover:bg-gray-200 hover:text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                              </svg>
                             {/*  <i className="w-2 h-2 far fa-window-close fa fa-2xl hover:text-blue-300" onClick={closeModal}></i> */}
                           </div>
                  </div>
                     <div className="px-2 py-8 text-center text-md font-normal">
							{t('experience.delcontent')}{" "}
                        <span className="font-semibold">
                           {filterRecords.title}
                        </span>{" "}
                        -{" "}
                        <span className="font-semibold">
                           {filterRecords.company}
                        </span>
                     </div>
                     <div className="flex justify-center p-5">
                       {/*  <button
                           className="bg-red-500 text-white p-2 rounded focus:outline-none"
                           onClick={closeModal}
                        >
                           {t('experience.nobtn')}
                        </button> */}
                        <button
                           className="bg-green-100 border-2 border-green-600 hover:bg-green-500 hover:text-white text-green-600 p-2 rounded font-semibold focus:outline-nonee"
                           onClick={handleDelete}
                        >
                           {t('experience.yesbtn')}
                        </button>
                     </div>
                  </div>
               </div>
            </>
         )}
      </>
   );
}

const initialState = {
  records: [],
  loaded: false,
};

export default function currencyReducers(state = initialState, action) {
  switch (action.type) {
    case "CURRENCY_RECORDS": {
      return {
        ...state,
        records: action.payload,
        loaded: true,
      };
    }
    case "CURRENCY_STORE": {
      return {
        ...state,
        loaded: false,
      };
    }
    case "CURRENCY_UPDATE": {
      return {
        ...state,
        loaded: false,
      };
    }
    case "CURRENCY_DELETE": {
      return {
        ...state,
        loaded: false,
      };
    }
    default:
      return state;
  }
}

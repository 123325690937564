import React from "react";

import { useTranslation } from "react-i18next";

export default function JobDetail({ jobsSpecific, loaded }) {
  const { t } = useTranslation("common");
  return (
    <>
      {loaded && jobsSpecific && (
        <div className="vacancies">
          <div className="pb-4 mb-2 border-b">
            <h6 className="font-poppins mb-2 font-semibold leading-5">
              {t("vacancies.positionSumary")}
              {":"}
            </h6>
            <p
              className="font-poppins text-sm text-justify font-light text-gray-900"
              dangerouslySetInnerHTML={{ __html: jobsSpecific.description_job }}
            ></p>
          </div>
          <div className="pb-4 mb-4 border-b">
            <h6 className="font-poppins mb-2 font-semibold leading-5">
              {t("vacancies.esentialJob")}
              {":"}
            </h6>
            <div
              className="font-poppins list-decimal text-sm font-light text-gray-900"
              dangerouslySetInnerHTML={{ __html: jobsSpecific.esential_job }}
            ></div>
          </div>
          <div className="pb-4 mb-4 border-b">
            <h6 className="font-poppins mb-2 font-semibold leading-5">
              {t("vacancies.knowSkills")}
              {":"}
            </h6>
            <div
              className="font-poppins list-disc text-sm font-light text-gray-900"
              dangerouslySetInnerHTML={{ __html: jobsSpecific.know_skills }}
            ></div>
          </div>
          <div className="pb-4 mb-4 border-b">
            <h6 className="font-poppins mb-2 font-semibold leading-5">
              {t("vacancies.qualityJob")}
            </h6>
            <p className="font-poppins mb-2 text-sm underline">
              {t("vacancies.minQuality")}
              {":"}
            </p>
            <div
              className="font-poppins list-disc text-sm font-light text-gray-900"
              dangerouslySetInnerHTML={{ __html: jobsSpecific.quality_min_job }}
            ></div>
            <p className="font-poppins my-2 text-sm underline">
              {t("vacancies.preferedJobs")}
              {":"}
            </p>
            <div
              className="font-poppins list-disc text-sm font-light text-gray-900"
              dangerouslySetInnerHTML={{ __html: jobsSpecific.prefered_job }}
            ></div>
          </div>
          <div className="pb-4 mb-20 ">
            <h6 className="font-poppins mb-2 font-semibold">
              {t("vacancies.workConditions")}
              {":"}
            </h6>
            <p className="font-poppins text-xs italic  text-gray-900">
              {jobsSpecific.work_conditions_job}
            </p>
          </div>
        </div>
      )}
    </>
  );
}

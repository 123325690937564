import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSkills, getSkillsDataUser } from "../../redux/actions/skillActions";
import Table from "./Table";
import FormAdd from "./FormAdd";
import { useTranslation } from "react-i18next";

export default function Skill() {
  const dispatch = useDispatch();
  const skill = useSelector((state) => state.skillReducers);
  const [showFormAdd, setShowFormAdd] = useState(false);
  const { t } = useTranslation("common");

  useEffect(() => {
    if (!skill.loaded) dispatch(getSkills());
  }, [dispatch, skill.loaded]);

  const openFormAdd = () => {
    setShowFormAdd(!showFormAdd);
    dispatch(getSkillsDataUser());
    dispatch({ type: "SKILL_SUCCESS_OFF" });
    dispatch({ type: "SKILL_ERROR_OFF" });
  };

  const closeFormAdd = () => {
    setShowFormAdd(false);
    dispatch({ type: "SKILL_SUCCESS_OFF" });
    dispatch({ type: "SKILL_ERROR_OFF" });
  };
  const skilluser = useSelector((state) => state.skillReducers);
  return (
    <>
      <div className="flex flex-col flex-1 w-full h-full pb-16 overflow-y-auto container grid px-6 mx-auto">
        <h1 className="mt-10 text-2xl font-semibold font-poppins text-black dark:text-gray-200">
          {t("skills.title")}
        </h1>
        <div className="flex justify-between items-end mb-4">
          <h4 className="mt-2 mb-3 font-poppins lg:text-sm text-xs inline-block">
            {t("skills.skill_description")}
          </h4>
        </div>
        <div>
          <button
            className="flex items-center justify-between py-2 px-5 mr-10 text-sm font-medium leading-5 text-gray-700 font-poppins hover:text-white bg-gray-200 transition-colors duration-150 border-2 border-gray-700  border-transparent rounded-md active:bg-red-600 hover:bg-gray-600 focus:outline-none focus:shadow-outline-black float-right"
            onClick={openFormAdd}
          >
            <i
              className={`fa ${
                showFormAdd ? "fa-times" : "fa-plus"
              } fa-1x mr-2`}
            ></i>{" "}
            {showFormAdd ? t("skills.close") : t("skills.add")}
          </button>
        </div>
        <FormAdd
          skilluser2={skilluser}
          status={showFormAdd}
          closeFormAdd={closeFormAdd}
        />
        {!showFormAdd && <Table />}
      </div>
    </>
  );
}

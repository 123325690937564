import api from "../../../utils/api";

export const getUserApplied = () => async (dispatch) => {
  try {
    const response = await api.get(`/jobs/applied`);
    dispatch({
      type: "USER_JOBS_APPLY",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "USER_APPLY_ERROR",
      payload: e.response.data.errors,
    });
  }
};
export const getPostulates = (jobs_id, filter) => async (dispatch) => {
  try {
    const response = !filter
      ? await api.get(`/board/postulates/${jobs_id}`)
      : await api.get(`/board/postulates/${jobs_id}/${filter}`);
    dispatch({
      type: "USER_JOBS_APPLY",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "USER_APPLY_ERROR",
      payload: e.response.data.errors,
    });
  }
};

export const setElement = (element) => async () => {
  await api.post("/elements/change", element);
};
export const removeElement = (uuid) => async () => {
  await api.delete(`/elements/delete/${uuid}`);
};
export const getUserPostulate = (user_id) => async (dispatch) => {
  try {
    const response = await api.get(`/board/user/${user_id}`);
    dispatch({
      type: "USER_MODAL",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "USER_APPLY_ERROR",
      payload: e.response.data.errors,
    });
  }
};
export const getDetail = (jobs_id) => async (dispatch) => {
  try {
    const response = await api.get(`/board/detail/${jobs_id}`);
    dispatch({
      type: "USER_JOBS_DETAIL",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "USER_APPLY_ERROR",
      payload: e.response.data.errors,
    });
  }
};

// export const setBoard = () => async (dispatch) => {
// 	dispatch({ type: "UT_BUTTON" });
// 	try {
// 		const response = await api.post("/board/save", data);
// 		if (response.status === 200) {
// 			dispatch({
// 				type: 'USER_JOBS_APPLY',
// 				payload: response.data
// 			})
// 		}
// 	} catch (e) {
// 		dispatch({
// 			type: "USER_APPLY_ERROR",
// 			payload: e.response.data.errors,
// 		});
// 	}
// };

import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../redux/actions/admin/usersActions";
import Button from "@material-ui/core/Button";
import CAccordion from "../../common/Accordion";
import UserDetail from "./UserDetail";
import UserProfessional from "./UserProfessional";
import UserJobs from "./UserJobs";
import UserExperience from "./UserExperience";
import UserSkills from "./UserSkills";
import UserPortfolio from "./UserPortfolio";
import UserReferences from "./UserReferences";
import { useTranslation } from "react-i18next";

export default function Profile() {
  let { uuid } = useParams();
  let { applied } = useParams();
  let { jobsId } = useParams();
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.usersReducers);
  useEffect(() => {
    if (!user.user_loaded) dispatch(getUserProfile(uuid));
  }, [dispatch, user.user_loaded, uuid]);

  useEffect(() => {
    return () => {
      dispatch({ type: "USER_RECORD_OFF" });
      localStorage.removeItem("back_url");
    };
  }, [dispatch]);

  return (
    <>
      {user.user_loaded && (
        <div className="w-11/12 mx-auto mt-10 animate__animated animate__fadeIn mb-24">
          <div>
            {applied !== undefined && jobsId !== undefined ? (
              <Link to={`/vacancies/admin/list/${jobsId}`}>
                <Button
                  className="focus:outline-none outline font-poppins"
                  variant="outlined"
                >
                  <i className="fa fa-arrow-left mr-2 font-poppins"></i>{" "}
                  {t("personal.back")}
                </Button>
              </Link>
            ) : (
              <Link
                to="/admin/users"
                onClick={() => dispatch({ type: "USER_RECORD_OFF" })}
              >
                <Button
                  className="focus:outline-none outline font-poppins"
                  variant="outlined"
                >
                  <i className="fa fa-arrow-left mr-2 font-poppins"></i>{" "}
                  {t("personal.back")}
                </Button>
              </Link>
            )}
          </div>
          <div className="bg-black text-white p-2 my-2 shadow rounded font-poppins">
            {user.record.name} {user.record.lastname} -{" "}
            <a href={`mailto:${user.records.email}`}>{user.record.email}</a>
          </div>

          <CAccordion panel="panel1" title={t("personal.personalInfo")}>
            {user.record.user_detail ? (
              <UserDetail
                records={user.record.user_detail}
                aditional={[
                  {
                    avalaible_strut: user.record.avalaible_strut,
                    hired_strut: user.record.hired_strut,
                  },
                ]}
              />
            ) : user.record.user_detail === null && !user.loaded ? (
              <div className="justify-center align-center mx-auto">
                <label style={{ textAlign: "center" }}>
                  {t("portfolio.norecords")}
                </label>
              </div>
            ) : (
              !user.loaded && (
                <div className="flex justify-center mt-15 mb-24">
                  <i className="fa fa-spin fa-spinner fa-2x"></i>
                </div>
              )
            )}
          </CAccordion>
          {/* información profesional */}
          {user.record.professional &&
            (user.record.professional.length > 0 ? (
              <CAccordion
                panel="panel2"
                title={t("professional.info")}
                color={"black"}
              >
                {user.record.professional && (
                  <UserProfessional records={user.record.professional} />
                )}
              </CAccordion>
            ) : (
              <CAccordion
                panel="panel2"
                title={t("professional.info")}
                color={"red"}
              >
                {user.record.professional && (
                  <UserProfessional records={user.record.professional} />
                )}
              </CAccordion>
            ))}
          {/* Vacantes aplicadas */}
          {user.record.user_jobs &&
            (user.record.user_jobs.length > 0 ? (
              <CAccordion
                panel="panel2"
                title={t("vacancies.applyVacant")}
                color={"black"}
              >
                {user.record.user_jobs && (
                  <UserJobs
                    records={user.record.user_jobs}
                    vacancies={user.record.vacants}
                    user={user.record}
                  />
                )}
              </CAccordion>
            ) : (
              <CAccordion
                panel="panel2"
                title={t("vacancies.applyVacant")}
                color={"red"}
              >
                {user.record.user_jobs && (
                  <UserJobs
                    records={user.record.user_jobs}
                    vacancies={user.record.vacants}
                    user={user.record}
                  />
                )}
              </CAccordion>
            ))}

          {/* experiencia laboral */}
          {user.record.experience &&
            (user.record.experience.length > 0 ? (
              <CAccordion
                panel="panel2"
                title={t("experience.info")}
                color={"black"}
              >
                {user.record.experience && (
                  <UserExperience records={user.record.experience} />
                )}
              </CAccordion>
            ) : (
              <CAccordion
                panel="panel2"
                title={t("experience.info")}
                color={"red"}
              >
                {user.record.experience && (
                  <UserExperience records={user.record.experience} />
                )}
              </CAccordion>
            ))}

          {/* habilidades y destrezas */}
          {user.record.user_skill &&
            (user.record.user_skill.length > 0 ? (
              <CAccordion
                panel="panel2"
                title={t("menu_profile.skills")}
                color={"black"}
              >
                {user.record.user_skill && user.record.user_skill.length > 0 ? (
                  <UserSkills records={user.record.user_skill} user_uuid={uuid} />
                ) : (
                  <div className="justify-center align-center mx-auto">
                    <label style={{ textAlign: "center" }}>
                      {t("portfolio.norecords")}
                    </label>
                  </div>
                )}
              </CAccordion>
            ) : (
              <CAccordion
                panel="panel2"
                title={t("menu_profile.skills")}
                color={"red"}
              >
                {user.record.user_skill && user.record.user_skill.length > 0 ? (
                  <UserSkills records={user.record.user_skill} user_uuid={uuid} />
                ) : (
                  <div className="justify-center align-center mx-auto">
                    <label style={{ textAlign: "center" }}>
                      {t("portfolio.norecords")}
                    </label>
                  </div>
                )}
              </CAccordion>
            ))}

          {/* portafolio */}
          {user.record.portfolio &&
            (user.record.portfolio.length > 0 ? (
              <CAccordion
                panel="panel2"
                title={t("menu_profile.portfolio")}
                color={"black"}
              >
                {user.record.portfolio && (
                  <UserPortfolio records={user.record.portfolio} />
                )}
              </CAccordion>
            ) : (
              <CAccordion
                panel="panel2"
                title={t("menu_profile.portfolio")}
                color={"red"}
              >
                {user.record.portfolio && (
                  <UserPortfolio records={user.record.portfolio} />
                )}
              </CAccordion>
            ))}

          {/* referencias */}
          {user.record.reference &&
            (user.record.reference.length > 0 ? (
              <CAccordion
                panel="panel2"
                title={t("menu_profile.references")}
                color={"black"}
              >
                {user.record.reference && (
                  <UserReferences records={user.record.reference} />
                )}
              </CAccordion>
            ) : (
              <CAccordion
                panel="panel2"
                title={t("menu_profile.references")}
                color={"red"}
              >
                {user.record.reference && (
                  <UserReferences records={user.record.reference} />
                )}
              </CAccordion>
            ))}
        </div>
      )}
    </>
  );
}

const initialState = {
  user: [],
  status: "",
  modules: [],
  loaded_modules: false,
  mode: undefined,
  access: undefined,
  comment_sent: false,
  error: false,
  error_message: [],
  message: "",
  message_status: false,
};

export default function mainReducers(state = initialState, action) {
  switch (action.type) {
    case "LOGIN_ROLE_VERIFIED": {
      return {
        ...state,
        user: action.payload.user,
        status: action.payload.status,
        mode: action.payload.mode,
      };
    }
    case "LOGIN_PERMISSIONS_VERIFIED": {
      return {
        ...state,
        modules: action.payload,
        loaded_modules: true,
      };
    }
    case "ACCESS_VERIFIED": {
      return {
        ...state,
        access: action.payload,
      };
    }
    case "SENT_COMMENT": {
      return {
        ...state,
        comment_sent: true,
        error: false,
        message: "Your comment was sent. Thanks.",
        message_status: true,
      };
    }
    case "SENT_COMMENT_OFF": {
      return {
        ...state,
        comment_sent: false,
      };
    }
    case "MESSAGE_OFF": {
      return {
        ...state,
        message: "",
        message_status: false,
      };
    }
    case "SENT_COMMENT_ERROR": {
      return {
        ...state,
        error: true,
        error_message: action.payload,
      };
    }
    case "MODULES_RELOAD": {
      return {
        ...state,
        loaded_modules: false,
      };
    }
    default:
      return state;
  }
}

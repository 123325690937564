const initialState = {
	button: false,
	loaded: false,
	records: [],
	error: false,
	error_message: [],
	success: false
}

export default function referencesReducers( state = initialState, action) {
	switch(action.type) {
		case 'REF_BUTTON': {
			return {
				...state,
				button: true
			}
		}
		case 'REF_BUTTON_OFF': {
			return {
				...state,
				button: false
			}
		}
		case 'REF_ERROR': {
			return {
				...state,
				button: false,
				error: true,
				error_message: action.payload,
				success: false
			}
		}
		case 'REF_SUCCESS': {
			return {
				...state,
				button: false,
				error: false,
				error_message: [],
				success: true,
				loaded: false,
			}
		}
		case 'REF_SUCCESS_OFF': {
			return {
				...state,
				success: false
			}
		}
		case 'REF_ERROR_OFF': {
			return {
				...state,
				error: false,
				error_message: [],
				button: false
			}
		}
		case 'REF_RECORDS': {
			return {
				...state,
				loaded: true,
				records: action.payload
			}
		}
		case 'REF_RECORDS_EDIT': {
			return {
				...state,
				records: action.payload
			}
		}
		case 'REF_UPD': {
			return {
				...state,
				records: action.payload
			}
		}
		default: return state;
	}
}
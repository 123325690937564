import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { verifyPermissions } from "../../redux/actions/loginActions";
import { getCounts } from "../../redux/actions/admin/contactActions";
import { useDispatch } from "react-redux";
export default function Dashboard() {
  const main = useSelector((state) => state.mainReducers);
  const contact = useSelector((state) => state.contactReducers);
  const { t, i18n } = useTranslation("common");

  const panel = {
    1: {
      tag: "candidates",
    },
    2: {
      tag: "skillstest",
    },
    3: {
      tag: "questions",
    },
    4: {
      hover: "hover:border-green-600 hover:bg-green-100 hover:text-green-500",
      span: "bg-green-100 text-green-500",
      tag: "users",
    },
    5: {
      hover:
        "hover:border-yellow-600 hover:bg-yellow-100 hover:text-yellow-500",
      span: "bg-yellow-100 text-yellow-400",
      tag: "messages",
    },
    6: {
      hover:
        "hover:border-purple-600 hover:bg-purple-100 hover:text-purple-500",
      span: "bg-purple-100 text-purple-400",
      tag: "clients",
    },
    7: {
      hover: "hover:border-red-600 hover:bg-red-100 hover:text-red-500",
      span: "bg-red-100 text-red-400",
      tag: "roles",
    },
    9: {
      hover: "hover:border-red-600 hover:bg-red-100 hover:text-red-500",
      span: "bg-red-100 text-red-400",
      tag: "currencies"
    },
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCounts(i18n.language));
  }, [dispatch, i18n.language]);

  useEffect(() => {
    if (!main.loaded_modules) dispatch(verifyPermissions());
  }, [dispatch, main]);

  return (
    <>
      {main.loaded_modules ? (
        <>
          <h1 className="mx-16 mt-12 text-3xl font-semibold font-poppins text-black pl-2">
            {t("menu_profile.dashboard")}
          </h1>
          <div className=" mx-16 justify-between items-end mb-4">
            <h4 className="mt-2 mb-3 font-poppins font-normal lg:text-sm text-xs inline-block pl-2">
              {t("dashboard.welcome")}
            </h4>

            <h1 className="mt-10 font-medium font-poppins text-black border-b border-gray-200 my-10">
              {t("dashboard.recruiment")}
            </h1>
          </div>
          <div className="mb-10 mt-2 mx-16">
            <section className="text-gray-600 body-font">
              <div className="container pb-14 lg:pb-10 px-8 mx-auto">
                <div className="w-full pt-2 pb-6">
                  <div className="flex">
                    <div className="w-1/3">
                      <div className="relative mb-2">
                        <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-gray-600 w-full">
                            1
                          </span>
                        </div>
                      </div>
                      <div className="text-xs text-center">
                        {t("dashboard.step1")}
                      </div>
                    </div>
                    <div className="w-1/3">
                      <div className="relative mb-2">
                        <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-gray-600 w-full">
                            2
                          </span>
                        </div>
                      </div>
                      <div className="text-xs text-center">
                        {t("dashboard.step2")}
                      </div>
                    </div>
                    <div className="w-1/3">
                      <div className="relative mb-2">
                        <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-gray-600 w-full">
                            3
                          </span>
                        </div>
                      </div>
                      <div className="text-xs text-center">
                        {t("dashboard.step3")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap items-center justify-center ">
                  <div className="flex flex-wrap  rounded overflow-hidden p-4 shadow-lg">
                    {main.modules.length &&
                      main.modules.slice(0, 3).map((module) => (
                        <div key={module.id} className="xl:w-1/3 md:w-1/3 p-4">
                          <a
                            className={`${
                              !module.visible_user
                                ? "pointer-events-none opacity-50"
                                : ""
                            } shadow-md shadow-blue-500 flex justify-center border-l-8 border-gray-100 hover:border-blue-600 bg-gray-100 hover:bg-blue-100 hover:blue-green-500 px-6 py-4 rounded-sm transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110`}
                            href={module.route}
                          >
                            <div className="flex  justify-center items-center w-1/3">
                              <span className="flex justify-center items-center w-14 h-14 rounded-full bg-blue-100 text-blue-500">
                                <i className={`${module.icon} text-3xl`}></i>
                              </span>
                            </div>
                            <div className="flex justify-center items-center w-2/3">
                              <span className="ml-4 flex items-start flex-col leading-none">
                                <span className="title-font font-poppins text-md font-semibold">
                                  {t(module.title)}
                                </span>
                                <span className="text-sm text-gray-600 my-4">
                                  <span className=" text-lg font-bold">
                                    {contact.records
                                      ? contact.records[panel[module.id].tag]
                                      : 0}{" "}
                                  </span>
                                  {t(module.description)}
                                </span>
                              </span>
                            </div>
                          </a>
                        </div>
                      ))}
                  </div>
                </div>

                <div>
                  <h1 className="mt-10 font-medium font-poppins text-black border-b border-gray-200 my-10 ">
                    {t("dashboard.admin")}
                  </h1>
                  <div className="flex flex-wrap px-16">
                    {main.modules.length &&
                      main.modules.slice(3).map(
                        (module) =>
                          module.id !== 8 && (
                            <div
                              key={module.id}
                              className="xl:w-1/3 md:w-1/3 p-4"
                            >
                              <a
                                className={`${
                                  !module.visible_user
                                    ? "pointer-events-none opacity-50"
                                    : ""
                                } shadow-md flex justify-center  border-l-8 border-gray-100 bg-gray-100 ${
                                  panel[module.id]["hover"]
                                } p-6 rounded-sm transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110`}
                                href={module.route}
                              >
                                <div className="flex justify-center items-center w-1/3">
                                  <span
                                    className={`flex justify-center items-center w-14 h-14 rounded-full ${
                                      panel[module.id]["span"]
                                    }`}
                                  >
                                    <i
                                      className={`${module.icon} text-3xl`}
                                    ></i>
                                  </span>
                                </div>
                                <div className="flex justify-center items-center w-2/3">
                                  <span className="ml-4 flex items-start flex-col leading-none">
                                    <span className="title-font font-poppins text-lg font-semibold">
                                      {t(module.title)}
                                    </span>
                                    <span className="text-md text-gray-600 my-4">
                                      <span className=" text-lg font-bold">
                                        {contact.records &&
                                          (contact.records[
                                            panel[module.id]?.tag
                                          ] ??
                                            0)}
                                      </span>{" "}
                                      {t(module.description)}
                                    </span>
                                  </span>
                                </div>
                              </a>
                            </div>
                          )
                      )}
                  </div>
                </div>
              </div>
              <hr />
            </section>
          </div>
        </>
      ) : (
        <div className="mt-40 justify-content-center">
          <div className="text-center my-5 p-4 overflow-x-auto">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import { savePersonalChanges } from "../../redux/actions/personalActions";
import Button from "../common/Button";
import { handleErrorMessage } from "../../utils/functions";

export default function PersonalForm({ preloadedValues }) {
  const personal = useSelector((state) => state.personalReducers);
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const [younger, setYounger] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: preloadedValues,
  });

  const calculateAge = (birthDate) => {
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const onSubmitData = async (data) => {
    const { date_of_birth } = data;
    const age = calculateAge(new Date(date_of_birth));

    if (age < 15) {
      setYounger(true);
      return;
    }
    await dispatch(savePersonalChanges(data));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <div className="w-full md:flex mb-5">
          <div className="w-full my-5 md:w-1/2 md:my-0 md:mx-2">
            <Controller
              name="name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("personal.error.name_required"),
                },
                pattern: {
                  value: /^[a-zA-Z0-9 ñÑáéíóúÁÉÍÓÚ:.,*#]{3,}$/,
                  message: t("personal.error.name_pattern"),
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  id="name"
                  name="name"
                  label={`${t("personal.full_name")} *`}
                  variant="outlined"
                  error={errors.name ? true : false}
                  className="w-full"
                  helperText={t(handleErrorMessage(personal, "name"))}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => (
                <p className="mt-1 text-sm text-red-500">{message}</p>
              )}
            />
          </div>
          <div className="w-full my-5 md:w-1/2 md:my-0 md:mx-2">
            <Controller
              name="lastname"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("personal.error.last_required"),
                },
                pattern: {
                  value: /^[a-zA-Z0-9 ñÑáéíóúÁÉÍÓÚ:.,*#]{3,}$/,
                  message: t("personal.error.last_pattern"),
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  id="lastname"
                  name="lastname"
                  label={`${t("personal.lastname")} *`}
                  variant="outlined"
                  className="w-full"
                  error={errors.lastname ? true : false}
                  helperText={t(handleErrorMessage(personal, "lastname"))}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="lastname"
              render={({ message }) => (
                <p className="mt-1 text-sm text-red-500">{message}</p>
              )}
            />
          </div>
        </div>
        <div className="w-full md:flex mb-5">
          <div className="w-full my-5 md:w-1/3 md:my-0 md:mx-2">
            <Controller
              name="email"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("personal.error.email_required"),
                },
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,7}$/,
                  message: t("personal.error.email"),
                },
              }}
              render={({ field }) => (
                <TextField
                  type="email"
                  id="email"
                  name="email"
                  label={`${t("personal.email")} *`}
                  error={errors.email ? true : false}
                  variant="outlined"
                  className="w-full"
                  helperText={t(handleErrorMessage(personal, "email"))}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => (
                <p className="mt-1 text-sm text-red-500">{message}</p>
              )}
            />
          </div>
          <div className="w-full my-5 md:w-1/3 md:my-0 md:mx-2">
            <Controller
              name="personal_phone"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("personal.error.pp_required"),
                },
                pattern: {
                  value:
                    /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
                  message: t("personal.error.pp_numeric"),
                },
              }}
              render={({ field }) => (
                <TextField
                  type="tel"
                  id="personal_phone"
                  name="personal_phone"
                  error={errors.personal_phone ? true : false}
                  label={`${t("personal.personal_phone")} *`}
                  variant="outlined"
                  className="w-full"
                  helperText={t(handleErrorMessage(personal, "personal_phone"))}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="personal_phone"
              render={({ message }) => (
                <p className="mt-1 text-sm text-red-500">{message}</p>
              )}
            />
          </div>
          <div className="w-full my-5 md:w-1/3 md:my-0 md:mx-2">
            <Controller
              name="date_of_birth"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("personal.error.dob_date"),
                },
                pattern: {
                  message: t("personal.error.dob_date"),
                },
              }}
              render={({ field }) => (
                <TextField
                  type="date"
                  id="date_of_birth"
                  name="date_of_birth"
                  label={`${t("personal.dob")} *`}
                  variant="outlined"
                  className="w-full"
                  error={errors.date_of_birth ? true : false}
                  helperText={t(handleErrorMessage(personal, "date_of_birth"))}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: new Date().toISOString().slice(0, 10),
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="date_of_birth"
              render={({ message }) => (
                <p className="mt-1 text-sm text-red-500">{message}</p>
              )}
            />
            {younger && (
              <p className="mt-1 text-sm text-red-500">
                {t("personal.error.dob_before")}
              </p>
            )}
          </div>
        </div>
        <div className="w-full md:flex mb-5">
          <div className="w-full my-5 md:w-1/2 md:my-0 md:mx-2">
            <Controller
              name="address"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("personal.error.address_required"),
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  id="address"
                  name="address"
                  label={`${t("personal.address_1")} *`}
                  error={errors.address ? true : false}
                  variant="outlined"
                  className="w-full"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={t(handleErrorMessage(personal, "address"))}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="address"
              render={({ message }) => (
                <p className="mt-1 text-sm text-red-500">{message}</p>
              )}
            />
          </div>
          <div className="w-full my-5 md:w-1/2 md:my-0 md:mx-2">
            <Controller
              name="address2"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  id="address2"
                  name="address2"
                  label={t("personal.address_2")}
                  variant="outlined"
                  className="w-full"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className="w-full md:flex mb-5">
          <div className="w-full my-5 md:w-1/2 md:my-0 md:mx-2">
            <Controller
              name="state"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("personal.error.state_required"),
                },
                pattern: {
                  value: /^[a-zA-Z0-9 ñÑáéíóúÁÉÍÓÚ:.,*#]{3,}$/,
                  message: t("personal.error.name_pattern"),
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  id="state"
                  name="state"
                  error={errors.state ? true : false}
                  label={`${t("personal.state")} *`}
                  variant="outlined"
                  className="w-full"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={t(handleErrorMessage(personal, "state"))}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="state"
              render={({ message }) => (
                <p className="mt-1 text-sm text-red-500">{message}</p>
              )}
            />
          </div>
          <div className="w-full my-5 md:w-1/2 md:my-0 md:mx-2">
            <Controller
              name="apartment"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  id="apartment"
                  name="apartment"
                  error={errors.apartment ? true : false}
                  label={t("personal.apartment")}
                  variant="outlined"
                  className="w-full"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="apartment"
              render={({ message }) => (
                <p className="mt-1 text-sm text-red-500">{message}</p>
              )}
            />
          </div>
        </div>
        <div className="w-full md:flex mb-5">
          <div className="w-full my-5 md:w-1/2 md:my-0 md:mx-2">
            <Controller
              name="country"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("personal.error.country_required"),
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  id="country"
                  name="country"
                  error={errors.country ? true : false}
                  label={`${t("personal.country")} *`}
                  variant="outlined"
                  className="w-full"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={t(handleErrorMessage(personal, "country"))}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="country"
              render={({ message }) => (
                <p className="mt-1 text-sm text-red-500">{message}</p>
              )}
            />
          </div>
          <div className="w-full my-5 md:w-1/2 md:my-0 md:mx-2">
            <Controller
              name="zip_code"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("personal.error.zip_code_required"),
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  id="zip_code"
                  name="zip_code"
                  error={errors.zip_code ? true : false}
                  label={`${t("personal.zip_code")} *`}
                  variant="outlined"
                  className="w-full"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="zip_code"
              render={({ message }) => (
                <p className="mt-1 text-sm text-red-500">{message}</p>
              )}
            />
          </div>
        </div>
        <div className="w-full flex mb-5 mx-2">
          <Controller
            name="about_me"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("personal.error.about_required"),
              },
            }}
            render={({ field }) => (
              <TextField
                id="about_me"
                name="about_me"
                error={errors.about_me ? true : false}
                label={`${t("personal.about_me")} *`}
                multiline
                minRows={4}
                variant="outlined"
                className="w-full"
                InputLabelProps={{
                  shrink: true,
                }}
                {...field}
              />
            )}
          />
        </div>
        <ErrorMessage
          errors={errors}
          name="about_me"
          render={({ message }) => (
            <p className="mt-1 text-sm text-red-500">{message}</p>
          )}
        />
        <div className="flex justify-center mt-10">
          <Button
            status={personal.button_status}
            text={t("personal.save_changes")}
            icon="fa fa-save"
          />
        </div>
      </form>
    </>
  );
}

import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { handleErrorMessage, handleErrorInput } from "../../utils/functions";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "@material-ui/core/Select";
import { saveSkill } from "../../redux/actions/admin/skillActions";
import { getSkillsData } from "../../redux/actions/skillActions";
import { Dialog, DialogContent } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

export default function ModalSkills() {
  const dispatch = useDispatch();
  const skill = useSelector((state) => state.skillsReducers);
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation("common");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [data, setData] = useState({
    skill_test: 1,
  });
  const handleSubmitmodal = (e) => {
    e.preventDefault();
    //se toma el valor del select para enviarlo a la tabla.. por defecto se le indicó que tomara el valor cero o (NO) del select
    data["skill_test"] = document.getElementById("skill_test").value;
    dispatch(saveSkill(data));
    dispatch(getSkillsData());
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(getSkillsData());
  };

  const handleChange = (text) => {
    setData({
      ...data,
      [text.target.name]: text.target.value,
    });
  };

  return (
    <div>
      {
        <button
          type="button"
          variant="outlined"
          className="inline-flex items-center justify-center w-10 h-10 mr-2 p-2 text-green-600 hover:text-white mt-2 hover:bg-green-500 bg-green-100 rounded-lg"
          title={t("skills.add_skill")}
          onClick={handleClickOpen}
        >
          <i className="fa fa-plus"></i>
        </button>
      }
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogContent>
          <>
            <div className="flex justify-end w-full mt-0">
              <svg
                onClick={handleClose}
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer h-5 w-5  border-2 rounded-md hover:bg-gray-200 hover:text-gray-800"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <form action="" className="w-full mx-auto">
              <h1 className="font-bold font-poppins border-b">
                {t("vacancies.modalAddSkill")}
              </h1>
              <p className="font-normal text-xs font-poppins mt-2">
                {t("vacancies.spanAddSkill")}
              </p>
              <div className="w-full mx-2 my-5">
                <TextField
                  error={handleErrorInput(skill, "name")}
                  type="text"
                  id="name"
                  name="name"
                  label={t("personal.first_name")}
                  variant="outlined"
                  onChange={handleChange}
                  className="w-full"
                  helperText={handleErrorMessage(skill, "name")}
                />
              </div>
              <div className="w-full mx-2 my-5">
                <FormControl variant="outlined" className="w-full">
                  <InputLabel htmlFor="skill_test">
                    {t("skills.habilityTest")}
                  </InputLabel>
                  <Select
                    native
                    defaultValue={0}
                    onChange={handleChange}
                    label={t("skills.habilityTest")}
                    inputProps={{
                      name: "skill_test",
                      id: "skill_test",
                    }}
                    disabled
                  >
                    <option value="1">{t("vacancies.Yes")}</option>
                    <option value="0">{t("vacancies.n")}</option>
                  </Select>
                </FormControl>
              </div>
              <div className="w-full mx-2 my-5">
                <TextField
                  error={handleErrorInput(skill, "description")}
                  type="text"
                  id="description"
                  name="description"
                  label={t("experience.description")}
                  variant="outlined"
                  onChange={handleChange}
                  className="w-full"
                  helperText={handleErrorMessage(skill, "description")}
                  multiline
                  minRows={4}
                />
              </div>
              <div className="flex justify-center w-full mt-7">
                <button
                  type="button"
                  className="fa fa-check bg-gray-800 hover:bg-green-600 text-white py-2 px-4 my-4 rounded inline-flex items-center"
                  onClick={handleSubmitmodal}
                >
                  <span className="mx-2 font-poppins font-normal text-sm">
                    {t("experience.addbtn")}
                  </span>
                </button>
              </div>
            </form>
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
}

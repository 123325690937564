const initialState = {
	button: false,
	loaded: false,
	records: [],
	error: false,
	error_message: [],
	success: false
}

export default function portfolioReducers( state = initialState, action) {
	switch(action.type) {
		case 'PF_BUTTON': {
			return {
				...state,
				button: true
			}
		}
		case 'PF_BUTTON_OFF': {
			return {
				...state,
				button: false
			}
		}
		case 'PF_ERROR': {
			return {
				...state,
				button: false,
				error: true,
				error_message: action.payload,
				success: false
			}
		}
		case 'PF_SUCCESS': {
			return {
				...state,
				button: false,
				error: false,
				error_message: [],
				success: true,
				loaded: false,
			}
		}
		case 'PF_SUCCESS_OFF': {
			return {
				...state,
				success: false
			}
		}
		case 'PF_ERROR_OFF': {
			return {
				...state,
				error: false,
				error_message: [],
				button: false
			}
		}
		case 'PF_RECORDS': {
			return {
				...state,
				loaded: true,
				records: action.payload
			}
		}
		case 'PF_RECORDS_EDIT': {
			return {
				...state,
				records: action.payload
			}
		}
		default: return state;
	}
}
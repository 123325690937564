import api from "../../../utils/api";

export const getCurrencies = () => async (dispatch) => {
  try {
    const { data } = await api.get("/currencies");
    dispatch({
      type: "CURRENCY_RECORDS",
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const createCurrency = (currency) => async (dispatch) => {
  try {
    const response = await api.post("/currencies", currency);
    dispatch({
      type: "CURRENCY_STORE",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateCurrency = (currency, uuid) => async (dispatch) => {
  try {
    const response = await api.put(`/currencies/${uuid}`, currency);
    dispatch({
      type: "CURRENCY_UPDATE",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const deleteCurrency = (uuid) => async (dispatch) => {
  try {
    const response = await api.delete(`/currencies/${uuid}`);
    dispatch({
      type: "CURRENCY_DELETE",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

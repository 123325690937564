import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import CustomButton from "../../common/Button";

import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { getSkillsData } from "../../../redux/actions/skillActions";
import { updateQuestion } from "../../../redux/actions/admin/questionActions";

import { SingleError } from "../../common";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function QuestionEditForm({ uuid, preloadedValues }) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const question = useSelector((state) => state.questionReducers);
  const skill = useSelector((state) => state.skillReducers);
  const [isFileBigger, setIsFileBigger] = useState(false);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: preloadedValues,
  });

  useEffect(() => {
    if (!skill.skill.loaded) dispatch(getSkillsData());
  }, [dispatch, skill.skill.loaded]);

  useEffect(() => {
    register("file");

    register("skills_uuid", {
      required: t("vacancies.error.skills"),
    });
  }, [register, t]);

  const onSubmitData = (data) => {
    const { file } = data;

    if (file && file.size / 1024 / 1024 > 1) {
      setIsFileBigger(true);
      return;
    }

    const formData = new FormData();

    Object.entries(data).map(([key, item]) =>
      key !== "skills_uuid"
        ? formData.append(key, item)
        : formData.append("skills_uuid", JSON.stringify(data["skills_uuid"]))
    );

    dispatch(updateQuestion(formData, uuid));
  };

  return (
    <form className="w-1/2 mx-auto" onSubmit={handleSubmit(onSubmitData)}>
      <h1 className="text-xl lg:text-2xl font-semibold title-font text-gray-900">
        {t("question.tooltipEditQuestion")}
      </h1>
      <SingleError
        status={isFileBigger}
        styleParent="m-5 ml-2 w-full mx-auto"
        errors={t("portfolio.error.cv_limit")}
        closer={() => setIsFileBigger(false)}
      />
      <div className="w-full mx-2 my-5">
        <Controller
          name="question"
          control={control}
          rules={{
            required: {
              value: true,
              message: t("question.error.question_required"),
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              id="question"
              name="question"
              label={t("question.question")}
              variant="outlined"
              className="w-full"
              error={errors.question ? true : false}
              multiline
              minRows={4}
              {...field}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="question"
          render={({ message }) => (
            <p className="mt-1 text-sm text-red-500">{message}</p>
          )}
        />
      </div>
      <div className="w-full mx-2 my-5">
        <TextField
          type="file"
          id="file"
          name="file"
          label={t("portfolio.file") + "/" + t("question.img")}
          variant="outlined"
          onChange={(event) => setValue("file", event.target?.files[0])}
          className="w-full"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            accept: ".png,.jpg,.jpeg,.pdf,.docx,.doc,.odt,.odg",
          }}
        />
      </div>
      <div className="w-full mx-2 my-5">
        <Controller
          name="type"
          control={control}
          rules={{
            required: {
              value: true,
            },
          }}
          render={({ field }) => (
            <>
              <FormControl variant="outlined" className="w-full">
                <InputLabel htmlFor="type">{t("references.type")}</InputLabel>
                <Select
                  native
                  label={t("references.type")}
                  inputProps={{
                    name: "type",
                    id: "type",
                  }}
                  {...field}
                >
                  <option value="select">{t("userSkills.select")}</option>
                  <option value="text">{t("userSkills.text")}</option>
                </Select>
              </FormControl>
            </>
          )}
        />
      </div>
      <div className="w-full mx-2 my-5">
        <Controller
          name="language"
          id="language"
          control={control}
          rules={{
            required: {
              value: true,
            },
          }}
          render={({ field }) => (
            <>
              <FormControl variant="outlined" className="w-full">
                <InputLabel htmlFor="type">{t("menu.language")}</InputLabel>
                <Select
                  native
                  label={t("menu.language")}
                  inputProps={{
                    name: "language",
                    id: "language",
                  }}
                  {...field}
                >
                  <option value="en">{t("menu.english")}</option>
                  <option value="es">{t("menu.spanish")}</option>
                </Select>
              </FormControl>
            </>
          )}
        />
      </div>
      <div className="w-full mx-2">
        {skill.skill.loaded && (
          <Autocomplete
            multiple
            id="skills_uuid"
            name="skills_uuid"
            className="w-full"
            options={skill.skill.records}
            defaultValue={preloadedValues.skills_uuid}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(event, options) => {
              setValue("skills_uuid", options);
            }}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={`${t("portfolio.link")} ${t("menu_profile.skills")} (*)`}
                name="skills_uuid"
                id="skills_uuid"
                placeholder={t("skills.select")}
                error={errors.skills_uuid ? true : false}
              />
            )}
          />
        )}
        <ErrorMessage
          errors={errors}
          name="skills_uuid"
          render={({ message }) => (
            <p className="text-sm mt-1 text-red-500">{message}</p>
          )}
        />
      </div>
      <div className="flex justify-center w-full mt-7">
        <CustomButton
          status={question.button}
          text={t("question.update")}
          icon="fa fa-edit"
        />
      </div>
    </form>
  );
}

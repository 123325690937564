import api from "../../../utils/api";

export const getJobs = (language) => async (dispatch) => {
  try {
    const response = await api.get(`/jobs/list/${language}`);
    dispatch({
      type: "JOBS_ALL",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getJobByUuid = (uuid) => async (dispatch) => {
  try {
    const response = await api.get(`/jobs/${uuid}`);
    dispatch({
      type: "JOBS_SINGLE",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getJobsLimit = (limit) => async (dispatch) => {
  try {
    const response = await api.get(`/jobs/list/${JSON.stringify(limit)}`);
    dispatch({
      type: "JOBS_ALL",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateJobs = (data, uuid) => async (dispatch) => {
  dispatch({ type: "JOBS_BUTTON" });
  try {
    const response = await api.put(`/jobs/update/${uuid}`, data);
    dispatch({
      type: "JOBS_UPDATE",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "JOBS_ERROR",
      payload: e.response.data.errors,
    });
  }
};
export const findJobs = (data) => async (dispatch) => {
  try {
    const response = await api.get(`/jobs/find/${JSON.stringify(data)}`);
    dispatch({
      type: "JOBS_ALL",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "JOBS_ERROR",
      payload: e.response.data.errors,
    });
  }
};

export const deleteJobs = (uuid) => async (dispatch) => {
  dispatch({ type: "JOBS_BUTTON" });
  try {
    const response = await api.delete(`/jobs/delete/${uuid}`);
    if (response.status === 200) {
      dispatch({
        type: "JOBS_DELETE",
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: "JOBS_ERROR",
      payload: e.response.data.errors,
    });
  }
};
export const saveJobs = (data) => async (dispatch) => {
  dispatch({ type: "JOBS_BUTTON" });
  try {
    const response = await api.post("/jobs/save", data);
    dispatch({
      type: "JOBS_SAVE",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "JOBS_ERROR",
      payload: e.response.data.errors,
    });
  }
};
export const restartVacant = () => async (dispatch) => {
  dispatch({ type: "JOBS_RESTART" });
};

import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMessages,
  readMessage,
} from "../../../redux/actions/admin/contactActions";
import { useTranslation } from "react-i18next";

import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

// components;
import { Pagination, InputSearch, TableGrid } from "../../common";

export default function QuestionMain() {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const refHandlerRead = useCallback((uuid) => {
    dispatch(readMessage(uuid));
    dispatch({ type: "CONTACT_LOADED_OFF" });
  }, [dispatch]);
  
  const columns = useMemo(
    () => [
      {
        Header: () => t("personal.name"),
        accessor: "fullname",
      },
      {
        Header: () => t("personal.email"),
        accessor: "email",
      },
      {
        Header: () => t("contactus.FormPlaceholderMessage"),
        accessor: "message",
      },
      {
        Header: () => t("clients.status"),
        accessor: "status",
        Cell: ({ row: { original: item } }) => (
          <>
            {item.status_message === "R" ? (
              <div className="inline-block px-4 py-1 cursor-pointer font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-red-100">
                {t("contactus.read")}
              </div>
            ) : (
              <button
                className="inline-block font-bold px-4 py-1 cursor-pointer leading-tight text-yellow-700 bg-yellow-100 rounded-full dark:bg-yellow-700 dark:text-red-100"
                onClick={() => refHandlerRead(item.uuid)}
              >
                {t("contactus.unread")}
              </button>
            )}
          </>
        ),
      },
    ],
    [t, refHandlerRead]
  );

  const rCon = useSelector((state) => state.contactReducers);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    globalFilter,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: rCon.records,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const [value, setValue] = useState(globalFilter);

  const onChangeInputSearch = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  useEffect(() => {
    if (!rCon.loaded) dispatch(getMessages());
  }, [rCon.loaded, dispatch]);

  return (
    <>
      <div className="mt-5">
        <section className="text-gray-600 body-font">
          <div className="container px-5 pt-20 pb-4 mx-auto flex items-center md:flex-row flex-col">
            <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
              <h2 className="text-xs text-blue-500 tracking-widest font-medium title-font mb-2 bg-blue-50 rounded px-2 py-2 w-52">
                <i className="fas fa-inbox mr-4"></i>
                {t("contactus.message_list")}
              </h2>
              <h1 className="text-xl lg:text-2xl font-semibold title-font text-gray-900">
                {t("vacancies.listsmessage")}
              </h1>
            </div>
            <div className="flex flex-col md:flex-row md:ml-auto md:mr-0 mx-auto items-start flex-shrink-0 space-x-4">
              <button
                className="bg-green-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-green-300 focus:outline-none"
                variant="outlined"
                onClick={() => dispatch({ type: "CONTACT_LOADED_OFF" })}
              >
                <i className="fa fa-sync-alt mr-2"></i>
                <span className="ml-4 flex items-start flex-col leading-none">
                  <span className="text-xs text-gray-600 mb-1">
                    {t("list.load")}
                  </span>
                  <span className="title-font font-medium">
                    {t("list.again")}
                  </span>
                </span>
              </button>
            </div>
          </div>
        </section>
      </div>

      <InputSearch
        setValue={setValue}
        onChange={onChangeInputSearch}
        value={value}
      />
      <TableGrid
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        loaded={rCon.loaded}
        getTableBodyProps={getTableBodyProps}
        page={page}
        prepareRow={prepareRow}
      />
      <Pagination
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
}

const initialState = {
	loaded: false,
    data : []
}

export default function stepsReducers (state = initialState, action) {
	switch(action.type) {
		case 'STEP_RECRUITMENT': {
			return {
				...state,
                data:action.payload,
				loaded: true
			}
		}	
		default: return state;
	}
}
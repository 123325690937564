import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import VacantMain from "./VacantMain";
import VacantEdit from "./VacantEdit";
import VacantDelete from "./VacantDelete";
import VacantStore from "./VacantStore";
import VacantApplied from "./VacantApplied";
import AdminRoutes from "../../routes/AdminRoutes";
import Email from "../emails/Email";

export default function Vacant() {
  let { path } = useRouteMatch();

  return (
    <div className="w-11/12 mx-auto">
      <Switch>
        <AdminRoutes exact path={`${path}`} component={VacantMain} />
        <AdminRoutes path={`${path}/edit/:uuid`} component={VacantEdit} />
        <AdminRoutes path={`${path}/delete/:uuid`} component={VacantDelete} />
        <AdminRoutes path={`${path}/add-jobs`} component={VacantStore} />
        <AdminRoutes path={`${path}/list/:id`} component={VacantApplied} />
        <AdminRoutes path={`${path}/notifications`} component={Email} />
      </Switch>
    </div>
  );
}

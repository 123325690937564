import api from '../../../utils/api'

export const getAnswers = (uuid) => async dispatch => {
	try {
		const response = await api.get(`/answers/${uuid}/edit`);
		dispatch({
			type: 'ANSWERS_RECORDS',
			payload: response.data
		})
	} catch (e) {
		console.log(e)
	}
}
export const typetextanswers = (uuid) => async dispatch => {
	try {
		const response = await api.get(`/typetextanswers/${uuid}`);
		
		dispatch({
			type: 'ANSWERS_TYPETEXT',
			payload: response.data
		})
		
	} catch (e) {
		console.log(e)
	}
}
export const saveAnswers = (data) => async dispatch => {
	dispatch({ type: 'ANSWERS_BUTTON' })
	try {
		const response = await api.post('/answers', data);
		dispatch({
			type: 'ANSWERS_SUCCESS',
			payload: response.data
		})
	} catch (e) {
		dispatch({
			type: 'ANSWERS_ERROR',
			payload: e.response.data.errors
		})
	}
}

export const setAsDefault = (question_uuid, uuid) => async dispatch => {
	try {
		let data = {
			question_uuid, uuid
		}
		const response = await api.put('/answers/set/default', data)
		dispatch({
			type: 'ANSWERS_DEFAULT_SUCCESS',
			payload: response.data
		})
	} catch (e) {
		console.log(e)
	}
}

export const deleteAnswers = (uuid) => async dispatch => {
	try {
		const response = await api.delete(`/answers/${uuid}`);
		dispatch({
			type: 'ANSWERS_DELETE',
			payload: response.data
		})
	} catch (e) {
		console.log(e)
	}
}
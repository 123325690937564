import React from "react";
import { Switch, useRouteMatch } from 'react-router-dom'
import List from './List'
import AdminRoutes from '../../routes/AdminRoutes'

export default function Contact() {
	let { path } = useRouteMatch();

   return (
      <div className="w-11/12 mx-auto font-poppins">
			<Switch>
				<AdminRoutes exact path={`${path}`} component={List} />
			</Switch>
      </div>
   );
}
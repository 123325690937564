import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { TextField, Select, InputLabel, FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { Button } from "../../common";
import { updateNotification } from "../../../redux/actions/admin/notificationActions";

export default function EmailEditForm({ preloadedValues, uuid }) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  let history = useHistory();
  const [isInterview, setIsInterview] = useState(false);
  const editorRef = useRef();
  const [editorLoaded, setEditorLoaded] = useState(false);
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      ...preloadedValues,
      title: preloadedValues.title || "",
    },
  });

  const proccessPhases = [
    { name: "apply", description: t("board.email.apply") },
    { name: "makeTest", description: t("board.email.makeTest") },
    { name: "firstInterview", description: t("board.email.firstInterview") },
    { name: "secondInterview", description: t("board.email.secondInterview") },
    { name: "approved", description: t("board.email.approved") },
    { name: "reject", description: t("board.email.reject") },
  ];

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    register("board_name", { required: t("notification.error.stage") });
    register("message", { required: t("notification.error.message") });

    if (preloadedValues.board_name?.includes("Interview")) {
      setIsInterview(true);
    } else {
      setIsInterview(false);
    }
  }, [register, t, preloadedValues.board_name]);

  const onSubmitData = (data) => {
    dispatch(updateNotification(data, uuid));
    history.push("/vacancies/admin/notifications");
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmitData)}
        className="w-1/2 mx-auto mt-10"
      >
        <h1 className="text-xl lg:text-2xl font-semibold title-font text-gray-900">
          {t("notification.edit")}
        </h1>
        <div className="w-full mx-2 my-5">
          <Autocomplete
            id="board_name"
            className="h-1/4 w-full"
            options={proccessPhases}
            getOptionLabel={(option) => option.description}
            defaultValue={proccessPhases.find(
              (phase) => phase.name === preloadedValues.board_name
            )}
            style={{ width: 380 }}
            onChange={(event, value) => {
              if (value) {
                setValue("board_name", value.name);
                value.name.includes("Interview")
                  ? setIsInterview(true)
                  : setIsInterview(false);
              }
            }}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("notification.select_stage")}
                variant="outlined"
                className="w-full"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="board_name"
            render={({ message }) => (
              <div className="text-sm mt-1 text-red-500 font-poppins">
                {message}
              </div>
            )}
          />
        </div>

        <div className="w-full mx-2 my-5">
          <Controller
            name="subject"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("notification.error.subject"),
              },
            }}
            render={({ field }) => (
              <TextField
                id="subject"
                name="subject"
                type="text"
                label={t("notification.subject")}
                variant="outlined"
                className="w-full"
                error={errors.subject ? true : false}
                {...field}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="subject"
            render={({ message }) => (
              <div className="text-sm mt-1 text-red-500 font-poppins">
                {message}
              </div>
            )}
          />
        </div>
        <div className="w-full mx-2 my-5">
          <Controller
            name="title"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("notification.error.title"),
              },
            }}
            render={({ field }) => (
              <TextField
                id="title"
                name="title"
                type="text"
                label={t("notification.title")}
                variant="outlined"
                className="w-full"
                error={errors.title ? true : false}
                {...field}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="title"
            render={({ message }) => (
              <div className="text-sm mt-1 text-red-500 font-poppins">
                {message}
              </div>
            )}
          />
        </div>
        <div className="w-full mx-2 my-5">
          <div>
            <div>{t("notification.variables_list")}</div>
            <div className="mt-2 mb-4">
              <ul className="list-disc ml-5">
                <li>{`${t("notification.variables.name.legend")}: ${t(
                  "notification.variables.name.label"
                )}`}</li>
                <li>{`${t("notification.variables.job.legend")}: ${t(
                  "notification.variables.job.label"
                )}`}</li>
                {isInterview && (
                  <>
                    <li>{`${t("notification.variables.interview.legend")}: ${t(
                      "notification.variables.interview.label"
                    )}`}</li>
                    <ul className="list-disc ml-5">
                      <li>{`${t("notification.variables.interview.from")}: ${t(
                        "notification.variables.interview.from_label"
                      )}`}</li>
                      <li>{`${t("notification.variables.interview.to")}: ${t(
                        "notification.variables.interview.to_label"
                      )}`}</li>
                      <li>{`${t("notification.sender")}: ${t(
                        "notification.variables.interview.sender"
                      )}`}</li>
                    </ul>
                  </>
                )}
              </ul>
              <p className="mt-2">{t("notification.variables_description")}</p>
            </div>
          </div>
          {editorLoaded && (
            <div className="relative flex-grow w-full mt-6">
              <InputLabel htmlFor="message" className="mb-2">
                {t("notification.message")}
              </InputLabel>
              <CKEditor
                id="message"
                name="message"
                editor={ClassicEditor}
                onReady={(editor) => {
                  if (preloadedValues.message)
                    editor.setData(preloadedValues.message);
                }}
                data="<p></p>"
                error={errors.message ? true : false}
                onChange={(event, editor) => {
                  setValue("message", editor.getData());
                }}
              />
            </div>
          )}
          <ErrorMessage
            errors={errors}
            name="message"
            render={({ message }) => (
              <div className="text-sm mt-1 text-red-500 font-poppins">
                {message}
              </div>
            )}
          />
        </div>
        <div className="w-full mx-2 my-5">
          <Controller
            name="status"
            control={control}
            rules={{
              required: {
                value: true,
              },
            }}
            render={({ field }) => (
              <>
                <FormControl variant="outlined" className="w-full">
                  <InputLabel htmlFor="status">
                    {t("notification.status")}
                  </InputLabel>
                  <Select
                    native
                    label={t("notification.status")}
                    inputProps={{
                      name: "status",
                      id: "status",
                    }}
                    {...field}
                    onChange={(event) =>
                      field.onChange(parseInt(event.target.value))
                    }
                  >
                    <option value={1}>{t("notification.default_email")}</option>
                    <option value={0}>
                      {t("notification.no_default_email")}
                    </option>
                  </Select>
                </FormControl>
              </>
            )}
          />
          <ErrorMessage
            errors={errors}
            name="status"
            render={({ message }) => (
              <div className="text-sm mt-1 text-red-500 font-poppins">
                {message}
              </div>
            )}
          />
        </div>
        <div className="w-full mx-2 my-5">
          <Controller
            name="language"
            id="language"
            control={control}
            rules={{
              required: {
                value: true,
              },
            }}
            render={({ field }) => (
              <>
                <FormControl variant="outlined" className="w-full">
                  <InputLabel htmlFor="type">{t("menu.language")}</InputLabel>
                  <Select
                    native
                    label={t("menu.language")}
                    inputProps={{
                      name: "language",
                      id: "language",
                    }}
                    {...field}
                  >
                    <option value="en">{t("menu.english")}</option>
                    <option value="es">{t("menu.spanish")}</option>
                  </Select>
                </FormControl>
              </>
            )}
          />
          <ErrorMessage
            errors={errors}
            name="language"
            render={({ message }) => (
              <div className="text-sm mt-1 text-red-500 font-poppins">
                {message}
              </div>
            )}
          />
        </div>
        <div className="flex justify-center w-full mt-7">
          <Button
            status={false}
            text={t("question.update")}
            icon="fa fa-edit"
          />
        </div>
      </form>
    </>
  );
}

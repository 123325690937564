import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import {
  verifyRoleAccess,
  verifyPermissions,
  logoutAction,
} from "./redux/actions/loginActions";
import { useDispatch, useSelector } from "react-redux";

import SessionTimeOut from "./components/SessionTimeOut";
import DropDrag from "./components/DropDrag";
import Main from "./components/common/Main";
import Home from "./components/pages/Home";
import ContactUs from "./components/pages/ContactUs";
import Profile from "./components/user/Profile";
import Results from "./components/admin/users/Results";
import Login from "./components/pages/Login";
import AboutUs from "./components/pages/AboutUs";
import PrivPolicy from "./components/pages/PrivPolicy";
import TermsCondition from "./components/pages/TermsCondition";
import HiringProcess from "./components/pages/HiringProcess";
import HomeJobs from "./components/pages/HomeJobs";
import Vacancies from "./components/pages/Vacancies";
import VacanciesDetail from "./components/pages/VacanciesDetail";
import Jobs from "./components/pages/Jobs";
import RegisterVacancies from "./components/pages/RegisterVacancies";
import Register from "./components/pages/Register";
import RouteLogin from "./components/routes/RouteLogin";
import AdminRoutes from "./components/routes/AdminRoutes";
import Admin from "./components/admin/index";
import AdminUsers from "./components/admin/users/";
import UserProfile from "./components/admin/users/Profile";
import ListUsersSkills from "./components/admin/reports/ListUsersSkills";
import Tests from "./components/admin/questions/Questions";
import Skills from "./components/admin/skills/Skills";
import Vacant from "./components/admin/vacancies/Vacant";
import Contact from "./components/admin/contact/Contact";
import Clients from "./components/admin/clients/Client";
import Dashboard from "./components/admin/Dashboard";

import Forgot_password from "./components/pages/Forgot_password";
import ResetPassword from "./components/pages/ResetPassword";
import Page_404 from "./components/pages/Page_404";
import QuestionFreq from "./components/pages/QuestionFreq";
import Roles from "./components/admin/roles/Roles";
import Currencies from "./components/admin/currencies/Currencies";
import TestResultsPrint from "./components/admin/users/TestResultsPrint";
import ListQuestionsPrint from "./components/admin/questions/ListQuestionsPrint";
// import LoaderSpinner from "./components/common/LoaderSpinner";

function App() {
  const dispatch = useDispatch();
  const logg = useSelector((state) => state.mainReducers);
  const expirationTime = parseInt(localStorage.getItem("expires_in")) * 1000;

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      dispatch(verifyRoleAccess()); //esto verifica el token de acceso, pero lo hacía sin verificar previamente si éste existe. en loginActions.js se destruye esa variable del local storage al cerrar sesión
      dispatch(verifyPermissions());
    }
  }, [dispatch]);

  const logOut = (e) => {
    dispatch(logoutAction());
  };

  // window.onbeforeunload = function (e) {
  //   e.preventDefault();
  //   if (logg.status && e && e.currentTarget.scrollX === 0 && e.currentTarget.scrollY === 0) {
  //    dispatch(logoutAction());
  //   }
  // };

  return (
    <BrowserRouter>
      {/* <LoaderSpinner /> */}
      <Main>
        <Switch>
          <Route exact path="/home" component={Home} />
          <Route path="/Forgot" component={Forgot_password} />
          <Route path="/reset/:token" component={ResetPassword} />
          <Route path="/error404" component={Page_404} />
          <Route path="/contactus" component={ContactUs} />
          <Route path="/question" component={QuestionFreq} />
          <Route path="/aboutus" component={AboutUs} />
          <Route path="/privacy" component={PrivPolicy} />
          <Route path="/terms" component={TermsCondition} />
          <Route path="/hiring/process" component={HiringProcess} />
          <Route
            path="/info/vacancies/detail/:uuid"
            component={VacanciesDetail}
          />
          <Route path="/info/vacancies" component={Vacancies} />
          <Route path="/jobs" component={Jobs} />
          <Route exact path="/" component={HomeJobs} />
          <Route
            path="/register/vacancies/:uuid?"
            component={RegisterVacancies}
          />
          <Route path="/register/" component={Register} />
          <Route path="/login" component={Login} />
          <Route path="/dropDrag/:id?/:name?" component={DropDrag} />
          <RouteLogin path="/profile" component={Profile} />
          <AdminRoutes exact path="/admin" component={Admin} />
          <AdminRoutes exact path="/admin/users/" component={AdminUsers} />
          <AdminRoutes path="/admin/users/list" component={ListUsersSkills} />
          <AdminRoutes
            path="/admin/users/profile/:uuid/:applied?/:jobsId?"
            component={UserProfile}
          />
          <AdminRoutes path="/tests/admin/list/question/skill/:uuid" component={ListQuestionsPrint} />
          <AdminRoutes path="/tests/admin" component={Tests} />
          <AdminRoutes path="/skills/admin" component={Skills} />
          <AdminRoutes path="/admin/users/result/:uuid" component={Results} />
          <AdminRoutes path="/admin/users/print-results/:uuid" component={TestResultsPrint} />
          <AdminRoutes path="/contact/admin" component={Contact} />
          <AdminRoutes path="/vacancies/admin" component={Vacant} />
          <AdminRoutes path="/clients/admin" component={Clients} />
          <AdminRoutes path="/roles/admin" component={Roles} />
          <AdminRoutes path="/currencies/admin" component={Currencies} />
          <AdminRoutes path="/dashboard/admin" component={Dashboard} />
          <Route path="" component={Page_404} />
        </Switch>
      </Main>
      {logg.status && (
        <SessionTimeOut
          isAuthenticated={true}
          logOut={logOut}
          expirationTime={expirationTime}
        />
      )}
    </BrowserRouter>
  );
}

export default App;

import React, { useState, useRef, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { handleErrorMessage, handleErrorInput } from "../../../utils/functions";
//import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Checkbox from "@material-ui/core/Checkbox";
import CustomButton from "../../common/Button";
import { saveAnswers } from "../../../redux/actions/admin/answerActions";
import { useDispatch } from "react-redux";
import {useTranslation} from "react-i18next";


export default function AnswerForm(props) {
   const { t } = useTranslation('common');
   const { answers, uuid } = props;
   const dispatch = useDispatch();
   const formRef = useRef();
   const [data, setData] = useState({
      //result: 0,
      question_uuid: uuid,
		answer: ""
   });

	useEffect(() => {
		dispatch({ type: 'ANSWERS_RESTART' })
	}, [dispatch])

   const handleChange = (e) => {
      setData({
         ...data,
         [e.target.name]: e.target.value,
      });
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      var formData = new FormData();
      var imagefile = document.querySelector("#file");
      formData.append("file", imagefile.files[0]);
      formData.append("answer", data.answer);
      //formData.append("result", data.result);
		formData.append("question_uuid", data.question_uuid);
      dispatch(saveAnswers(formData));
   };

   useEffect(() => {
      if (answers.success) {
         formRef.current.reset();
         //if (data.result) setData({ result: false, question_uuid: uuid });
      }
   }, [data.result, answers.success, uuid])

   return (
      <>
         <form
            action=""
            className="flex justify-start items-start mt-5 w-full"
            onSubmit={handleSubmit}
            ref={formRef}
         >
            <div className="w-4/12 mx-2">
               <TextField
                  error={handleErrorInput(answers, "answer")}
                  type="text"
                  id="answer"
                  name="answer"
                  label={t('question.answer')}
                  variant="outlined"
                  onChange={handleChange}
                  className="w-full"
                  helperText={handleErrorMessage(answers, "answer")}
                  multiline
                  minRows={4}
               />
            </div>
            <div className="w-4/12 flex flex-col">
               <div className="w-full mx-2">
                  <TextField
                     error={handleErrorInput(answers, "file")}
                     type="file"
                     id="file"
                     name="file"
                     label={t('portfolio.file')+"/"+t('question.img')}
                     variant="outlined"
                     onChange={handleChange}
                     className="w-full"
                     helperText={handleErrorMessage(answers, "file")}
                     InputLabelProps={{
                        shrink: true,
                     }}
                  />
               </div>
               <div className="flex mt-4 items-center">
                  {/*<div className="mx-2">
                     <FormControlLabel
                        control={
                           <Checkbox
                              id="result"
                              onChange={() =>
                                 setData({ ...data, result: !data.result })
                              }
                              name="result"
                              color="primary"
                              value={data.result}
                              checked={data.result}
                           />
                        }
                        label="Is correct?"
                     ></FormControlLabel>
                  </div>*/}
                  <div className="mx-2">
                     <CustomButton
                        status={answers.button}
                        text={t('experience.addbtn')}
                        icon="fa fa-check"
                     />
                  </div>
               </div>
               {/*<div className="text-red-500 text-sm">
                  {answers.error &&
                     answers.error_message.result &&
                     answers.error_message.result[0]}
               </div>*/}
            </div>
         </form>
      </>
   );
}

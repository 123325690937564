import { oauth } from "../../utils/functions";
import axios from "axios";
import api from "../../utils/api";

export const loginAction = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${oauth.url}/token`, {
      grant_type: oauth.type,
      client_id: oauth.id,
      client_secret: oauth.secret,
      username: data.email,
      password: data.password,
      scope: oauth.scope,
    });
    localStorage.setItem("token_type", response.data.token_type);
    localStorage.setItem("access_token", response.data.access_token);
    localStorage.setItem("refresh_token", response.data.refresh_token);
    localStorage.setItem("expires_in", "3600");
    window.location.href = "/dashboard/admin";
  } catch (error) {
    //console.log(error.response.data.hint)
    dispatch({ type: "USER_LOGINERROR", payload: "personal.error.login" });
  }
};

export const logoutAction = () => async (dispatch) => {
  localStorage.removeItem("token_type");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("expires_in");
  window.location.href = "/";
};

export const verifyPermissions = () => async (dispatch) => {
  try {
    const { data } = await api.get("/users/verify/permissions");

    dispatch({
      type: "LOGIN_PERMISSIONS_VERIFIED",
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const verifyRoleAccess = () => async (dispatch) => {
  try {
    const response = await api.get("/users/verify/role");
    dispatch({
      type: "LOGIN_ROLE_VERIFIED",
      payload: {
        user: response.data.user,
        status: response.data.status,
        mode: response.data.mode,
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendComment = (data) => async (dispatch) => {
  try {
    const response = await api.post("/contact", data);
    if (response.status === 200) {
      dispatch({
        type: "SENT_COMMENT",
      });
    }
  } catch (e) {
    dispatch({
      type: "SENT_COMMENT_ERROR",
      payload: e.response.data.errors,
    });
  }
};

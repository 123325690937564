import api from "../../utils/api";

export const getPersonalInfo = () => async (dispatch) => {
  try {
    const response = await api.get("/users/get/personal");
    dispatch({
      type: "PERSONAL_RECORDS",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const savePersonalChanges = (data) => async (dispatch) => {
  dispatch({ type: "PERSONAL_BUTTON" });
  dispatch({
    type: "PERSONAL_RELOAD",
  });

  try {
    const response = await api.put("/users/save/personal", data);
    dispatch({
      type: "PERSONAL_SUCCESS",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "PERSONAL_ERROR",
      payload: e.response.data.errors,
    });
  }
};

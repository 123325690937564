import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Link, Redirect, useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { useDispatch, useSelector } from "react-redux";
import { handleErrorMessage, handleErrorInput } from "../../../utils/functions";
import CustomButton from "../../common/Button";
import {
    getJobs,
    deleteJobs
} from "../../../redux/actions/admin/jobActions";
import { useTranslation } from "react-i18next";

export default function VacantDelete() {
    const { t, i18n } = useTranslation('common');
    const dispatch = useDispatch();
    const [filterRecords, setFilterRecords] = useState(undefined);
    const job = useSelector((state) => state.jobsReducers);
    let { uuid } = useParams();

    useEffect(() => {
        if (!job.loaded) dispatch(getJobs(i18n.language));
    }, [dispatch, job.loaded, i18n.language]);

    useEffect(() => {
        if (job.loaded) {
            const results = job.data.filter((item) => item.uuid === uuid);
            setFilterRecords(results[0]);
        }
    }, [job.loaded, job.data, uuid, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(deleteJobs(uuid));
    };
    return (
        <>
            {job.redirect && <Redirect to="/vacancies/admin" />}

            <div className="mt-5 flex justify-end">
                <Link to="/vacancies/admin">
                    <Button className="focus:outline-none" variant="outlined">
                        <i className="fa fa-arrow-left mr-2"></i> {t('personal.back')}
                    </Button>
                </Link>
            </div>
            {filterRecords !== undefined && (
                <form action="" className="w-1/2 mx-auto" onSubmit={handleSubmit}>
                    <div className="flex lg:w-full sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-6 ">

                        <div className="relative flex-grow w-full">

                            <TextField
                                error={handleErrorInput(job, "name_job")}
                                type="text"
                                id="name_job"
                                label={t('vacancies.position')}
                                name="name_job"
                                variant="outlined"
                                className="w-full"
                                helperText={handleErrorMessage(job, "name_job")}
                                defaultValue={filterRecords.name_job} disabled
                            />
                        </div>
                        <div className="relative flex-grow w-full">
                            <TextField
                                error={handleErrorInput(job, "report_job")}
                                type="text"
                                id="report_job"
                                label={t('vacancies.reportsTo')}
                                name="report_job"
                                variant="outlined"
                                className="w-full"
                                helperText={handleErrorMessage(job, "report_job")}
                                defaultValue={filterRecords.report_job} disabled
                            />
                        </div>
                    </div>
                    <div className="flex lg:w-full sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-6 ">

                        <div className="relative flex-grow w-full">
                            <TextField
                                error={handleErrorInput(job, "salary")}
                                type="text"
                                id="salary"
                                label={t('vacancies.salary')}
                                name="salary"
                                variant="outlined"
                                className="w-full"
                                helperText={handleErrorMessage(job, "salary")}
                                defaultValue={filterRecords.salary} disabled
                            />
                        </div>

                        <div className="relative flex-grow w-full">
                            <FormControl variant="outlined" className="w-full">
                                <InputLabel htmlFor="type_job">{t('vacancies.priority')}</InputLabel>
                                <Select
                                    native
                                    defaultValue={filterRecords.priority_job}
                                    label={t('vacancies.priority')}
                                    inputProps={{
                                        name: "priority_job",
                                        id: "priority_job",
                                    }} disabled
                                >
                                    <option value="Urgent">{t('vacancies.urgent')}</option>
                                    <option value="Normal">{t('vacancies.normal')}</option>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="flex lg:w-full sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-6 ">

                        <div className="relative flex-grow w-full">
                            <FormControl variant="outlined" className="w-full">
                                <InputLabel htmlFor="type_job">{t('vacancies.journal')}</InputLabel>
                                <Select
                                    native
                                    defaultValue={filterRecords.type_job}
                                    label={t('vacancies.journal')}
                                    inputProps={{
                                        name: "type_job",
                                        id: "type_job",
                                    }} disabled
                                >
                                    <option value="PartTime">{t('vacancies.partTime')}</option>
                                    <option value="FullTime">{t('vacancies.fullTime')}</option>
                                </Select>
                            </FormControl>
                        </div>

                        <div className="relative flex-grow w-full">
                            <FormControl variant="outlined" className="w-full">
                                <InputLabel htmlFor="location">{t('vacancies.typeJob')}</InputLabel>
                                <Select
                                    native
                                    defaultValue={filterRecords.location}
                                    label={t('vacancies.typeJob')}
                                    inputProps={{
                                        name: "location",
                                        id: "location",
                                    }} disabled
                                >
                                    <option value="Remote">{t('vacancies.remoto')}</option>
                                    <option value="Present">{t('vacancies.presential')}</option>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="flex lg:w-full sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-6 ">
                        <div className="relative flex-grow w-full">
                            <TextField
                                type="text"
                                id="cant_vacant"
                                label={t('vacancies.cantVacant')}
                                name="cant_vacant"
                                variant="outlined"
                                className="w-full"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={handleErrorInput(job, "cant_vacant")}
                                helperText={t(handleErrorMessage(job, "cant_vacant"))}
                                defaultValue={filterRecords.cant_vacant}
                                disabled
                            />
                        </div>
                        <div className="relative flex-grow w-full mt-6">
                            <TextField
                                error={handleErrorInput(job, "ubication")}
                                type="text"
                                id="ubication"
                                label={t('vacancies.ubication')}
                                name="ubication"
                                variant="outlined"
                                className="w-full"
                                helperText={handleErrorMessage(job, "ubication")}
                                defaultValue={filterRecords.ubication} disabled
                            />
                        </div>
                    </div>
                    <div className="flex lg:w-full sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-6 ">
                        <div className="relative flex-grow w-full">
                            <TextField
                                type="text"
                                id="client_id"
                                label={t('clients.clientAsoc')}
                                name="client_id"
                                variant="outlined"
                                className="w-full"
                                defaultValue={filterRecords.name_client}
                                disabled
                            />
                        </div>

                        <div className="relative flex-grow w-full mt-6">
                            <FormControl variant="outlined" className="w-full">
                                <InputLabel
                                    htmlFor="status_job"
                                >
                                    {t('clients.status')}
                                </InputLabel>
                                <Select
                                    native
                                    defaultValue={filterRecords.status_job}
                                    label={t('clients.status')}
                                    inputProps={{
                                        name: "status_job",
                                        id: "status_job",
                                    }} disabled
                                >
                                    <option value="A">{t('clients.active')}</option>
                                    <option value="I">{t('clients.inactive')}</option>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="relative flex-grow w-full mt-6">
                        <TextField
                            error={handleErrorInput(job, "description_job")}
                            type="text"
                            id="description_job"
                            name="description_job"
                            variant="outlined"
                            defaultValue={filterRecords.description_job}
                            label={t("vacancies.positionSumary")}
                            className="w-full"
                            helperText={handleErrorMessage(job, "description_job")}
                            multiline
                            minRows={4} disabled
                        />
                    </div>
                    <div className="flex lg:w-full sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-6 ">
                        <div className="relative flex-grow w-full">
                            <TextField
                                error={handleErrorInput(job, "esential_job")}
                                type="text"
                                id="esential_job"
                                name="esential_job"
                                variant="outlined"
                                label={t("vacancies.esentialJob")}
                                defaultValue={filterRecords.esential_job}
                                className="w-full"
                                helperText={handleErrorMessage(job, "esential_job")}
                                multiline
                                minRows={4} disabled
                            />
                        </div>
                        <div className="w-full mx-2 my-5">
                            <TextField
                                error={handleErrorInput(job, "know_skills")}
                                type="text"
                                id="know_skills"
                                name="know_skills"
                                variant="outlined"
                                label={t("vacancies.knowSkills")}
                                defaultValue={filterRecords.know_skills}
                                className="w-full"
                                helperText={handleErrorMessage(job, "know_skills")}
                                multiline
                                minRows={4} disabled
                            />
                        </div>
                    </div>
                    <div className="flex lg:w-full sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-6 ">
                        <div className="relative flex-grow w-full">
                            <TextField
                                error={handleErrorInput(job, "quality_min_job")}
                                type="text"
                                id="quality_min_job"
                                name="quality_min_job"
                                variant="outlined"
                                label={t("vacancies.minQuality")}
                                defaultValue={filterRecords.quality_min_job}
                                className="w-full"
                                helperText={handleErrorMessage(job, "quality_min_job")}
                                multiline
                                minRows={4} disabled
                            />
                        </div>
                        <div className="relative flex-grow w-full">
                            <TextField
                                error={handleErrorInput(job, "prefered_job")}
                                type="text"
                                id="prefered_job"
                                name="prefered_job"
                                variant="outlined"
                                label={t("vacancies.preferedJobs")}
                                defaultValue={filterRecords.prefered_job}
                                className="w-full"
                                helperText={handleErrorMessage(job, "prefered_job")}
                                multiline
                                minRows={4} disabled
                            />
                        </div>
                    </div>

                    <div className="relative flex-grow w-full mt-6">
                        <TextField
                            error={handleErrorInput(job, "work_conditions_job")}
                            type="text"
                            id="work_conditions_job"
                            name="work_conditions_job"
                            variant="outlined"
                            label={t("vacancies.workConditions")}
                            defaultValue={filterRecords.work_conditions_job}
                            className="w-full"
                            helperText={handleErrorMessage(job, "work_conditions_job")}
                            multiline
                            minRows={4} disabled
                        />
                    </div>
                    {filterRecords.num_applied > 0 ?
                        <div>
                            <div className="flex justify-center w-full mt-7">
                                    <button disabled={true}
                                        className={` font-bold py-2 px-4 rounded justify-center inline-flex items-center mt-10 mb-4 bg-gray-800 text-white opacity-50 cursor-default`}>

                                        <i className="fa fa-trash"></i>
                                        <span className="mx-2"> {t('question.delete')}  </span>
                                    </button>                       
                            </div>
                            <div className="flex justify-center">
                                <label className="w-full text-center text-gray-800 font-semibold italic">
                                        {t('vacancies.noDelete')}
                                    </label>
                            </div>
                        </div>
                        :
                        <div className="flex justify-center w-full mt-7">
                            <CustomButton
                                status={job.button}
                                text={t('question.delete')}
                                icon="fa fa-trash"
                            />
                        </div>
                    }
                </form>
            )}
        </>
    );
}

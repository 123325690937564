import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  Font,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf",
      fontWeight: "semibold",
    },
  ],
});

const stylesPDF = StyleSheet.create({
  pageWrapper: {
    width: "95%",
    marginHorizontal: "auto",
    fontSize: 10,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  skillColumn: {
    width: "20%",
  },
  columnTable: {
    width: "40%",
  },
  contentRow: {
    borderWidth: 1,
    height: "100%",
    borderColor: "#e5e7eb",
  },
  contentPadding: {
    padding: 8,
  },
  centeredContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  testExcellent: {
    color: "#3b82f6",
  },
  headerTable: {
    fontWeight: "bold",
    textAlign: "center",
    borderWidth: 1,
    borderColor: "#9ca3af",
    backgroundColor: "#f3f4f6",
    padding: 8,
  },
  pageNumberView: { position: "relative", paddingTop: 80 },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 16,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default function ListQuestionsPdf({ listQuestions, skillUuid, t }) {
  return (
    <Document>
      <Page size="A4">
        <View style={stylesPDF.pageWrapper}>
          <View fixed style={[stylesPDF.row, { marginTop: 20 }]}>
            <View style={stylesPDF.skillColumn}>
              <Text style={stylesPDF.headerTable}>{t("skills.skill")}</Text>
            </View>
            <View style={stylesPDF.columnTable}>
              <Text style={stylesPDF.headerTable}>
                {t("question.question")}
              </Text>
            </View>
            <View style={stylesPDF.columnTable}>
              <Text style={stylesPDF.headerTable}>{t("question.answer")}</Text>
            </View>
          </View>
          {Object.entries(listQuestions).map(([key, item]) => (
            <View style={[stylesPDF.row, { minHeight: 80 }]} key={key}>
              <View
                break
                style={[
                  stylesPDF.skillColumn,
                  stylesPDF.contentRow,
                  stylesPDF.centeredContent,
                ]}
              >
                {item.question_skills &&
                  item.question_skills.length > 0 &&
                  Object.entries(item.question_skills).map(
                    ([key2, item2]) =>
                      item2.skill.uuid === skillUuid && (
                        <Text key={key2} style={stylesPDF.contentPadding}>
                          {item2.skill.name}
                        </Text>
                      )
                  )}
              </View>
              <View
                style={[
                  stylesPDF.columnTable,
                  stylesPDF.contentRow,
                  stylesPDF.centeredContent,
                  {
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text style={[stylesPDF.contentPadding, { marginBottom: 8 }]}>
                  {item.question}
                </Text>
                {item.image && (
                  <View
                    style={[
                      stylesPDF.centeredContent,
                      { maxWidth: 500, maxHeight: 500 },
                    ]}
                  >
                    <Image
                      style={{
                        marginTop: 4,
                        marginHorizontal: "auto",
                      }}
                      src={`${process.env.REACT_APP_BASE_URL}` + item.image}
                    />
                  </View>
                )}
              </View>
              <View
                style={[
                  stylesPDF.columnTable,
                  stylesPDF.contentRow,
                  {
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                  },
                ]}
              >
                {item.answer && item.answer.length > 0 && (
                  <View style={[stylesPDF.contentPadding, { paddingTop: 0 }]}>
                    {Object.entries(item.answer).map(([key2, item2]) => (
                      <View key={key2}>
                        <Text
                          style={{
                            marginVertical: 16,
                            color: item2.result ? "#10b981" : "#000",
                          }}
                        >
                          - {item2.answer}
                        </Text>
                      </View>
                    ))}
                  </View>
                )}
              </View>
            </View>
          ))}
        </View>

        <View fixed style={stylesPDF.pageNumberView}>
          <Text
            style={stylesPDF.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  );
}

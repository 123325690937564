import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import CurrenciesMain from "./CurrenciesMain";
import AdminRoutes from "../../routes/AdminRoutes";

export default function Currencies() {
  let { path } = useRouteMatch();

  return (
    <div className="w-11/12 mx-auto">
      <Switch>
        <AdminRoutes exact path={`${path}`} component={CurrenciesMain} />
      </Switch>
    </div>
  );
}

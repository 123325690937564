import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { getUserProfile } from "../../../redux/actions/admin/usersActions";
import { emailApply } from "../../../redux/actions/emailActions";

export default function UserJobs(props) {
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const email = useSelector((state) => state.emailReducers);
  const { t, i18n } = useTranslation("common");
  const { records, vacancies, user } = props;
  const [isSuggestSent, setIsSuggestSent] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);

  const handleApply = async () => {
    if (data.uuid) {
      await dispatch(getUserProfile(user.uuid));
      dispatch(
        emailApply({
          lang: i18n.language,
          name: user.name,
          email: user.email,
          job: data.name_job,
          uuid: data.uuid,
          url: `${window.location.origin}/info/vacancies/detail/${data.uuid}`,
        })
      );
      setButtonStatus(true);
    }
  };

  useEffect(() => {
    if (email.loaded) {
      setIsSuggestSent(true);
      setButtonStatus(false);
      setTimeout(() => {
        dispatch({
          type: "EMAIL_OFF"
        })
        setIsSuggestSent(false);
      }, 3000);
    } else {
    }
  }, [dispatch, email.loaded]);

  return (
    <>
      <div className="w-full userInfoTable font-poppins text-sm">
        <div className="w-full flex lg:w-full sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-5">
          <div className="w-2/3 mt-5">
            <Autocomplete
              id="vacants"
              name="vacants"
              options={vacancies}
              getOptionLabel={(option) => option.name_job}
              onChange={(event, value) => setData(value)}
              getOptionSelected={(option, value) => option.uuid === value.uuid}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("vacancies.listjobs")}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>
          <div className="w-1/3 relative flex-grow w-full">
            <button
              onClick={() => handleApply()}
              className={`className="font-poppins border-2 border-red-500 bg-red-500 hover:bg-red-600 text-white hover:text-white  inline-flex items-center text-xs lg:text-base font-normal py-2 px-4 mt-8 mb-2 rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 
                            "bg-red-500 hover:bg-red-600 text-white hover:text-white"                         
                        ${buttonStatus ? "opacity-50 cursor-default pointer-events-none" : ""}`}
            >
              <span className="font-poppins">{t("vacancies.suggest_job")}</span>
              {!buttonStatus ? (
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              ) : (
                <i className="ml-3 fa fa-spin fa-spinner"></i>
              )}
            </button>
            {isSuggestSent && (
              <div>
                <label className="w-full font-poppins bg-blue-100 rounded-full border-blue-500 text-blue-800 font-semibold italic">
                  {t("vacancies.suggest_sent")}
                </label>
              </div>
            )}
          </div>
        </div>

        <table className="w-full userInfoTable font-poppins text-sm mt-5">
          <thead>
            <tr>
              <th>{t("vacancies.position")}</th>
              <th>{t("vacancies.journal")}</th>
              <th>{t("vacancies.dateApply")}</th>
            </tr>
          </thead>

          <tbody>
            {records !== undefined && records.length > 0 ? (
              Object.entries(records).map(([key, item]) => (
                <tr key={key}>
                  <td>{item.jobs !== null && item.jobs.name_job}</td>
                  <td>
                    {item.jobs !== null &&
                      (item.jobs.type_job === "FullTime" ? (
                        <span className="inline-block px-2 py-1 font-semibold leading-tight text-blue-700 bg-blue-100 rounded-full dark:bg-green-700 dark:text-red-100">
                          {t("vacancies." + item.jobs.type_job)}
                        </span>
                      ) : (
                        <span className="inline-block px-2 py-1 font-semibold leading-tight text-yellow-700 bg-yellow-100 rounded-full dark:bg-green-700 dark:text-red-100">
                          {t("vacancies." + item.jobs.type_job)}
                        </span>
                      ))}
                  </td>
                  <td>
                    {new Date(item.created_at).toISOString().slice(0, 10)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" style={{ textAlign: "center" }}>
                  {t("portfolio.norecords")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

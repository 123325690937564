import React, { useState, useEffect, useMemo } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  filterSkills,
  countSkillQuestions,
  restartState,
} from "../../../redux/actions/admin/skillActions";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";

import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

import { Pagination, InputSearch, TableGrid, Success } from "../../common";

export default function SkillsMain() {
  let { url } = useRouteMatch();
  const { t, i18n } = useTranslation("common");

  const columns = useMemo(
    () => [
      {
        Header: () => t("skills.skill_name"),
        accessor: "name",
      },
      {
        Header: () => t("experience.description"),
        accessor: "description",
        Cell: ({ value: description }) => (
          <span className="capitalize">{description}</span>
        ),
      },
      {
        Header: () => t("skills.habilityTest"),
        accessor: "skill_test",
        Cell: ({ value: skill_test }) => (
          <>
            {skill_test === 1 ? (
              <span className="border-2 border-blue-500 bg-blue-500 text-white font-semibold py-1.5 px-2 lg:inline-block block m-2 lg:rounded-lg md:rounded-lg">
                {t("vacancies.Yes")}
              </span>
            ) : (
              <span className="border-2 border-gray-500 bg-gray-500 text-white font-semibold py-1.5 px-2 lg:inline-block block m-2 lg:rounded-lg md:rounded-lg">
                {t("vacancies.n")}
              </span>
            )}
          </>
        ),
      },
      //cantidad de preguntas de esa habilidad
      {
        Header: () => t("skills.countquestions"),
        accessor: "count_questions",
        Cell: ({ value: description }) => (
          <span className="capitalize">{description}</span>
        ),
      },
      {
        Header: () => t("skills.actions_test"),
        accessor: "uuid",
        Cell: ({ value: uuid }) => (
          <div className="flex justify-start text-sm">
            <Link
              to={`${url}/edit/${uuid}`}
              className="my-1 h-10 border-2 border-yellow-500 hover:bg-yellow-500 text-yellow-500 hover:text-white font-semibold  mx-1 py-2 px-2 rounded-lg"
              title={t("skills.tooltipEditSkill")}
            >
              <svg
                className="h-4 w-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                ></path>
              </svg>
            </Link>
            <Link
              to={`${url}/delete/${uuid}`}
              className="my-1 h-10 border-2 border-red-500  hover:bg-red-500 text-red-500 hover:text-white font-bold mx-1 py-2 px-2 rounded-lg"
              title={t("skills.tooltipDeleteSkill")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </Link>
            {/* se crea el link para nueva pregunta */}

            <Link
              target={"_blank"}
              to={{
                pathname: `/tests/admin/add-question/${uuid}`,
              }}
              className="my-1 h-10 border-2 border-green-500  hover:bg-green-500 text-green-500 hover:text-white font-bold mx-1 py-2 px-2 rounded-lg"
              title={t("skills.tooltipQuestionSkill")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                />
              </svg>
            </Link>
          </div>
        ),
      },
    ],
    [t, url]
  );

  const skill = useSelector((state) => state.skillsReducers);
  const [filterRecords, setFilterRecords] = useState(skill.records);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    globalFilter,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filterRecords,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const [value, setValue] = useState(globalFilter);

  const onChangeInputSearch = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(restartState());
  }, [dispatch]);

  useEffect(() => {
    if (!skill.loaded) {
      dispatch(countSkillQuestions(i18n.language));
    } else {
      setFilterRecords(skill.records);
    }
  }, [dispatch, skill.loaded, skill.records, i18n.language]);

  useEffect(() => {
    if (skill.search?.length && skill.loaded) {
      setFilterRecords(skill.search);
    }
  }, [skill]);

  useEffect(() => {
    dispatch({
      type: "SKILLS_LOADED_OFF",
    });
  }, [dispatch, i18n.language]);

  const resetTable = () => {
    setFilterRecords(skill.records);
  };

  return (
    <>
      <div className="mt-5">
        <section className="text-gray-600 body-font">
          <div className="container px-5 pt-20 pb-4 mx-auto flex items-center md:flex-row flex-col">
            <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
              <h2 className="text-xs text-blue-500 tracking-widest font-medium title-font mb-2 bg-blue-50 rounded px-2 py-2 w-52">
                <i className="mr-4 fas fa-brain"></i>
                {t("vacancies.listspan")}
              </h2>
              <h1 className="text-xl lg:text-2xl font-semibold title-font text-gray-900">
                {t("vacancies.listskills")}
              </h1>
            </div>
            <div className="flex flex-col md:flex-row md:ml-auto md:mr-0 mx-auto items-start flex-shrink-0 space-x-4">
              <Autocomplete
                id="combo-box-demo"
                options={skill.records}
                getOptionLabel={(option) => option.name}
                style={{ width: 300 }}
                onChange={(event, value) =>
                  value ? dispatch(filterSkills(value.uuid, i18n.language)) : resetTable()
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("skills.select")}
                    variant="outlined"
                  />
                )}
              />
              <Link to={`${url}/add-skill`} className="mr-2">
                <button className="bg-blue-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-blue-300 focus:outline-none">
                  <i className="fas fa-plus"></i>
                  <span className="ml-4 flex items-start flex-col leading-none">
                    <span className="text-xs text-gray-600 mb-1">
                      {t("skills.add_skill")}
                    </span>
                    <span className="title-font font-medium">
                      {t("skills.new_skill")}
                    </span>
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </section>
      </div>

      <Success
        status={skill.success}
        styleParent="mt-5"
        message={skill.success_message}
        closer="SKILLS_SUCCESS_OFF"
      />

      <InputSearch
        setValue={setValue}
        onChange={onChangeInputSearch}
        value={value}
      />
      <TableGrid
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        loaded={skill.loaded}
        getTableBodyProps={getTableBodyProps}
        page={page}
        prepareRow={prepareRow}
      />
      <Pagination
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
}

const initialState = {
	loaded: false,
    data : [],
    modal : [],
    detail : [],
	error: false,
	error_message: [],
	list_user:[],
	open_modal: false,
}

export default function jobReducers(state = initialState, action) {
	switch(action.type) {
		case 'USER_JOBS_APPLY': {
			return {
				...state,
                data:action.payload,
				loaded: true
			}
		}
		case 'USER_MODAL': { 
			return {
				...state,
                modal:action.payload,
				loaded: true
			}
		}		
		case 'USER_JOBS_DETAIL': { 
			return {
				...state,
                detail:action.payload,
				loaded: true
			}
		}		
		case 'USER_APPLY_ERROR': {
			return {
				...state,
				error: true,
				error_message: action.payload,
			}
		}
		case 'HANDLE_MODAL': {
			return {
				...state,
				open_modal: action.payload
			}
		}
	
		default: return state;
	}
}

import React from "react";
import { useTranslation } from "react-i18next";

export default function UserReferences(props) {
   const { t } = useTranslation('common');
   const { records } = props;

   return (
      <table className="w-full userInfoTable font-poppins text-sm">
         <thead>
            <tr>
               <th>{t('references.fullname')}</th>
               <th>{t('references.address')}</th>
               <th>{t('references.phone')}</th>
               <th>{t('references.type')}</th>               
            </tr>
         </thead>
         <tbody>
            {records.length > 0 ?
               Object.entries(records).map(([key, item]) => (
                  <tr key={key}>
                     <td>{item.fullname}</td>
                     <td>{item.address}</td>
                     <td>{item.phone}</td>
                     <td className="capitalize">{item.reference}</td>
                  </tr>
               ))
               :
               <tr>
                  <td colSpan="8" style={{ textAlign: 'center' }}>{t('portfolio.norecords')}</td>
               </tr>
            }
         </tbody>
      </table>
   );
}

import React from "react";
import { Switch, useRouteMatch } from 'react-router-dom'
import ClientMain from './ClientMain'
import ClientEdit from './ClientEdit'
import ClientDelete from './ClientDelete'
import ClientStore from './ClientStore'
import AdminRoutes from '../../routes/AdminRoutes'

export default function Client() {
	let { path } = useRouteMatch();

   return (
      <div className="w-11/12 mx-auto font-poppins">
			<Switch>
				<AdminRoutes exact path={`${path}`} component={ClientMain} />
				<AdminRoutes path={`${path}/edit/:uuid`} component={ClientEdit} />
				<AdminRoutes path={`${path}/delete/:uuid`} component={ClientDelete} />
				<AdminRoutes path={`${path}/add-clients`} component={ClientStore} />
			</Switch>
      </div>
   );
}
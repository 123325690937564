import api from "../../utils/api";

export const getPortfolio = (uuid) => async (dispatch) => {
   try {
      const response = await api.get("/portfolio");
      if (response.status === 200) {
         dispatch({
            type: "PF_RECORDS",
            payload: response.data,
         });
      }
   } catch (e) {
      dispatch({
         type: "PF_ERROR",
         payload: e.response.data.errors,
      });
   }
};

export const savePortfolio = (data) => async (dispatch) => {
   dispatch({ type: "PF_BUTTON" });
   try {
      const response = await api.post("/portfolio", data, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      });
      if (response.status === 200) {
         dispatch({
            type: "PF_SUCCESS",
         });
      }
   } catch (e) {
      dispatch({
         type: "PF_ERROR",
         payload: e.response.data.errors,
      });
   }
};

export const deletePortfolio = (uuid) => async (dispatch) => {
   const response = await api.delete(`/portfolio/${uuid}`);
   if (response.status === 200) {
      dispatch({
         type: "PF_SUCCESS",
      });
   }
};

export const portfolioEdit = (uuid) => async dispatch => {
   try {
      const response = await api.get(`/portfolio/${uuid}`);
      dispatch({
         type: "REF_RECORDS_EDIT",
         payload: response.data,
      });
   } catch (e) {
      console.log(e)
   }
};

export const updatePortfolio = (data, uuid) => async (dispatch) => {
   try {
      const response = await api.post(`/editPortfolio/${uuid}`, data, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      });
      if (response.status === 200) {
         dispatch({
            type: "PF_SUCCESS",
         });
      }
   } catch (e) {
      console.log(e)
   }
};

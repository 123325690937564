import api from "../../../utils/api";

export const getRoles = () => async (dispatch) => {
  try {
    const { data } = await api.get("/roles");
    dispatch({
      type: "ROLE_RECORDS",
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const createRole = (role) => async (dispatch) => {
  try {
    const response = await api.post("/roles", role);
    dispatch({
      type: "ROLE_STORE",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateRole = (role, uuid) => async (dispatch) => {
  try {
    const response = await api.put(`/roles/${uuid}`, role);
    dispatch({
      type: "ROLE_UPDATE",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const deleteRole = (uuid) => async (dispatch) => {
  try {
    const response = await api.delete(`/roles/${uuid}`);
    dispatch({
      type: "ROLE_DELETE",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getModules = () => async (dispatch) => {
  try {
    const response = await api.get(`/modules`);
    dispatch({
      type: "ROLE_MODULES",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getPermissionByModuleId = (data) => async (dispatch) => {
  try {
    const response = await api.post(`/roles/permissions/module`, data);
    dispatch({
      type: "ROLE_PERMISSION_MODULE",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const managePermissions = (uuid) => async (dispatch) => {
  try {
    const response = await api.get(`/roles/permissions/${uuid}`);
    dispatch({
      type: "ROLE_PERMISSIONS",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const updatePermissions = (uuid, data) => async (dispatch) => {
  try {
    const response = await api.put(`/roles/permissions/${uuid}`, data);
    dispatch({
      type: "ROLE_UPDATE_PERMISSIONS",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link, Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {
  getAnswers,
  setAsDefault,
} from "../../../redux/actions/admin/answerActions";
import AnswerForm from "./AnswerForm";
import { getQuestions } from "../../../redux/actions/admin/questionActions";
import { typetextanswers } from "../../../redux/actions/admin/answerActions";
import AnswerDelete from "./AnswerDelete";
import ShowAreas from "../questions/ShowAreas";
import { useTranslation } from "react-i18next";

import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

import { Pagination, InputSearch, TableGrid } from "../../common";

export default function AnswersMain() {
  let { uuid } = useParams();
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  
  const columns = useMemo(
    () => [
      {
        Header: () => t("question.answer"),
        accessor: "answer",
      },
      {
        Header: () => t("userSkills.result"),
        accessor: "result",
        Cell: ({ value: result }) => (
          <>
            {result ? (
              <i className="fa fa-check text-green-500"></i>
            ) : (
              <i className="fa fa-times text-red-600"></i>
            )}
          </>
        ),
      },
      {
        Header: () => <i className="fa fa-ellipsis-h"></i>,
        accessor: "default",
        Cell: ({ row: { original: item } }) => (
          <>
            {!item.result && (
              <>
                <button
                  className="btn_delete my-1 h-10 border-2 border-red-500  hover:bg-red-500 text-red-500 hover:text-white font-bold mx-1 py-2 px-2 rounded-lg"
                  onClick={() =>
                    setModal({
                      isOpen: true,
                      itemToRead: item.uuid,
                    })
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </button>
                <button
                  className="bg-yellow-100 text-yellow-800 border border-yellow-300 py-1 px-2 hover:bg-yellow-200"
                  onClick={() => dispatch(setAsDefault(uuid, item.uuid))}
                >
                  {t("question.setCorrect")}
                </button>
              </>
            )}
          </>
        ),
      },
    ],
    [dispatch, t, uuid]
  );

  const answers = useSelector((state) => state.answerReducers);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    globalFilter,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: answers.records,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const [value, setValue] = useState(globalFilter);

  const onChangeInputSearch = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const questions = useSelector((state) => state.questionReducers);
  const type = useSelector((state) => state.answerReducers);
  const [loaded, setLoaded] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(undefined);
  const [modal, setModal] = useState({
    isOpen: false,
    itemToRead: "",
  });

  useEffect(() => {
    if (!questions.loaded) dispatch(getQuestions());
  }, [dispatch, questions.loaded]);

  useEffect(() => {
    if (questions.loaded) {
      const result = Object.values(questions.records).filter(
        (item) => item.uuid === uuid
      );
      setCurrentQuestion(result[0]);
    }
  }, [questions.loaded, questions.records, uuid]);

  useEffect(() => {
    dispatch(getAnswers(uuid));
    dispatch(typetextanswers(uuid));
    setTimeout(() => {
      setLoaded(true);
    }, 200);
  }, [dispatch, uuid]);

  return (
    <>
      {type.type === "text" && <Redirect to={`/tests/admin/`} />}

      <AnswerDelete
        isOpen={modal.isOpen}
        currentItem={modal.itemToRead}
        propCloseModal={() => setModal({ isOpen: false, itemToRead: "" })}
      />
      <div className="mt-5 flex justify-end">
        <Link to="/tests/admin">
          <Button className="focus:outline-none" variant="outlined">
            <i className="fa fa-arrow-left mr-2"></i> {t("personal.back")}
          </Button>
        </Link>
      </div>
      {currentQuestion !== undefined && (
        <div className="w-8/12 mx-2 bg-blue-50 p-3 rounded border border-blue-200 text-blue-800 font-semibold">
          <ShowAreas skills={currentQuestion.question_skills} /> -{" "}
          {currentQuestion.question}
        </div>
      )}
      <AnswerForm answers={answers} uuid={uuid} />

      <InputSearch
        setValue={setValue}
        onChange={onChangeInputSearch}
        value={value}
      />
      <TableGrid
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        loaded={loaded}
        getTableBodyProps={getTableBodyProps}
        page={page}
        prepareRow={prepareRow}
      />
      <Pagination
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
}

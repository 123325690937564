import React from "react";

export default function CustomButton(props) {
  const { status, text, icon,disabledbutton,id } = props;  
  
  return (
    <>
      {status ? (
         <button
        
         className={`disabled  bg-black text-white font-bold py-2 px-4 rounded inline-flex items-center`}
         
       >
          <i className="fa fa-spin fa-spinner mr-2"></i> 
         <span className="mx-2 font-poppins font-normal text-sm"> Loading  </span>
         
       </button>
         
        
      ) : (
        <button
          type="submit"
          className={` ${icon} bg-gray-800 hover:bg-green-600 text-white py-2 px-4 my-4 rounded inline-flex items-center`}                    
          disabled={disabledbutton}
          id={id}              
        >
          <span className="mx-2 font-poppins font-normal text-sm"> {text}  </span>          
        </button>
      )}
    </>
  );    
 
}

import React from "react";

export default function InputSearch({
  setValue,
  onChange,
  value,
  handleSearch,
}) {
  return (
    <>
      <div className="relative text-gray-600 focus-within:text-gray-400 mt-2">        
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
          <button
            type="submit"
            onClick={handleSearch && handleSearch}
            className="p-1 focus:outline-none focus:shadow-outline"
          >
            <svg
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              className="w-6 h-6"
            >
              <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </button>
        </span>
        <input
          type="search"
          value={value || ""}
          onChange={(e) => {            
            setValue(e.target.value);
            onChange && onChange(e.target.value);
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter" && handleSearch) {
              handleSearch();
            }
          }}
          name="q"
          className="py-2 text-sm text-black bg-gray-200 rounded-md pl-10 focus:outline-none focus:bg-white  "
          placeholder="Search..."
        />
      </div>
    </>
  );
}

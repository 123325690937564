import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReferences } from "../../redux/actions/referencesActions";
import Table from './Table'
import FormAdd from './FormAdd'
import {useTranslation} from "react-i18next";

export default function Professional() {
   const dispatch = useDispatch();
   const ref = useSelector((state) => state.referencesReducers);
   const [showFormAdd, setShowFormAdd] = useState(false);
	const { t } = useTranslation('common');
	
   useEffect(() => {
      if (!ref.loaded) dispatch(getReferences());
   }, [dispatch, ref.loaded]);

   const openFormAdd = () => {
      setShowFormAdd(!showFormAdd);
      dispatch({ type: "REF_SUCCESS_OFF" });
		dispatch({ type: 'REF_ERROR_OFF' })
   };

   const closeFormAdd = () => {
      setShowFormAdd(false);
      dispatch({ type: "REF_SUCCESS_OFF" });
		dispatch({ type: 'REF_ERROR_OFF' })
   };

   return (
      <>
      <div className="flex flex-col flex-1 w-full h-full pb-16 overflow-y-auto container grid px-6 mx-auto">
         <h1 className="mt-10 text-2xl font-semibold font-poppins text-black dark:text-gray-200">{t('references.title')} </h1>
         <div className="flex justify-between items-end mb-4">
               <h4 className="mt-2 mb-3 font-poppins inline-block text-sm">{t('references.your_reference')}</h4>
          </div>
         <div>
            <button
               className=" flex items-center justify-between py-2 px-4 mb-4 text-sm font-medium leading-5 font-poppins text-gray-700 hover:text-white bg-gray-200 transition-colors duration-150 border-2 border-gray-700  border-transparent rounded-md active:bg-red-600 hover:bg-gray-600 focus:outline-none focus:shadow-outline-black float-right"
               onClick={openFormAdd}
            >
               <i
                  className={`fa ${
                     showFormAdd ? "fa-times" : "fa-people-arrows"
                  } fa-1x mr-2`}
               ></i>{" "}
               {showFormAdd ? t('references.close') : t('references.add') }
            </button>
         </div>
         <FormAdd
            status={showFormAdd}
            closeFormAdd={closeFormAdd}
         />
         {!showFormAdd && <Table />}
      </div>
      </>
   );
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getVacant } from "../../redux/actions/jobActions";
import { Link, useParams } from "react-router-dom";
import { verifyLogin } from "../../utils/functions";
import { getApplyJobs } from "../../redux/actions/jobActions";
import Footer from "../admin/footer";
import { emailApplicant } from "../../redux/actions/emailActions";
import { getPersonalInfo } from "../../redux/actions/personalActions";


export default function VacanciesDetail(props) {
	const dispatch = useDispatch();
	const jobsSpecific = useSelector((state) => state.jobReducers);
	const personal = useSelector((state) => state.personalReducers);
	const { t, i18n } = useTranslation('common');
	const [applyJob, setApplyJob] = useState(false);
	const [disable, setDisable] = useState(false);
	const isLogged = verifyLogin();
	let { uuid } = useParams();
	const token = localStorage.getItem("access_token");

	useEffect(() => {
		dispatch(getVacant(uuid));
	}, [dispatch, uuid]);

	useEffect(() => {
    if (!personal.loaded && token) dispatch(getPersonalInfo());
  }, [personal.loaded, token, dispatch]);

	const handleApply = () => {		
		dispatch(getApplyJobs(uuid, personal.records[0].id));
		dispatch(
      emailApplicant({
        lang: i18n.language,
        stage: "apply",
        name: personal.records[0].name,
        email: personal.records[0].email,
        job: jobsSpecific.data[0].name_job,
        greeting: t('notification.greeting'),
        vacant: t('notification.vacant'),
        description: t('notification.description'),
        noreply: t('notification.noreply'),
      })
    );
		setApplyJob(true)
		setDisable(true)
	};

	return (
		<>
			{jobsSpecific.loaded && jobsSpecific.data.length > 0 &&
				<div>
					<div className="flex-inline justify-between px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8"></div>
					<div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-40 lg:pt-10 pb-8">
						<h2>
							<span className="relative font-bold text-2xl">
								{jobsSpecific.data[0].name_job}
							</span>
							{/* {" "}
						<button className="inline-flex items-center justify-center w-8 h-8  transition-colors duration-150 border-2 border-blue-500 rounded-full text-blue-500 focus:shadow-outline hover:bg-blue-100">
							<i className="far fa-heart"></i>
						</button>
						{" "}
						<button className="inline-flex items-center justify-center w-8 h-8 transition-colors duration-150 border-2 border-blue-500 rounded-full text-blue-500 focus:shadow-outline hover:bg-blue-100">
							<i className="far fa-bookmark"></i>
						</button> */}
							{" "}
							{/* {copy && 
								<label className="w-full bg-blue-100 rounded-full ml-2 px-2 py-1 border-blue-500 text-blue-800 font-semibold italic">
									{t('vacancies.linkCopy')}
								</label>
							} */}
						</h2>
						<h2 className="text-base text-gray-700 md:text-md">
							{jobsSpecific.data[0].report_job}
						</h2>
						<div className="mt-1 space-x-2 text-gray-500 text-sm">

							{(jobsSpecific.data[0].type_job === "FullTime") ?
								<span
									className="inline-block px-2 py-1 font-semibold leading-tight text-blue-700 bg-blue-100 rounded-full dark:bg-green-700 dark:text-red-100">
									{jobsSpecific.data[0].type_job}
								</span>
								:
								<span
									className="inline-block px-2 py-1 font-semibold leading-tight text-yellow-700 bg-yellow-100 rounded-full dark:bg-green-700 dark:text-red-100">
									{jobsSpecific.data[0].type_job}
								</span>
							}
							<i className="fas fa-map-marker-alt"></i>
							<span className="mt-1 space-x-2 text-gray-500 text-sm">	{t("vacancies." + jobsSpecific.data[0].location)} {jobsSpecific.data[0].ubication ? ("-" + jobsSpecific.data[0].ubication) : ""} </span>
							<i className="far fa-calendar-alt"></i>
							<span className="mt-1 space-x-2 text-gray-500 text-sm">{t('vacancies.public').replace(/%d/, jobsSpecific.data[0].dias)}</span>
						</div>
					</div>
					<div className="grid max-w-screen-lg  sm:mx-auto vacancies">
						<div className="flex flex-col justify-center mx-20">
							<div className="pb-4 mb-4 border-b">
								<h6 className="mb-2 font-semibold leading-5">
									{t('vacancies.positionSumary')}{":"}
								</h6>
								<p className="text-sm text-justify text-gray-900" dangerouslySetInnerHTML={{ __html: jobsSpecific.data[0].description_job }}>
								</p>
							</div>
							<div className="pb-4 mb-4 border-b">
								<h6 className="mb-2 font-semibold leading-5">
									{t('vacancies.esentialJob')}{":"}
								</h6>
								<div className="list-decimal text-sm text-gray-900" dangerouslySetInnerHTML={{ __html: jobsSpecific.data[0].esential_job }}>
								</div>
							</div>
							<div className="pb-4 mb-4 border-b">
								<h6 className="mb-2 font-semibold leading-5">
									{t('vacancies.knowSkills')}{":"}
								</h6>
								<div className="list-disc text-sm text-gray-900" dangerouslySetInnerHTML={{ __html: jobsSpecific.data[0].know_skills }}>
								</div>
							</div>
							<div className="pb-4 mb-4 border-b">
								<h6 className="mb-2 font-semibold leading-5">
									{t('vacancies.qualityJob')}
								</h6>
								<p className="mb-2 text-sm underline">{t('vacancies.minQuality')}{":"}</p>
								<div className="list-disc text-sm text-gray-900" dangerouslySetInnerHTML={{ __html: jobsSpecific.data[0].quality_min_job }}>
								</div>
								<p className="my-2 text-sm underline">{t('vacancies.preferedJobs')}{":"}</p>
								<div className="list-disc text-sm text-gray-900" dangerouslySetInnerHTML={{ __html: jobsSpecific.data[0].prefered_job }} >
								</div>
							</div>
							<div className="pb-4 mb-20 ">
								<h6 className="mb-2 font-semibold">
									{t('vacancies.workConditions')}{":"}
								</h6>
								<p className="text-sm italic text-gray-900">
									{jobsSpecific.data[0].work_conditions_job}
								</p>
								{isLogged ?
									<button
										disabled={disable}
										onClick={() => handleApply()}
										className={` font-bold py-2 px-4 rounded inline-flex items-center mt-10 mb-4 bg-gray-800 text-white ${!disable ? "hover:bg-green-300 hover:text-green-900" : "opacity-50 cursor-default"}`}>
										<i className="mx-2 fas fa-file-import"></i>
										<span>{t('vacancies.applyJob')}</span>
									</button>
									:
									<Link to={`/register/vacancies/${jobsSpecific.data[0].uuid}`}>
										<button className="hover:bg-green-300 bg-gray-800 hover:text-green-900 text-white font-bold py-2 px-4 rounded inline-flex items-center mt-10 mb-4">
											<i className="mx-2 fas fa-file-import"></i>
											<span>{t('vacancies.applyJob')}</span>
										</button>
									</Link>
								}
								{isLogged && applyJob &&
									<div>
										<label className="w-full bg-blue-100 rounded-full border-blue-500 text-blue-800 font-semibold italic">
											{jobsSpecific.apply && jobsSpecific.apply[0].apply_job === "new_apply" && t('vacancies.applyYour')}
											{jobsSpecific.apply && jobsSpecific.apply[0].apply_job === "old_apply" && t('vacancies.applyOld')}
										</label>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			}
			{!jobsSpecific.loaded &&
				<div className="flex justify-center mt-24 mb-32">
					<i className="fa fa-spin fa-spinner fa-2x"></i>
				</div>
			}
			<Footer />
		</>
	);
}

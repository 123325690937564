import api from '../../utils/api';

export const forgotEmail = (data) =>  async (dispatch) => {
	try {
		const response = await api.post('/forgot',data);
		dispatch({
			type: 'EMAIL_RESPONSE',
			payload: response.data
		})
	} catch(e) {
		console.log(e)
	}
}
export const afterEmail = (token) =>  async (dispatch) => {
	try {
		const response = await api.get(`/users/reset/${token}`);
		dispatch({
			type: 'EMAIL_RESPONSE',
			payload: response.data
		})
	} catch(e) {
		dispatch({
			type: "EMAIL_ERROR",
			payload: e.response.data.errors,
		 });
	}
}
export const newPass = (data) =>  async (dispatch) => {
	try {
		const response = await api.post('/users/newPassword',data);
		dispatch({
			type: 'EMAIL_RESPONSE',
			payload: response.data
		})
	} catch(e) {
		console.log(e)
	}
}
export const emailApply = (data) =>  async (dispatch) => {
	try {
		const response = await api.post('/jobs/email',data);
		dispatch({
			type: 'EMAIL_RESPONSE',
			payload: response.data
		})
	} catch(e) {
		console.log(e)
	}
}

export const emailApplicant = (data) =>  async (dispatch) => {
	try {
		const response = await api.post('/jobs/email/applicant', data);
		dispatch({
			type: 'EMAIL_RESPONSE',
			payload: response.data
		})
		dispatch({
			type: 'NOTIFICATION_SEND_SUCCESS'
		})
		dispatch({
			type: "NOTIFICATION_TEMPLATES_OFF"
		})
	} catch(e) {
		console.log(e)
	}
}
 


import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getRoles } from "../../../redux/actions/admin/roleActions";
import { updateUserRole } from "../../../redux/actions/admin/usersActions";

export default function RoleModal({
  open,
  handleClose,
  roleData: { role_id, uuid: uuid_user },
}) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [newRole, setNewRole] = useState(0);
  const role = useSelector((state) => state.roleReducers);
  const [option, setOption] = useState({});

  useEffect(() => {
    if (!role.loaded) dispatch(getRoles());
    else setOption(role.records.find(({ id }) => id === role_id));
  }, [dispatch, role, role_id]);

  const handleChange = (selectedRole) => {
    setNewRole(selectedRole);
  };

  const handleSubmit = () => {
    if (newRole) dispatch(updateUserRole({ new_role_id: newRole, uuid_user }));
    handleClose();
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth={true}>
      <div className="flex justify-end mt-2 mr-2">
        <button onClick={() => handleClose()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer h-5 w-5  border-2 rounded-md hover:bg-gray-200 hover:text-gray-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <DialogTitle className="border-b text-center">
        <span className="font-bold font-poppins">{t("role.edit")}</span>
      </DialogTitle>
      <DialogContent>
        {role.loaded && option?.name && (
          <div className="w-full pr-3">
            <div className="w-full flex justify-center mx-2 my-5">
              <Autocomplete
                id="vacant"
                className="h-1/4 w-full"
                options={role.records}
                getOptionLabel={(option) => option.name}
                style={{ width: 380 }}
                defaultValue={option}
                onChange={(event, value) => value && handleChange(value.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("role.select")}
                    variant="outlined"
                    className="w-full"
                    size="small"
                  />
                )}
              />
            </div>
            <div className="w-full flex justify-center">
              <button
                onClick={handleSubmit}
                className="font-poppins bg-black hover:bg-green-600 text-sm text-white py-2 px-4 my-4 rounded"
              >
                <i className="fa fa-edit mr-2"></i>
                <span>{t("question.update")}</span>
              </button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

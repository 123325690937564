import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Link, Redirect, useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { handleErrorMessage } from "../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../common/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {
  getSkillByUuid,
  updateSkill,
} from "../../../redux/actions/admin/skillActions";
import { useTranslation } from "react-i18next";

import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { SingleError } from "../../common";

export default function SkillsEdit() {
  let { uuid } = useParams();
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const skill = useSelector((state) => state.skillsReducers);
  const [loadedValues, setLoadedValues] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (!skill.record_loaded) dispatch(getSkillByUuid(uuid, i18n.language));
    else {
      setValue("name", skill.record.name);
      setValue("skill_test", skill.record.skill_test);
      setValue("description", skill.record.description || "");
      setLoadedValues(true);
    }
  }, [dispatch, skill.record_loaded, skill.record, uuid, setValue, i18n.language]);

  const onSubmitData = (data) => {
    dispatch(updateSkill(data, uuid));
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: "SKILLS_RECORD_OFF",
      });
    };
  }, [dispatch]);

  return (
    <>
      {skill.redirect && <Redirect to="/skills/admin" />}

      {skill.record_loaded && loadedValues ? (
        <>
          <div className="mt-5 flex justify-end">
            <Link to="/skills/admin">
              <Button className="focus:outline-none" variant="outlined">
                <i className="fa fa-arrow-left mr-2"></i> {t("personal.back")}
              </Button>
            </Link>
          </div>
          <form className="w-1/2 mx-auto" onSubmit={handleSubmit(onSubmitData)}>
            <h1 className="text-xl lg:text-2xl font-semibold title-font text-gray-900">
              {t("skills.tooltipEditSkill")}
            </h1>
            <SingleError
              status={skill.error}
              styleParent="m-5 ml-2 w-full mx-auto"
              errors={t(skill.error_message)}
              closer={"SKILLS_RESTART"}
            />
            <div className="w-full mx-2 my-5">
              <Controller
                name="name"
                id="name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("skills.error.skillname_required"),
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#]{3,}$/,
                    message: t("skills.error.skill_invalid"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="name"
                    name="name"
                    label={t("skills.skill_name")}
                    variant="outlined"
                    className="w-full"
                    error={errors.name ? true : false}
                    helperText={t(handleErrorMessage(skill, "name"))}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                    onChange={(event) => field.onChange(event.target.value)}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => (
                  <p className="mt-1 text-sm text-red-500">{message}</p>
                )}
              />
            </div>
            <div className="w-full mx-2 my-5">
              <Controller
                name="skill_test"
                control={control}
                rules={{
                  required: {
                    value: true,
                  },
                }}
                render={({ field }) => (
                  <>
                    <FormControl variant="outlined" className="w-full">
                      <InputLabel htmlFor="skill_test">
                        {t("skills.habilityTest")}
                      </InputLabel>
                      <Select
                        native
                        label={t("skills.habilityTest")}
                        inputProps={{
                          name: "skill_test",
                          id: "skill_test",
                        }}
                        {...field}
                        onChange={(event) =>
                          field.onChange(parseInt(event.target.value))
                        }
                      >
                        <option value="1">{t("vacancies.Yes")}</option>
                        <option value="0">{t("vacancies.n")}</option>
                      </Select>
                    </FormControl>
                  </>
                )}
              />
            </div>
            <div className="w-full mx-2 my-5">
              <Controller
                name="description"
                id="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="text"
                    multiline
                    minRows={4}
                    id="description"
                    name="description"
                    label={t("experience.description")}
                    variant="outlined"
                    className="w-full"
                    error={errors.question ? true : false}
                    helperText={t(handleErrorMessage(skill, "description"))}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                    onChange={(event) => field.onChange(event.target.value)}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <p className="mt-1 text-sm text-red-500">{message}</p>
                )}
              />
            </div>
            <div className="flex justify-center w-full mt-7">
              <CustomButton
                status={skill.button}
                text={t("question.update")}
                icon="fa fa-edit"
              />
            </div>
          </form>
        </>
      ) : (
        <div className="justify-center">
          <div className="text-center mt-60 p-4 overflow-x-auto">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAnswers } from '../../../redux/actions/admin/answerActions'
import { useTranslation } from "react-i18next";


export default function AnswerDelete(props) {
   let { isOpen, currentItem, propCloseModal } = props;
   const { t } = useTranslation('common');
   const dispatch = useDispatch();
   const answer = useSelector((state) => state.answerReducers);
   const [effectBG, setEffectBG] = useState("fadeIn");
   const [effectModal, setEffectModal] = useState("fadeInDown");
	const [result, setResult] = useState(undefined)

	const closeModal = () => {
      setEffectBG("fadeOut");
      setEffectModal("fadeOutUp");
      setTimeout(() => {
         propCloseModal();
         setEffectBG("fadeIn");
         setEffectModal("fadeInDown");
			setResult(undefined)
      }, 200);
   };

	useEffect( () => {
		const filter_result = Object.values(answer.records).filter( item => item.uuid === currentItem );
		setResult(filter_result[0]);
	},[currentItem, answer.records])

   const handleDelete = () => {
      dispatch(deleteAnswers(currentItem))
		closeModal()
   };

   return (
      <>
         {isOpen && result !== undefined && (
            <>
               <div
                  className={`fixed bg-black w-full h-full top-0 left-0 z-20 w-full animate__animated animate__${effectBG} animate__faster`}
                  style={{ backgroundColor: "rgba(0,0,0,.5)" }}
                  onClick={closeModal}
               ></div>
               <div
                  className={`fixed top-0 left-0 mt-64 w-4/12 bg-white z-30 animate__animated animate__${effectModal} animate__faster`}
                  style={{ marginLeft: "33%" }}
               >
                  <div className="border border-red-400 p-2 bg-red-200 font-bold">
                     {t('question.deleteAnswer')}
                  </div>
                  <div className="p-3">
                     {t('question.sureDelete')}{" "}
                     <span className="font-semibold">{result.answer}</span>
                  </div>
                  <div className="flex justify-between p-5">
                     <button
                        className="bg-yellow-500 text-white p-2 rounded focus:outline-none"
                        onClick={closeModal}
                     >
                        {t('question.noCancel')}
                     </button>
                     <button
                        className="bg-red-500 text-white p-2 rounded focus:outline-none"
                        onClick={handleDelete}
                     >
                        Yes, delete!
                     </button>
                  </div>
               </div>
            </>
         )}
      </>
   );
}

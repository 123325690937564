import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Link, Redirect, useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { handleErrorInput } from "../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../common/Button";
import {
    getClients,
    deleteClients
} from "../../../redux/actions/admin/clientActions";
import { useTranslation } from "react-i18next";


export default function ClientEdit() {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const clients = useSelector((state) => state.clientsReducers);
    const [filterRecords, setFilterRecords] = useState(undefined);
    const [data, setData] = useState({});
    let { uuid } = useParams();

    useEffect(() => {
        if (!clients.loaded) dispatch(getClients());
    }, [dispatch, clients.loaded]);

    useEffect(() => {
        if (clients.loaded) {
            const results = clients.data.filter((item) => item.uuid === uuid);
            setFilterRecords(results[0]);
        }
    }, [clients.loaded, clients.data, uuid]);

    const handleChange = (text) => {
        setData({
            ...data,
            [text.target.name]: text.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(deleteClients(uuid));
    };

    return (
        <>
            {clients.redirect && <Redirect to="/clients/admin" />}

            <div className="mt-5 flex justify-end">
                <Link to="/clients/admin">
                    <Button className="focus:outline-none" variant="outlined">
                        <i className="fa fa-arrow-left mr-2"></i> {t('personal.back')}
                    </Button>
                </Link>
            </div>
            {filterRecords !== undefined && (
                <form action="" className="w-1/2 mx-auto" onSubmit={handleSubmit}>
                    <div className="w-full mx-2 my-5">
                        <TextField
                            error={handleErrorInput(clients, "name_clients")}
                            type="text"
                            id="name_clients"
                            name="name_clients"
                            label={t('personal.name')}
                            variant="outlined"
                            onChange={handleChange}
                            className="w-full"
                            defaultValue={filterRecords.name_clients}
                            disabled
                        />
                    </div>
                    <div className="w-full mx-2 my-5">
                        <TextField
                            error={handleErrorInput(clients, "ubication_clients")}
                            type="text"
                            id="ubication_clients"
                            name="ubication_clients"
                            defaultValue={filterRecords.ubication_clients}
                            label={t('vacancies.ubication')}
                            variant="outlined"
                            onChange={handleChange}
                            className="w-full"
                            disabled
                        />
                    </div>
                    <div className="w-full mx-2 my-5">
                        <FormControl variant="outlined" className="w-full">
                            <InputLabel
                                htmlFor="status_clients"
                                error={handleErrorInput(clients, "status_clients")}
                            >
                                {t('clients.status')}
                            </InputLabel>
                            <Select
                                native
                                defaultValue={filterRecords.status_clients}
                                onChange={handleChange}
                                label={t('clients.status')}
                                inputProps={{
                                    name: "status_clients",
                                    id: "status_clients",
                                }}
                                disabled
                            >
                                <option value="A">{t('clients.active')}</option>
                                <option value="I">{t('clients.inactive')}</option>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="flex justify-center w-full mt-7">
                        <CustomButton
                            status={clients.button}
                            text={t('question.delete')}
                            icon="fa fa-trash"
                        />
                    </div>
                </form>
            )}
        </>
    );
}

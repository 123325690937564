import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useDispatch } from "react-redux";
import { evaluatiodev } from "../../redux/actions/admin/usersActions";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SimpleSelect(props) {
  const { t } = useTranslation("common");
  const { idprueba, likert } = props;
  const dispatch2 = useDispatch();
  const classes = useStyles();
  const [age, setAge] = React.useState(likert);
  const [form, setForm] = useState(null);
  const handleChange = (event) => {
   
    const enviar = {
      uid: event.target.name,
      val: event.target.value
    }

    setForm({
      ...form,
      [event.target.name]: event.target.value,
    })

    setAge(event.target.value);
    dispatch2(evaluatiodev(enviar));
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        labelId="demo-simple-select-label"
        id={idprueba}
        name={idprueba}
        value={age}
        onChange={handleChange}
      >
        <MenuItem value="S">{t('userSkills.outstanding')}</MenuItem>
        <MenuItem value="E">{t('userSkills.excelent')}</MenuItem>
        <MenuItem value="R">{t('userSkills.regul')}</MenuItem>
        <MenuItem value="D">{t('userSkills.deficient')}</MenuItem>
        <MenuItem value="M">{t('userSkills.badd')}</MenuItem>
      </Select>
    </FormControl>
  );
}

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { verifyLogin } from "../../utils/functions";

export default function RouteLogin(props) {
  const isLogged = verifyLogin();
  if (isLogged) {
    return <Route {...props} />;
  }
  return <Redirect to="/login" />;
}

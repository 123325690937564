import api from "../../utils/api";

export const getWorkExperience = (uuid) => async (dispatch) => {
   try {
      const response = await api.get("/experience");
      if (response.status === 200) {
         dispatch({
            type: "WE_RECORDS",
            payload: response.data,
         });
      }
   } catch (e) {
      dispatch({
         type: "WE_ERROR",
         payload: e.response.data.errors,
      });
   }
};

export const saveWorkExperience = (data) => async (dispatch) => {
   
	dispatch({ type: 'WE_BUTTON' })
   try {
      const response = await api.post("/experience", data);
      if (response.status === 200) {
         dispatch({
            type: "WE_SUCCESS"
         });
      }
   } catch (e) {
   
      dispatch({
         type: "WE_ERROR",
         payload: e.response.data.errors,
      });
   }
};

export const deleteWorkExperience =(uuid) => async dispatch => {
	const response = await api.delete(`/experience/${uuid}`);
	if (response.status === 200) {
		dispatch({
			type: 'WE_SUCCESS'
		})
	}
}
export const editWorkExperience = (data,uuid) => async (dispatch) => {
   try {
      const response = await api.post(`/editWorkExperience/${uuid}`, data);
      if (response.status === 200) {
         dispatch({
            type: "WE_SUCCESS"
         });
      }
   } catch (e) {
      console.log(e)
   }
};
import React from "react";
import { useTranslation } from "react-i18next";
import { changeLang } from "../../../utils/functions";
export default function Lang() {
const { i18n } = useTranslation("common");

const handleChange = ({ target: { value: lang } }) => {
   i18n.changeLanguage(lang);
   changeLang(lang);
};

return (
   <>
   <div className="hidden lg:inline-block mx-4 md:pt-0 md:pb-6 invisible lg:visible md:visible">
      <i className="fa fa-globe"></i>
      <select
         onChange={handleChange}
         value={i18n.language}
         className="block bg-black mx-1 px-1 py-1 font-poppins text-sm uppercase font-semibold lg:inline-block lg:mt-7 text-white"
      >
         <option value="en">en</option>
         <option value="es">es</option>
      </select>
   </div>
   </>
);
}

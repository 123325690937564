const initialState = {
  records: [],
  loaded: false,
  user_loaded: false,
  skills_loaded: false,
  record: [],
  results: [],
  resulskill: [],
  user_skills_list: [],
  find: [],
};

export default function usersReducers(state = initialState, action) {
  switch (action.type) {
    case "USERS_RECORDS": {
      return {
        ...state,
        records: action.payload,
        loaded: true,
      };
    }
    case "USERS_RECORDS_DELETE": {
      return {
        ...state,
        records: action.payload,
        loaded: false,
      };
    }
    case "USERS_RECORD": {
      var con = action.payload.user_skill.length;
      var condel = 0;
      for (let i = 0; i < action.payload.deleted_skills.length; i++) {
        action.payload.user_skill[con] = action.payload.deleted_skills[condel];
        con++;
        condel++;
      }
      return {
        ...state,
        user_loaded: true,
        record: action.payload,
      };
    }
    case "USER_RECORD_OFF": {
      return {
        ...state,
        user_loaded: false,
        skills_loaded: false,
      };
    }
    case "USERS_FIND": {
      return {
        ...state,
        search: action.payload,
      };
    }
    case "DATASKILL": {
      return {
        ...state,
        skills_loaded: true,
        resulskill: action.payload,
      };
    }
    case "USER_RESULTS": {
      return {
        ...state,
        results: action.payload,
      };
    }
    case "USER_RESULTS_OFF": {
      return {
        ...state,
        results: [],
      };
    }
    case "USER_RESULTS_REV": {
      return {
        ...state,
        loaded: true,
      };
    }
    case "USER_SKILLS_LIST": {
      return {
        ...state,
        user_skills_list: action.payload,
      };
    }
    case "USER_RELOAD": {
      return {
        ...state,
        loaded: false,
      };
    }
    case "USER_SKILL_LOADED_OFF": {
      return {
        ...state,
        skills_loaded: false,
      };
    }
    default:
      return state;
  }
}

import { useTranslation } from "react-i18next";

export default function DefaultColumnFilter({
  column: { setFilter, filterValue },
}) {
  const { t } = useTranslation("common");
  return (
    <input
      type="text"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={t("vacancies.city")
      }
      className="font-poppins w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-red-500 focus:bg-transparent focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
    />
  );
}

import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Link, Redirect, useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../common/Button";
import {
   getSkills,
   deleteSkill,
} from "../../../redux/actions/admin/skillActions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { useTranslation } from "react-i18next";

export default function SkillsEdit() {
   const { t } = useTranslation('common');
   const dispatch = useDispatch();
   const skill = useSelector((state) => state.skillsReducers);
   let { uuid } = useParams();
   const [data, setData] = useState({});

   useEffect(() => {
      if (!skill.loaded) dispatch(getSkills());
   }, [dispatch, skill.loaded]);

   useEffect(() => {
      if (skill.loaded) {
         const results = skill.records.filter((item) => item.uuid === uuid);
         setData(results[0]);
      }
   }, [skill.loaded, skill.records, uuid]);

   const handleSubmit = (e) => {
      e.preventDefault();
      dispatch(deleteSkill(uuid));
   };

   return (
      <>
         {skill.redirect && <Redirect to="/skills/admin" />}

         <div className="mt-5 flex justify-end">
            <Link to="/skills/admin">
               <Button className="focus:outline-none" variant="outlined">
                  <i className="fa fa-arrow-left mr-2"></i> {t('personal.back')}
               </Button>
            </Link>
         </div>
         {data !== undefined && (
            <form action="" className="w-1/2 mx-auto" onSubmit={handleSubmit}>
               <div className="w-full mx-2 my-5">
                  <TextField
                     type="text"
                     id="name"
                     name="name"
                     label={t('personal.name')}
                     variant="outlined"
                     className="w-full"
                     value={data.name || ""}
                     disabled
                  />
               </div>
               <div className="w-full mx-2 my-5">
                  <FormControl variant="outlined" className="w-full">
                     <InputLabel htmlFor="skill_test">{t('skills.habilityTest')}</InputLabel>
                     <Select
                        native
                        value={data.skill_test}
                        label={t('skills.habilityTest')}
                        inputProps={{
                           name: "skill_test",
                           id: "skill_test",
                        }} disabled
                     >
                        <option value="1">{t('vacancies.Yes')}</option>
                        <option value="0">{t('vacancies.n')}</option>
                     </Select>
                  </FormControl>
               </div>
               <div className="w-full mx-2 my-5">
                  <TextField
                     type="text"
                     id="description"
                     name="description"
                     label={t("experience.description")}
                     variant="outlined"
                     className="w-full"
                     multiline
                     minRows={4}
                     value={data.description || ""}
                     disabled
                  />
               </div>
             
               <div className="flex justify-center w-full mt-7">
                  <CustomButton
                     status={skill.button}
                     text={t('question.delete')}
                     icon="fa fa-trash"
                  />
               </div>
            </form>
         )}
      </>
   );
}

import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getNotifications } from "../../../redux/actions/admin/notificationActions";

import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

import sanitize from "sanitize-html";
import { Button } from "@material-ui/core";

import { Pagination, InputSearch, TableGrid, Success } from "../../common";
import EmailDelete from "./EmailDelete";

export default function EmailMain() {
  const { t, i18n } = useTranslation("common");
  let { path } = useRouteMatch();

  const columns = useMemo(
    () => [
      {
        Header: () => t("notification.board_name"),
        accessor: "board_name",
        Cell: ({ value: board_name }) => t(`board.email.${board_name}`),
      },
      {
        Header: () => t("notification.subject"),
        accessor: "subject",
      },
      {
        Header: () => t("notification.title"),
        accessor: "title",
      },
      {
        Header: () => t("notification.message"),
        accessor: "message",
        Cell: ({ value: message }) => {
          const sanitizedMessage = sanitize(message);
          const parsedHtml = new DOMParser().parseFromString(
            sanitizedMessage,
            "text/html"
          );
          const text = parsedHtml.body.innerText.trim();
          return text.length > 30 ? text.slice(0, 30).concat("...") : text;
        },
      },
      {
        Header: () => t("notification.status"),
        accessor: "default",
        Cell: ({ value: status }) =>
          status
            ? t("notification.default_email")
            : t("notification.no_default_email"),
      },
      {
        Header: () => t("notification.language"),
        accessor: "language",
      },
      {
        Header: () => t("skills.actions_test"),
        accessor: "uuid",
        Cell: ({ row: { original } }) => (
          <div className="flex justify-center text-sm">
            <Link
              to={`${path}/edit/${original.uuid}`}
              className="my-1 h-10 border-2 border-yellow-500 hover:bg-yellow-500 text-yellow-500 hover:text-white font-semibold  mx-1 py-2 px-2 rounded-lg"
            >
              <svg
                className="h-4 w-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                ></path>
              </svg>
            </Link>
            <button
              className="my-1 h-10 border-2 border-red-500  hover:bg-red-500 text-red-500 hover:text-white font-bold mx-1 py-2 px-2 rounded-lg"
              onClick={() => {
                setUuid(original.uuid);
                handleOpen("delete");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
            <Link
              to={`${path}/detail/${original.uuid}`}
              className="my-1 h-10 border-2 border-blue-500 hover:bg-blue-500 text-blue-500 hover:text-white font-semibold  mx-1 py-2 px-2 rounded-lg"
            >
              <i className="fas fa-info-circle"></i>
            </Link>
          </div>
        ),
      },
    ],
    [path, t]
  );

  const { records, loaded, success, success_message } = useSelector(
    (state) => state.notificationReducers
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    globalFilter,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: records,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const dispatch = useDispatch();
  const [value, setValue] = useState(globalFilter);
  const [uuid, setUuid] = useState("");
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const handleOpen = () => setOpenModalDelete(true);
  const handleClose = () => setOpenModalDelete(false);

  const onChangeInputSearch = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  useEffect(() => {
    if (!loaded) dispatch(getNotifications(i18n.language));
  }, [loaded, dispatch, i18n.language]);

  useEffect(() => {
    dispatch({
      type: "NOTIFICATION_RELOAD",
    });
  }, [dispatch, i18n.language]);

  return (
    <>
      <div className="mt-5">
        <Link to="/vacancies/admin">
          <Button className="focus:outline-none" variant="outlined">
            <i className="fa fa-arrow-left mr-2"></i> {t("personal.back")}
          </Button>
        </Link>
      </div>
      <div className="mb-8">
        <section className="text-gray-600 body-font">
          <div className="container px-5 pt-20 pb-4 mx-auto flex items-center md:flex-row flex-col">
            <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
              <h2 className="text-xs text-blue-500 tracking-wider font-medium title-font mb-2 bg-blue-50 rounded px-2 py-2 w-52">
                <i className="mr-4 fas fa-envelope"></i>
                {t("notification.list")}
              </h2>
              <h1 className="text-xl lg:text-2xl font-semibold title-font text-gray-900">
                {t("dashboard.email")}
              </h1>
            </div>
            <div className="flex flex-row md:flex-row md:ml-auto md:mr-0 mx-auto items-start flex-shrink-0 space-x-4">
              <div className="mr-2 font-poppins">
                <Link
                  to={`${path}/add`}
                  className="bg-blue-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-blue-300 focus:outline-none"
                  variant="outlined"
                >
                  <i className="fa fa-plus mr-2"></i>
                  <span className="ml-4 flex items-start flex-col leading-none">
                    <span className="text-xs text-gray-600 mb-1">
                      {t("question.add")}
                    </span>
                    <span className="title-font font-medium">
                      {t("notification.new")}
                    </span>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Success
        status={success}
        styleParent="mt-5"
        message={t(success_message)}
        closer="NOTIFICATION_SUCCESS_OFF"
      />

      <InputSearch
        setValue={setValue}
        onChange={onChangeInputSearch}
        value={value}
      />
      {!loaded && !records?.length ? (
        <div className="my-6 justify-content-center">
          <div className="text-center my-5 p-4 overflow-x-auto">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        </div>
      ) : (
        <>
          <TableGrid
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            loaded={loaded}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
          />
          <Pagination
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </>
      )}

      <EmailDelete
        open={openModalDelete}
        handleClose={handleClose}
        uuid={uuid}
      />
    </>
  );
}

import React, { useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

//actions
import { selectProfesional } from "../../redux/actions/professionalActions";
import { getProfessional } from "../../redux/actions/professionalActions";

// components
import Delete from "./Delete";
import FormEdit from "./FormEdit";
import Pagination from "../common/Pagination";
import InputSearch from "../common/InputSearch";
import TableGrid from "../common/TableGrid";

export default function Table() {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const [state, setState] = useState({
    showModalDelete: false,
    showModalEdit: false,
    uuid: undefined,
  });

  const handleModalDelete = useCallback((uuid) => {
    setState({
      showModalDelete: true,
      uuid,
    });
    dispatch({ type: "PROF_SUCCESS_OFF" });
  }, [dispatch])

  const handleModalEdit = useCallback((uuid) => {
    setState({
      showModalEdit: true,
      uuid,
    });
    dispatch(selectProfesional(uuid));
    dispatch({ type: "PROF_SUCCESS_OFF" });
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: () => t("professional.level"),
        accessor: "level",
      },
      {
        Header: () => t("professional.field"),
        accessor: "field",
      },
      {
        Header: () => t("professional.school"),
        accessor: "school",
      },
      {
        Header: () => t("skills.actions_test"),
        accessor: "uuid",
        Cell: ({ value: uuid }) => (
          <div>
            <button
              className="border-2 border-red-500  hover:bg-red-500 text-red-500 hover:text-white font-bold mx-1 py-2 px-2 rounded-lg"
              onClick={() => handleModalDelete(uuid)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
            <button
              className="border-2 border-yellow-500 hover:bg-yellow-500 text-yellow-500 hover:text-white font-semibold  mx-1 py-2 px-2 rounded-lg"
              onClick={() => handleModalEdit(uuid)}
            >
              <svg
                className="h-4 w-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                ></path>
              </svg>
            </button>
          </div>
        ),
      },
    ],
    [t, handleModalEdit, handleModalDelete]
  );
  
  const { records: professions, loaded: loading } = useSelector(
    (state) => state.professionalReducers
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    globalFilter,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: professions,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );
  const [value, setValue] = useState(globalFilter);

  const onChangeInputSearch = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const closeModalDelete = () => {
    setState({
      showModalDelete: false,
    });
    dispatch({ type: "PROF_SUCCESS_OFF" });
  };
  const closeModalEdit = () => {
    setState({
      showModalEdit: false,
    });
    dispatch(getProfessional());
  };
  return (
    <>
      <InputSearch
        setValue={setValue}
        onChange={onChangeInputSearch}
        value={value}
      />
      <TableGrid
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        loaded={loading}
        getTableBodyProps={getTableBodyProps}
        page={page}
        prepareRow={prepareRow}
      />
      <Pagination
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
      <Delete
        uuid={state.uuid}
        status={state.showModalDelete}
        closeModal={closeModalDelete}
      />
      <FormEdit
        uuid={state.uuid}
        status={state.showModalEdit}
        closeModal={closeModalEdit}
      />
    </>
  );
}

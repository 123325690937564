import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { updateSkill } from "../../redux/actions/skillActions";
import { handleErrorMessage } from "../../utils/functions";
import TextField from "@material-ui/core/TextField";
import Button from "../common/Button";

export default function FormEdit({
  uuid,
  preloadedValues,
  status,
  closeModal,
}) {
  const dispatch = useDispatch();
  const skill = useSelector((state) => state.skillReducers);
  const [hidden, setHidden] = useState(false);
  const { t } = useTranslation("common");
  const effectBG = status ? "fadeIn" : "fadeOut";
  const effectModal = status ? "fadeInDown" : "fadeOutUp";
  const [ShowModalerror, setShowModalerror] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      experience_timefrom: new Date(preloadedValues.experience_timefrom)
        .toISOString()
        .slice(0, 10),
      experience_timeto: new Date(preloadedValues.experience_timeto)
        .toISOString()
        .slice(0, 10),
      note: preloadedValues.note,
    },
  });

  useEffect(() => {
    setShowModalerror(false);
  }, [status, uuid, skill.records]);

  useEffect(() => {
    if (status) {
      setHidden(true);
    } else {
      setTimeout(() => {
        setHidden(false);
      }, 200);
    }
  }, [status]);

  const onSubmitData = (data) => {
    dispatch(updateSkill(data, uuid));
    setTimeout(() => {
      closeModal();
    }, 200);
  };

  return (
    <>
      {hidden && (
        <div>
          <div
            className={`fixed bg-black w-full h-full top-0 left-0 z-20 animate__animated animate__${effectBG} animate__faster`}
            style={{ backgroundColor: "rgba(0,0,0,.5)" }}
            onClick={closeModal}
          ></div>
          <div className="flex justify-center">
            <div
              className={`rounded-lg fixed top-0 left-20 ml-40 mt-44 w-auto items-center center-11-12 bg-white z-30 animate__animated animate__${effectModal} animate__faster`}
            >
              <div className="rounded-md">
                <div className="border-gray-700 bg-gray-700 text-white font-bold">
                  <div className="flex justify-between p-3">
                    <label>{t("skills.edit")}</label>
                    <svg
                      onClick={closeModal}
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5  border-2 rounded-md hover:bg-white hover:text-gray-800"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                </div>
                <div className="mt-4 mb-6 px-10 pt-10 pb-2">
                  <form
                    action=""
                    className="w-full animate__animated animate__fadeIn animate__faster"
                    onSubmit={handleSubmit(onSubmitData)}
                  >
                    <div className="w-full md:flex">
                      <div className="py-2 md:py-0 md:w-1/2 mx-2">
                        <Controller
                          name="experience_timefrom"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: t("experience.error.from_date"),
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              type="date"
                              id="experience_timefrom"
                              name="experience_timefrom"
                              label={t("experience.from")}
                              variant="outlined"
                              className="w-full"
                              error={errors.experience_timefrom ? true : false}
                              helperText={t(
                                handleErrorMessage(skill, "experience_timefrom")
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: new Date().toISOString().slice(0, 10),
                              }}
                              {...field}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="experience_timefrom"
                          render={({ message }) => (
                            <p className="mt-1 text-sm text-red-500">
                              {message}
                            </p>
                          )}
                        />
                      </div>
                      <div className="py-2 md:py-0 md:w-1/2 mx-2">
                        <Controller
                          name="experience_timeto"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: t("experience.error.from_date"),
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              type="date"
                              id="experience_timeto"
                              name="experience_timeto"
                              label={t("experience.to")}
                              variant="outlined"
                              className="w-full"
                              error={errors.experience_timeto ? true : false}
                              helperText={t(
                                handleErrorMessage(skill, "experience_timeto")
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: new Date().toISOString().slice(0, 10),
                              }}
                              {...field}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="experience_timeto"
                          render={({ message }) => (
                            <p className="mt-1 text-sm text-red-500">
                              {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    {ShowModalerror ? (
                      <div className="md:w-11/12 mb-5 flex justify-center mx-2">
                        <label className="text-xs font-semibold	text-red-500">
                          {t("experience.error.to_after")}
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="md:flex items-center justify-between mt-5">
                      <div className="py-2 md:py-0 md:w-10/12 flex mb-5 mx-2">
                        <Controller
                          name="note"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              type="text"
                              id="note"
                              name="note"
                              label={t("skills.note")}
                              variant="outlined"
                              className="w-full"
                              helperText={t(
                                handleErrorMessage(skill, "experience_timeto")
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: new Date().toISOString().slice(0, 10),
                              }}
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {ShowModalerror ? (
                        " "
                      ) : (
                        <div className="md:w-2/12 mb-5 flex justify-center mx-2">
                          <Button
                            status={skill.button}
                            text={t("modal.edit")}
                            icon="fa fa-check"
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getJobByUuid } from "../../../redux/actions/admin/jobActions";
import { useTranslation } from "react-i18next";

import Button from "@material-ui/core/Button";

import VacantEditForm from "./VacantEditForm";

export default function VacantEdit() {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const job = useSelector((state) => state.jobsReducers);
  const [editData, setEditData] = useState(undefined);
  let { uuid } = useParams();

  const [checkedStatus, setCheckedStatus] = useState(undefined);

  useEffect(() => {
    if (!job.record_loaded) {
      dispatch(getJobByUuid(uuid));
    } else {
      const salary = job.record.salary.split("-");

      if (
        salary[0] === "0" ||
        job.record.salary.toLowerCase() === "a convenir" ||
        job.record.salary === "Salary to be agreed"
      ) {
        setCheckedStatus(true);
      } else {
        setCheckedStatus(false);
      }

      setEditData({
        ...job.record,
        salary: salary[0] || "",
        salarymax: salary[1] || "",
      });
    }
  }, [dispatch, job.record, job.record_loaded, uuid]);

  useEffect(() => {
    return () => {
      dispatch({ type: "JOB_SINGLE_RELOAD" });
    };
  }, [dispatch]);

  return (
    <>
      {job.redirect && <Redirect to="/vacancies/admin" />}
      <div className="mt-5 flex justify-end">
        <Link to="/vacancies/admin">
          <Button
            onClick={() => dispatch({ type: "JOB_SINGLE_RELOAD" })}
            className="focus:outline-none"
            variant="outlined"
          >
            <i className="fa fa-arrow-left mr-2"></i> {t("personal.back")}
          </Button>
        </Link>
      </div>
      {editData && typeof checkedStatus === "boolean" ? (
        <VacantEditForm
          preloadedValues={editData}
          checked={checkedStatus}
          uuid={uuid}
        />
      ) : (
        <div className="justify-center">
          <div className="text-center mt-56 p-4 overflow-x-auto">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        </div>
      )}
    </>
  );
}

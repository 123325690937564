import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserApplied } from "../../../redux/actions/admin/userJobsActions";
import { useTranslation } from "react-i18next";

export default function VacantApplied() {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const userJobs = useSelector((state) => state.userJobsReducers);
    const [filterRecords, setFilterRecords] = useState(undefined);
    let { id } = useParams();
    useEffect(() => {
        if (!userJobs.loaded) dispatch(getUserApplied());
    }, [dispatch, userJobs.loaded]);

    useEffect(() => {
        if (userJobs.loaded) {
            const results = userJobs.data.filter((item) =>item.jobs_id===Number(id));
            setFilterRecords(results);
        }
    }, [userJobs.loaded, userJobs.data, id, dispatch]);
    return (
        <>
            <div className="mt-5 flex justify-end">
                <div className="mt-5">
                    <Link to="/vacancies/admin">
                        <Button className="focus:outline-none" variant="outlined">
                            <i className="fa fa-arrow-left mr-2"></i> {t('personal.back')}
                        </Button>
                    </Link>
                </div>
            </div>
            <table className="defaultTable">
                <thead>
                    <tr>
                        <th>{t('personal.name')}</th>
                        <th>{t('personal.email')}</th>
                        <th>{t('vacancies.dateApply')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="w-4/12">
                    {userJobs.loaded && filterRecords !== undefined && (
                        Object.entries(filterRecords).map(([key, item]) => (
                            <tr key={key}>
                                <td>
                                    {item.name}
                                </td>
                                <td>
                                    {item.email}
                                </td>
                                <td>{new Date(item.created_at).toISOString().slice(0, 10)}</td>
                                <td>
                                    <Link className="btn_options" to={`/admin/users/profile/${item.uuid}/applied/${item.jobs_id}`}>
                                        <i className="fa fa-arrow-right"></i>
                                    </Link>
                                </td>
                            </tr>
                        )))
                    }

                    {!userJobs.loaded &&
                        <tr>
                            <td colSpan="10" className="text-center">
                                <i className="fa fa-spin fa-spinner fa-lg"></i>
                            </td>
                        </tr>
                    }

                </tbody>
            </table>
        </>
    );
}

export default function ShowAreas(props) {
   const { skills } = props;
   const total_skills = skills.length;
   if (total_skills === 1) {
      return skills[0].skill.name;
   } else if (total_skills > 1) {
      let var_to_return = "";
      for (let x = 0; x < total_skills; x++) {
         var endPoint = x + 1 < total_skills ? ", " : ". ";
         var_to_return = `${var_to_return} ${skills[x].skill.name}${endPoint}`;
      }
      return var_to_return;
   } else {
      return "No skills";
   }
}

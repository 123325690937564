import { useTranslation } from "react-i18next";

const COLUMNS = [
  {
    Header: () => (
      <label
        htmlFor="ubication"
        className="leading-7 text-sm font-poppins text-gray-600"
      >
        {" "}
        {useTranslation("common").t("vacancies.whereJob")}{" "}
      </label>
    ),
    accessor: "ubication",
  },
  {
    accessor: "priority_job",
  },
  {
    accessor: "location",
  },
  {
    accessor: "type_job",
  },
  {
    accessor: "salary",
  },
  {
    accessor: "report_job",
  },
  {
    accessor: "name_job",
  },
  {
    accessor: "work_conditions_job",
  },
];

export { COLUMNS };

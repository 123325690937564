const initialState = {
	button: {
		status: false
	},
	error: {
		status: false,
		errors: []
	},
	loginerror: {
		status: false,
		errors: ""
	},	
	emails: []
}
export default function userReducers (state = initialState, action) {
	switch(action.type) {
		case 'USER_BUTTON': {
			return {
				...state,
				button: {
					status: true
				}
			}
		}
		case 'USER_BUTTON_OFF': {
			return {
				...state,
				button: {
					status: false
				}
			}
		}
		case 'USER_ERROR': {			
			return {
				...state,
				error: {
					status: true,
					errors: action.payload
				},
				button: {
					status: false
				}
			}
		}
		case 'USER_LOGINERROR': {
			return {
				...state,
				loginerror: {
					status: true,
					errors: action.payload
				},
				button: {
					status: false
				}
			}
		}
		case 'USER_ERROR_OFF': {
			return {
				...state,
				error: {
					status: false,
					errors: []
				},
				button: {
					status: false
				},
				loginerror: {
					status: false,
					errors: ""
				},
			}
		}
		case 'EMAIL_DUPLICATE': {				
			return {
				...state,				
				emails: action.payload
			}
		}
		default: return state;
	}
}
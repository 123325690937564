import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from '@material-ui/core/Divider';

export default function AdminMenuDrawer() {
   const { t } = useTranslation('common');
   const main = useSelector((state) => state.mainReducers);
   const contact = useSelector((state) => state.contactReducers);
	
   if (main.mode === "denied") return null;
   else if (main.mode === "allowed") {
      const menu_2 = [
         {
            name: t('menu_profile.users'),
            url: "/admin/users/",
            icon: "fa fa-users",
				id: 2024
         },
         {
            name: t('menu.test'),
            url: "/tests/admin",
            icon: "fa fa-file-alt",
				id: 2025
         },
         {
            name: t('menu_profile.skills_mobile'),
            url: "/skills/admin",
            icon: "fa fa-brain",
				id: 2026
         },
         {
            name: t('menu.vacancies'),
            url: "/vacancies/admin",
            icon: "fa fa-briefcase",
				id: 2028
         },         {
            name: t('menu.clients'),
            url: "/clients/admin",
            icon: "fas fa-user-friends",
				id: 2029
         },
      ];
      const menu_3 = [
         {
            name: t('menu.messages'),
            url: "/contact/admin",
            icon: "fa fa-comments",
				id: 2027
         },
      ];
      return (
         <>
				<Divider />
            <div className="m-4">
            <h1 className="font-poppins font-bold">
               {t('menu_profile.adminPanel')}
            </h1>
            </div>
            {menu_2.map((item) => (
               <ListItem button key={item.id}>
                  <ListItemIcon>
                     <i className={item.icon}></i>
                  </ListItemIcon>
                  <NavLink to={item.url}>
                     <ListItemText primary={item.name} />
                  </NavLink>
               </ListItem>
            ))}
            {menu_3.map((item) => (
               <ListItem button key={item.id}>
                  <ListItemIcon>
                     <i className={item.icon}></i>
                  </ListItemIcon>
                  <NavLink to={item.url}>
                     <ListItemText primary={item.name} />
                  </NavLink>
                  <span className="rounded-full text-white bg-black px-2 py-1 text-sm font-semibold w-8 ml-4 pop-message">+{contact.countMessages?contact.countMessages:0}</span>
               </ListItem>
            ))}
         </>
      );
   } else {
      return null;
   }
}

const initialState = {
	button: false,
	loaded: false,
	records: [],
	search:[],
	error: false,
	error_message: [],
	success: false,
	skill: {
		loaded: false,
		records: []
	},
	skilluser: {
		loaded: false,
		records: []
	},
	skilledit:[]
	
}

export default function skillReducers( state = initialState, action) {
	switch(action.type) {
		case 'SKILL_BUTTON': {
			return {
				...state,
				button: true
			}
		}
		case 'SKILL_BUTTON_OFF': {
			return {
				...state,
				button: false
			}
		}
		case 'SKILL_ERROR': {
			return {
				...state,
				button: false,
				error: true,
				error_message: action.payload,
				success: false
			}
		}
		case 'SKILL_SUCCESS': {
			return {
				...state,
				button: false,
				error: false,
				error_message: [],
				 success: true,
				 loaded: false,
			}
		}
		case 'SKILL_SUCCESS_OFF': {
			return {
				...state,
				success: false
			}
		}
		case 'SKILL_ERROR_OFF': {
			return {
				...state,
				error: false,
				error_message: [],
				button: false
			}
		}
		case 'SKILL_RECORDS': {
			return {
				...state,
				loaded: true,
				records: action.payload
			}
		}
		case 'SKILL_GET_EDIT': {
			return {
				...state,
				skilledit: action.payload
			}
		}
		case 'SKILL_RECORDS2': {
			return {
				...state,
				loaded: true,
				search: action.payload
			}
		}
		case 'SKILL_DATA_RECORD': {
			return {
				...state,
				skill: {
					loaded: true,
					records: action.payload
				}
			}
		}
		case 'SKILL_DATA_RECORD_USER': {
			return {
				...state,
				skilluser: {
					loaded: true,
					records: action.payload
				}
			}
		}
		case 'SKILL_LOADED_OFF': {
			return {
				...state,
				loaded: false
			}
		}
		default: return state;
	}
} 
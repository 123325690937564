import api from '../../../utils/api'

export const getMessages = () => async dispatch => {
	try {
		const response = await api.get('/contact');
		dispatch({
			type: 'CONTACT_RECORDS',
			payload: response.data
		})
	} catch(e) {
		console.log(e)
	}
}
export const getUnread = () => async dispatch => {
	try {
		const response = await api.get('/messages');
		dispatch({
			type: 'CONTACT_MESS',
			payload: response.data
		})
	} catch(e) {
		console.log(e)
	}
}
export const readMessage = (uuid) => async dispatch => {
	try {
		await api.post(`/contact/read/${uuid}`);	
	} catch (e) {	
		console.log(e)	
	}
}
export const getCounts = (lang) => async dispatch => {
	//se hace el llamado a la api para traer los datos de los contadores	
	try {		
		const response = await api.get(`/board/counts/${lang}`);
	//	const response = await api.get('/board/counts');			
		 dispatch({
		 	type: 'COUNTS_ALL',
		 	payload: response.data
		 })
	} catch (e) {	
		console.log(e)	
	}
	
}
import api from "../../utils/api";

export const getReferences = (uuid) => async (dispatch) => {
   try {
      const response = await api.get("/reference");
      if (response.status === 200) {
         dispatch({
            type: "REF_RECORDS",
            payload: response.data,
         });
      }
   } catch (e) {
      dispatch({
         type: "REF_ERROR",
         payload: e.response.data.errors,
      });
   }
};

export const saveReferences = (data) => async (dispatch) => {
	dispatch({ type: 'REF_BUTTON' })
   try {
      const response = await api.post("/reference", data);
      if (response.status === 200) {
         dispatch({
            type: "REF_SUCCESS"
         });
      }
   } catch (e) {
      dispatch({
         type: "REF_ERROR",
         payload: e.response.data.errors,
      });
   }
};

export const deleteReferences = (uuid) => async dispatch => {
	const response = await api.delete(`/reference/${uuid}`);
	if (response.status === 200) {
		dispatch({
			type: 'REF_SUCCESS'
		})
	}
}
export const referEdit = (uuid) => async dispatch => {
	try {
      const response = await api.get(`/reference/${uuid}`);
      dispatch({
         type: "REF_RECORDS_EDIT",
         payload: response.data,
      });   
	} catch (e) {
		console.log(e)
	}
}
export const updateReferences = (data,uuid) => async (dispatch) => {
   try {
      // envio por put
      await api.put(`/editReferences/${uuid}`, data);   
      /*
      dispatch({
         type: "REF_UPD",
         payload: response.data,
      });
      */    
   } catch (e) {
      console.log(e)
   }
};
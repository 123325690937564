const initialState = {
  button: false,
  loaded: false,
  data: [],
  record: undefined,
  record_loaded: false,
  error: false,
  error_message: [],
  redirect: false,
  success: false,
  success_message: "",
};

export default function jobReducers(state = initialState, action) {
  switch (action.type) {
    case "CLIENTS_ALL": {
      return {
        ...state,
        data: action.payload,
        loaded: true,
      };
    }
    case "CLIENTS_RECORD": {
      return {
        ...state,
        record: action.payload,
        record_loaded: true,
      };
    }
    case "CLIENTS_DELETE": {
      const arreglo = state.data.filter(
        (item) => item.uuid !== action.payload.uuid
      );
      return {
        ...state,
        error: false,
        error_message: [],
        success: true,
        redirect: true,
        button: false,
        data: arreglo,
        success_message: `${action.payload.name_clients} was deleted successfully`,
      };
    }
    case "CLIENTS_UPDATE": {
      let indice = state.data.findIndex(
        (item) => item.uuid === action.payload.uuid
      );
      const copy_array = [...state.data];
      copy_array[indice] = action.payload;
      return {
        ...state,
        error: false,
        error_message: [],
        success: true,
        redirect: true,
        button: false,
        data: copy_array,
        success_message: `${action.payload.name_clients} was modified successfully`,
      };
    }
    case "CLIENTS_SAVE": {
      return {
        ...state,
        error: false,
        error_message: [],
        data: state.data.concat(action.payload),
        success: true,
        redirect: true,
        button: false,
        success_message: `${action.payload.name_clients} was added successfully`,
      };
    }
    case "CLIENTS_ERROR": {
      return {
        ...state,
        error: true,
        error_message: action.payload,
        button: false,
      };
    }
    case "CLIENTS_OFF": {
      return {
        ...state,
        error: false,
        error_message: [],
        button: false,
      };
    }
    case "CLIENTS_RECORD_OFF": {
      return {
        ...state,
        record_loaded: false,
      };
    }
    case "CLIENTS_BUTTON": {
      return {
        ...state,
        button: true,
      };
    }
    case "CLIENTS_RESTART": {
      return {
        ...state,
        button: false,
        error: false,
        error_message: false,
        redirect: false,
      };
    }
    case "CLIENTS_SUCCESS_OFF": {
      return {
        ...state,
        success: false,
        success_message: "",
      };
    }
    default:
      return state;
  }
}

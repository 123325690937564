import { combineReducers } from "redux";
import mainReducers from "./mainReducers";
import userReducers from "./userReducers";
import personalReducers from "./personalReducers";
import workExpReducers from "./workExpReducers";
import portfolioReducers from "./porfolioReducers";
import professionalReducers from "./professionalReducers";
import referencesReducers from "./referencesReducers";
import skillReducers from "./skillReducers";
import stepsReducers from "./stepsReducers";
import jobReducers from "./jobReducers";
import emailReducers from "./emailReducers";
import usersReducers from "./admin/usersReducers";
import questionReducers from "./admin/questionReducers";
import answerReducers from "./admin/answerReducers";
import userTestReducers from "./admin/userTestReducers";
import skillsReducers from "./admin/skillsReducers";
import jobsReducers from "./admin/jobsReducers";
import clientsReducers from "./admin/clientsReducers";
import userJobsReducers from "./admin/userJobsReducers";
import contactReducers from "./admin/contactReducers";
import loadingReducers from "./loadingReducers";
import roleReducers from "./admin/roleReducers";
import notificationReducers from "./admin/notificationReducers";
import currencyReducers from "./admin/currencyReducers";

export default combineReducers({
  mainReducers,
  userReducers,
  personalReducers,
  workExpReducers,
  portfolioReducers,
  professionalReducers,
  referencesReducers,
  skillReducers,
  stepsReducers,
  jobReducers,
  emailReducers,
  usersReducers,
  questionReducers,
  answerReducers,
  userTestReducers,
  skillsReducers,
  jobsReducers,
  clientsReducers,
  contactReducers,
  userJobsReducers,
  loadingReducers,
  roleReducers,
  notificationReducers,
  currencyReducers,
});

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../common/Button";
import { saveSkills, getSkillsData } from "../../redux/actions/skillActions";
import { handleErrorMessage, handleErrorInput } from "../../utils/functions";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ErrorMessage } from "@hookform/error-message";
import { useForm, Controller } from "react-hook-form";
export default function FormAdd(props) {
  const dispatch = useDispatch();
  const skill = useSelector((state) => state.skillReducers);
  let { status, closeFormAdd, skilluser2 } = props;
  const { t } = useTranslation("common");

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    register,
    setValue,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      skill_id: "",
      experience_timefrom: "",
      experience_timeto: "",
      note: "",
    },
  });

  useEffect(() => {
    if (!skill.skill.loaded) dispatch(getSkillsData());
  }, [dispatch, skill.skill.loaded]);

  const onSubmitData = (data) => {
    dispatch(saveSkills(data));
    closeFormAdd();
  };

  useEffect(() => {
    reset({
      skill_id: "",
      experience_timefrom: "",
      experience_timeto: "",
      note: "",
    });
  }, [reset, closeFormAdd]);

  useEffect(() => {
    register("skill_id", {
      required: t("skills.error.skill_required"),
    });
  }, [register, t]);

  return (
    <>
      {status && (
        <form
          action=""
          className="w-full animate__animated animate__fadeIn animate__faster"
          onSubmit={handleSubmit(onSubmitData)}
        >
          <div className="w-full md:flex justify-center ">
            <div className="py-2 md:py-0 md:w-4/12 mx-2">
              <Autocomplete
                id="skill_id"
                options={skilluser2.skilluser.records}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => setValue("skill_id", value?.uuid)}
                name="skill_id"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("skills.select")}
                    variant="outlined"
                    error={errors.skill_id ? true : false}
                    helperText={t(handleErrorMessage(skill, "skill_id"))}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="skill_id"
                render={({ message }) => (
                  <p className="mt-1 text-sm text-red-500">{message}</p>
                )}
              />
            </div>
            <div className="py-1 md:py-0 md:w-3/12 mx-2">
              <Controller
                name="experience_timefrom"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("experience.error.from_date"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="date"
                    id="experience_timefrom"
                    name="experience_timefrom"
                    label={t("experience.from")}
                    variant="outlined"
                    className="w-full"
                    error={errors.experience_timefrom ? true : false}
                    helperText={t(
                      handleErrorMessage(skill, "experience_timefrom")
                    )}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: new Date().toISOString().slice(0, 10),
                    }}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="experience_timefrom"
                render={({ message }) => (
                  <p className="mt-1 text-sm text-red-500">{message}</p>
                )}
              />
            </div>
            <div className="py-2 md:py-0 md:w-3/12 mx-2">
              <Controller
                name="experience_timeto"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("experience.error.from_date"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="date"
                    id="experience_timeto"
                    name="experience_timeto"
                    label={t("experience.to")}
                    variant="outlined"
                    className="w-full"
                    error={errors.experience_timeto ? true : false}
                    helperText={t(
                      handleErrorMessage(skill, "experience_timeto")
                    )}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: new Date().toISOString().slice(0, 10),
                    }}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="experience_timeto"
                render={({ message }) => (
                  <p className="mt-1 text-sm text-red-500">{message}</p>
                )}
              />
            </div>
            <div className="py-2 md:py-0 md:w-4/12 mx-2">
              <Controller
                name="note"
                id="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="note"
                    name="note"
                    label={t("skills.note")}
                    variant="outlined"
                    className="w-full"
                    error={handleErrorInput(skill, "note")}
                    helperText={t(handleErrorMessage(skill, "note"))}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: new Date().toISOString().slice(0, 10),
                    }}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="md:w-2/12 mt-3 md:mt-0 md:mx-2 flex justify-center items-center">
              <Button
                status={skill.button}
                text={t("skills.addbtn")}
                icon="fa fa-check"
              />
            </div>
          </div>
        </form>
      )}
    </>
  );
}

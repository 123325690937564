import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { saveReferences } from "../../redux/actions/referencesActions";
import { handleErrorMessage } from "../../utils/functions";
import Button from "../common/Button";

export default function FormAdd(props) {
  const dispatch = useDispatch();
  const ref = useSelector((state) => state.referencesReducers);
  let { status, closeFormAdd } = props;
  const { t } = useTranslation("common");

  const {
    control,
    handleSubmit,
    setValue,
    register,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      fullname: "",
      address: "",
      phone: "",
      relation: "",
      type: "reference",
    },
  });

  const onSubmitData = (data) => {
    dispatch(saveReferences(data));
    closeFormAdd();
  };

  useEffect(() => {
    register("type", {
      required: true,
    });
  }, [register]);

  useEffect(() => {
    reset({
      fullname: "",
      address: "",
      phone: "",
      relation: "",
      type: "reference",
    });
  }, [reset, closeFormAdd]);

  return (
    <>
      {status && (
        <form
          action=""
          className="w-full animate__animated animate__fadeIn animate__faster"
          onSubmit={handleSubmit(onSubmitData)}
        >
          <div className="w-full md:flex md:mb-5">
            <div className="py-2 md:py-0 md:w-1/2 mx-2">
              <Controller
                name="fullname"
                id="fullname"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("references.error.fullname_required"),
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#]{3,}$/,
                    message: t("references.error.fullname_invalid"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="fullname"
                    name="fullname"
                    label={t("references.fullname")}
                    variant="outlined"
                    className="w-full"
                    error={errors.fullname ? true : false}
                    helperText={t(handleErrorMessage(ref, "fullname"))}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: new Date().toISOString().slice(0, 10),
                    }}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="fullname"
                render={({ message }) => (
                  <p className="mt-1 text-sm text-red-500">{message}</p>
                )}
              />
            </div>
            <div className="py-2 md:py-0 md:w-1/2 mx-2">
              <Controller
                name="address"
                id="address"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("references.error.address_required"),
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#-/]{3,}$/,
                    message: t("references.error.address_invalid"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="address"
                    name="address"
                    label={t("references.address")}
                    variant="outlined"
                    className="w-full"
                    error={errors.address ? true : false}
                    helperText={t(handleErrorMessage(ref, "address"))}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: new Date().toISOString().slice(0, 10),
                    }}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="address"
                render={({ message }) => (
                  <p className="mt-1 text-sm text-red-500">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="w-full md:flex md:mb-5">
            <div className="py-2 md:py-0 md:w-5/12 mx-2">
              <Controller
                name="phone"
                id="phone"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("references.error.phone_required"),
                  },
                  pattern: {
                    value: /^([0-9]{8})/,
                    message: t("references.error.phone_invalid"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="phone"
                    name="phone"
                    label={t("references.phone")}
                    variant="outlined"
                    className="w-full"
                    error={errors.phone ? true : false}
                    helperText={t(handleErrorMessage(ref, "phone"))}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: new Date().toISOString().slice(0, 10),
                    }}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="phone"
                render={({ message }) => (
                  <p className="mt-1 text-sm text-red-500">{message}</p>
                )}
              />
            </div>
            <div className="py-2 md:py-0 md:w-5/12 mx-2">
              <Controller
                name="relation"
                id="relation"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("references.error.relation_required"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="relation"
                    name="relation"
                    label={t("references.relation")}
                    variant="outlined"
                    className="w-full"
                    error={errors.relation ? true : false}
                    helperText={t(handleErrorMessage(ref, "relation"))}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: new Date().toISOString().slice(0, 10),
                    }}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="relation"
                render={({ message }) => (
                  <p className="mt-1 text-sm text-red-500">{message}</p>
                )}
              />
            </div>
            <div className="py-2 md:py-0 md:w-5/12 mx-2">
              <FormControl variant="outlined" className="w-full">
                <InputLabel htmlFor="type">{t("references.type")}</InputLabel>
                <Select
                  id="type"
                  name="type"
                  native
                  onChange={(event) => setValue("type", event.target.value)}
                  label={t("references.type")}
                  inputProps={{
                    name: "type",
                    id: "type",
                  }}
                >
                  <option value="reference">{t("references.reference")}</option>
                  <option value="emergency">{t("references.emergency")}</option>
                </Select>
              </FormControl>
            </div>
            <div className="flex justify-center md:w-2/12">
              <Button
                status={ref.button}
                text={t("references.addbtn")}
                icon="fa fa-check"
              />
            </div>
          </div>
        </form>
      )}
    </>
  );
}

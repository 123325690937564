import api from "../../../utils/api";

export const getSkills = () => async (dispatch) => {
  try {
    const response = await api.get("/skills");
    dispatch({
      type: "SKILLS_RECORDS",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getSkillByUuid = (uuid, language) => async (dispatch) => {
  try {
    const response = await api.get(`/skills/${uuid}/${language}`);
    dispatch({
      type: "SKILLS_RECORD",
      payload: response.data[0],
    });
  } catch (e) {
    console.log(e);
  }
};

export const filterSkills = (uuid, language) => async (dispatch) => {
  try {
    const response = await api.get(`/skills/${uuid}/${language}`);
    dispatch({
      type: "SKILLS_SEARCH",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const countSkillQuestions = (language) => async (dispatch) => {
  try {
    const response = await api.get(`/countSkillQuestions/${language}`);
    dispatch({
      type: "SKILLS_RECORDS_COUNT",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const saveSkill = (data) => async (dispatch) => {
  dispatch({ type: "SKILLS_BUTTON" });
  try {
    const response = await api.post("/skills", data);
    dispatch({
      type: "SKILLS_STORE",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "SKILLS_ERROR",
      payload: e.response.data.errors,
    });
  }
};

export const updateSkill = (data, uuid) => async (dispatch) => {
  dispatch({ type: "SKILLS_BUTTON" });
  try {
    const response = await api.put(`/skills/${uuid}`, data);
    if (response?.data) {
      dispatch({
        type: "SKILLS_UPDATE",
        payload: response.data,
      });
    } else {
      throw new Error("Error creating skill");
    }
  } catch (e) {
    dispatch({
      type: "SKILLS_ERROR",
      payload: "skills.error.skill_unique",
    });
  }
};

export const deleteSkill = (uuid) => async (dispatch) => {
  dispatch({ type: "SKILLS_BUTTON" });
  try {
    const response = await api.delete(`/skills/${uuid}`);
    if (response.status === 200) {
      dispatch({
        type: "SKILLS_DELETE",
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: "SKILLS_ERROR",
      payload: e.response.data.errors,
    });
  }
};

export const restartState = () => async (dispatch) => {
  dispatch({ type: "SKILLS_RESTART" });
};
export const changeAdminRole = (uuid) => async (dispatch) => {
  try {
    await api.post(`/users/changeAdmin/${uuid}`);
  } catch (e) {
    dispatch({
      type: "SKILL_ERROR",
      payload: e.response.data.errors,
    });
  }
};

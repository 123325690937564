import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListUsersSkills } from "../../../redux/actions/admin/usersActions";

export default function ListUsersSkills() {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.usersReducers);

  useEffect(() => {
    dispatch(getListUsersSkills());
  }, [dispatch]);

  return (
    <div className="my-10">
      <table className="w-10/12 mx-auto my-3">
        <thead>
          <tr>
            <th className="border border-gray-400 bg-gray-100 p-2">Name</th>
            <th className="border border-gray-400 bg-gray-100 p-2">Skills</th>
          </tr>
        </thead>
        <tbody>
          {list.user_skills_list.length > 0 &&
            Object.entries(list.user_skills_list).map(([key, item]) => (
              <tr key={key}>
                <td className="border p-2">
                  {item.name} {item.lastname}{" "}
                </td>
                <td className="border p-2">
                  {item.user_skill && (
                    <>
                      {Object.entries(item.user_skill).length > 0 &&
                        Object.entries(item.user_skill).map(
                          ([key2, item2]) =>
                            item2 &&
                            item2.skill && (
                              <div key={key2}>
                                {item2.skill.name} {item2.skill.lastname} (
                                {item2.experience_time})<br />
                              </div>
                            )
                        )}
                    </>
                  )}
                </td>
              </tr>
            ))}
          {list.user_skills_list.length === 0 && (
            <tr>
              <td colSpan="4" className="text-center">
                <i className="fa fa-spin fa-spinner fa-lg"></i>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

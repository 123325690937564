import React from 'react';
import { useTranslation } from "react-i18next";

export default function UserProfessional(props) {
	const { t } = useTranslation('common');
	const { records } = props;

	return (
		<table className="w-full userInfoTable font-poppins text-sm">
			<thead>
				<tr>
					<th>{t('professional.level')} / {t('professional.field')}</th>
					<th>{t('professional.school')} ( {t('professional.location')} )</th>
					<th>{t('professional.from')}</th>
					<th>{t('professional.to')}</th>
				</tr>
			</thead>
			<tbody>
				{records.length > 0 ? Object.entries(records).map(([key, item]) =>
					<tr key={key}>
						<td>{item.level} / {item.field}</td>
						<td>{item.school} {item.location ? `(${item.location})` : null}</td>
						<td>{item.from}</td>
						<td>{item.to}</td>
					</tr>
				)
					:
					<tr>
						<td colSpan="8" style={{ textAlign: 'center' }}>{t('portfolio.norecords')}</td>
					</tr>
				}
			</tbody>
		</table>
	);
}
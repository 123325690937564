import React, { useEffect, useState, useMemo } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TextField from "@material-ui/core/TextField";
import { Link, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  filterQuestions,
  getQuestions,
} from "../../../redux/actions/admin/questionActions";
import { getSkills } from "../../../redux/actions/admin/skillActions";
import { useTranslation } from "react-i18next";
import Success from "../../common/Success";
import ShowAreas from "./ShowAreas";
import ModalSkillsEdit from "../../modals/ModalSkillsEdit";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

import { Pagination, InputSearch, TableGrid } from "../../common";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function QuestionMain() {
  const { t, i18n } = useTranslation("common");

  const columns = useMemo(
    () => [
      {
        Header: () => t("professional.field"),
        accessor: "question_skills",
        Cell: ({ value: question_skills }) => (
          <ShowAreas skills={question_skills} />
        ),
      },
      {
        Header: () => t("question.question"),
        accessor: "question",
        Cell: ({ row: { original: item } }) => (
          <div className="flex justify-start items-center">
            {item.image && (
              <div className="my-1 h-10 border-2 border-blue-500  hover:bg-blue-500 text-blue-500 hover:text-white font-bold mx-1 py-2 px-2 rounded-lg">
                <a
                  href={`${process.env.REACT_APP_BASE_URL}` + item.image}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                    ></path>
                  </svg>
                </a>
              </div>
            )}
            <div className="ml-2">{item.question}</div>
          </div>
        ),
      },
      {
        Header: () => t("references.type"),
        accessor: "type",
        Cell: ({ value: type }) => <span className="capitalize">{type}</span>,
      },
      {
        Header: () => t("menu.language"),
        accessor: "language",
      },
      {
        Header: () => <i className="fa fa-ellipsis-h"></i>,
        accessor: "data",
        Cell: ({ row: { original: item } }) => (
          <div className="flex justify-start text-sm">
            <Link
              to={`admin/edit/${item.uuid}`}
              className="my-1 h-10 border-2 border-yellow-500 hover:bg-yellow-500 text-yellow-500 hover:text-white font-semibold  mx-1 py-2 px-2 rounded-lg"
              title={t("question.tooltipEditQuestion")}
            >
              <svg
                className="h-4 w-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                ></path>
              </svg>
            </Link>
            <Link
              to={`admin/delete/${item.uuid}`}
              className="my-1 h-10 border-2 border-red-500  hover:bg-red-500 text-red-500 hover:text-white font-bold mx-1 py-2 px-2 rounded-lg"
              title={t("question.tooltipDeleteQuestion")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </Link>
            {item.type === "select" && (
              <Link
                to={`admin/answers/${item.uuid}`}
                className="my-1 h-10 border-2 border-green-500  hover:bg-green-500 text-green-500 hover:text-white font-bold mx-1 py-2 px-2 rounded-lg"
                title={t("question.tooltipAnswerQuestion")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                  />
                </svg>
              </Link>
            )}
            <ModalSkillsEdit uuid={`${item.uuid}`}></ModalSkillsEdit>
          </div>
        ),
      },
    ],
    [t]
  );

  const question = useSelector((state) => state.questionReducers);
  const skill = useSelector((state) => state.skillsReducers);
  const [filterRecords, setFilterRecords] = useState(question.records);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    globalFilter,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filterRecords,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const [value, setValue] = useState(globalFilter);
  const [showFilters, setShowFilters] = useState(false);
  const [skillsFilter, setSkillsFilter] = useState([]);

  const onChangeInputSearch = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  let { url } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!question.loaded) {
      dispatch(getQuestions(i18n.language));
    }
  }, [dispatch, question.loaded, i18n.language]);

  useEffect(() => {
    dispatch({
      type: "QUESTION_RELOAD",
    });
  }, [dispatch, i18n.language]);

  useEffect(() => {
    if (question?.search !== undefined) {
      setFilterRecords(question.search);
    }
    if (question.loaded) {
      !question.search.length
        ? setFilterRecords(question.records)
        : setFilterRecords(question.search);
    }
  }, [dispatch, question, question.search]);

  useEffect(() => {
    if (!skill.loaded) dispatch(getSkills());
  }, [dispatch, skill.loaded]);

  const toggleFilters = () => {
    setSkillsFilter([]);
    setShowFilters(!showFilters);
  };

  const handleChange = (e, value) => {
    setSkillsFilter(value);
  };

  const searchQuestions = () => {
    if (skillsFilter.length) {
      const skills_id = skillsFilter.map((item) => item.id);
      dispatch(filterQuestions({ skills: skills_id, lang: i18n.language }));
    }
    toggleFilters();
  };

  const resetTable = () => {
    setFilterRecords(question.records);
  };

  return (
    <>
      <div className="">
        <section className="text-gray-600 body-font">
          <div className="container px-5 pt-20 pb-4 mx-auto flex items-center md:flex-row flex-col">
            <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
              <h2 className="text-xs text-blue-500 tracking-widest font-medium title-font mb-2 bg-blue-50 rounded px-2 py-2 w-52">
                <i className="fas fa-question mr-4"></i>
                {t("question.list_question")}
              </h2>
              <h1 className="text-xl lg:text-2xl font-semibold title-font text-gray-900">
                {t("vacancies.listtest")}
              </h1>
              <div className="mt-2 flex items-start justify-start">
                <span className="font-semibold font-poppins">
                  {t("question.current_lang")}
                </span>
                : <span className="uppercase">{i18n.language}</span>
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:ml-auto md:mr-0 mx-auto items-start flex-shrink-0 space-x-4">
              {/* <Link className="mr-2 font-poppins"> */}
              <button
                className="bg-green-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-green-300 focus:outline-none"
                variant="outlined"
                onClick={() => dispatch({ type: "QUESTION_LOADED_OFF" })}
              >
                <i className="fa fa-sync-alt mr-2"></i>
                <span className="ml-4 flex items-start flex-col leading-none">
                  <span className="text-xs text-gray-600 mb-1">
                    {t("list.load")}
                  </span>
                  <span className="title-font font-medium">
                    {t("list.again")}
                  </span>
                </span>
              </button>
              {/* </Link> */}
              <Link to={`${url}/add-question`} className="mr-2 font-poppins">
                <button
                  className="bg-blue-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-blue-300 focus:outline-none"
                  variant="outlined"
                >
                  <i className="fa fa-plus"></i>
                  <span className="ml-4 flex items-start flex-col leading-none">
                    <span className="text-xs text-gray-600 mb-1">
                      {t("question.add")}
                    </span>
                    <span className="title-font font-medium">
                      {t("question.new_question")}
                    </span>
                  </span>
                </button>
              </Link>
              <Link to={`${url}/list/question`} className="mr-2 font-poppins">
                <button
                  className="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-300 focus:outline-none"
                  variant="outlined"
                >
                  <i className="fas fa-print"></i>
                  <span className="ml-4 flex items-start flex-col leading-none">
                    <span className="text-xs text-gray-600 mb-1">
                      {t("userSkills.print")}
                    </span>
                    <span className="title-font font-medium">
                      {t("question.list_question")}
                    </span>
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </section>
      </div>

      <Success
        status={question.success}
        styleParent="mt-5"
        message={question.success_message}
        closer="QUESTION_SUCCESS_OFF"
      />

      <div className="relative flex items-center">
        <div>
          <InputSearch
            setValue={setValue}
            onChange={onChangeInputSearch}
            value={value}
          />
        </div>
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="flex ml-8 cursor-pointer" onClick={toggleFilters}>
              <svg
                className="h-6 w-6 mr-2 text-gray-600"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z" />
              </svg>
              <span>{t("tables.advancedSearch")}</span>
            </div>
            <button
              onClick={resetTable}
              className="ml-6 bg-gray-100 hover:bg-gray-300 py-2 px-5 rounded-lg font-medium"
            >
              {t("tables.all")}
            </button>
          </div>
          {showFilters && (
            <div className="bg-white w-full p-5 flex flex-col absolute left-5 top-14 border rounded-lg shadow-md">
              <Autocomplete
                multiple
                id="skills"
                options={skill.records}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                onChange={handleChange}
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={`${t("portfolio.link")} ${t(
                      "menu_profile.skills"
                    )} (*)`}
                    name="skills"
                    placeholder={t("skills.select")}
                    size="small"
                  />
                )}
              />
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="cursor-pointer mt-3 bg-black text-white px-6 py-2 rounded-lg"
                  onClick={searchQuestions}
                >
                  {t("tables.filter")}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <TableGrid
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        loaded={question.loaded}
        getTableBodyProps={getTableBodyProps}
        page={page}
        prepareRow={prepareRow}
      />
      <Pagination
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getJobsLimit, findJobs } from "../../redux/actions/jobActions";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Footer from "../admin/footer";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export default function Vacancies() {
	const { t } = useTranslation('common');
	const dispatch = useDispatch();
	const jobs = useSelector((state) => state.jobReducers);
	const [find, setFind] = useState({
		loaded: false,
		name_job: [],
		ubication: [],
		location: [],
		type_job: []
	});
	const [data, setData] = useState({ loaded: false });
	const [loadMore, setLoadMore] = useState(false);
	const [loadSelect, setLoadSelect] = useState(false);
	const [loadData, setLoadData] = useState(false);
	const [limitLoad, setLimitLoad] = useState({
		init: 0,
		final: 5
	});
	useEffect(() => {
		dispatch(getJobsLimit({
			init: 0,
			final: 5
		}));
	}, [dispatch]);

	useEffect(() => {
		if (jobs.loaded) setData(jobs);
	}, [jobs]);

	useEffect(() => {
		if (limitLoad.final > 5) {
			data.loaded = false;
			dispatch(findJobs({
				...find,
				init: 0,
				final: limitLoad.final
			}));
			setData({ ...jobs, loaded: true });
			setLoadMore(false);
		}
	}, [dispatch, limitLoad, data, find, jobs]);

	const handleFind = useCallback((e) => {
		if (e !== undefined) {
			data.loaded = false;
			dispatch(findJobs(find));
			setData({ ...jobs, loaded: true });
		}
	}, [dispatch, data, find, jobs]);

	useEffect(() => {
		if(loadSelect){
			data.loaded = false;
			handleFind(true)
			setLoadSelect(false)
		}		
	}, [loadSelect, data, handleFind]);

	useEffect(() => {
		if(loadData){
			data.loaded = false;
			setLoadData(false)
			handleFind(true)
			data.loaded = true;
		}		
	}, [loadData, data, handleFind]);

	const handleLoad = () => {
		setLoadMore(true);
		setLimitLoad({
			init: 0,
			final: limitLoad.final + 5
		})
	}
	const handleChange = (text) => {
		setFind({
			...find,
			[text.target.name]: text.target.value,
		});
		setLoadData(true)
	};

	return (
		<>
			<section>
				<div className="px-4 pt-16 pb-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20 lg:pb-2">
					<div className="max-w-xl lg:mb-10 mb-2 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
						<div>
							<p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
								{t('menu.vacancies')}
							</p>
						</div>
						<h2 className="max-w-lg mb-4 font-poppins md:text-3xl text-lg font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
							<span className="relative inline-block">
								<svg viewBox="0 0 52 24" fill="currentColor" className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
									<defs>
										<pattern id="07690130-d013-42bc-83f4-90de7ac68f76" x="0" y="0" width=".135" height=".30">
											<circle cx="1" cy="1" r=".7"></circle>
										</pattern>
									</defs>
									<rect fill="url(#07690130-d013-42bc-83f4-90de7ac68f76)" width="50" height="24"></rect>
								</svg>
								<span className="relative">{t('vacancies.findVacant')}</span>
							</span>
						</h2>
						<p className="text-base font-sans text-gray-700 md:text-lg">
							{t('vacancies.letCompany')}
						</p>
					</div>
				</div>
				<div className="flex lg:w-2/3 w-full sm:flex-row flex-col mx-auto px-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
					<div className="relative flex-grow w-full">
						<label htmlFor="name_job" className="leading-7 text-sm text-gray-600">{t('vacancies.keyword')}</label>
						<input
							type="text"
							id="name_job"
							name="name_job"
							onChange={handleChange}
							className="w-full bg-gray-100 bg-opacity-25 rounded border border-gray-300 focus:border-blue-900 focus:bg-blue-50 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
						/>
					</div>
					<div className="relative flex-grow w-full">
						<label htmlFor="ubication" className="leading-7 text-sm text-gray-600">{t('vacancies.ubication')}</label>
						<input
							type="text"
							id="ubication"
							name="ubication"
							onChange={handleChange}
							className="w-full bg-gray-100 bg-opacity-25 rounded border border-gray-300 focus:border-blue-900 focus:bg-blue-50 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
						/>
					</div>
				</div>
{/* 				<div className="flex lg:w-2/3 w-full sm:flex-row flex-col mx-auto px-8 sm:space-x-4 mt-2 sm:space-y-0 space-y-4 sm:px-0 items-center">
					<label htmlFor="filtrar" className="flex flex-col leading-7 text-sm text-gray-600">{t("vacancies.filter")}</label></div> */}
				<div className="flex lg:w-2/3 w-full sm:flex-row flex-col mx-auto px-8 sm:space-x-4 mt-4 sm:space-y-0 space-y-4 sm:px-0 justify-start">
				<label htmlFor="filtrar" className="flex flex-col leading-7 text-sm text-gray-600">{t("vacancies.filter")}</label>
					<div className="relative flex-grow lg:inline-flex lg:space-x-5 lg:space-y-0 space-y-5">
						<Autocomplete
							multiple
								id="checkboxes-tags-demo"
								options={['FullTime', 'PartTime']}
								disableCloseOnSelect
								getOptionLabel={(option) => t('vacancies.' + option)}
								onChange={(event, type_job) => {
									setFind({
										...find,
										type_job: type_job
									});
									setLoadSelect(true);
								}
								}
								style={{ width: 300 }}
								renderOption={(option, { selected }) => (
									<React.Fragment>
										<Checkbox
											icon={icon}
											checkedIcon={checkedIcon}
											style={{ marginRight: 2 }}
											checked={selected}
											size="small"
										/>
										{t('vacancies.' + option)}
									</React.Fragment>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										placeholder={t('vacancies.journal')}
										size="small"
									/>
							)}
						/>
						<Autocomplete
							multiple
								id="checkboxes-tags-demo"
								name="location"
								options={['Remote', 'Present']}
								disableCloseOnSelect
								getOptionLabel={(option) => t('vacancies.' + option)}
								onChange={(event, loc) => {
									setFind({
										...find,
										location: loc
									})
									setLoadSelect(true);
								}
                }
								style={{ width: 300 }}
								renderOption={(option, { selected }) => (
									<React.Fragment>
										<Checkbox
											icon={icon}
											checkedIcon={checkedIcon}
											style={{ marginRight: 8 }}
											checked={selected}
											size="small"
										/>
										{t('vacancies.' + option)}
									</React.Fragment>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										placeholder={t('vacancies.typeJob')}
										size="small"
									/>
							)}
						/> 
					<div className="flex lg:w-1/2 justify-start mx-auto px-2 sm:space-x-4 mt-4 sm:space-y-0">
						<button
						onClick={handleFind}
						className="text-white bg-red-500 border-0 py-2 px-6 lg:mt-0 sm:mt-4 content-start focus:outline-none hover:bg-red-600 rounded-lg text-sm font-medium transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
						{t("vacancies.find")}
						</button>
					</div>
					</div>
				</div>	
				<section className="text-gray-600 body-font mb-20 ">
					{data.loaded && data.data.length > 0 &&
						Object.entries(data.data).map(([key, item]) => (
							<div className="justify-center flex" key={key}>
								<div className="p-4  lg:w-4/6 md:w-full">
									<div className="flex flex-col-reverse border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
										<div className="flex-grow">
											<h2 className="text-gray-900 lg:text-2xl title-font text-xl font-bold mb-3">{item.name_job}</h2>
											<div className="flex">
												<i className="mt-1 ml-2 mr-2 fas fa-map-marker-alt"></i>
												<span className="mt-1 space-x-2 text-gray-500 text-sm">{t("vacancies." + item.location)} {item.ubication ? ("-" + item.ubication) : ""} </span>
												<i className="mt-1 ml-4 mr-2 far fa-calendar-alt"></i>
												<span className="mt-1 space-x-2 text-gray-500 text-sm">{t('vacancies.public').replace(/%d/, item.dias)}</span>
											</div>
											<p className="font-bold mx-2 mt-2">{item.salary + " " + t('vacancies.monthly')}</p>
											<p className="leading-relaxed mt-8 text-base text-justify" dangerouslySetInnerHTML={{ __html: item.description_job }}></p>
											<Link to={`/info/vacancies/detail/${item.uuid}`}>
												<button className="border-2 border-red-500 hover:bg-red-500 text-red-500 hover:text-white inline-flex items-center text-xs lg:text-base font-bold py-2 px-4 mt-8 mb-2 rounded-full transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
													{t('vacancies.continue')}
													<svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
														<path d="M5 12h14M12 5l7 7-7 7"></path>
													</svg>
												</button>
											</Link>
										</div>
										<div className="sm:mr-8 sm:mb-0 mb-4 items-center justify-center rounded-full flex-shrink-0">
											<div className="space-x-2">
												{(item.type_job === "FullTime") ?
													<span
														className="inline-block px-2 py-1 font-semibold leading-tight text-blue-700 bg-blue-100 rounded-full dark:bg-green-700 dark:text-red-100">
														{t('vacancies.' + item.type_job)}
													</span>
													:
													<span
														className="inline-block px-2 py-1 font-semibold leading-tight text-yellow-700 bg-yellow-100 rounded-full dark:bg-green-700 dark:text-red-100">
														{t('vacancies.' + item.type_job)}
													</span>
												}
											</div>
											
											{item.priority_job === "Urgent" &&
												<div className="px-2 space-y-2 h-14 w-28 text-red-500 rounded-md">
													<i className="far fa-clock fa-md"></i>{"  "}
													<span className="ml-1 uppercase font-bold font-mono">{t('vacancies.urgent')}</span>
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						))}
					{!data.loaded &&
						<div className="flex justify-center mt-15 mb-24">
							<i className="fa fa-spin fa-spinner fa-2x"></i>
						</div>
					}
					{data.loaded && data.data.length === 0 &&
						<div className="flex justify-center mt-24 mb-24">
							<p className="text-base font-semibold text-gray-700 md:text-lg">{t("vacancies.norecords")}</p>
						</div>
					}
					{data.loaded && data.data.length > 0 && !loadMore &&
						<div className="justify-center flex ">
							<div className="p-4 lg:w-4/6 md:w-full">
								<button
									onClick={handleLoad}
									className="w-full h-12 px-6 text-red-500 font-bold transition-colors duration-150  rounded-sm focus:shadow-outline hover:bg-gray-100">
									{t('vacancies.loadMore')}{" "}
									<i className="fas fa-arrow-down"></i>
								</button>
							</div>
						</div>
					}
					{loadMore &&
						<div className="justify-center flex ">
							<div className="p-4 lg:w-4/6 md:w-full">
								<i className="fa fa-spin fa-spinner fa-2x"></i>
							</div>
						</div>
					}
				</section>
			</section>

			<Footer />
		</>
	)
}
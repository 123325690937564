import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import QuestionStore from "./QuestionStore";
import QuestionMain from "./QuestionMain";
import QuestionEdit from "./QuestionEdit";
import QuestionDelete from "./QuestionDelete";
import Answers from "../answers/Answers";
import AdminRoutes from "../../routes/AdminRoutes";
import ListQuestion from "../reports/ListQuestion";

export default function Tests() {
  let { path } = useRouteMatch();

  return (
    <div className="w-11/12 mx-auto font-poppins">
      <Switch>
        <AdminRoutes exact path={`${path}`} component={QuestionMain} />
        <AdminRoutes
          path={`${path}/add-question/:uuid?`}
          component={QuestionStore}
        />
        <AdminRoutes path={`${path}/list/question`} component={ListQuestion} />
        <AdminRoutes path={`${path}/edit/:uuid`} component={QuestionEdit} />
        <AdminRoutes path={`${path}/delete/:uuid`} component={QuestionDelete} />
        <AdminRoutes path={`${path}/answers/:uuid`} component={Answers} />
      </Switch>
    </div>
  );
}

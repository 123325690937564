const initialState = {
    data : [],
	loaded:false,
	error_message: [],	
}

export default function jobReducers(state = initialState, action) {
	switch(action.type) {
		case 'EMAIL_RESPONSE': {
			return {
				...state,
				loaded:true,
                data:action.payload,
			}
		}
		case 'EMAIL_ERROR': {
			return {
				...state,
				error_message: [],
			}
		}
		case 'EMAIL_OFF': {
			return {
				...state,
				loaded: false
			}
		}		
		default: return state;
	}
}
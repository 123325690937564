import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { handleErrorMessage, handleErrorInput } from "../../utils/functions";
import { updateProfessional } from "../../redux/actions/professionalActions";
import TextField from "@material-ui/core/TextField";
import Button from "../common/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

export default function FormEdit(props) {
  const dispatch = useDispatch();
  const prof = useSelector((state) => state.professionalReducers);
  let { uuid, status, closeModal } = props;
  const [hidden, setHidden] = useState(false);
  const [filterRecords, setFilterRecords] = useState(undefined);
  const effectBG = status ? "fadeIn" : "fadeOut";
  const effectModal = status ? "fadeInDown" : "fadeOutUp";
  const { t } = useTranslation("common");
  const [data, setData] = useState({});
  const [ShowModalerror, setShowModalerror] = useState(false);
  const [dates, setDates] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    setShowModalerror(false);
    if (status) {
      var filter = prof.records.filter((item) => item.uuid === uuid);
      setFilterRecords(filter);
      filter[0].time_professional === "P" ? setDates(true) : setDates(false);
    }
  }, [status, uuid, prof.records]);

  useEffect(() => {
    if (status) {
      setHidden(true);
    } else {
      setTimeout(() => {
        setHidden(false);
      }, 200);
    }
  }, [status]);

  useEffect(() => {
    if (prof.success) closeModal();
    else reset({});
  }, [reset, prof.success, closeModal]);

  const handleChange = (text) => {
    setData({
      ...data,
      [text.target.name]: text.target.value,
    });
    if (
      document.getElementById("time_professional").value === "P" &&
      document.getElementById("from") &&
      document.getElementById("to")
    ) {
      document.getElementById("from").value >
      document.getElementById("to").value
        ? setShowModalerror(true)
        : setShowModalerror(false);
    }
    // (document.getElementById('time_experience').value === "A" && document.getElementById('from').value) ? setShowModalerror(true) : setShowModalerror(false);
  };
  const handleTime = (text) => {
    text.target.name === "time_professional" && text.target.value === "P"
      ? setDates(true)
      : setDates(false);
    handleChange(text);
  };
  const onSubmitData = (data) => {
    // e.preventDefault();
    var time_exp = document.getElementById("time_professional").value;
    data["time_professional"] = time_exp;
    dispatch(updateProfessional(data, uuid));
    setTimeout(() => {
      closeModal();
    }, 200);
  };
  
  useEffect(() => {
    reset({}); //blanquea los valores del formulario
  }, [isSubmitSuccessful, reset]);

  return (
    <>
      {hidden && (
        <>
          <div
            className={`fixed bg-black w-full h-full top-0 left-0 z-20 animate__animated animate__${effectBG} animate__faster`}
            style={{ backgroundColor: "rgba(0,0,0,.5)" }}
            onClick={closeModal}
          ></div>
          <div
            className={`ronded-lg fixed top-0 -left-28 ml-40 mt-44 w-auto items-center center-11-12 rounded-lg bg-white z-30 animate__animated animate__${effectModal} animate__faster`}
          >
            <div className="rounded-md">
              <div className="border-gray-700 bg-gray-700 text-white font-bold">
                <div className="flex justify-between p-3">
                  <label>{t("professional.edit")}</label>
                  <svg
                    onClick={closeModal}
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5  border-2 rounded-md hover:bg-white hover:text-gray-800"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                  {/*  <i className="w-2 h-2 far fa-window-close fa fa-2xl hover:text-blue-300" onClick={closeModal}></i> */}
                </div>
              </div>
              <div className="flex mt-4 mb-6 px-10 pt-10 pb-2">
                {/*cuerpo del modal */}
                <form
                  action=""
                  className="w-full animate__animated animate__fadeIn animate__faster"
                  onSubmit={handleSubmit(onSubmitData)}
                >
                  <div className="w-full md:flex md:mb-5">
                    <div className="py-2 md:py-0 md:w-1/2 mx-2">
                      {/* <TextField
                                    type="text"
                                    id="level"
                                    name="level"
                                    label={t("professional.level")}
                                    variant="outlined"
                                    onChange={handleChange}
                                    defaultValue={filterRecords[0].level}
                                    className="w-full"
                                    error={handleErrorInput(prof, "level")}
                                    helperText={t(handleErrorMessage(prof, "level"))}
                                    InputLabelProps={{
                                       shrink: true,
                                    }}
                                 /> */}
                      <Controller
                        name="level"
                        id="level"
                        control={control}
                        defaultValue={filterRecords[0].level}
                        rules={{
                          required: {
                            value: true,
                            message: t("professional.error.level_required"),
                          },
                          pattern: {
                            value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#]{3,}$/,
                            message: t("professional.error.level_invalid"),
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            type="text"
                            id="level"
                            name="level"
                            label={t("professional.level")}
                            variant="outlined"
                            onChange={handleChange}
                            className="w-full"
                            error={errors.level ? true : false}
                            helperText={t(handleErrorMessage(prof, "level"))}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              max: new Date().toISOString().slice(0, 10),
                            }}
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="level"
                        render={({ message }) => (
                          <p className="mt-1 text-sm text-red-500">{message}</p>
                        )}
                      />
                    </div>
                    <div className="py-2 md:py-0 md:w-1/2 mx-2">
                      {/* <TextField
                                    defaultValue={filterRecords[0].field}
                                    type="text"
                                    id="field"
                                    name="field"
                                    label={t("professional.field")}
                                    variant="outlined"
                                    onChange={handleChange}
                                    className="w-full"
                                    error={handleErrorInput(prof, "field")}
                                    helperText={t(handleErrorMessage(prof, "field"))}
                                    InputLabelProps={{
                                       shrink: true,
                                    }}
                                 /> */}
                      <Controller
                        name="field"
                        id="field"
                        control={control}
                        defaultValue={filterRecords[0].field}
                        rules={{
                          required: {
                            value: true,
                            message: t("professional.error.field_required"),
                          },
                          pattern: {
                            value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#]{3,}$/,
                            message: t("professional.error.field_invalid"),
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            type="text"
                            id="field"
                            name="field"
                            label={t("professional.field")}
                            variant="outlined"
                            onChange={handleChange}
                            className="w-full"
                            error={errors.field ? true : false}
                            helperText={t(handleErrorMessage(prof, "field"))}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              max: new Date().toISOString().slice(0, 10),
                            }}
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="field"
                        render={({ message }) => (
                          <p className="mt-1 text-sm text-red-500">{message}</p>
                        )}
                      />
                    </div>
                    <div className="py-2 md:py-0 md:w-1/2 mx-2">
                      {/* <TextField
                                    defaultValue={filterRecords[0].school}
                                    type="text"
                                    id="school"
                                    name="school"
                                    label={t("professional.school")}
                                    variant="outlined"
                                    onChange={handleChange}
                                    className="w-full"
                                    error={handleErrorInput(prof, "school")}
                                    helperText={t(handleErrorMessage(prof, "school"))}
                                    InputLabelProps={{
                                       shrink: true,
                                    }}
                                 /> */}
                      <Controller
                        name="school"
                        id="school"
                        control={control}
                        defaultValue={filterRecords[0].school}
                        rules={{
                          required: {
                            value: true,
                            message: t("professional.error.school_required"),
                          },
                          pattern: {
                            value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#]{3,}$/,
                            message: t("professional.error.school_invalid"),
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            type="text"
                            id="school"
                            name="school"
                            label={t("professional.school")}
                            variant="outlined"
                            onChange={handleChange}
                            className="w-full"
                            error={errors.school ? true : false}
                            helperText={t(handleErrorMessage(prof, "school"))}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              max: new Date().toISOString().slice(0, 10),
                            }}
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="school"
                        render={({ message }) => (
                          <p className="mt-1 text-sm text-red-500">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="md:flex mb-5">
                    <div className="py-2 md:py-0 md:w-3/12 mx-2">
                      {/* <TextField
                                    defaultValue={filterRecords[0].from}
                                    type="date"
                                    id="from"
                                    name="from"
                                    label={t("professional.from")}
                                    variant="outlined"
                                    onChange={handleChange}
                                    className="w-full"
                                    InputLabelProps={{
                                       shrink: true,
                                    }}
                                    inputProps={{
                                       max: new Date().toISOString().slice(0, 10)
                                    }}
                                    error={handleErrorInput(prof, "from")}
                                    helperText={t(handleErrorMessage(prof, "from"))}
                                 />  */}
                      <Controller
                        name="from"
                        id="from"
                        control={control}
                        defaultValue={filterRecords[0].from}
                        rules={{
                          required: {
                            value: true,
                            message: t("professional.error.from_date"),
                          },
                          pattern: {
                            //value: /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/,
                            message: t("professional.error.from_date"),
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            type="date"
                            id="from"
                            name="from"
                            label={t("professional.from")}
                            variant="outlined"
                            onChange={handleChange}
                            className="w-full"
                            error={errors.from ? true : false}
                            helperText={t(handleErrorMessage(prof, "from"))}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              max: new Date().toISOString().slice(0, 10),
                            }}
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="from"
                        render={({ message }) => (
                          <p className="mt-1 text-sm text-red-500">{message}</p>
                        )}
                      />
                    </div>
                    <div className="py-2 md:py-0 md:w-4/12 mx-2">
                      <InputLabel htmlFor="time_experience">
                        {t("professional.to")}
                      </InputLabel>
                      <Select
                        className="w-full md:my-1"
                        native
                        defaultValue={filterRecords[0].time_professional}
                        onChange={handleTime}
                        inputProps={{
                          name: "time_professional",
                          id: "time_professional",
                        }}
                      >
                        <option value="A">{t("experience.actually")}</option>
                        <option value="P">{t("experience.dateNormal")}</option>
                      </Select>
                    </div>
                    {dates && (
                      <div className="py-2 md:py-0 md:w-3/12 mx-2">
                        {/* <TextField
                                       defaultValue={filterRecords[0].to}
                                       type="date"
                                       id="to"
                                       name="to"
                                       label={t("professional.to")}
                                       variant="outlined"
                                       onChange={handleChange}
                                       className="w-full"
                                       InputLabelProps={{
                                          shrink: true,
                                       }}
                                       inputProps={{
                                          max: new Date().toISOString().slice(0, 10)
                                       }}
                                       error={handleErrorInput(prof, "to")}
                                       helperText={t(handleErrorMessage(prof, "to"))}
                                    /> */}
                        <Controller
                          name="to"
                          id="to"
                          control={control}
                          defaultValue={filterRecords[0].to}
                          rules={{
                            required: {
                              value: true,
                              message: t("professional.error.from_date"),
                            },
                            pattern: {
                              //value: /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/,
                              message: t("professional.error.from_date"),
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              type="date"
                              id="to"
                              name="to"
                              label={t("professional.to")}
                              variant="outlined"
                              onChange={handleChange}
                              className="w-full"
                              error={errors.to ? true : false}
                              helperText={t(handleErrorMessage(prof, "to"))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: new Date().toISOString().slice(0, 10),
                              }}
                              {...field}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="to"
                          render={({ message }) => (
                            <p className="mt-1 text-sm text-red-500">
                              {message}
                            </p>
                          )}
                        />
                      </div>
                    )}
                  </div>
                  <div className="md:flex mb-5">
                    <div className="py-2 md:py-0 md:w-full mx-2">
                      {/* <TextField
                                    defaultValue={filterRecords[0].location}
                                    type="text"
                                    id="location"
                                    name="location"
                                    label={t("professional.location")}
                                    variant="outlined"
                                    onChange={handleChange}
                                    className="w-full"
                                    InputLabelProps={{
                                       shrink: true,
                                    }}
                                 /> */}
                      <Controller
                        name="location"
                        id="location"
                        control={control}
                        defaultValue={filterRecords[0].location}
                        rules={{
                          required: {
                            value: true,
                            message: t("professional.error.location_required"),
                          },
                          pattern: {
                            value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#]{3,}$/,
                            message: t("professional.error.location_invalid"),
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            type="text"
                            id="location"
                            name="location"
                            label={t("professional.location")}
                            variant="outlined"
                            onChange={handleChange}
                            className="w-full"
                            error={handleErrorInput(prof, "location")}
                            helperText={t(handleErrorMessage(prof, "location"))}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              max: new Date().toISOString().slice(0, 10),
                            }}
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="location"
                        render={({ message }) => (
                          <p className="mt-1 text-sm text-red-500">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                  {ShowModalerror ? (
                    <div className="md:w-11/12 mb-5 flex justify-center mx-2">
                      <label className="text-xs font-semibold	text-red-500">
                        {t("experience.error.to_after")}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                  {ShowModalerror ? (
                    " "
                  ) : (
                    <div className="flex justify-end px-5 pt-5 pb-1">
                      <Button
                        status={prof.button}
                        text={t("modal.edit")}
                        icon="fa fa-check"
                      />
                    </div>
                  )}
                </form>
                {/*cuerpo del modal */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateCurrency } from "../../../redux/actions/admin/currencyActions";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { Button } from "../../common";

export default function CurrenciesEdit({
  open,
  handleClose,
  preloadedValues: { uuid, code, name },
}) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      code,
      name,
    },
  });

  const onSubmitData = (data) => {
    dispatch(updateCurrency(data, uuid));
    handleClose("edit");
  };

  return (
    <Dialog open={open} onClose={() => handleClose("edit")} fullWidth={true}>
      <div className="flex justify-end mt-2 mr-2">
        <button onClick={() => handleClose("edit")}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer h-5 w-5  border-2 rounded-md hover:bg-gray-200 hover:text-gray-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <DialogTitle className="border-b text-center">
        <span className="font-bold font-poppins">{t("currency.edit")}</span>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmitData)} className="w-full pr-3">
          <div className="w-full mx-2 my-5">
            <Controller
              name="code"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("currency.error.code_required"),
                },
              }}
              render={({ field }) => (
                <TextField
                  id="code"
                  name="code"
                  type="text"
                  label={t("currency.code")}
                  variant="outlined"
                  className="w-full"
                  error={errors.code ? true : false}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="code"
              render={({ message }) => (
                <div className="text-sm mt-1 text-red-500 font-poppins">
                  {message}
                </div>
              )}
            />
          </div>
          <div className="w-full mx-2 my-5">
            <Controller
              name="name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("personal.error.name_required"),
                },
              }}
              render={({ field }) => (
                <TextField
                  id="name"
                  name="name"
                  type="text"
                  label={t("role.name")}
                  variant="outlined"
                  className="w-full"
                  error={errors.name ? true : false}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => (
                <div className="text-sm mt-1 text-red-500 font-poppins">
                  {message}
                </div>
              )}
            />
          </div>

          <div className="flex justify-center w-full mt-7">
            <Button
              status={false}
              text={t("question.update")}
              icon="fa fa-edit"
            />
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Slide
} from "@material-ui/core";

import { useTranslation } from "react-i18next";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SessionTimeout(props) {
    const { open, countdown, onLogout, onContinue } = props;
    const { t } = useTranslation("common");

    return (
        
        <Dialog
            open={open}
            className={`fixed bg-black w-full h-full top-0 left-0 z-20 font-sans `}
            style={{ backgroundColor: "rgba(0,0,0,.5)" }}
            TransitionComponent={Transition}>
            <DialogTitle className="border-gray-700 bg-gray-700">
                <div className="font-sans  text-white font-bold">
                    {t('session.expire')}
                </div>
            </DialogTitle>
            <DialogContent>
                <div>
                    <span className="px-1 my-1">{t('session.expired')}</span>
                    <span className="text-red-500 font-bold">{countdown}</span>
                </div>
                <div>    
                    <span className="font-bold">{t('session.continue')}</span>
                </div>
            </DialogContent>
            <DialogActions>     
                <button
                    onClick={onLogout}
                    className=" bg-red-500 text-white active:bg-emerald-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:bg-red-700 hover:shadow-md outline-none focus:outline-none mr-1 mb-1">           
                    {t('menu.logout')}
                </button>
                <button
                    onClick={onContinue}
                    className=" bg-blue-500 text-white active:bg-emerald-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:bg-blue-700 hover:shadow-md outline-none focus:outline-none mr-1 mb-1">           
                    {t('session.continueSession')}
                </button>
            </DialogActions>
        </Dialog>
    );
}

import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import EmailMain from "./EmailMain";
import AdminRoutes from "../../routes/AdminRoutes";
import EmailAdd from "./EmailAdd";
import EmailEdit from "./EmailEdit";
import EmailDetail from "./EmailDetail";

export default function Email() {
  let { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <AdminRoutes exact path={`${path}`} component={EmailMain} />
        <AdminRoutes path={`${path}/add`} component={EmailAdd} />
        <AdminRoutes path={`${path}/edit/:uuid`} component={EmailEdit} />
        <AdminRoutes path={`${path}/detail/:uuid`} component={EmailDetail} />
      </Switch>
    </div>
  );
}

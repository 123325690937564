import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { TextField } from "@material-ui/core";

import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import CustomButton from "../../common/Button";
import { emailApplicant } from "../../../redux/actions/emailActions";

export default function EmailCustom({
  dataPostulate,
  dateRef,
  fromRef,
  toRef,
  setNotifyModal,
  setError,
  closeForm,
}) {
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      board_name: dataPostulate.stage,
      subject: "",
      title: "",
      message: "",
      language: i18n.language,
    },
  });

  useEffect(() => {
    register("board_name", { required: t("notification.error.stage") });
  }, [register, t]);

  const onSubmitData = (data) => {
    const date = dateRef.current.querySelector("input").value;
    const from = fromRef.current.querySelector("input").value;
    const to = toRef.current.querySelector("input").value;

    dispatch(
      emailApplicant({
        ...dataPostulate,
        interview_data: data.board_name?.includes("Interview")
          ? {
              date,
              from,
              to,
            }
          : "",
        template: { uuid: "", ...data },
      })
    );
    dispatch({
      type: "NOTIFICATION_SUCCESS",
    });
    setNotifyModal(false);
    closeForm(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitData)} className="w-4/5 mx-4">
        <div className="w-full mx-2 my-7">
          <Controller
            name="subject"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("notification.error.subject"),
              },
            }}
            render={({ field }) => (
              <TextField
                id="subject"
                name="subject"
                type="text"
                label={t("notification.subject")}
                variant="outlined"
                className="w-full"
                error={errors.subject ? true : false}
                {...field}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="subject"
            render={({ message }) => (
              <div className="text-sm mt-1 text-red-500 font-poppins">
                {message}
              </div>
            )}
          />
        </div>
        <div className="w-full mx-2 my-5">
          <Controller
            name="title"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("notification.error.title"),
              },
            }}
            render={({ field }) => (
              <TextField
                id="title"
                name="title"
                type="text"
                label={t("notification.title")}
                variant="outlined"
                className="w-full"
                error={errors.title ? true : false}
                {...field}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="title"
            render={({ message }) => (
              <div className="text-sm mt-1 text-red-500 font-poppins">
                {message}
              </div>
            )}
          />
        </div>
        <div className="w-full mx-2 my-5">
          <div>
            <div>{t("notification.variables_list")}</div>
            <div className="mt-2 mb-4">
              <ul className="list-disc ml-5">
                <li>{`${t("notification.variables.name.legend")}: ${t(
                  "notification.variables.name.label"
                )}`}</li>
                <li>{`${t("notification.variables.job.legend")}: ${t(
                  "notification.variables.job.label"
                )}`}</li>
                {getValues("board_name")?.includes("Interview") && (
                  <>
                    <li>{`${t("notification.variables.interview.legend")}: ${t(
                      "notification.variables.interview.label"
                    )}`}</li>
                    <ul className="list-disc ml-5">
                      <li>{`${t("notification.variables.interview.from")}: ${t(
                        "notification.variables.interview.from_label"
                      )}`}</li>
                      <li>{`${t("notification.variables.interview.to")}: ${t(
                        "notification.variables.interview.to_label"
                      )}`}</li>
                      <li>{`${t("notification.sender")}: ${t(
                        "notification.variables.interview.sender"
                      )}`}</li>
                    </ul>
                  </>
                )}
              </ul>
              <p className="mt-2">{t("notification.variables_description")}</p>
            </div>
          </div>
          <Controller
            name="message"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("notification.error.message"),
              },
            }}
            render={({ field }) => (
              <TextField
                id="message"
                name="message"
                multiline
                minRows={12}
                label={t("notification.message")}
                variant="outlined"
                className="w-full"
                error={errors.message ? true : false}
                {...field}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="message"
            render={({ message }) => (
              <div className="text-sm mt-1 text-red-500 font-poppins">
                {message}
              </div>
            )}
          />
        </div>

        <div className="flex justify-center w-full mt-7">
          <CustomButton
            status={false}
            text={t("notification.send")}
            icon="fa fa-check"
          />
        </div>
      </form>
    </>
  );
}

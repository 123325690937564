import React from "react";
import { useTranslation } from "react-i18next";

export default function CardOne({ item, handlePage, cardSelected, apply }) {
  const { t } = useTranslation("common");

  return (
    <>
      <div
        className={
          cardSelected.uuid === item.uuid
            ? "block bg-white pt-10 pb-8 border-2 rounded-2xl mb-2 lg:border-red-500 overflow-hidden"
            : "block bg-white pt-10 pb-8 border-2 rounded-2xl border-gray-100 mb-2 lg:hover:border-2 hover:border-gray-500 focus:border-gray-500 overflow-hidden"
        }
        name={item.uuid}
        id={item.uuid}
        onClick={() => handlePage(item)}
      >
        <div className="px-4 py-2 flex justify-between">
          <div className="flex-grow">
            {item.priority_job === "Urgent" && (
              <p className=" text-red-500 text-xs font-poppins font-bold mx-2">
                {t("vacancies.urgent")}
              </p>
            )}
            <div className="flex flex-row-reverse md:flex-none justify-between">
              <div className="space-x-2">
                {item.type_job === "FullTime" ? (
                  <span className="inline-block px-2 py-1 font-semibold leading-tight text-xs text-blue-700 bg-blue-100 rounded-md font-poppins">
                    {t("vacancies." + item.type_job)}
                  </span>
                ) : (
                  <span className="font-poppins inline-block px-2 py-1 font-semibold leading-tight text-xs text-yellow-700 bg-yellow-100 rounded-md">
                    {t("vacancies." + item.type_job)}
                  </span>
                )}
              </div>
              <h2 className="text-gray-900 lg:text-lg title-font text-sm font-semibold mb-3 mx-2 font-poppins">
                {item.name_job}
              </h2>
            </div>
            <div className="flex">
              <i className="mt-1 ml-2 mr-2 fas fa-map-marker-alt"></i>
              <span className="mt-1 space-x-2 text-gray-500 text-xs font-poppins">
                {t("vacancies." + item.location)}{" "}
                {item.ubication ? "- " + item.ubication : ""}
              </span>
              <i className="mt-1 ml-4 mr-2 fas fa-calendar-alt"></i>
              <span className="mt-1 space-x-2 text-gray-500 text-xs font-poppins">
                {t("vacancies.public").replace(/%d/, item.dias)}
              </span>
            </div>
            <i className="mt-1 ml-1 mr-1 fas fa-money-bill-alt"></i>
            <span className="mt-1 space-x-2 text-gray-500 text-xs font-poppins">
              {`${
                item.salary.toLowerCase() !== "a convenir" &&
                item.salary !== "Salary to be agreed"
                  ? item.currency + " "
                  : ""
              }
                ${item.salary} ${t("vacancies.monthly")}`}
            </span>
          </div>
        </div>
        <p className="font-poppins mx-2 px-4 py-2 text-xs font-semibold overflow-hidden text-gray-700 truncate">
          {item.description_job
            ? item.description_job.replace(/(<([^>]+)>|&nbsp;)/gi, "")
            : ""}
        </p>
        <div className="flex justify-end mx-8">
          <button className="visible lg:invisible bg-gray-100 text-gray-500 border-2 hover:border-gray-300 focus:border-4 px-2 py-1 rounded-lg text-xs">
            {t("vacancies.viewDetails")}
            <i className="fas fa-info-circle mx-1 text-lg"></i>
          </button>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import { Link, Redirect } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { handleErrorMessage, handleErrorInput } from "../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { getSkillsData } from "../../../redux/actions/skillActions";
import CustomButton from "../../common/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getJobs, saveJobs } from "../../../redux/actions/admin/jobActions";
import { getSelectClients } from "../../../redux/actions/admin/clientActions";
import { getCurrencies } from "../../../redux/actions/admin/currencyActions";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useTranslation } from "react-i18next";
import ModalSkills from "../../modals/ModalSkills";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Switch } from "@material-ui/core";
import { SingleError } from "../../common";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function VacantsStore() {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const job = useSelector((state) => state.jobsReducers);
  const { i18n } = useTranslation("common");
  const clients = useSelector((state) => state.clientsReducers);
  const skill = useSelector((state) => state.skillReducers);
  const currency = useSelector((state) => state.currencyReducers);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      name_job: "",
      report_job: "",
      currency: "$",
      salary: "",
      salarymax: "",
      priority_job: "Normal",
      cant_vacant: "",
      ubication: "",
      type_job: "FullTime",
      location: "Remote",
      status_job: "A",
      client_id: undefined,
      description_job: "",
      esential_job: "",
      know_skills: "",
      language: i18n.language,
    },
  });
  const editorRef = useRef();
  const [isSalaryAgreed, setIsSalaryAgreed] = useState(false);
  const [isSalaryRange, setIsSalaryRange] = useState(false);
  const [hasSalaryError, setHasSalaryError] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    register("description_job", {
      required: true,
    });

    register("esential_job", {
      required: true,
    });

    register("know_skills", {
      required: true,
    });

    register("quality_min_job");
    register("prefered_job");

    register("client_id", {
      required: t("vacancies.error.clientAsoc"),
    });

    register("skills", {
      required: t("vacancies.error.skills"),
    });
  }, [register, t]);

  useEffect(() => {
    if (!job.loaded) {
      dispatch(getJobs());
    }
    dispatch(getSelectClients());
  }, [dispatch, job.loaded]);

  useEffect(() => {
    if (!currency.loaded) dispatch(getCurrencies());
  }, [dispatch, currency.loaded]);

  useEffect(() => {
    if (!skill.skill.loaded) dispatch(getSkillsData());
  }, [dispatch, skill.skill.loaded]);

  const onSubmitJob = async (data) => {
    let thousandSeparator = i18n.language === "es" ? "." : ",";
    let { salary, salarymax } = data;
    if (!isSalaryAgreed) salary = formatCurrency(salary);

    if (isSalaryRange) {
      salarymax = formatCurrency(salarymax);

      if (
        parseFloat(salary.replaceAll(thousandSeparator, "")) >
        parseFloat(salarymax.replaceAll(thousandSeparator, ""))
      ) {
        setHasSalaryError(true);
        return;
      }

      data.salary = `${salary}-${salarymax}`;
    }

    if (isSalaryAgreed) {
      data.salary = t("vacancies.salary_agreed");
    }

    await dispatch(saveJobs(data));
  };

  const formatCurrency = (amount) => {
    let decimalSeparator = i18n.language === "es" ? "," : ".";
    let thousandSeparator = i18n.language === "es" ? "." : ",";
    const formattedSalary = amount
      .replace(/\D/g, "")
      .replace(/([0-9])([0-9]{2})$/, `$1${decimalSeparator}$2`)
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, `${thousandSeparator}`);

    return formattedSalary;
  };

  const handleSalaryAgreed = ({ target: { checked } }) => {
    setIsSalaryAgreed(checked);
    setValue("salary", "");

    if (isSalaryRange) {
      setIsSalaryRange(false);
      setValue("salarymax", "");
    }
  };

  const handleSalaryRange = ({ target: { checked } }) => {
    setIsSalaryRange(checked);
  };

  return (
    <>
      {job.redirect && <Redirect to="/vacancies/admin" />}
      <div className="mt-5 flex justify-end">
        <Link to="/vacancies/admin">
          <Button className="focus:outline-none" variant="outlined">
            <i className="fa fa-arrow-left mr-2"></i> {t("personal.back")}
          </Button>
        </Link>
      </div>
      {currency.loaded ? (
        <form className="w-10/12 mx-auto" onSubmit={handleSubmit(onSubmitJob)}>
          <div className="flex w-full sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-6">
            <div className="relative flex-grow w-full">
              <Controller
                name="name_job"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("vacancies.error.name_job"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="name_job"
                    label={t("vacancies.position") + " (*)"}
                    name="name_job"
                    variant="outlined"
                    className="w-full"
                    error={errors.name_job ? true : false}
                    helperText={t(handleErrorMessage(job, "name_job"))}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="name_job"
                render={({ message }) => (
                  <p className="text-xs mt-1 text-red-500">{message}</p>
                )}
              />
            </div>
            <div className="relative flex-grow w-full">
              <Controller
                name="report_job"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("vacancies.error.report_job"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="report_job"
                    label={`${t("vacancies.reportsTo")} (*)`}
                    name="report_job"
                    variant="outlined"
                    className="w-full"
                    error={errors.report_job ? true : false}
                    helperText={t(handleErrorMessage(job, "report_job"))}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="report_job"
                render={({ message }) => (
                  <p className="text-xs mt-1 text-red-500">{message}</p>
                )}
              />
            </div>
          </div>
          {/* se crea el div que contendrá el  checkbox que no define salario */}
          <div className="flex items-center">
            <div className="flex lg:w-full lg:flex-row flex-start items-center flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-6">
              {/* div para el toogle */}
              <div className="flex items-center mt-4 sm:mt-0 relative">
                <Switch
                  id="checksalary"
                  name="checksalary"
                  size="medium"
                  defaultChecked={false}
                  onChange={handleSalaryAgreed}
                />
                <span>{t("vacancies.salary_to_be_agreed")}</span>
              </div>
              {!isSalaryAgreed && (
                <div className="flex items-center mt-4 sm:mt-0 relative">
                  <Switch
                    id="salary_range"
                    name="salary_range"
                    size="medium"
                    defaultChecked={false}
                    onChange={handleSalaryRange}
                  />
                  <span>{t("vacancies.salary_range")}</span>
                </div>
              )}
              <div className="w-3/5 pl-8">
                <SingleError
                  status={hasSalaryError}
                  styleParent="w-full mx-auto"
                  errors={t("vacancies.error.minsalary_above_maxsalary")}
                  closer={() => setHasSalaryError(false)}
                />
              </div>
            </div>
          </div>

          <div className="flex lg:w-full lg:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-6">
            <div className="flex lg:w-1/2 w-full mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0">
              <div
                className={`mt-4 sm:mt-0 relative flex-grow ${
                  isSalaryRange ? "w-1/3 lg:w-2/12" : ""
                }`}
              >
                <FormControl className="w-full" variant="outlined">
                  <InputLabel
                    htmlFor="currency"
                    error={handleErrorInput(clients, "currency")}
                  >
                    {`${t("clients.currency")}(*)`}
                  </InputLabel>
                  <Controller
                    disabled
                    name="currency"
                    id="currency"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                      },
                    }}
                    render={({ field }) => (
                      <Select
                        name="currency"
                        id="currency"
                        native
                        value={field.value}
                        label={`${t("clients.currency")} (*)`}
                        className="w-full"
                        disabled={isSalaryAgreed}
                        {...field}
                      >
                        {currency.records?.map((option) => {
                          return (
                            <option key={option.code} value={option.code}>
                              {option.code}
                            </option>
                          );
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
              </div>

              <div
                className={`relative flex-grow w-2/3 sm:pb-5 lg:w-10/12 ${
                  isSalaryRange ? "pl-3" : ""
                }`}
              >
                <Controller
                  name="salary"
                  id="salary"
                  control={control}
                  rules={{
                    required: {
                      value: !isSalaryAgreed,
                      message: t("vacancies.error.salary"),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      type="text"
                      id="salary"
                      label={t("vacancies.salarymin") + " (*)"}
                      name="salary"
                      variant="outlined"
                      className="w-full"
                      disabled={isSalaryAgreed}
                      error={errors.salary && !isSalaryAgreed ? true : false}
                      {...field}
                      value={formatCurrency(field.value)}
                    />
                  )}
                />
                {!isSalaryAgreed && (
                  <ErrorMessage
                    errors={errors}
                    name="salary"
                    render={({ message }) => (
                      <p className="text-xs mt-1 text-red-500">{message}</p>
                    )}
                  />
                )}
              </div>
              {isSalaryRange && (
                <div className="relative flex-grow w-2/3 lg:w-10/12">
                  <Controller
                    name="salarymax"
                    id="salarymax"
                    control={control}
                    rules={{
                      required: {
                        value: !isSalaryAgreed && isSalaryRange,
                        message: t("vacancies.error.salary_max"),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        type="text"
                        id="salarymax"
                        label={t("vacancies.salarymax") + " (*)"}
                        name="salarymax"
                        variant="outlined"
                        className="w-full"
                        disabled={isSalaryAgreed}
                        error={
                          errors.salarymax && !isSalaryAgreed && isSalaryRange
                            ? true
                            : false
                        }
                        {...field}
                        value={formatCurrency(field.value)}
                      />
                    )}
                  />
                  {!isSalaryAgreed && isSalaryRange && (
                    <ErrorMessage
                      errors={errors}
                      name="salarymax"
                      render={({ message }) => (
                        <p className="text-xs mt-1 text-red-500">{message}</p>
                      )}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="lg:mt-0 mt-2 relative flex-grow w-full lg:w-1/2">
              <Controller
                name="priority_job"
                control={control}
                rules={{
                  required: {
                    value: true,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    select
                    variant="outlined"
                    className="w-full mt-20"
                    value={field.value}
                    label={`${t("vacancies.priority")} (*)`}
                    error={errors.priority_job ? true : false}
                    helperText={t(handleErrorMessage(job, "priority_job"))}
                    inputProps={{
                      name: "priority_job",
                      id: "priority_job",
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    {...field}
                  >
                    <option value="Normal">{t("vacancies.normal")}</option>
                    <option value="Urgent">{t("vacancies.urgent")}</option>
                  </TextField>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="priority_job"
                render={({ message }) => (
                  <p className="text-xs mt-1 text-red-500">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="flex lg:w-full sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-6 ">
            <div className="relative flex-grow w-full">
              <Controller
                name="type_job"
                control={control}
                rules={{
                  required: {
                    value: true,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    select
                    variant="outlined"
                    className="w-full"
                    value={field.type_job}
                    error={errors.type_job ? true : false}
                    label={`${t("vacancies.journal")} (*)`}
                    inputProps={{
                      name: "type_job",
                      id: "type_job",
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    {...field}
                  >
                    <option value="PartTime">{t("vacancies.partTime")}</option>
                    <option value="FullTime">{t("vacancies.fullTime")}</option>
                  </TextField>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="type_job"
                render={({ message }) => (
                  <p className="text-xs mt-1 text-red-500">{message}</p>
                )}
              />
            </div>
            <div className="relative flex-grow w-full">
              <Controller
                name="location"
                control={control}
                rules={{
                  required: {
                    value: true,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    select
                    variant="outlined"
                    className="w-full"
                    value={field.value}
                    error={errors.location ? true : false}
                    label={`${t("vacancies.typeJob")} (*)`}
                    inputProps={{
                      name: "location",
                      id: "location",
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    {...field}
                  >
                    <option value="Remote">{t("vacancies.remoto")}</option>
                    <option value="Present">{t("vacancies.presential")}</option>
                  </TextField>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="location"
                render={({ message }) => (
                  <p className="text-xs mt-1 text-red-500">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="flex lg:w-full sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-6 ">
            <div className="relative flex-grow w-full">
              <Controller
                name="cant_vacant"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("vacancies.error.cant_vacant"),
                  },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: t("vacancies.error.fieldNumeric"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="cant_vacant"
                    label={t("vacancies.cantVacant") + " (*)"}
                    name="cant_vacant"
                    variant="outlined"
                    className="w-full"
                    error={errors.cant_vacant ? true : false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={t(handleErrorMessage(job, "cant_vacant"))}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="cant_vacant"
                render={({ message }) => (
                  <p className="text-xs mt-1 text-red-500">{message}</p>
                )}
              />
            </div>
            <div className="relative flex-grow w-full mt-6">
              <Controller
                name="ubication"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("vacancies.error.ubication"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="ubication"
                    label={`${t("vacancies.ubication")} (*)`}
                    name="ubication"
                    variant="outlined"
                    error={errors.ubication ? true : false}
                    className="w-full"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={t(handleErrorMessage(job, "ubication"))}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="ubication"
                render={({ message }) => (
                  <p className="text-xs mt-1 text-red-500">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="flex lg:w-full sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-6 ">
            <div className="relative flex-grow w-full">
              {clients.data !== undefined && (
                <>
                  <Autocomplete
                    id="client_id"
                    options={clients.data}
                    getOptionLabel={(option) => option.name_clients}
                    onChange={(event, options) =>
                      setValue("client_id", options.id)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("clients.clientAsoc") + " (*)"}
                        variant="outlined"
                        error={errors.client_id ? true : false}
                        helperText={t(handleErrorMessage(job, "client_id"))}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="client_id"
                    render={({ message }) => (
                      <p className="text-xs mt-1 text-red-500">{message}</p>
                    )}
                  />
                  {!clients.data.length && (
                    <div className="mt-1 bg-red-100 border-l-4 border-red-500 text-red-700 p-1">
                      <svg
                        className="inline flex-shrink-0 mr-3 w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>{t("clients.noClients")}</span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="relative flex-grow w-full mt-6">
              <FormControl variant="outlined" className="w-full">
                <InputLabel
                  htmlFor="status_job"
                  error={handleErrorInput(clients, "status_job")}
                >
                  {`${t("clients.status")} (*)`}
                </InputLabel>
                <Controller
                  name="status_job"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t("personal.error.status_job"),
                    },
                  }}
                  render={({ field }) => (
                    <Select
                      native
                      error={errors.status_job ? true : false}
                      label={`${t("clients.status")} (*)`}
                      inputProps={{
                        name: "status_job",
                        id: "status_job",
                      }}
                      {...field}
                    >
                      <option value="A">{t("clients.active")}</option>
                      <option value="I">{t("clients.inactive")}</option>
                    </Select>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="status_job"
                  render={({ message }) => (
                    <p className="text-xs mt-1 text-red-500">{message}</p>
                  )}
                />
              </FormControl>
            </div>
          </div>
          <div className="flex lg:w-full sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-6">
            <div className="relative flex items-start flex-grow w-1/2">
              {skill.skill.loaded && (
                <>
                  <Autocomplete
                    multiple
                    id="skills"
                    name="skills"
                    className="w-full"
                    options={skill.skill.records}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, options) => {
                      setValue("skills", options);
                    }}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          variant="outlined"
                          label={`${t("portfolio.link")} ${t(
                            "menu_profile.skills"
                          )} (*)`}
                          name="skills"
                          placeholder={t("skills.select")}
                          error={errors.skills ? true : false}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="skills"
                          render={({ message }) => (
                            <p className="text-xs mt-1 text-red-500">
                              {message}
                            </p>
                          )}
                        />
                      </>
                    )}
                  />
                  <div className="ml-4">
                    <ModalSkills></ModalSkills>
                  </div>
                </>
              )}
            </div>

            <div className="w-1/2">
              <Controller
                name="language"
                id="language"
                control={control}
                rules={{
                  required: {
                    value: true,
                  },
                }}
                render={({ field }) => (
                  <>
                    <FormControl variant="outlined" className="w-full">
                      <InputLabel htmlFor="language">
                        {t("menu.language")}
                      </InputLabel>
                      <Select
                        native
                        label={t("menu.language")}
                        inputProps={{
                          name: "language",
                          id: "language",
                        }}
                        {...field}
                      >
                        <option value="en">{t("menu.english")}</option>
                        <option value="es">{t("menu.spanish")}</option>
                      </Select>
                    </FormControl>
                  </>
                )}
              />
            </div>
          </div>
          {editorLoaded && (
            <div className="relative flex-grow w-full mt-6">
              <InputLabel htmlFor="description_job">
                {`${t("vacancies.positionSumary")} (*)`}
              </InputLabel>
              <CKEditor
                id="description_job"
                editor={ClassicEditor}
                onReady={(editor) => {}}
                data="<p></p>"
                error={errors.description_job ? true : false}
                onChange={(event, editor) => {
                  setValue("description_job", editor.getData());
                }}
              />
              {errors.description_job && (
                <p className="text-xs mt-1 text-red-500">
                  {t("vacancies.error.job_description")}
                </p>
              )}
            </div>
          )}
          {editorLoaded && (
            <div className="flex lg:w-full sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-6 ">
              <div className="relative flex-grow w-full">
                <InputLabel htmlFor="esential_job">
                  {`${t("vacancies.esentialJob")} (*)`}
                </InputLabel>
                <CKEditor
                  id="esential_job"
                  name="esential_job"
                  editor={ClassicEditor}
                  data="<p></p>"
                  onReady={(editor) => {}}
                  error={errors.esential_job ? true : false}
                  onChange={(event, editor) => {
                    setValue("esential_job", editor.getData());
                  }}
                />
                {errors.esential_job && (
                  <p className="text-xs mt-1 text-red-500">
                    {t("vacancies.error.esential_job")}
                  </p>
                )}
              </div>
              <div className="relative flex-grow w-full">
                <InputLabel htmlFor="know_skills">
                  {`${t("vacancies.knowSkills")} (*)`}
                </InputLabel>
                <CKEditor
                  id="know_skills"
                  name="know_skills"
                  editor={ClassicEditor}
                  data="<p></p>"
                  onReady={(editor) => {}}
                  error={errors.know_skills ? true : false}
                  onChange={(event, editor) => {
                    setValue("know_skills", editor.getData());
                  }}
                />
                {errors.description_job && (
                  <p className="text-xs mt-1 text-red-500">
                    {t("vacancies.error.know_skills")}
                  </p>
                )}
              </div>
            </div>
          )}
          {editorLoaded && (
            <div className="flex lg:w-full sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-6 ">
              <div className="relative flex-grow w-full">
                <InputLabel htmlFor="quality_min_job">
                  {t("vacancies.minQuality")}
                </InputLabel>
                <CKEditor
                  id="quality_min_job"
                  name="quality_min_job"
                  editor={ClassicEditor}
                  data="<p></p>"
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    setValue("quality_min_job", editor.getData());
                  }}
                />
              </div>
              <div className="relative flex-grow w-full">
                <InputLabel htmlFor="prefered_job">
                  {t("vacancies.preferedJobs")}
                </InputLabel>
                <CKEditor
                  id="prefered_job"
                  name="prefered_job"
                  editor={ClassicEditor}
                  data="<p></p>"
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    setValue("prefered_job", editor.getData());
                  }}
                />
              </div>
            </div>
          )}
          <div className="relative flex-grow w-full mt-6">
            <Controller
              name="work_conditions_job"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  id="work_conditions_job"
                  name="work_conditions_job"
                  variant="outlined"
                  label={t("vacancies.workConditions")}
                  className="w-full"
                  helperText={handleErrorMessage(job, "work_conditions_job")}
                  multiline
                  minRows={4}
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex justify-center w-full mt-7">
            <CustomButton
              status={job.button}
              text={t("experience.addbtn")}
              icon="fa fa-check"
            />
          </div>
        </form>
      ) : (
        <div className="justify-center">
          <div className="text-center mt-56 p-4 overflow-x-auto">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        </div>
      )}
    </>
  );
}

const initialState = {
	button: false,
	records: [],
	error: false,
	success: false,
	error_message: [],
	success_message: "",
	type:""
}

export default function answerReducers( state = initialState, action ) {
	switch(action.type) {
		case 'ANSWERS_BUTTON': {
			return {
				...state,
				button: true
			}
		}
		case 'ANSWERS_RECORDS': {
			return {
				...state,
				records: action.payload
			}	
		}
		case 'ANSWERS_TYPETEXT': {
			return {
				...state,
				type: action.payload
			}	
		}
		case 'ANSWERS_SUCCESS': {
			return {
				...state,
				records: state.records.concat(action.payload),
				error: false,
				success: true,
				button: false
			}
		}
		case 'ANSWERS_ERROR': {
			return {
				...state,
				error: true,
				error_message: action.payload,
				button: false,
				success: false
			}
		}
		case 'ANSWERS_DEFAULT_SUCCESS': {
			return {
				...state,
				error: false,
				success: true,
				records: action.payload
			}
		}
		case 'ANSWERS_DELETE': {
			const result = state.records.filter( item => item.uuid !== action.payload.uuid);
			return {
				...state,
				error: false,
				success: false,
				records: result
			}
		}
		case 'ANSWERS_RESTART': {
			return {
				...state,
				error: false,
				success: false,
				button:false
			}
		}
		default: return state;
	}
}
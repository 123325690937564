import React from "react";
import { useTranslation } from "react-i18next";

export default function UserPortfolio(props) {
  const { t } = useTranslation("common");
  const { records } = props;

  return (
    <table className="w-full userInfoTable font-poppins text-sm">
      <thead>
        <tr>
          <th>{t("experience.description")}</th>
          <th>{t("portfolio.file")}</th>
          <th>{t("portfolio.link")}</th>
        </tr>
      </thead>
      <tbody>
        {records.length > 0 ? (
          Object.entries(records).map(([key, { description, file, link }]) => (
            <tr key={key}>
              <td>{description}</td>
              <td>
                {file && (
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}` + file}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-external-link-alt"></i> Open Capture
                  </a>
                )}
              </td>
              <td>
                {link && (
                  <a href={link} target="_blank" rel="noreferrer">
                    <i className="fa fa-link"></i> Go to
                  </a>
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="8" style={{ textAlign: "center" }}>
              {t("portfolio.norecords")}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { PDFViewer } from "@react-pdf/renderer";
import { getListQuestion } from "../../../redux/actions/admin/questionActions";
import ListQuestionsPdf from "./ListQuestionsPdf";

export default function ListQuestionsPrint(props) {
  const {
    match: {
      params: { uuid },
    },
  } = props;
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const list = useSelector((state) => state.questionReducers);

  useEffect(() => {
    dispatch(getListQuestion(i18n.language, uuid));
  }, [dispatch, uuid, i18n.language]);

  return (
    <>
      {list.questions_list?.length ? (
        <PDFViewer style={{ width: "100%", height: "90vh" }}>
          <ListQuestionsPdf listQuestions={list.questions_list} skillUuid={uuid} t={t}/>
        </PDFViewer>
      ) : (
        <div
          className="fixed"
          style={{ top: "45%", left: "45%" }}
        >
          <div className="text-center p-4 overflow-x-auto">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        </div>
      )}
    </>
  );
}

const initialState = {
	button: false,
	records: {
		total: 0,
		questions: [],
		current: -1,
		finish: false
	},
	answers: [],
	loaded: false,
	complete: false,
	countt :false,
	loaded_answers: false,
	reset_timer: false
}

export default function userTestReducers(state = initialState, action) {
	switch(action.type) {
		case 'UT_BUTTON': {
			return {
				...state,
				button: true
			}
		}
		case 'UT_RECORDS': {
			return {
				...state,
				records: {
					...state.records,
					total: action.payload.length-1,
					questions: action.payload,
				},
				loaded: true
			}
		}
		case 'UT_RECORDS2': {
			return {
				...state,
				countt: action.payload
			}
		}
		case 'UT_RESTART': {
			return {
				...state,
				records: {
					total: 0,
					questions: [],
					current: -1,
					finish: false,
				},
				loaded: false,
				loaded_answers: false
			}
		}
		case 'UT_SET_ANSWERS': {
			return {
				...state,
				answers: action.payload,
				loaded_answers: true,
				reset_timer: true
			}
		}
		case 'UT_ERROR': {
			return {
				...state,
				button: false
			}
		}
		case 'UT_COMPLETED': {
			return {
				...state,
				complete: true
			}
		}
		case 'UT_NEXT_QUESTION': {
			return {
				...state,
				button: false,
				records: {
					...state.records,
					real_current: state.records.current,
					current: state.records.current+1,
					finish: (state.records.current >= state.records.total) ? true : false,
					last_button: (state.records.current+1 >= state.records.total) ? true : false
				}
			}
		}
		case 'UT_LOADED_ANSWERS_OFF': {
			return {
				...state,
				loaded_answers: false
			}
		}
		case 'UT_RESET_TIMER_OFF': {
			return {
				...state,
				reset_timer: false
			}
		}
		default: return state;
	}
}
const initialState = {
  button: false,
  loaded: false,
  data: [],
  error: false,
  error_message: [],
  redirect: false,
  success: false,
  success_message: "",
  record: undefined,
  record_loaded: false,
};

export default function jobReducers(state = initialState, action) {
  switch (action.type) {
    case "JOBS_ALL": {
      return {
        ...state,
        data: action.payload,
        loaded: true,
      };
    }
    case "JOBS_SINGLE": {
      return {
        ...state,
        record: action.payload,
        record_loaded: true,
      };
    }
    case "JOBS_DELETE": {
      const arreglo = state.data.filter(
        (item) => item.uuid !== action.payload.uuid
      );
      return {
        ...state,
        error: false,
        error_message: [],
        success: true,
        redirect: true,
        button: false,
        data: arreglo,
        success_message: `${action.payload.name_job} was deleted successfully`,
      };
    }
    case "JOBS_UPDATE": {
      let indice = state.data.findIndex(
        (item) => item.uuid === action.payload.uuid
      );
      const copy_array = [...state.data];
      copy_array[indice] = action.payload;
      return {
        ...state,
        error: false,
        error_message: [],
        success: true,
        redirect: true,
        button: false,
        data: copy_array,
        success_message: `${action.payload.name_job} was modified successfully`,
      };
    }
    case "JOBS_SAVE": {
      let data = state.data.concat(action.payload);
      let dataSort = data.sort((a, b) => {
        let x = a["name_job"].toLowerCase();
        let y = b["name_job"].toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return {
        ...state,
        error: false,
        error_message: [],
        data: dataSort,
        success: true,
        redirect: true,
        button: false,
        success_message: `${action.payload[0].name_job} was added successfully`,
      };
    }
    case "JOBS_ERROR": {
      return {
        ...state,
        error: true,
        error_message: action.payload,
        button: false,
      };
    }
    case "JOBS_OFF": {
      return {
        ...state,
        error: false,
        error_message: [],
        button: false,
      };
    }
    case "JOBS_BUTTON": {
      return {
        ...state,
        button: true,
      };
    }
    case "JOBS_RESTART": {
      return {
        ...state,
        button: false,
        error: false,
        error_message: false,
        redirect: false,
      };
    }
    case "JOBS_SUCCESS_OFF": {
      return {
        ...state,
        success: false,
        success_message: "",
      };
    }
    case "JOBS_RELOAD": {
      return {
        ...state,
        loaded: false,
      };
    }
    case "JOB_SINGLE_RELOAD": {
      return {
        ...state,
        record_loaded: false,
      };
    }
    default:
      return state;
  }
}

import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Link, Redirect, useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../common/Button";
import { getQuestions, deleteQuestion } from "../../../redux/actions/admin/questionActions";
import { useTranslation } from "react-i18next";


export default function QuestionStore() {
   const { t } = useTranslation('common');
   const dispatch = useDispatch();
   const question = useSelector((state) => state.questionReducers);
   let { uuid } = useParams();
   const [data, setData] = useState(undefined);

   useEffect(() => {
      if (!question.loaded) dispatch(getQuestions());
   }, [question.loaded, dispatch]);

   useEffect(() => {
      if (question.loaded) {
         const results = question.records.filter((item) => item.uuid === uuid);
         setData(results[0]);
      }
   }, [question.loaded, question.records, uuid]);

   const handleChange = (text) => {
      setData({
         ...data,
         [text.target.name]: text.target.value,
      });
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      dispatch(deleteQuestion(uuid));
   };

   return (
      <>
         {question.redirect && <Redirect to="/tests/admin" />}

         <div className="mt-5 flex justify-end">
            <Link to="/tests/admin">
               <Button className="focus:outline-none" variant="outlined">
                  <i className="fa fa-arrow-left mr-2"></i> {t('personal.back')}
               </Button>
            </Link>
         </div>
         {data !== undefined && (
            <form action="" className="w-1/2 mx-auto" onSubmit={handleSubmit}>
               <div className="w-full mx-2 my-5">
                  <TextField
							disabled
                     type="text"
                     label={t('question.question')}
                     variant="outlined"
                     onChange={handleChange}
                     className="w-full"
                     multiline
                     minRows={4}
							value={data.question || ""}
                  />
               </div>
               <div className="w-full mx-2">
                  <FormControl variant="outlined" className="w-full">
                     <InputLabel htmlFor="type">{t('references.type')}</InputLabel>
                     <Select
								disabled	
                        native
                        value={data.type}
                        onChange={handleChange}
                        label="Type"
                        inputProps={{
                           name: "type",
                           id: "type",
                        }}
                     >
                        <option value="select">{t('userSkills.select')}</option>
                        <option value="text">{t('userSkills.text')}</option>
                     </Select>
                  </FormControl>
               </div>
               <div className="flex justify-center w-full mt-7">
                  <CustomButton
                     status={question.button}
                     text={t('question.delete')}
                     icon="fa fa-trash"
                  />
               </div>
            </form>
         )}
      </>
   );
}

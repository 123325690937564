const initialState = {
  loaded: false,
  records: [],
  record: undefined,
  record_loaded: false,
  search: [],
  button: false,
  error: false,
  error_message: "",
  success: false,
  redirect: false,
  success_message: "",
};

export default function skillsReducers(state = initialState, action) {
  switch (action.type) {
    case "SKILLS_RESTART": {
      return {
        ...state,
        button: false,
        error: false,
        error_message: "",
        redirect: false,
      };
    }
    case "SKILLS_BUTTON": {
      return {
        ...state,
        button: true,
      };
    }
    case "SKILLS_RECORDS": {
      return {
        ...state,
        loaded: true,
        records: action.payload,
      };
    }
    case "SKILLS_RECORD": {
      return {
        ...state,
        record_loaded: true,
        record: action.payload,
      };
    }
    case "SKILLS_SEARCH": {
      return {
        ...state,
        loaded: true,
        search: action.payload,
      };
    }
    case "SKILLS_RECORDS_COUNT": {
      return {
        ...state,
        loaded: true,
        records: action.payload,
      };
    }
    case "SKILLS_LOADED_OFF": {
      return {
        ...state,
        loaded: false,
      };
    }
    case "SKILLS_RECORD_OFF": {
      return {
        ...state,
        record_loaded: false,
      };
    }
    case "SKILLS_STORE": {
      return {
        ...state,
        error: false,
        error_message: [],
        records: state.records.concat(action.payload),
        success: true,
        redirect: true,
        button: false,
        success_message: `${action.payload.name} was added successfully`,
      };
    }
    case "SKILLS_UPDATE": {
      let indice = state.records.findIndex(
        (item) => item.uuid === action.payload.uuid
      );
      const copy_array = [...state.records];
      copy_array[indice] = action.payload;
      return {
        ...state,
        error: false,
        error_message: "",
        success: true,
        redirect: true,
        button: false,
        records: copy_array,
        success_message: `${action.payload.name} was modified successfully`,
      };
    }
    case "SKILLS_DELETE": {
      const arreglo = state.records.filter(
        (item) => item.uuid !== action.payload.uuid
      );
      return {
        ...state,
        error: false,
        error_message: [],
        success: true,
        redirect: true,
        button: false,
        records: arreglo,
        success_message: `${action.payload.name} was deleted successfully`,
      };
    }
    case "SKILLS_ERROR": {
      return {
        ...state,
        error: true,
        error_message: action.payload,
        button: false,
      };
    }
    case "SKILLS_SUCCESS_OFF": {
      return {
        ...state,
        success: false,
        success_message: "",
      };
    }
    default:
      return state;
  }
}

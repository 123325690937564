import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "@material-ui/core";

import { getNotificationByUuid } from "../../../redux/actions/admin/notificationActions";

import EmailEditForm from "./EmailEditForm";

export default function EmailEdit() {
  const { t } = useTranslation("common");
  const notification = useSelector((state) => state.notificationReducers);
  const dispatch = useDispatch();
  let { uuid } = useParams();
  const [data, setData] = useState(undefined);

  useEffect(() => {
    if (!notification.record_loaded) {
      dispatch(getNotificationByUuid(uuid));
    } else {
      setData({
        board_name: notification.record.board_name,
        subject: notification.record.subject,
        title: notification.record.title,
        message: notification.record.message,
        status: notification.record.default,
        language: notification.record.language,
      });
    }
  }, [dispatch, notification, uuid]);

  useEffect(() => {
    return () => {
      dispatch({ type: "RELOAD_RECORD" });
    };
  }, [dispatch]);

  return (
    <>
      {data ? (
        <div className="mt-5 flex flex-row-reverse justify-end">
          <div>
            <Link
              to="/vacancies/admin/notifications"
              onClick={() => dispatch({ type: "RELOAD_RECORD" })}
            >
              <Button className="focus:outline-none" variant="outlined">
                <i className="fa fa-arrow-left mr-2"></i> {t("personal.back")}
              </Button>
            </Link>
          </div>
          <EmailEditForm uuid={uuid} preloadedValues={data} />
        </div>
      ) : (
        <div className="justify-center">
          <div className="text-center mt-56 p-4 overflow-x-auto">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        </div>
      )}
    </>
  );
}

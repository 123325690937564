/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import EdiText from "react-editext";
import {
  saveComment,
  getComment,
  editComment,
  deleteComment,
} from "../../../redux/actions/jobActions";

export default function VacantPerson(props) {
  const dispatch = useDispatch();
  let {
    id,
    position,
    status,
    closeModal,
    uuid_board,
    job_id,
    user,
    styleLetter,
    jobsDetail,
    prepareNotification,
  } = props;
  const jobs = useSelector((state) => state.jobReducers);

  const [hidden, setHidden] = useState(false);
  const effectBG = status ? "fadeIn" : "fadeOut";
  const effectModal = status ? "fadeInDown" : "fadeOutUp";
  const [toggleState, setToggleState] = useState(1);
  const { t } = useTranslation("common");
  const [data, setData] = useState({
    description: "",
    position: position,
    uuid_board: uuid_board,
    id_user: id,
    job_id: jobsDetail && jobsDetail.id ? jobsDetail.id : job_id,
    cambio: false,
  });
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const handleChange = (text) => {
    setData({
      ...data,
      description: text.target.value,
      position: position,
      uuid_board: uuid_board,
      id_user: id,
      job_id: jobsDetail && jobsDetail.id ? jobsDetail.id : job_id,
    });
  };

  const comment = () => {
    if (data.description) {
      dispatch({ type: "BLOCK_COMENT_BUTTOM" });
      dispatch(saveComment(data));
      setData({
        ...data,
        description: "",
      });
    }
  };

  useEffect(() => {
    if (status && jobsDetail.id) {
      setData((data) => ({
        ...data,
        job_id: jobsDetail.id,
        id_user: id,
      }));
      setToggleState(1);
      setHidden(true);
    } else {
      setTimeout(() => {
        setHidden(false);
      }, 200);
    }
  }, [status, id, jobsDetail.id]);

  useEffect(() => {
    if (data.id_user && !jobs.modal_loaded) {
      dispatch(getComment(data));
    }
  }, [dispatch, data, jobs.modal_loaded]);

  return (
    <>
      {hidden && (
        <>
          <div
            className={`fixed bg-black w-full h-full justify-items-center top-0 left-0 z-20 animate__animated animate__${effectBG} animate__faster`}
            style={{ backgroundColor: "rgba(0,0,0,.5)" }}
          ></div>
          <div
            className={`rounded-md fixed top-0 left-0 mt-20 w-11/12 center-11-12 md:-left-36 lg:-left-24 md:w-6/12 lg:1/3 lg:mt-14  bg-white z-30 animate__animated animate__${effectModal} animate__faster`}
          >
            <div className="relative w-full max-w-full h-full md:h-auto overflow-hidden">
              <div className="container flex flex-col relative">
                <div className="bloc-tabs flex">
                  <button
                    className={
                      toggleState === 1
                        ? "tabs active-tabs inline-block py-4 px-4 text-sm border-b-4 border-blue-300 font-medium text-center text-blue-400 bg-white rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"
                        : "tabs relative cursor-pointer outline-none inline-block py-4 px-4 text-sm font-medium text-center bg-gray-100 text-gray-500 rounded-t-lg hover:text-gray-600 hover:bg-gray-50"
                    }
                    onClick={() => toggleTab(1)}
                  >
                    {t("userSkills.details")}
                  </button>
                  <button
                    className={
                      toggleState === 2
                        ? "tabs active-tabs inline-block py-4 px-4 text-sm border-b-4 border-red-300 font-medium text-center text-red-500 bg-white rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"
                        : "tabs relative cursor-pointer outline-none inline-block py-4 px-4 text-sm font-medium text-center bg-gray-100  text-gray-500 rounded-t-lg hover:text-gray-600 hover:bg-gray-50"
                    }
                    onClick={() => toggleTab(2)}
                  >
                    {t("board.notepad")}
                  </button>
                  <button
                    onClick={() => {
                      setData({
                        ...data,
                        description: "",
                      });
                      closeModal();
                      dispatch({
                        type: "RESTART_MODAL_JOBS"
                      })
                    }}
                    type="button"
                    className="text-gray-400 bg-transparent mr-4 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm px-4 py-2 ml-auto inline-flex items-center"
                  >
                    <i className="text-lg fas fa-times"></i>
                  </button>
                </div>

                <div className="content-tabs grow">
                  <div
                    className={
                      toggleState === 1
                        ? "content active-content block p-6"
                        : "content bg-white hidden"
                    }
                  >
                    <div className="flex  justify-between">
                      <div>
                        <h2 className="mt-4 mb-2 text-lg font-semibold text-gray-800  align-text-bottom">
                          {t("board.aplicantsDetails")}
                        </h2>
                      </div>
                      <div className="flex">
                        <div className="flex flex-col">
                          <button
                            onClick={() => {
                              prepareNotification({ user, position });
                            }}
                            className={`flex justify-center mt-4 mb-2 py-2 rounded-full bg-gray-100  mr-8 text-xl leading-4 text-gray-600 hover:bg-blue-100 hover:text-blue-600 justify-self-end transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110`}
                          >
                            <i className="fas fa-solid fa-envelope fa-1x"></i>{" "}
                          </button>
                          <span className=" mb-2 mr-8 text-xs font-medium leading-4 text-gray-600 justify-self-end">
                            {t("contactus.SendEmail")}
                          </span>
                        </div>
                        {user.cv && (
                          <div className="flex flex-col">
                            <a
                              href={
                                `${process.env.REACT_APP_BASE_URL}` + user.cv
                              }
                              className={`flex justify-center mt-4 mb-2 py-2 rounded-full bg-gray-100  mr-8 text-xl leading-4 text-gray-600 ${
                                user.cv !== null
                                  ? "hover:bg-blue-100 hover:text-blue-600 justify-self-end transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                                  : "disable"
                              }`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fas fa-file-download fa-1x justify-self-end"></i>{" "}
                            </a>
                            <span className=" mb-2 mr-8 text-xs font-medium leading-4 text-gray-600 justify-self-end">
                              {t("board.downloadCV")}
                            </span>
                          </div>
                          // <div className="flex flex-col">
                          //   <Link
                          //     to={user.cv}
                          //     className={`flex justify-center mt-4 mb-2 py-2 rounded-full bg-gray-100  mr-8 text-xl leading-4 text-gray-600 ${
                          //       user.cv !== null
                          //         ? "hover:bg-blue-100 hover:text-blue-600 justify-self-end transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                          //         : "disable"
                          //     }`}
                          //     target="_blank"
                          //     download={user.cv.replace(
                          //       `${process.env.REACT_APP_BASE_URL}/user_files/`,
                          //       " "
                          //     )}
                          //   >
                          //     <i className="fas fa-file-download text-center"></i>{" "}
                          //   </Link>
                          //   <span className=" mb-2 mr-8 text-xs font-medium leading-4 text-gray-600 justify-self-end">
                          //     {t("board.downloadCV")}
                          //   </span>
                          // </div>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="flex mt-4 space-x-3 lg:mt-6 overflow-y-auto max-h-96">
                      <div className="flex flex-col sm:flex-row mt-0">
                        <div className="sm:w-2/5 text-center sm:pr-8 sm:py-1">
                          <div className="w-10 h-10 lg:w-20 lg:h-20 rounded-full inline-flex items-center justify-center text-2xl lg:text-4xl bg-blue-500 text-white">
                            {user.user.name.toUpperCase().charAt(0)}
                          </div>
                          <div className="flex-none">
                            <div className="flex flex-col items-center text-center justify-center">
                              <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">
                                {user.user.name} {user.user.lastname}
                              </h2>
                              <p className="flex pt-2 text-gray-600 text-xs items-center select-all justify-center lg:justify-start">
                                {user.user.email}
                              </p>
                              <div className="w-12 h-1 bg-red-500 rounded mt-2 mb-4"></div>
                            </div>
                            <span className="inline-flex sm:mt-0 mt-4 gap-4 justify-center sm:justify-start">
                              <div>
                                <div className="flex justify-center">
                                  <span className="text-gray-500">
                                    <i className="fas fa-brain mx-2"></i>
                                  </span>
                                  <p className="font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-400">
                                    {user.skills_check}
                                    {"/"}
                                    {user.skills ? user.skills : 0}
                                  </p>
                                </div>
                                <span className="text-xs font-medium leading-4 text-gray-600">
                                  {t("menu_profile.skills_mobile")}{" "}
                                  {t("board.skillsGenerals")}
                                </span>
                              </div>

                              <div>
                                <div className="flex justify-center">
                                  <span className="text-gray-500">
                                    <i className="fas fa-list mx-2"></i>
                                  </span>
                                  <p className="font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-400">
                                    {user.skills_test ? user.skills_test : 0}/
                                    {user.skills_specific
                                      ? user.skills_specific
                                      : 0}
                                  </p>
                                </div>
                                <span className="text-xs font-medium leading-4 text-gray-600">
                                  {t("menu_profile.skills_mobile")}{" "}
                                  {t("board.skillsSpecifics")}
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col items-start justify-start sm:w-2/3 sm:pl-8 sm:py-1 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                          <p className="lg:text-base pt-4 text-sm font-bold flex items-start justify-start">
                            <svg
                              className="h-4 fill-current text-red-700 pr-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9 12H1v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-8v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v6h-9V9H9v2zm3-8V2H8v1h4z" />
                            </svg>
                            {jobsDetail.name_job}
                          </p>
                          <p className="pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-center lg:justify-start">
                            <i className="fas fa-globe h-4 text-md fill-current text-red-700 pr-4"></i>{" "}
                            {t("vacancies." + jobsDetail.location)} -{" "}
                            {jobsDetail.ubication}
                          </p>

                          <div className="mt-4">
                            <span className="inline-block px-1 py-1 mx-1 font-semibold text-xs text-center text-blue-700 bg-blue-100 lg:rounded-full rounded-md dark:text-red-100">
                              {t("vacancies." + jobsDetail.type_job)}
                            </span>
                            <span className="inline-block px-2 py-1 mx-1 font-semibold leading-tight text-xs text-red-700 bg-red-100 rounded-full">
                              {t(
                                "vacancies." +
                                  jobsDetail.priority_job.toLowerCase()
                              )}
                            </span>
                          </div>
                          <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-red-500 opacity-25"></div>

                          <p
                            className="text-sm pt-8 text-gray-500 dark:text-gray-400 text-ellipsis overflow-hidden"
                            dangerouslySetInnerHTML={{
                              __html: jobsDetail.description_job
                                ? jobsDetail.description_job
                                : "",
                            }}
                          ></p>

                          <div className="pt-6 pb-4">
                            <Link
                              to={`/admin/users/profile/${user.user.uuid}`}
                              target="_blank"
                              className="text-gray-800 bg-white border-2 border-gray-200 hover:bg-gray-200 focus:ring-4 focus:ring-gray-100 font-medium rounded-md text-sm px-2 py-2.5 text-center mx-2 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                            >
                              {t("board.goProfile")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      toggleState === 2
                        ? "content active-content block p-6"
                        : "content bg-white hidden"
                    }
                  >
                    <h2 className="mt-4 mb-2 text-lg font-semibold text-gray-800">
                      {t("board.processRegister")}
                    </h2>
                    <hr />
                    <div className="grid justify-items-end ">
                      <label
                        htmlFor="description"
                        className="block justify-self-start mb-2 mx-2 text-sm mt-4"
                      >
                        {t("board.lastAdvance")}
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        cols="8"
                        minRows="4"
                        placeholder={t("board.whriteHere")}
                        value={data.description}
                        onChange={handleChange}
                        className="w-full p-4 text-gray-600 text-sm bg-gray-100 outline-none rounded-md"
                      ></textarea>
                      <button
                        className={`text-white my-2 text-sm py-2 px-4 rounded ${
                          jobs.blockbutton
                            ? "bg-red-500 cursor-not-allowed"
                            : "bg-blue-500 hover:bg-blue-800 "
                        } `}
                        onClick={comment}
                        disabled={jobs.blockbutton}
                      >
                        {t("board.comment")}
                      </button>
                      {/* <button
                        className="bg-blue-500 hover:bg-blue-800 text-white my-2 text-sm py-2 px-4 rounded"
                        onClick={comment}
                        disabled={jobs.blockbutton}
                      >
                        {t("board.comment")}
                      </button> */}
                    </div>
                    <div className="overflow-y-auto max-h-48">
                      {jobs.modal.map((comment, index) => (
                        <div
                          key={index}
                          className={
                            styleLetter[comment.position] +
                            " grid justify-items-end p-4  rounded-lg justify-end my-1"
                          }
                        >
                          <div className="flex justify-start">
                            <p className="my-2 font-bold mr-2">
                              {comment.user_comment + "@ " + comment.created_at}
                            </p>
                            <button
                              className="text-sm h-10 border-2 border-red-500 hover:bg-red-500 text-red-500 hover:text-white font-bold mx-1 py-2 px-2 rounded-lg"
                              onClick={() => {
                                dispatch(deleteComment(comment.uuid_comment));
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>
                          </div>
                          <EdiText
                            className="text-xs"
                            type="textarea"
                            inputProps={{
                              className: "textarea",
                              style: {
                                outline: "none",
                                minWidth: "auto",
                                padding: "0.5rem",
                                border: "1px solid #ccc",
                                borderRadius: "0.25rem",
                              },
                              rows: 3,
                            }}
                            viewContainerClassName="flex justify-center items-start mt-2"
                            editContainerClassName="flex justify-center items-start mt-2"
                            editButtonContent={
                              <svg
                                className="h-4 w-4 text-sm"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="pen"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="currentColor"
                                  d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"
                                ></path>
                              </svg>
                            }
                            editButtonClassName="h-10 border-2 border-gray-500 hover:bg-gray-500 text-gray-500 hover:text-white font-semibold mx-1 py-2 px-2 rounded-lg text-sm"
                            saveButtonClassName="h-10 border-2 border-yellow-500 hover:bg-yellow-500 text-yellow-500 hover:text-white font-semibold mx-1 py-2 px-2 rounded-lg"
                            saveButtonContent={
                              <svg
                                className="h-4 w-4"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                ></path>
                              </svg>
                            }
                            cancelButtonClassName="h-10 border-2 border-red-500 hover:bg-red-500 text-red-500 hover:text-white font-bold mx-1 py-2 px-2 rounded-lg"
                            cancelButtonContent={
                              <svg
                                className="h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="times"
                                role="img"
                                viewBox="0 0 352 512"
                              >
                                <path
                                  fill="currentColor"
                                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                                ></path>
                              </svg>
                            }
                            value={comment.body_comment}
                            onSave={(newContent) => {
                              dispatch(
                                editComment(
                                  { description: newContent },
                                  comment.uuid_comment
                                )
                              );
                            }}
                          />

                          <p className="justify-self-start text-xs"></p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const initialState = {
  records: [],
  loaded: false,
  button_status: false,
  error: false,
  error_message: [],
  success: false,
};

export default function personalReducers(state = initialState, action) {
  switch (action.type) {
    case "PERSONAL_RECORDS": {
      return {
        ...state,
        records: action.payload,
        loaded: true,
      };
    }
    case "PERSONAL_BUTTON": {
      return {
        ...state,
        button_status: true,
      };
    }
    case "PERSONAL_SUCCESS": {
      return {
        ...state,
        loaded: false,
        button_status: false,
        error: false,
        error_message: [],
        success: true,
      };
    }
    case "PERSONAL_SUCCESS_OFF": {
      return {
        ...state,
        success: false,
      };
    }
    case "PERSONAL_ERROR": {
      return {
        ...state,
        error: true,
        error_message: action.payload,
        button_status: false,
      };
    }
    case "PERSONAL_ERROR_OFF": {
      return {
        ...state,
        error: false,
        error_message: [],
      };
    }
    case "PERSONAL_RELOAD": {
      return {
        ...state,
        loaded: false,
      };
    }
    default:
      return state;
  }
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { handleErrorMessage, handleErrorInput } from "../../utils/functions";
import { useTranslation } from "react-i18next";
import CustomButton from "../common/Button";
import { getSkillsData } from "../../redux/actions/skillActions";
import { Dialog, DialogContent } from "@material-ui/core";

import {
  getQuestionByUuid,
  updateQuestionSkills,
} from "../../redux/actions/admin/questionActions";
import { Redirect } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ModalSkillsEdit(props) {
  const { uuid } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    dispatch({
      type: "QUESTION_RECORD_OFF",
    });
  };

  // lo que viene de edit question
  const dispatch = useDispatch();
  const question = useSelector((state) => state.questionReducers);
  const skill = useSelector((state) => state.skillReducers);
  const [skillsValue, setSkillsValue] = useState([]);
  const { t } = useTranslation("common");

  useEffect(() => {
    if (!skill.skill.loaded && open) dispatch(getSkillsData());
  }, [dispatch, skill.skill.loaded, open]);

  useEffect(() => {
    if (!question.record_loaded && open) dispatch(getQuestionByUuid(uuid));
  }, [dispatch, question.record_loaded, uuid, open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (skillsValue.length) {
      dispatch(
        updateQuestionSkills({ skills_uuid: JSON.stringify(skillsValue) }, uuid)
      );
      handleClose();
    }
  };

  return (
    <div>
      {
        <button
          type="button"
          variant="outlined"
          className="my-1 h-10 border-2 border-blue-500 hover:bg-blue-500 text-blue-500 hover:text-white font-semibold  mx-1 py-2 px-2 rounded-lg"
          title={t("question.tooltipskillQuestion")}
          onClick={handleClickOpen}
        >
          <i className="fa fa-brain"></i>
        </button>
      }
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogContent>
          <>
            <div className="flex justify-end w-full mt-0">
              <svg
                onClick={handleClose}
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer h-5 w-5  border-2 rounded-md hover:bg-gray-200 hover:text-gray-800"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <div className="mt-5 flex justify-end"></div>
            {question.redirect && <Redirect to="/tests/admin" />}

            <div className="mt-5 flex justify-end"></div>
            <h1 className="font-bold font-poppins border-b text-center">
              {t("question.modalEditQuestion")}
            </h1>
            {question.record_loaded ? (
              <form className="w-full mx-auto" onSubmit={handleSubmit}>
                <div className="w-full mx-2 my-5">
                  <TextField
                    disabled
                    error={handleErrorInput(question, "question")}
                    type="text"
                    id="question"
                    name="question"
                    label={t("question.question")}
                    className="w-full"
                    helperText={handleErrorMessage(question, "question")}
                    multiline
                    minRows={4}
                    value={question.record.question}
                  />
                </div>
                <div className="w-full mx-2">
                  <Autocomplete
                    multiple
                    id="skills_uuid"
                    defaultValue={question.record.question_skills.map(
                      ({ skill }) => skill
                    )}
                    options={skill.skill.records}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.uuid === value.uuid
                    }
                    onChange={(_, selectedOptions) => {
                      setSkillsValue(selectedOptions);
                    }}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        variant="outlined"
                        label={
                          t("portfolio.link") + " " + t("menu_profile.skills")
                        }
                        placeholder={t("skills.select")}
                        error={handleErrorInput(question, "skills_uuid")}
                        helperText={handleErrorMessage(question, "skills_uuid")}
                      />
                    )}
                  />
                </div>
                <div className="flex justify-center w-full mt-7">
                  <CustomButton
                    status={question.button}
                    text={t("question.update")}
                    icon="fa fa-edit"
                  />
                </div>
              </form>
            ) : (
              <div className="justify-content-center">
                <div className="text-center my-5 p-4 overflow-x-auto">
                  <i className="fa fa-spin fa-spinner fa-2x"></i>
                </div>
              </div>
            )}
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import TextField from "@material-ui/core/TextField";
import { updatePortfolio } from "../../redux/actions/portfolioActions";
import { handleErrorMessage, handleErrorInput } from "../../utils/functions";
import { SingleError, Button } from "../common";

export default function FormEdit(props) {
  const dispatch = useDispatch();
  const port = useSelector((state) => state.portfolioReducers);
  let { uuid, status, closeModal } = props;
  const [hidden, setHidden] = useState(false);
  const [filterRecords, setFilterRecords] = useState(undefined);
  const effectBG = status ? "fadeIn" : "fadeOut";
  const effectModal = status ? "fadeInDown" : "fadeOutUp";
  const [isFileBigger, setIsFileBigger] = useState(false);
  const [isRequired, setIsRequired] = useState({
    file: false,
    link: true,
  });
  const { t } = useTranslation("common");

  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    setIsFileBigger(false);
    if (status) {
      var filter = port.records.filter((item) => item.uuid === uuid);
      setFilterRecords(filter[0]);
    }
  }, [status, uuid, port.records]);

  useEffect(() => {
    if (status) {
      setHidden(true);
    } else {
      setTimeout(() => {
        setHidden(false);
      }, 200);
    }
  }, [status]);

  const onSubmitData = (data) => {
    var formData = new FormData();
    const { file, link } = data;
    const fileValid = file?.size / 1024 / 1024 < 1;

    if (!file && !link) {
      return;
    }

    if (!file && link) {
      Object.entries(data).map(([key, item]) =>
        key !== "file"
          ? formData.append(key, item)
          : formData.append("file", "")
      );

      dispatch(updatePortfolio(formData, uuid));
    }

    if (file && !fileValid) {
      setIsFileBigger(true);
      return;
    } else if (fileValid || link) {
      Object.entries(data).map(([key, item]) => formData.append(key, item));

      dispatch(updatePortfolio(formData, uuid));
    }

    setTimeout(() => {
      closeModal();
      setIsRequired({
        file: false,
        link: true,
      });
      reset({ file: "", link: "", description: "" });
    }, 200);
  };

  useEffect(() => {
    register("file", {
      required: isRequired.file ? t("portfolio.error.file_required") : false,
    });
  }, [isRequired, register, t]);

  return (
    <>
      {hidden && (
        <>
          <div
            className={`fixed bg-black w-full h-full top-0 left-0 z-20 animate__animated animate__${effectBG} animate__faster`}
            style={{ backgroundColor: "rgba(0,0,0,.5)" }}
            onClick={closeModal}
          ></div>
          <div className="flex justify-center">
            <div
              className={`fixed top-0 -left-28 ml-48 mt-44 w-auto items-center center-11-12 rounded bg-white z-30 animate__animated animate__${effectModal} animate__faster`}
            >
              <div className="rounded">
                <div className="border-gray-700 bg-gray-700 text-white font-bold">
                  <div className="flex justify-between p-3">
                    <label>{t("portfolio.edit")}</label>
                    <svg
                      onClick={closeModal}
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5  border-2 rounded-md hover:bg-white hover:text-gray-800"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                </div>
                <SingleError
                  status={isFileBigger}
                  styleParent="m-5 w-3/5 mx-auto"
                  errors={t("portfolio.error.cv_limit")}
                  closer={() => setIsFileBigger(false)}
                />
                <div className="mt-4 mb-6 px-10 pt-10 pb-2">
                  <form
                    action=""
                    className="w-full animate__animated animate__fadeIn animate__faster"
                    onSubmit={handleSubmit(onSubmitData)}
                  >
                    <div className="w-full md:flex md:mb-5">
                      <div className="py-2 md:py-0 md:w-1/2 mx-2">
                        <TextField
                          type="file"
                          id="file"
                          name="file"
                          label={t("portfolio.file")}
                          variant="outlined"
                          onChange={(event) => {
                            if (event.target?.files[0])
                              setValue("file", event.target?.files[0]);

                            setIsRequired({
                              ...isRequired,
                              link: false,
                            });
                          }}
                          className="w-full"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            accept: ".png,.jpg,.jpeg,.pdf,.docx,.doc,.odt,.odg",
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="file"
                          render={({ message }) =>
                            isRequired.file && (
                              <p className="text-sm mt-1 text-red-500">
                                {message}
                              </p>
                            )
                          }
                        />
                      </div>

                      <div className="py-2 md:py-0 md:w-1/2 mx-2">
                        <Controller
                          name="link"
                          id="link"
                          control={control}
                          defaultValue={filterRecords.link || ""}
                          rules={{
                            required: {
                              value: isRequired.link,
                              message: t("portfolio.error.link_urlrequired"),
                            },
                            pattern: {
                              value: /^https?:\/\/[\w]+(\.[\w]+)+[/#?]?.*$/,
                              message: t("portfolio.error.link_url"),
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              type="text"
                              id="link"
                              name="link"
                              label={t("portfolio.link")}
                              variant="outlined"
                              className="w-full"
                              error={errors.link ? true : false}
                              helperText={t(handleErrorMessage(port, "link"))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...field}
                              onChange={(event) => {
                                field.onChange(event.target.value);
                                setIsRequired({
                                  ...isRequired,
                                  file: false,
                                });
                              }}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="link"
                          render={({ message }) =>
                            isRequired.link && (
                              <p className="mt-1 text-sm text-red-500">
                                {message}
                              </p>
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="md:flex items-center justify-between">
                      <div className="py-2 md:py-0 md:w-10/12 flex mb-5 mx-2">
                        <Controller
                          name="description"
                          id="description"
                          control={control}
                          defaultValue={filterRecords.description}
                          render={({ field }) => (
                            <TextField
                              type="text"
                              id="description"
                              name="description"
                              multiline
                              label={t("portfolio.description")}
                              variant="outlined"
                              className="w-full"
                              error={handleErrorInput(port, "description")}
                              helperText={t(
                                handleErrorMessage(port, "description")
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...field}
                            />
                          )}
                        />
                      </div>

                      <div className="md:w-2/12 flex justify-center mx-2">
                        <Button
                          status={port.button}
                          text={t("modal.edit")}
                          icon="fa fa-check"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Link, Redirect, useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { handleErrorMessage, handleErrorInput } from "../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../common/Button";
import {
  getClientByUuid,
  updateClients,
} from "../../../redux/actions/admin/clientActions";
import { useTranslation } from "react-i18next";

import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
export default function ClientEdit() {
  const { t } = useTranslation("common");
  let { uuid } = useParams();
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.clientsReducers);
  const [loadedValues, setLoadedValues] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (!clients.record_loaded) {
      dispatch(getClientByUuid(uuid));
    } else {
      setValue("name_clients", clients.record.name_clients);
      setValue("ubication_clients", clients.record.ubication_clients);
      setValue("status_clients", clients.record.status_clients);
      setLoadedValues(true);
    }
  }, [dispatch, clients.record_loaded, clients.record, uuid, setValue]);

  const onSubmitData = (data) => {
    dispatch(updateClients(data, uuid));
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: "CLIENTS_RECORD_OFF",
      });
    };
  }, [dispatch]);

  return (
    <>
      {clients.redirect && <Redirect to="/clients/admin" />}

      <div className="mt-5 flex justify-end">
        <Link to="/clients/admin">
          <Button className="focus:outline-none" variant="outlined">
            <i className="fa fa-arrow-left mr-2"></i> {t("personal.back")}
          </Button>
        </Link>
      </div>
      {clients.record_loaded && loadedValues ? (
        <form className="w-1/2 mx-auto" onSubmit={handleSubmit(onSubmitData)}>
          <div className="w-full mx-2 my-5">
            <Controller
              name="name_clients"
              id="name_clients"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("personal.error.name_required"),
                },
                pattern: {
                  value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#]{3,}$/,
                  message: t("personal.error.name_invalid"),
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  id="name_clients"
                  name="name_clients"
                  label={t("clients.name_client")}
                  variant="outlined"
                  className="w-full"
                  error={errors.name_clients ? true : false}
                  helperText={t(handleErrorMessage(clients, "name_clients"))}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="name_clients"
              render={({ message }) => (
                <p className="mt-1 text-sm text-red-500">{message}</p>
              )}
            />
          </div>
          <div className="w-full mx-2 my-5">
            <Controller
              name="ubication_clients"
              id="ubication_clients"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("experience.error.location_required"),
                },
                pattern: {
                  value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#-]{3,}$/,
                  message: t("experience.error.location_invalid"),
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  id="ubication_clients"
                  name="ubication_clients"
                  label={t("experience.location")}
                  variant="outlined"
                  className="w-full"
                  error={errors.ubication_clients ? true : false}
                  helperText={t(
                    handleErrorMessage(clients, "ubication_clients")
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="ubication_clients"
              render={({ message }) => (
                <p className="mt-1 text-sm text-red-500">{message}</p>
              )}
            />
          </div>
          <div className="w-full mx-2 my-5">
            <FormControl variant="outlined" className="w-full">
              <InputLabel
                htmlFor="status_clients"
                error={handleErrorInput(clients, "status_clients")}
              >
                {t("clients.status")}
              </InputLabel>
              <Select
                native
                label={t("clients.status")}
                inputProps={{
                  name: "status_clients",
                  id: "status_clients",
                }}
              >
                <option value="A">{t("clients.active")}</option>
                <option value="I">{t("clients.inactive")}</option>
              </Select>
            </FormControl>
          </div>
          <div className="flex justify-center w-full mt-7">
            <CustomButton
              status={clients.button}
              text={t("question.update")}
              icon="fa fa-edit"
            />
          </div>
        </form>
      ) : (
        <div className="justify-center">
          <div className="text-center mt-60 p-4 overflow-x-auto">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { emailApply } from "../../../redux/actions/emailActions";

export default function VacantEmail(props) {
    const dispatch = useDispatch();
    const email = useSelector((state) => state.emailReducers);
    let { uuid, status, closeModal } = props;
    const [hidden, setHidden] = useState(false);
    const [load, setLoad] = useState(false);
    const effectBG = status ? "fadeIn" : "fadeOut";
    const effectModal = status ? "fadeInDown" : "fadeOutUp";
    const { t } = useTranslation("common");
    const [data, setData] = useState({
        lang: localStorage.getItem('lang'),
        uuid,
        url: `${(window.location.origin)}/info/vacancies/detail/${uuid}`
    })

    useEffect(() => {
        if (status) {
            setHidden(true);
            setData((data) => (
                {
                    ...data,
                    url: `${(window.location.origin)}/info/vacancies/detail/${uuid}`
                }
            ));
        } else {
            setTimeout(() => {
                setHidden(false);
            }, 200);
        }
    }, [status, uuid]);

    const handleEmail = (text) => {
        setData({
            ...data,
            [text.target.name]: text.target.value,
        });
        setLoad(false)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoad(true);
        dispatch(emailApply(data))
    }
    return (
        <>
            {hidden && (
                <>
                    <div
                        className={`fixed bg-black w-full h-full top-0 left-0 z-20 animate__animated animate__${effectBG} animate__faster`}
                        style={{ backgroundColor: "rgba(0,0,0,.5)" }}
                        onClick={closeModal}
                    ></div>
                    <div
                        className={`rounded-md fixed top-0 left-0 mt-64 w-11/12 center-11-12 md:w-4/12 bg-white z-30 animate__animated animate__${effectModal} animate__faster`}
                    >
                        <div className=" bg-blue-500 bg-opacity-80 text-white font-bold">
                            <div className="flex justify-between p-3">
                                <label >{t("personal.email_vacancies")}</label>
                                <svg onClick={closeModal} xmlns="http://www.w3.org/2000/svg" className="h-5 w-5  border-2 rounded-md hover:bg-gray-200 hover:text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                        </div>
                        <div className="flex justify-start px-20 ml-4 py-8 text-center text-sm font-normal">
                            <div>
                                <form action="" onSubmit={handleSubmit}>
                                    <div className="text-left">
                                        <span>{t('personal.name')}</span>
                                    </div>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        variant="outlined"
                                        onChange={handleEmail}
                                        required
                                        className="block w-full font-poppins mt-1 border border-gray-300 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm  focus:outline-none focus:ring-1 focus:ring-blue-400"
                                    />
                                    <div className="text-left mt-4">
                                        <span>{t('vacancies.emailApply')}</span>
                                    </div>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        variant="outlined"
                                        onChange={handleEmail}
                                        required
                                        className="block w-full font-poppins mt-1 border border-gray-300 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm  focus:outline-none focus:ring-1 focus:ring-blue-400"
                                    />
                                    <button
                                        type="submit"
                                        className="bg-green-100 mt-3 border-2 border-green-600 hover:bg-green-500 hover:text-white text-green-600 p-2 rounded font-semibold focus:outline-none"
                                    // onClick={handleDelete}
                                    >
                                        {t("contactus.SendEmail")}
                                    </button>
                                </form>
                            </div>
                            {load === false && !email.data &&
                                <div className="text-center mt-2 mb-5">
                                    <i className="fa fa-spin fa-spinner fa-2x mr-3"></i>
                                </div>
                            }

                            {load === true && email.data === true && email.loaded &&
                                <div className="text-center mb-5 mt-2 font-bold">
                                    <span>{t('contactus.emailSuccess')}</span>
                                </div>
                            }
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { changeLang } from '../../../utils/functions'

export default function DrawerLang() {
   const { i18n } = useTranslation("common");

	const handleChange = (lang) => {
		i18n.changeLanguage(lang)
		changeLang(lang);
	}

   return (
      <>
         {i18n.language === "en" ? (
            <ListItem button key={2022}>
               <ListItemIcon>
                  <i className="fa fa-globe-americas"></i>
               </ListItemIcon>
               <ListItemText
                  onClick={() => handleChange("es")}
                  primary="Es"
               />
            </ListItem>
         ) : (
            <ListItem button key={2022}>
               <ListItemIcon>
                  <i className="fa fa-globe-americas"></i>
               </ListItemIcon>
               <ListItemText
                  onClick={() => handleChange("en")}
                  primary="Es"
               />
            </ListItem>
         )}
      </>
   );
}

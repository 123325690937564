const initialState = {
  records: [],
  modules: [],
  permissions: [],
  permission: undefined,
  loaded: false,
  loaded_permissions: false,
};

export default function roleReducers(state = initialState, action) {
  switch (action.type) {
    case "ROLE_RECORDS": {
      return {
        ...state,
        records: action.payload,
        loaded: true,
      };
    }
    case "ROLE_MODULES": {
      return {
        ...state,
        modules: action.payload,
      };
    }
    case "ROLE_PERMISSIONS": {
      return {
        ...state,
        permissions: action.payload,
        loaded_permissions: true,
      };
    }
    case "ROLE_PERMISSION_MODULE": {
      return {
        ...state,
        permission: action.payload,
      };
    }
    case "ROLE_UPDATE_PERMISSIONS": {
      return {
        ...state,
        loaded_permissions: false,
      };
    }
    case "ROLE_STORE": {
      return {
        ...state,
        loaded: false,
      };
    }
    case "ROLE_UPDATE": {
      return {
        ...state,
        loaded: false,
      };
    }
    case "ROLE_DELETE": {
      return {
        ...state,
        loaded: false,
      };
    }
    case "ROLE_PERMISSIONS_OFF": {
      return {
        ...state,
        loaded_permissions: false,
      };
    }
    default:
      return state;
  }
}

import React from "react";
import { NotResult } from ".";

export default function TableGrid({
  getTableProps,
  headerGroups,
  loaded,
  getTableBodyProps,
  page,
  prepareRow,
}) {
  return (
    <>
      <div className="w-full overflow-hidden rounded shadow-xs mt-5">
        <div className="w-full overflow-x-auto  justify-center">
          {!loaded && (
            <div className="justify-content-center">
              <div className="text-center my-5 p-4 overflow-x-auto">
                <i className="fa fa-spin fa-spinner fa-2x"></i>
              </div>
            </div>
          )}

          {page?.length > 0 && loaded ? (
            <table {...getTableProps()} className="w-full whitespace-no-wrap">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="text-xs  font-semibold tracking-wide text-center text-white uppercase  border-b dark:border-gray-700 bg-black dark:text-gray-400 dark:bg-gray-800"
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="px-4 py-3"
                      >
                        {column.render("Header")}
                        {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody
                {...getTableBodyProps()}
                className="bg-white divide-y dark:divide-gray-700 text-center font-poppins font-light text-sm"
              >
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="text-gray-700 bg-gray-50 font-semibold "
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()} className="px-4 py-3">
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            loaded && (
              <div className="flex flex-col w-full ">
                <NotResult />
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import {
  managePermissions,
  updatePermissions,
} from "../../../redux/actions/admin/roleActions";

export default function RolesPermissions({ open, handleClose, uuid }) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const role = useSelector((state) => state.roleReducers);
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    if (open && !role.loaded_permissions) {
      dispatch(managePermissions(uuid));
    }
  }, [dispatch, open, role, uuid]);

  const handleChange = ({ target: { name, checked } }) => {
    setPermissions({ ...permissions, [name]: checked ? 1 : 0 });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updatePermissions(uuid, permissions));
    close();
  };

  const close = () => {
    dispatch({
      type: "ROLE_PERMISSIONS_OFF",
    });
    dispatch({
      type: "MODULES_RELOAD",
    });
    handleClose("permissions");
  };

  return (
    <Dialog open={open} onClose={close} fullWidth={true}>
      <div className="flex justify-end mt-2 mr-2">
        <button onClick={close}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer h-5 w-5  border-2 rounded-md hover:bg-gray-200 hover:text-gray-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <DialogTitle className="border-b text-center">
        <span className="font-bold font-poppins">
          {t("role.manage_permissions")}
        </span>
      </DialogTitle>
      <DialogContent>
        {role.loaded_permissions ? (
          <FormGroup>
            <div className="m-3 grid grid-cols-3 grid-rows-3">
              {role?.permissions?.map(({ id, title, visible_user }) => (
                <FormControlLabel
                  key={id}
                  control={
                    <Checkbox
                      name={`${id}`}
                      id={title}
                      defaultChecked={visible_user ? true : false}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={t(`${title}`)}
                />
              ))}
            </div>
            <div className="w-full flex justify-end">
              <button
                onClick={handleSubmit}
                className="font-poppins bg-black hover:bg-green-600 text-sm text-white py-2 px-4 my-4 rounded"
              >
                <i className="fa fa-edit mr-2"></i>
                <span>{t("question.update")}</span>
              </button>
            </div>
          </FormGroup>
        ) : (
          <div className="justify-content-center">
            <div className="text-center my-5 p-4 overflow-x-auto">
              <i className="fa fa-spin fa-spinner fa-2x"></i>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

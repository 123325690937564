import React  from "react";
import { useTranslation } from "react-i18next";
import imgerror from "../../views/assets/img/img404.png";
import Footer from '../admin/footer'
import { Link } from "react-router-dom";


export default function ResetPassword() {
    const { t } = useTranslation('common');   
    return (
        <>
                <div className="flex p-6 bg-black dark:bg-gray-900 pt-10 pb-24 xl:pt-12 xl:pb-40">
                    <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
                        <div className="flex flex-col justify-center overflow-y-auto md:flex-row"  >
                            <div className="flex items-center">
                                <div className="flex w-full px-10 py-20">
                                    <div className="px-10 w-2/3 z-10">
                                        <img className="object-cover sm:h-96 h-auto w-auto" src={imgerror} alt="" />
                                    </div>
                                    <div className="grid justify-items-center w-1/3 z-0">
                                        <h1 className="text-7xl text-center font-semibold text-gray-700 mt-10">
                                        {t("menu.404")}
                                        </h1>
                                        <h2 className="pt-8 mb-12 border-t-2 border-gray-600 mt-4 text-center">{t("menu.error_mesagge")}</h2>
                                        <Link to="/" className="bg-gradient-to-r content-center from-gray-800 to-red-500 hover:from-red-500 hover:to-red-500 text-sm text-white font-semibold px-6 pt-5 rounded-md">
                                        {t("menu.back_home")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>
    );
}

import api from "../../utils/api";
import { loginAction } from "./loginActions";

export const userRegister = (data) => async (dispatch) => {
  console.log("la data que se pasa")
  console.log(data)
  dispatch({ type: "USER_BUTTON" });
  try {
    const response = await api.post("/users/register", data);    
    if (response.status === 200) {
      const dataLogin = {
        email: data.email,
        password: data.password,
      };
      dispatch({ type: "USER_BUTTON_OFF" });
      dispatch(loginAction(dataLogin));
    }
  } catch (e) {
    dispatch({ type: "USER_ERROR", payload: e.response.data.errors });
  }
};

export const userCheckEmail = (data) => async (dispatch) => {
  try {
    const response = await api.post("/users/getEmail", data);
    dispatch({
      type: "EMAIL_DUPLICATE",
      payload: response.data,
    });

    return response.data;
  } catch (e) {
    dispatch({ type: "USER_ERROR", payload: e.response.data.errors });
  }
};

export const registerVacancies = (data) => async (dispatch) => {
  dispatch({ type: "USER_BUTTON" });
  try {
    const response = await api.post("/users/register", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 200) {
      const dataLogin = {
        email: Object.fromEntries(data).email,
        password: Object.fromEntries(data).password,
        vacancies: true,
      };
      dispatch({ type: "USER_BUTTON_OFF" });
      dispatch(loginAction(dataLogin));
    }
  } catch (e) {
    dispatch({ type: "USER_ERROR", payload: e.response?.data.errors });
  }
};
export const hiredAvalaible = (data) => async () => {
  try {
    await api.post("/users/hired-avalaible", data);
  } catch (e) {
    console.log(e);
  }
};

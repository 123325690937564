import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Accordion = withStyles({
   root: {
      border: "1px solid rgba(0, 0, 0, .125)",
      boxShadow: "none",
      "&:not(:last-child)": {
         borderBottom: 0,
      },
      "&:before": {
         display: "none",
      },
      "&$expanded": {
         margin: "auto",
      },
   },
   expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
   root: {
      backgroundColor: "rgba(0, 0, 0, .03)",
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
      marginBottom: -1,
      minHeight: 56,
      "&$expanded": {
         minHeight: 56,
      },
   },
   content: {
      "&$expanded": {
         margin: "12px 0",
      },
   },
   expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
   root: {
      padding: theme.spacing(2),
   },
}))(MuiAccordionDetails);

export default function CAccordion( props ) {
	let { panel, title,color } = props
	const [expanded, setExpanded] = React.useState('panel1');

   const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
   };
   return (
      <Accordion
         square
         expanded={expanded === panel}
         onChange={handleChange(panel)}
      >
         <AccordionSummary
            aria-controls={`${panel}d-content`}
            id={`${panel}d-header`}
            expandIcon={<ExpandMoreIcon />}
         >
            <div className="font-semibold" style={{color}}>{title}</div>
         </AccordionSummary>
         <AccordionDetails>
            {props.children}
         </AccordionDetails>
      </Accordion>
   );
}

import api from "../../../utils/api";

export const getClients = () => async (dispatch) => {
  try {
    const response = await api.get("/clients/list");
    dispatch({
      type: "CLIENTS_ALL",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getClientByUuid = (uuid) => async (dispatch) => {
  try {
    const response = await api.get(`/clients/get/${uuid}`);
    dispatch({
      type: "CLIENTS_RECORD",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getSelectClients = () => async (dispatch) => {
  try {
    const response = await api.get("/clients/select");
    dispatch({
      type: "CLIENTS_ALL",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getId = (id) => async (dispatch) => {
  try {
    const response = await api.get(`/clients/getId/${id}`);
    dispatch({
      type: "CLIENTS_ALL",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};
export const saveClients = (data) => async (dispatch) => {
  dispatch({ type: "CLIENTS_BUTTON" });
  try {
    const response = await api.post("/clients/save", data);
    dispatch({
      type: "CLIENTS_SAVE",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "CLIENTS_ERROR",
      payload: e.response.data.errors,
    });
  }
};

export const updateClients = (data, uuid) => async (dispatch) => {
  dispatch({ type: "CLIENTS_BUTTON" });
  try {
    const response = await api.put(`/clients/update/${uuid}`, data);
    dispatch({
      type: "CLIENTS_UPDATE",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "CLIENTS_ERROR",
      payload: e.response.data.errors,
    });
  }
};
export const deleteClients = (uuid) => async (dispatch) => {
  dispatch({ type: "CLIENTS_BUTTON" });
  try {
    const response = await api.delete(`/clients/delete/${uuid}`);
    if (response.status === 200) {
      dispatch({
        type: "CLIENTS_DELETE",
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: "CLIENTS_ERROR",
      payload: e.response.data.errors,
    });
  }
};

export const restartClient = () => async (dispatch) => {
  dispatch({ type: "CLIENTS_RESTART" });
};
//  export const findJobs = (data) => async (dispatch) => {
// 	try {
// 	   const response = await api.get(`/jobs/find/${JSON.stringify(data)}`);
// 	   dispatch({
// 			type: 'CLIENTS_ALL',
// 			payload: response.data
// 		})
// 	} catch (e) {
// 		dispatch({
// 			type: "CLIENTS_ERROR",
// 			payload: e.response.data.errors,
// 		 });
// 	}
//  };

import React, { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

// components
import Delete from "./Delete";
import FormEdit from "./FormEdit";
import Pagination from "../common/Pagination";
import InputSearch from "../common/InputSearch";
import TableGrid from "../common/TableGrid";

//actions
import { expreget } from "../../redux/actions/skillActions";
import { getWorkExperience } from "../../redux/actions/workExpActions";

export default function Table() {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState({
    uuid: "",
    edit: false,
    delete: false,
  });

  const [dataExperience, setDataExperience] = useState({});

  const handleModal = useCallback(
    (uuid) => {
      setShowModal({
        uuid,
        delete: true,
      });
      dispatch({ type: "WE_SUCCESS_OFF" });
    },
    [dispatch]
  );

  const handleModalEx = useCallback(
    ({
      uuid,
      title,
      company,
      location,
      from,
      to,
      time_experience,
      website,
      description,
    }) => {
      setShowModal({
        uuid,
        edit: true,
      });
      setDataExperience({
        title,
        company,
        location,
        from,
        to,
        time_experience,
        website,
        description,
      });
      dispatch(expreget(uuid));
      dispatch({ type: "WE_SUCCESS_OFF" });
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        Header: () => t("experience.titleform"),
        accessor: "title",
      },
      {
        Header: () => t("experience.company"),
        accessor: "company",
      },
      {
        Header: () => t("experience.from"),
        accessor: "from",
      },
      {
        Header: () => t("experience.to"),
        accessor: "to",
      },
      {
        Header: () => t("skills.actions_test"),
        accessor: "uuid",
        Cell: ({ row: { original } }) => (
          <div className="text-sm">
            <button
              className="border-2 border-red-500  hover:bg-red-500 text-red-500 hover:text-white font-bold mx-1 py-2 px-2 rounded-lg"
              onClick={() => handleModal(original.uuid)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
            <button
              className="border-2 border-yellow-500 hover:bg-yellow-500 text-yellow-500 hover:text-white font-semibold  mx-1 py-2 px-2 rounded-lg"
              onClick={() => handleModalEx(original)}
            >
              <svg
                className="h-4 w-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                ></path>
              </svg>
            </button>
          </div>
        ),
      },
    ],
    [t, handleModal, handleModalEx]
  );

  const { records: worksExperience, loaded: loading } = useSelector(
    (state) => state.workExpReducers
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    globalFilter,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: worksExperience,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );
  const [value, setValue] = useState(globalFilter);

  const onChangeInputSearch = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const closeModal = () => {
    setShowModal({
      delete: false,
    });
    dispatch({ type: "WE_SUCCESS_OFF" });
  };

  const closeModalEx = () => {
    setShowModal({
      edit: false,
    });
    dispatch(getWorkExperience());
  };

  return (
    <>
      <InputSearch
        setValue={setValue}
        onChange={onChangeInputSearch}
        value={value}
      />
      <TableGrid
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        loaded={loading}
        getTableBodyProps={getTableBodyProps}
        page={page}
        prepareRow={prepareRow}
      />
      <Pagination
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
      <Delete
        uuid={showModal.uuid}
        status={showModal.delete}
        closeModal={closeModal}
      />
      {showModal.edit && dataExperience && (
        <FormEdit
          uuid={showModal.uuid}
          status={showModal.edit}
          preloadedValues={dataExperience}
          closeModal={closeModalEx}
        />
      )}
    </>
  );
}

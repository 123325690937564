const initialState = {
  records: [],
  record: undefined,
  templates: [],
  loaded: false,
  record_loaded: false,
  templates_loaded: false,
  button: false,
  success: false,
  send_success: false,
  success_message: undefined,
  error_message: undefined,
};

export default function notificationReducers(state = initialState, action) {
  switch (action.type) {
    case "NOTIFICATION_RECORDS": {
      return {
        ...state,
        records: action.payload,
        loaded: true,
      };
    }
    case "NOTIFICATION_RECORD": {
      return {
        ...state,
        record: action.payload,
        record_loaded: true,
      };
    }
    case "NOTIFICATION_STORE": {
      return {
        ...state,
        loaded: false,
        success: true,
        success_message: "notification.success.created",
      };
    }
    case "NOTIFICATION_UPDATE": {
      return {
        ...state,
        loaded: false,
        record_loaded: false,
        success: true,
        success_message: "notification.success.updated",
      };
    }
    case "NOTIFICATION_DELETE": {
      return {
        ...state,
        loaded: false,
      };
    }
    case "RELOAD_RECORD": {
      return {
        ...state,
        record_loaded: false,
      };
    }
    case "NOTIFICATION_SUCCESS_OFF": {
      return {
        ...state,
        success: false,
      };
    }
    case "NOTIFICATION_BUTTON": {
      return {
        ...state,
        button: true,
      };
    }
    case "NOTIFICATION_RELOAD": {
      return {
        ...state,
        loaded: false,
      };
    }
    case "NOTIFICATION_SEND_SUCCESS": {
      return {
        ...state,
        send_success: true,
      };
    }
    case "NOTIFICATION_SEND_SUCCESS_OFF": {
      return {
        ...state,
        send_success: false,
      };
    }
    case "NOTIFICATION_ERROR": {
      return {
        ...state,
        error_message: action.payload,
      };
    }
    case "NOTIFICATION_TEMPLATES": {
      return {
        ...state,
        templates: action.payload,
        templates_loaded: true,
      };
    }
    case "NOTIFICATION_TEMPLATES_OFF": {
      return {
        ...state,
        templates_loaded: false,
      };
    }
    default:
      return state;
  }
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  createRole,
  getModules,
} from "../../../redux/actions/admin/roleActions";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { Button } from "../../common";

export default function RolesAdd({ open, handleClose }) {
  const { t } = useTranslation("common");
  const role = useSelector((state) => state.roleReducers);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      name: "",
      description: "",
      // module_id, access
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
    },
  });

  useEffect(() => {
    if (open && !role.modules.length) {
      dispatch(getModules());
    }
  }, [open, role, dispatch]);

  const onSubmitData = (data) => {
    dispatch(createRole(data));
    handleClose("new");
  };

  useEffect(() => {
    reset({
      name: "",
      description: "",
      // module_id, access
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
    });
  }, [handleClose, reset]);

  return (
    <Dialog open={open} onClose={() => handleClose("new")} fullWidth={true}>
      <div className="flex justify-end mt-2 mr-2">
        <button onClick={() => handleClose("new")}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer h-5 w-5  border-2 rounded-md hover:bg-gray-200 hover:text-gray-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <DialogTitle className="border-b text-center">
        <span className="font-bold font-poppins">{t("role.new")}</span>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmitData)} className="w-full pr-3">
          <div className="w-full mx-2 my-5">
            <Controller
              name="name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("personal.error.name_required"),
                },
              }}
              render={({ field }) => (
                <TextField
                  id="name"
                  name="name"
                  type="text"
                  label={t("role.name")}
                  variant="outlined"
                  className="w-full"
                  error={errors.name ? true : false}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => (
                <div className="text-sm mt-1 text-red-500 font-poppins">
                  {message}
                </div>
              )}
            />
          </div>
          <div className="w-full mx-2 my-5">
            <Controller
              name="description"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("portfolio.error.description_required"),
                },
              }}
              render={({ field }) => (
                <TextField
                  id="description"
                  name="description"
                  type="text"
                  label={t("experience.description")}
                  variant="outlined"
                  className="w-full"
                  error={errors.description ? true : false}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="description"
              render={({ message }) => (
                <div className="text-sm mt-1 text-red-500 font-poppins">
                  {message}
                </div>
              )}
            />
          </div>
          <div className="w-full font-poppins mx-2 my-4">
            {t("role.manage_permissions")}
          </div>
          <div className="m-3 grid grid-cols-3 grid-rows-3 flex-wrap">
            {role?.modules?.map(({ id, title }) => (
              <Controller
                key={id}
                name={`${id}`}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    key={id}
                    label={t(`${title}`)}
                    control={
                      <Checkbox
                        name={`${id}`}
                        id={title}
                        {...field}
                        onChange={({ target: { checked } }) =>
                          checked ? field.onChange(1) : field.onChange(0)
                        }
                      />
                    }
                  />
                )}
              />
            ))}
          </div>
          <div className="flex justify-center w-full mt-7">
            <Button
              status={false}
              text={t("experience.addbtn")}
              icon="fa fa-edit"
            />
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

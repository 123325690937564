import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getResults } from "../../../redux/actions/admin/usersActions";
import { useTranslation } from "react-i18next";
import ResultsPdf from "./ResultsPdf";
import { PDFViewer } from "@react-pdf/renderer";

export default function TestResultsPrint(props) {
  const {
    match: {
      params: { uuid },
    },
  } = props;
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const result = useSelector((state) => state.usersReducers);
  const { userSkill } = useParams();

  useEffect(() => {
    dispatch(getResults(uuid));
  }, [dispatch, userSkill, uuid]);

  return (
    <>
      {result.results &&
      Object.keys(result.results).length > 0 &&
      result.results.user ? (
        <PDFViewer style={{ width: "100%", height: "90vh" }}>
          <ResultsPdf result={result} t={t} />
        </PDFViewer>
      ) : (
        <div
          className="fixed"
          style={{ top: "45%", left: "45%" }}
        >
          <div className="text-center p-4 overflow-x-auto">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        </div>
      )}
    </>
  );
}

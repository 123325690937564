import React, { useEffect, useState } from "react";
import { Switch, Route, NavLink, useRouteMatch } from "react-router-dom";
import Dashboard from "../admin/Dashboard";
import Portfolio from "../portfolio/Portfolio";
import Personal from "./Personal";
import Professional from "../professional/Professional";
import References from "../references/References";
import WorkExperience from "../work_experience/WorkExperience";
import Skill from "../skills/Skill";
import { useDispatch, useSelector } from "react-redux";
import { getPersonalInfo } from "../../redux/actions/personalActions";
import UserTest from "../user_test/UserTest";
import { useTranslation } from "react-i18next";
import { isUserIncomplete } from "../../utils/functions";

const styles = {
  ul: "lg:divide-y lg:divide-gray-200 w-full md:w-full md:mx-auto md:mt-2 overflow-auto flex flex-row lg:flex-col lg:w-2/12 text-gray-500 dark:text-gray-400",
  li: "inline-flex items-center flex justify-between w-full text-xs font-poppins font-semibold transition-colors duration-150 hover:text-gray-800",
  link: "relative pl-8 pr-2 py-5 inline-flex items-center flex justify-between w-full text-xs font-poppins font-semibold transition-colors duration-150 hover:text-gray-800",
  active:
    "relative pl-8 pr-2 py-5 inline-flex items-center flex justify-between w-full text-xs font-poppins font-semibold text-gray-800 border-l-4 border-gray-800 bg-gray-100",
  linkgreen:
    "break-normal bg-red-500 block py-3 p-2 px-5 lg:px-0 lg:pl-4 lg:pr-3 bg-green-700 text-white border-b border-green-500 hover:bg-green-800 flex justify-between",
};

export default function Profile() {
  let { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  const personal = useSelector((state) => state.personalReducers);
  const { t } = useTranslation("common");
  const [isProfileIncomplete, setIsProfileIncomplete] = useState(undefined);
  const [toggleState, setToggleState] = useState(1);
  const [loaded, setLoaded] = useState(false);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  useEffect(() => {
    if (!personal.loaded) dispatch(getPersonalInfo());

    if (personal.records[0]) {
      const incomplete = isUserIncomplete(personal.records[0]);
      setIsProfileIncomplete(incomplete);
    }
  }, [dispatch, personal.loaded, personal.records]);

  useEffect(() => {
    if (isProfileIncomplete !== undefined) {
      styles.disabled = !isProfileIncomplete
        ? ""
        : "pointer-events-none opacity-50";

      setLoaded(true);
    }
  }, [isProfileIncomplete]);

  return (
    <>
      {loaded ? (
        <div className="w-full lg:mx-5 flex-col lg:flex-row flex lg:mt-5 animate__animated animate__fadeIn">
          <ul className={styles.ul}>
            {/* <li className={styles.li}>
            <NavLink
              exact
              className={
                toggleState === 1
                  ? "relative pl-8 pr-2 py-5 inline-flex items-center justify-between w-full text-xs font-poppins font-semibold text-gray-800 border-l-4 border-gray-800 bg-gray-100"
                  : "relative pl-8 pr-2 py-5 inline-flex items-center justify-between w-full text-xs font-poppins font-semibold transition-colors duration-150 hover:text-gray-800"
              }
              //onClick={() => toggleTab(1)}
              to="/dashboard/admin"
            >
              <i className="fas fa-tachometer-alt mr-4"></i>
              {t("menu_profile.dashboard")}
              <span className="hidden md:inline md:ml-2">
                <i className="fas fa-chevron-right pt-1 hidden"></i>
              </span>
            </NavLink>
          </li> */}
            <li className={styles.li}>
              <NavLink
                exact
                className={
                  toggleState === 2
                    ? "relative pl-8 pr-2 py-5 inline-flex items-center justify-between w-full text-xs font-poppins font-semibold text-gray-800 border-l-4 border-gray-800 bg-gray-100"
                    : "relative pl-8 pr-2 py-5 inline-flex items-center justify-between w-full text-xs font-poppins font-semibold transition-colors duration-150 hover:text-gray-800"
                }
                onClick={() => toggleTab(2)}
                to={`${url}`}
              >
                <i className="fas fa-user-cog fas mr-4"></i>
                {t("menu_profile.profile")}
                <span className="hidden md:inline md:ml-2">
                  <i className="fas fa-chevron-right pt-1 hidden"></i>
                </span>
              </NavLink>
            </li>

            <li className={`${styles.li} ${styles.disabled}`}>
              <NavLink
                exact
                className={
                  toggleState === 3
                    ? "relative pl-8 pr-2 py-5 inline-flex items-center justify-between w-full text-xs font-poppins font-semibold text-gray-800 border-l-4 border-gray-800 bg-gray-100"
                    : "relative pl-8 pr-2 py-5 inline-flex items-center justify-between w-full text-xs font-poppins font-semibold transition-colors duration-150 hover:text-gray-800"
                }
                onClick={() => toggleTab(3)}
                to={`${url}/skills`}
              >
                <i className="fas fa-brain mr-4"></i>
                {t("menu_profile.skills")}{" "}
                <span className="hidden md:inline md:ml-2">
                  <i className="fas fa-chevron-right pt-1"></i>
                </span>
              </NavLink>
            </li>

            <li className={`${styles.li} ${styles.disabled}`}>
              <NavLink
                exact
                className={
                  toggleState === 4
                    ? "relative pl-8 pr-2 py-5 inline-flex items-center justify-between w-full text-xs font-poppins font-semibold text-gray-800 border-l-4 border-gray-800 bg-gray-100"
                    : "relative pl-8 pr-2 py-5 inline-flex items-center justify-between w-full text-xs font-poppins font-semibold transition-colors duration-150 hover:text-gray-800"
                }
                onClick={() => toggleTab(4)}
                to={`${url}/work`}
              >
                <i className="fas fa-plus-circle mr-4"></i>
                {t("menu_profile.experience")}{" "}
                <span className="hidden md:inline md:ml-2">
                  <i className="fas fa-chevron-right pt-1"></i>
                </span>
              </NavLink>
            </li>

            <li className={`${styles.li} ${styles.disabled}`}>
              <NavLink
                exact
                className={
                  toggleState === 5
                    ? "relative pl-8 pr-2 py-5 inline-flex items-center justify-between w-full text-xs font-poppins font-semibold text-gray-800 border-l-4 border-gray-800 bg-gray-100"
                    : "relative pl-8 pr-2 py-5 inline-flex items-center justify-between w-full text-xs font-poppins font-semibold transition-colors duration-150 hover:text-gray-800"
                }
                onClick={() => toggleTab(5)}
                to={`${url}/portfolio`}
              >
                <i className="fas fa-mail-bulk mr-4"></i>
                {t("menu_profile.portfolio")}{" "}
                <span className="hidden  md:inline md:ml-2">
                  <i className="fas fa-chevron-right pt-1"></i>
                </span>
              </NavLink>
            </li>

            <li className={`${styles.li} ${styles.disabled}`}>
              <NavLink
                exact
                className={
                  toggleState === 6
                    ? "relative pl-8 pr-2 py-5 inline-flex items-center justify-between w-full text-xs font-poppins font-semibold text-gray-800 border-l-4 border-gray-800 bg-gray-100"
                    : "relative pl-8 pr-2 py-5 inline-flex items-center justify-between w-full text-xs font-poppins font-semibold transition-colors duration-150 hover:text-gray-800"
                }
                onClick={() => toggleTab(6)}
                to={`${url}/professional`}
              >
                <i className="fas fa-user-graduate mr-4"></i>
                {t("menu_profile.professional")}{" "}
                <span className="hidden  md:inline md:ml-2">
                  <i className="fas fa-chevron-right pt-1"></i>
                </span>
              </NavLink>
            </li>

            <li className={`${styles.li} ${styles.disabled}`}>
              <NavLink
                exact
                className={
                  toggleState === 7
                    ? "relative pl-8 pr-2 py-5 inline-flex items-center justify-between w-full text-xs font-poppins font-semibold text-gray-800 border-l-4 border-gray-800 bg-gray-100"
                    : "relative pl-8 pr-2 py-5 inline-flex items-center justify-between w-full text-xs font-poppins font-semibold transition-colors duration-150 hover:text-gray-800"
                }
                onClick={() => toggleTab(7)}
                to={`${url}/references`}
              >
                <i className="fas fa-people-arrows mr-4"></i>
                {t("menu_profile.references")}{" "}
                <span className="hidden  md:inline md:ml-2">
                  <i className="fas fa-chevron-right pt-1"></i>
                </span>
              </NavLink>
            </li>
          </ul>
          <div className="w-11/12 mt-5 lg:mt-0 md:w-10/12 mx-auto md:mx-10">
            <Route path={`${path}/Dashboard`} component={Dashboard} />
            <Switch>
              <Route
                exact
                path={path}
                component={() => (
                  <Personal setIsProfileIncomplete={setIsProfileIncomplete} />
                )}
              />
              <Route path={`${path}/work`} component={WorkExperience} />
              <Route path={`${path}/portfolio`} component={Portfolio} />
              <Route path={`${path}/professional`} component={Professional} />
              <Route path={`${path}/references`} component={References} />
              <Route exact path={`${path}/skills`} component={Skill} />
              <Route
                path={`${path}/skills/test/:user_skill_uuid`}
                component={UserTest}
              />
            </Switch>
          </div>
        </div>
      ) : (
        <div className="h-full">
          <div className="text-center my-72 p-4 overflow-x-auto">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        </div>
      )}
    </>
  );
}

import React from "react";
import { useDispatch } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

export default function Success(props) {
   const dispatch = useDispatch();
   let { status, styleParent, message, closer } = props;

   return (
      <>
         {status && (
            <div
               className={` ${styleParent} bg-green-50 border border-green-300`}
            >
               <Collapse in={status}>
                  <Alert
							severity="success"
                     action={
                        <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                              dispatch({ type: closer });
                           }}
                        >
                           <CloseIcon fontSize="inherit" />
                        </IconButton>
                     }
                  >
                     <AlertTitle>Success</AlertTitle>
                     {message}
                  </Alert>
               </Collapse>
            </div>
         )}
      </>
   );
}

import React from "react";
import { useTranslation } from "react-i18next";
import gifHiring from "../../views/assets/img/animation_640_krze75vw.gif";
import Footer from "../admin/footer";

export default function QuestionsFreq() {
    const { t } = useTranslation('common');
    return (
        <>

            <main className="bg-black bg-opacity-95">
                <div className="px-20 py-12 lg:px-8 lg:py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center mt-8 lg:max-w-2xl md:mb-12">
                        <h2 className="font-poppins max-w-lg mb-4 text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
                            <span className="relative inline-block">
                                <svg viewBox="0 0 52 24" fill="currentColor" className="absolute top-0 left-0 z-0 hidden w-32 mt-8 ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                                    <defs>
                                        <pattern id="07690130-d013-42bc-83f4-90de7ac68f76" x="0" y="0" width=".135" height=".30">
                                            <circle cx="1" cy="1" r=".7"></circle>
                                        </pattern>
                                    </defs>
                                    <rect fill="url(#07690130-d013-42bc-83f4-90de7ac68f76)" width="50" height="24"></rect>
                                </svg>
                                <span className="relative font-poppins"> {t("hiring.faq.title")}</span>
                            </span>
                        </h2>
                    </div>
                    <div className="grid max-w-screen-lg mx-auto space-y-6 lg:grid-cols-2 lg:space-y-0 lg:divide-x">
                        <div className="space-y-6 pb-2 sm:px-16">
                            <div className="flex flex-col max-w-md sm:flex-row">
                                <div className="mb-4 mr-4">
                                    <div className="flex items-center justify-center w-12 h-12 rounded-full text-red-500 bg-white">
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    <h6 className="font-poppins text-red-500 mb-3 text-md font-semibold leading-2">{t('hiring.faq.question_1')}</h6>
                                    <span className="font-poppins text-sm text-white font-light">
                                        <p className="text-justify">
                                            {t('hiring.faq.answer_1')}
                                        </p>
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-col max-w-md sm:flex-row">
                                <div className="mb-4 mr-4">
                                    <div className="flex items-center justify-center w-12 h-12 rounded-full text-red-500 bg-white">
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    <h6 className="font-poppins text-red-500 mb-3 text-md font-semibold leading-2">{t('hiring.faq.question_2')}</h6>
                                    <span className="font-poppins text-sm text-white font-light">
                                        <p className="text-justify">
                                            {t('hiring.faq.answer_2')}
                                        </p>
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-col max-w-md sm:flex-row">
                                <div className="mb-4 mr-4">
                                    <div className="flex items-center justify-center w-12 h-12 rounded-full text-red-500 bg-white">
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    <h6 className="font-poppins text-red-500 mb-3 text-md font-semibold leading-2">{t('hiring.faq.question_3')}</h6>
                                    <span className="text-sm text-white font-light">
                                        <p className="text-justify">
                                            {t('hiring.faq.answer_3')}
                                        </p>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="space-y-6 pb-8 sm:px-16">
                            <div className="flex flex-col max-w-md sm:flex-row">
                                <div className="mb-4 mr-4">
                                    <div className="flex items-center justify-center w-12 h-12 rounded-full text-red-500 bg-white">
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    <h6 className="font-poppins text-red-500 mb-3 text-md font-semibold leading-2">{t('hiring.faq.question_4')}</h6>
                                    <span className="font-poppins text-sm text-white font-light">
                                        <p className="text-justify font-poppins">
                                            {t('hiring.faq.answer_4')}
                                        </p>
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-col max-w-md sm:flex-row">
                                <div className="mb-4 mr-4">
                                    <div className="flex items-center justify-center w-12 h-12 rounded-full text-red-500 bg-white">
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    <h6 className="font-poppins text-red-500 mb-3 text-md font-semibold leading-2">{t('hiring.faq.question_5')}</h6>
                                    <span className="font-poppins text-sm text-white font-light">
                                        <p className="font-poppins text-justify">
                                            {t('hiring.faq.answer_5')}
                                        </p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center flex-shrink-0 text-white mr-6">
                        <img
                            alt="..."
                            src={gifHiring}
                            className="fill-current h-72 w-80 mr-2"
                        />
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}
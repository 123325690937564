import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getResults } from "../../../redux/actions/admin/usersActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import SimpleSelect from "../../selector/SimpleSelect";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";

export default function Results(props) {
  const {
    match: {
      params: { uuid },
    },
  } = props;
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const result = useSelector((state) => state.usersReducers);
  const { userSkill } = useParams();

  useEffect(() => {
    dispatch(getResults(uuid));
  }, [dispatch, userSkill, uuid]);

  return (
    <>
      {result.results && (
        <div className={`w-full h-full`}>
          <div className={`w-11/12 mt-10 mx-auto`}>
            <div className="flex justify-between items-center mb-5">
              <Link to={localStorage.getItem("back_url")}>
                <Button className="focus:outline-none" variant="outlined">
                  <i className="fa fa-arrow-left mr-2"></i> {t("personal.back")}
                </Button>
              </Link>
              <Link to={`/admin/users/print-results/${uuid}`} target="_blank">
                <button className="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-300 focus:outline-none">
                  <i className="fas fa-print"></i>
                  <span className="ml-4 flex items-start flex-col leading-none">
                    <span className="text-xs text-gray-600 mb-1">
                      {t("userSkills.print")}
                    </span>
                    <span className="title-font font-medium">
                      {t("userSkills.result")}
                    </span>
                  </span>
                </button>
              </Link>
            </div>
            {result.results.user !== undefined ? (
              <div className="shadow-lg">
                <div className="flex justify-between items-center p-3 border-b bg-gray-50 rounded-lg">
                  <span className="font-semibold">
                    {t("modal.result_for")} {result.results.user}
                  </span>
                </div>
                <div className="pb-2">
                  <div className="flex justify-between">
                    <div className="bg-blue-50 p-2 m-2 mr-2 border border-blue-300 w-5/12 mx-auto">
                      <div className="flex justify-between pt-4">
                        <div>
                          <span className="font-semibold">
                            {t("menu_profile.skills")}:{" "}
                          </span>{" "}
                          <span>{result.results.skill}</span>
                        </div>
                        <div>
                          <span className="font-semibold">
                            {t("userSkills.totalQuestion")}:{" "}
                          </span>{" "}
                          <span>
                            {result.results.count_question
                              ? result.results.count_question
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div>
                          <span className="font-semibold">
                            {t("userSkills.note")}:{" "}
                          </span>{" "}
                          <span>{result.results.note}</span>
                        </div>
                        <div>
                          <span className="font-semibold">
                            {" "}
                            {t("userSkills.corrects")} :
                          </span>{" "}
                          <span>
                            {result.results.corrects
                              ? result.results.corrects
                              : 0}{" "}
                            /{" "}
                            {result.results.correct_porc
                              ? result.results.correct_porc
                              : 0}{" "}
                            %{" "}
                          </span>
                          <i className="fa fa-check text-green-500 ml-2"></i>
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div>
                          <span className="font-semibold">
                            {t("userSkills.timeTest")}:{" "}
                          </span>{" "}
                          <span>
                            {result.results.time_complete
                              ? result.results.time_complete
                              : "00:00"}
                          </span>
                        </div>
                        <div>
                          <span className="font-semibold">
                            {t("userSkills.incorrects")}:
                          </span>{" "}
                          <span>
                            {result.results.incorrects
                              ? result.results.incorrects
                              : 0}{" "}
                            /{" "}
                            {result.results.incorrect_porc
                              ? result.results.incorrect_porc
                              : 0}{" "}
                            %{" "}
                          </span>{" "}
                          <i className="fa fa-times text-red-500 ml-2"></i>
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div></div>
                        <div>
                          <span className="font-semibold">
                            {t("userSkills.failEvaluate")}:
                          </span>{" "}
                          <span>
                            {result.results.evaluate
                              ? result.results.evaluate
                              : 0}{" "}
                            /{" "}
                            {result.results.evaluate_porc
                              ? result.results.evaluate_porc
                              : 0}{" "}
                            %{" "}
                          </span>{" "}
                          <i className="fa fa-edit text-yellow-500 ml-2"></i>
                        </div>
                      </div>
                    </div>
                    <div className="p-1 m-2 pt-5 border border-green-300 w-1/8 mx-auto">
                      <div>
                        <i className="fa fa-clipboard text-yellow-300 ml-2"></i>{" "}
                        <span className="font-semibold">
                          {t("userSkills.pasteAnswer")}:{" "}
                        </span>{" "}
                        <span>
                          {result.results.paste_answer
                            ? result.results.paste_answer
                            : 0}{" "}
                          /{" "}
                          {result.results.paste_answer_porc
                            ? result.results.paste_answer_porc
                            : 0}{" "}
                          %{" "}
                        </span>{" "}
                      </div>
                      <div>
                        <i className="fa fa-copy text-gray-300	 ml-2"></i>{" "}
                        <span className="font-semibold">
                          {t("userSkills.copyAnswer")}:{" "}
                        </span>{" "}
                        <span>
                          {result.results.copy_answer
                            ? result.results.copy_answer
                            : 0}{" "}
                          /{" "}
                          {result.results.copy_answer_porc
                            ? result.results.copy_answer_porc
                            : 0}{" "}
                          %{" "}
                        </span>{" "}
                      </div>
                      <div>
                        <i className="fa fa-file-import text-red-400 ml-2"></i>{" "}
                        <span className="font-semibold">
                          {t("userSkills.copyQuestion")}:{" "}
                        </span>{" "}
                        <span>
                          {result.results.copy_question
                            ? result.results.copy_question
                            : 0}{" "}
                          /{" "}
                          {result.results.copy_question_porc
                            ? result.results.copy_question_porc
                            : 0}{" "}
                          %{" "}
                        </span>{" "}
                      </div>
                    </div>
                    <div className="p-2 m-2 border border-green-300 w-1/8 mx-auto">
                      {Object.values(result.results.likert_user).map(
                        (val, index) => (
                          <div key={index}>
                            <span className="font-semibold">
                              {val.scale === "S"
                                ? t("userSkills.outstanding")
                                : val.scale === "E"
                                ? t("userSkills.excelent")
                                : val.scale === "R"
                                ? t("userSkills.regular")
                                : val.scale === "D"
                                ? t("userSkills.deficient")
                                : val.scale === "M"
                                ? t("userSkills.badd")
                                : t("userSkills.failEvaluate")}
                              :{" "}
                            </span>{" "}
                            <span>
                              {val.count_scale ? val.count_scale : 0} /{" "}
                              {val.count_scale
                                ? (
                                    (val.count_scale * 100) /
                                    result.results.count_question
                                  ).toFixed(2)
                                : 0}{" "}
                              %
                            </span>
                          </div>
                        )
                      )}
                      <div className="text-center border-t-2 border-solid border-light-blue-500 border-dashed mt-1">
                        {result.results.points <= 100 &&
                        result.results.points >= 81 ? (
                          <span className="font-semibold underline text-green-500">
                            {t("userSkills.sobresal")}
                          </span>
                        ) : result.results.points <= 80 &&
                          result.results.points >= 61 ? (
                          <span className="font-semibold underline text-blue-500">
                            {t("userSkills.excellent")}
                          </span>
                        ) : result.results.points <= 60 &&
                          result.results.points >= 41 ? (
                          <span className="font-semibold underline text-yellow-500">
                            {t("userSkills.regular")}
                          </span>
                        ) : result.results.points <= 40 &&
                          result.results.points >= 21 ? (
                          <span className="font-semibold underline text-yellow-500">
                            {t("userSkills.deficent")}
                          </span>
                        ) : (
                          <span className="font-semibold underline text-red-500">
                            {t("userSkills.bad")}
                          </span>
                        )}
                      </div>
                      <div className="text-center">
                        <span className="font-semibold">
                          {t("userSkills.points")}:{" "}
                        </span>{" "}
                        <span>
                          {result.results.points ? result.results.points : 0}{" "}
                          pts{" "}
                        </span>{" "}
                      </div>
                      <div className="text-center">
                        <span className="font-semibold">
                          {t("userSkills.average")}:{" "}
                        </span>{" "}
                        <span>
                          {result.results.points
                            ? (
                                result.results.points /
                                result.results.count_question
                              ).toFixed(2)
                            : 0}
                        </span>{" "}
                      </div>
                    </div>
                  </div>

                  {Object.values(result.results.user_test).map(
                    (item, index) => (
                      <div
                        key={index}
                        className="bg-gray-50 border border-gray-200 w-11/12 mx-auto mb-2 p-2"
                      >
                        <div className="flex justify-between">
                          <div className="flex justify-start w-6/12">
                            {item.image && (
                              <div
                                className="my-1 h-10 border-2 border-blue-500  hover:bg-blue-500 text-blue-500 hover:text-white font-bold mx-1 py-2 px-2 rounded-lg"
                                style={{ width: "5%" }}
                              >
                                <a
                                  href={
                                    `${process.env.REACT_APP_BASE_URL}` +
                                    item.image
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <svg
                                    className="w-4 h-4"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                                    ></path>
                                  </svg>
                                </a>
                              </div>
                            )}
                            <div className="ml-2">
                              <span className="font-semibold border-b">
                                {item.questions}
                              </span>
                            </div>
                          </div>
                          <div className="w-2/12 text-right">
                            <i className="fa fa-clock text-gray-500 ml-2"></i>{" "}
                            <span>{item.time}</span>
                          </div>
                          <div className="w-1/12">
                            {item.copy_answer === 1 ? (
                              <span>
                                <i className="fa fa-copy text-gray-300	 ml-2"></i>{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            {item.copy_question === 1 ? (
                              <span>
                                <i className="fa fa-file-import text-red-400 ml-2"></i>{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            {item.paste_answer === 1 ? (
                              <span>
                                <i className="fa fa-clipboard text-yellow-300 ml-2"></i>{" "}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          {item.question_type === "select" ? (
                            <div className="w-2/12">
                              <i className="fa fa-thumbtack text-gray-500 ml-2"></i>{" "}
                              <span className="font-semibold">
                                {item.likert === "S"
                                  ? t("userSkills.outstanding")
                                  : item.likert === "E"
                                  ? t("userSkills.excelent")
                                  : item.likert === "R"
                                  ? t("userSkills.regular")
                                  : item.likert === "D"
                                  ? t("userSkills.deficient")
                                  : item.likert === "M"
                                  ? t("userSkills.badd")
                                  : t("userSkills.failEvaluate")}
                              </span>
                            </div>
                          ) : (
                            <div className="w-2/12">
                              <SimpleSelect
                                idprueba={item.uuid}
                                likert={item.likert !== "SE" ? item.likert : ""}
                              ></SimpleSelect>
                            </div>
                          )}
                          <div className="w-1/12 text-right">
                            {item.likert === "S" ? (
                              <span className="font-semibold underline text-green-500">
                                {item.point_likert} pts
                              </span>
                            ) : item.likert === "E" ? (
                              <span className="font-semibold underline text-blue-500">
                                {item.point_likert} pts
                              </span>
                            ) : item.likert === "R" ? (
                              <span className="font-semibold underline text-yellow-500">
                                {item.point_likert} pts
                              </span>
                            ) : (
                              <span className="font-semibold underline text-red-500">
                                {item.point_likert} pts
                              </span>
                            )}
                          </div>
                        </div>
                        {item.question_type === "select" ? (
                          Object.entries(item.answers).map(
                            ([key, item2], index) => (
                              <div
                                key={index}
                                className={`flex justify-start ml-5 my-3 ${
                                  item2.result ? "text-green-500" : ""
                                }`}
                              >
                                {item2.image && (
                                  <div
                                    className=" h-5 border-2 border-blue-500  hover:bg-blue-500 text-blue-500 hover:text-white font-bold py-1 px-1 rounded-lg"
                                    style={{ width: "2%" }}
                                  >
                                    <a
                                      href={
                                        `${process.env.REACT_APP_BASE_URL}` +
                                        item2.image
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <svg
                                        style={{ marginLeft: "1%" }}
                                        className="w-3 h-3"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                                        ></path>
                                      </svg>
                                    </a>
                                  </div>
                                )}
                                {parseInt(key) + 1}.- {item2.answer}
                                {item2.id === item.answer && item2.result ? (
                                  <i className="fa fa-check text-green-500 ml-2"></i>
                                ) : (
                                  ""
                                )}
                                {item2.id === item.answer && !item2.result ? (
                                  <i className="fa fa-times text-red-500 ml-2"></i>
                                ) : (
                                  ""
                                )}
                              </div>
                            )
                          )
                        ) : (
                          <div className="grid grid-cols-2 gap-4">
                            <div className="ml-1 my-3">{item.annotation}</div>
                            <div className="ml-12 my-3"></div>
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : (
              <div className="flex justify-center p-24">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import SessionDialog from "./SessionDialog";

export default function SessionTimeout(props){
    const [timeModal, setTimeModal] = useState(false);
    const [timeDown, setTimeDown] = useState(0);
    const idleTimer = useRef(null);
    const alertContinue = useRef(null);
    let countInterval;
    let timeout;
    let countDown = 10;

    const { isAuthenticated, logOut, expirationTime } = props;

    const clearSession = () => {
        clearTimeout(timeout);
    };

    const clearSessionInterval = () => {
        clearInterval(countInterval);
    };

    const handleLogout = async (isTimedOut = false) => {        
        try {
            setTimeModal(false);
            clearSessionInterval();
            clearSession();
            logOut();
        } catch (err) {
            console.error(err);
        }
    };
    const handleContinue = () => {
        setTimeModal(false);
        alertContinue.current = true;    
    };
  
    const onIdle = () => {
        const delay = 1000 * 1;           
        if (isAuthenticated && !timeModal) {
            timeout = setTimeout(() => {
                setTimeModal(true);
                setTimeDown(countDown);
                countInterval = setInterval(() => {
                    if (countDown > 0) {
                        if(!alertContinue.current || alertContinue.current === null){
                            setTimeDown(--countDown);                       
                        }else{
                            clearInterval(countInterval);
                            alertContinue.current = false;
                        }
                    }else if(countDown === 0){  
                        handleLogout(true);
                    }                 
                }, 1000);
            }, delay);
        }
    };
    return (
        <>
            <IdleTimer
                ref={idleTimer}
                onIdle={onIdle}
                debounce={250}
                timeout={expirationTime}
            />
            <SessionDialog
                countdown={timeDown}
                onContinue={handleContinue}
                onLogout={() => handleLogout(false)}
                open={timeModal}
            />
        </>
    );

}
import api from "../../../utils/api";

export const getNotifications = (language) => async (dispatch) => {
  try {
    const { data } = await api.get(`/email/${language}`);
    dispatch({
      type: "NOTIFICATION_RECORDS",
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getNotificationByUuid = (uuid) => async (dispatch) => {
  try {
    const { data } = await api.get(`/email/record/${uuid}`);
    dispatch({
      type: "NOTIFICATION_RECORD",
      payload: data[0],
    });
  } catch (e) {
    console.log(e);
  }
};

export const createNotification = (email) => async (dispatch) => {
  dispatch({ type: "NOTIFICATION_BUTTON" });

  try {
    const response = await api.post("/email", email);
    if (response?.data) {
      dispatch({
        type: "NOTIFICATION_STORE",
        payload: response.data,
      });
    } else {
      throw new Error("Error creating notification");
    }
  } catch (e) {
    dispatch({
      type: "NOTIFICATION_ERROR",
      payload: "notification.error.existing",
    });
  }
};

export const updateNotification = (email, uuid) => async (dispatch) => {
  try {
    const response = await api.put(`/email/${uuid}`, email);
    dispatch({
      type: "NOTIFICATION_UPDATE",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const deleteNotification = (uuid) => async (dispatch) => {
  try {
    const response = await api.delete(`/email/${uuid}`);
    dispatch({
      type: "NOTIFICATION_DELETE",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getTemplatesByStage = (data) => async (dispatch) => {
  try {
    const response = await api.post(`/email/templates`, data);
    dispatch({
      type: "NOTIFICATION_TEMPLATES",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSteps } from "../../redux/actions/stepsActions";
import imgHiring from "../../img/hiring_proces.jpg";
import Footer from "../admin/footer";

export default function HiringProcess() {
	const { t, i18n } = useTranslation('common');
	const dispatch = useDispatch();
	const steps = useSelector((state) => state.stepsReducers);
	const lang = localStorage.getItem('lang');
	var i = 0;
	useEffect(() => {
		if (!steps.loaded || i18n.language) dispatch(getSteps());
	}, [dispatch, steps.loaded, i18n.language, lang]);
	return (
		<>
			<section>
				<div className="text-center px-4 pt-16 pb-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20 lg:pb-2">
					<div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
						<div>
							<span className="font-poppins inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
								{t('menu.recriutment')}
							</span>
						</div>
						<h2 className="text-center max-w-lg mb-4 font-poppins text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
							<span className="relative inline-block">
								<svg viewBox="0 0 52 24" fill="currentColor" className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
									<defs>
										<pattern id="07690130-d013-42bc-83f4-90de7ac68f76" x="0" y="0" width=".135" height=".30">
											<circle cx="1" cy="1" r=".7"></circle>
										</pattern>
									</defs>
									<rect fill="url(#07690130-d013-42bc-83f4-90de7ac68f76)" width="50" height="24"></rect>
								</svg>
								<span className="relative">{t('hiring.title')}</span>
							</span>
						</h2>
						<span className="text-base font-poppins text-gray-700 md:text-lg">
							{t('hiring.subtitle')}
						</span>
					</div>
				</div>
				{steps.loaded ?
					<div className="px-4 py-2 lg:py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
						<div className="grid gap-6 row-gap-10 lg:grid-cols-2">
							<div className="lg:py-6 lg:pr-16">
								{steps.data.length > 0 &&
									<>
										{Object.entries(steps.data).map(([key, item]) => {
											i++;
											return ((i !== steps.data.length)) ?
												<div className="flex" key={key}>
													<div className="flex flex-col items-center mr-4">
														<div>
															<div className="flex items-center justify-center w-10 h-10 border-2 rounded-full bg-red-600">
																<svg className="w-4 text-white" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
																	<line fill="none" strokeMiterlimit="10" x1="12" y1="2" x2="12" y2="22"></line>
																	<polyline fill="none" strokeMiterlimit="10" points="19,15 12,22 5,15"></polyline>
																</svg>
															</div>
														</div>
														<div className="w-px h-full bg-gray-300"></div>
													</div>
													<div className="pt-1 pb-8">
														<p className="mb-2 font-poppins text-lg font-bold">{t('hiring.process.step')} {item.number_step}</p>
														<label className="font-poppins text-gray-600 font-light">
															<p className="text-justify font-poppins">
																{item.description_step}
															</p>
														</label>
													</div>
												</div>
												:
												<div className="flex" key={key} >
													<div className="flex flex-col items-center mr-4" >
														<div>
															<div className="flex items-center justify-center w-10 h-10 rounded-full bg-green-300 border-8">
																<svg className="w-6 text-green-900" stroke="currentColor" viewBox="0 0 24 24">
																	<polyline fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="6,12 10,16 18,8"></polyline>
																</svg>
															</div>
														</div>
													</div>
													<div className="pt-1">
														<p className="mb-2 text-lg font-bold">{t('hiring.ready')}</p>
														<label className="font-poppins text-green-600" >
															<label className="font-poppins text-gray-700 font-light">
																{item.description_step}
															</label>
														</label>
													</div>
												</div>
										}
										)}
									</>
								}
							</div>
							{steps.data.length > 0 &&
								<div className="relative">
									<img
										className="inset-0 object-cover object-bottom w-full rounded shadow-lg h-96 lg:absolute lg:h-full"
										src={imgHiring}
										alt=""
									/>
								</div>
							}
						</div>
					</div>
					:
					<div className="flex justify-center mt-15 mb-24">
						<i className="fa fa-spin fa-spinner fa-2x"></i>
					</div>
				}
			</section>
			<Footer />
		</>
	)
}
const initialState = {
  $isLoading: false,
};

export default function loadingReducers(state = initialState, action) {
  switch (action.type) {
    case "SHOW_LOADING": {
      return {
        $isLoading: true,
      };
    }
    case "HIDDEN_LOADING": {
      return {
        $isLoading: false,
      };
    }
    default:
      return state;
  }
}

import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  Svg,
  Path,
  Font,
  StyleSheet,
} from "@react-pdf/renderer";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf",
      fontWeight: "semibold",
    },
  ],
});

const stylesPDF = StyleSheet.create({
  pageWrapper: {
    width: "95%",
    marginHorizontal: "auto",
    fontSize: 10,
    fontFamily: "Poppins",
  },
  titleWeight: { fontWeight: "semibold" },
  title: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 12,
    borderBottomWidth: 1,
    borderColor: "#e5e7eb",
    backgroundColor: "#f9fafb",
    borderRadius: 3,
    marginBottom: 10,
  },
  iconMargin: {
    marginLeft: 4,
    marginBottom: 2,
  },
  flexSpaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flexWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  skillInfo: {
    backgroundColor: "#eff6ff",
    padding: 8,
    margin: 8,
    marginTop: 0,
    borderWidth: 1,
    borderColor: "#93c5fd",
    width: "50%",
    lineHeight: 1.3,
  },
  skillInfoColumn: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 6,
    width: "50%",
  },
  answerTestInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: 4,
  },
  testInfoColumn: {
    padding: 8,
    margin: 8,
    marginTop: 0,
    borderWidth: 1,
    borderColor: "#6ee7b7",
    width: "25%",
  },
  testResult: {
    textAlign: "center",
    borderTopWidth: 2,
    borderColor: "#e5e7eb",
    borderStyle: "dashed",
    marginTop: 4,
    paddingTop: 4,
    lineHeight: 1.4,
  },
  testResultText: {
    fontWeight: "semibold",
    textDecoration: "underline",
  },
  pointsRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 4,
  },
  questionContainer: {
    backgroundColor: "#f9fafb",
    borderWidth: 1,
    borderColor: "#e5e7eb",
    padding: 8,
    marginBottom: 8,
  },
  questionInfo: { marginLeft: 4, width: "50%" },
  answerContainer: { marginTop: 6, width: "80vw" },
  answerTypeSelect: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: 6,
    marginBottom: 4,
  },
  questionColumn: {
    display: "flex",
    flexDirection: "column",
    width: "16.66%",
  },
  pointsText: {
    width: "15.33%",
    textAlign: "right",
    marginRight: 35,
  },
  testOutstanding: {
    color: "#10b981",
  },
  testExcellent: {
    color: "#3b82f6",
  },
  testRegular: {
    color: "#f59e0b",
  },
  testBad: {
    color: "#ce4c4c",
  },
  questionIconRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  questionIcon: { marginTop: 2, marginHorizontal: 4 },
  pageNumberView: { position: "relative", paddingTop: 80 },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 16,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default function ResultsPdf({ result, t }) {
  return (
    <Document>
      <Page size="A4">
        <View style={stylesPDF.pageWrapper}>
          <View fixed style={stylesPDF.title}>
            <Text style={stylesPDF.titleWeight}>
              {t("modal.result_for")} {result.results.user}
            </Text>
          </View>
          <View fixed>
            <View style={stylesPDF.flexSpaceBetween}>
              <View style={[stylesPDF.flexSpaceBetween, stylesPDF.skillInfo]}>
                <View style={[stylesPDF.skillInfoColumn, { marginRight: 10 }]}>
                  <View style={stylesPDF.flexWrap}>
                    <Text style={stylesPDF.titleWeight}>
                      {t("menu_profile.skills")}:{" "}
                    </Text>
                    <Text>{result.results.skill}</Text>
                  </View>
                  <View>
                    <Text style={stylesPDF.titleWeight}>
                      {t("userSkills.note")}:{" "}
                    </Text>
                    <Text>{result.results.note}</Text>
                  </View>
                  <View style={stylesPDF.flexWrap}>
                    <Text style={stylesPDF.titleWeight}>
                      {t("userSkills.timeTest")}:{" "}
                    </Text>
                    <Text>
                      {result.results.time_complete
                        ? result.results.time_complete
                        : "00:00"}
                    </Text>
                  </View>
                </View>
                <View style={stylesPDF.skillInfoColumn}>
                  <View style={stylesPDF.flexWrap}>
                    <Text style={stylesPDF.titleWeight}>
                      {t("userSkills.totalQuestion")}:{" "}
                    </Text>
                    <Text>
                      {result.results.count_question
                        ? result.results.count_question
                        : 0}
                    </Text>
                  </View>
                  <View style={[stylesPDF.flexWrap, { marginTop: 2 }]}>
                    <Text style={stylesPDF.titleWeight}>
                      {t("userSkills.corrects")}:{" "}
                    </Text>
                    <Text>
                      {result.results.corrects ? result.results.corrects : 0} /{" "}
                      {result.results.correct_porc
                        ? result.results.correct_porc
                        : 0}{" "}
                      %{" "}
                    </Text>
                    <Svg
                      width={9}
                      style={stylesPDF.iconMargin}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <Path
                        fill="#10b981"
                        d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                      />
                    </Svg>
                  </View>
                  <View style={[stylesPDF.flexWrap, { marginTop: 2 }]}>
                    <Text style={stylesPDF.titleWeight}>
                      {t("userSkills.incorrects")}:{" "}
                    </Text>
                    <Text>
                      {result.results.incorrects
                        ? result.results.incorrects
                        : 0}{" "}
                      /{" "}
                      {result.results.incorrect_porc
                        ? result.results.incorrect_porc
                        : 0}{" "}
                      %{" "}
                    </Text>{" "}
                    <Svg
                      width={7}
                      style={stylesPDF.iconMargin}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 352 512"
                    >
                      <Path
                        fill="#ce4c4c"
                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                      />
                    </Svg>
                  </View>
                  <View style={[stylesPDF.flexWrap, { marginTop: 2 }]}>
                    <Text style={stylesPDF.titleWeight}>
                      {t("userSkills.failEvaluate")}:{" "}
                    </Text>
                    <Text>
                      {result.results.evaluate ? result.results.evaluate : 0} /{" "}
                      {result.results.evaluate_porc
                        ? result.results.evaluate_porc
                        : 0}{" "}
                      %{" "}
                    </Text>{" "}
                    <Svg
                      width={10}
                      style={stylesPDF.iconMargin}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <Path
                        fill="#f59e0b"
                        d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
                      />
                    </Svg>
                  </View>
                </View>
              </View>
              <View style={stylesPDF.testInfoColumn}>
                <View style={stylesPDF.answerTestInfo}>
                  <Svg
                    width={8}
                    style={{ marginRight: 4 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <Path
                      fill="#fcd34d"
                      d="M336 64h-53.88C268.9 26.8 233.7 0 192 0S115.1 26.8 101.9 64H48C21.5 64 0 85.48 0 112v352C0 490.5 21.5 512 48 512h288c26.5 0 48-21.48 48-48v-352C384 85.48 362.5 64 336 64zM192 64c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S160 113.7 160 96C160 78.33 174.3 64 192 64zM272 224h-160C103.2 224 96 216.8 96 208C96 199.2 103.2 192 112 192h160C280.8 192 288 199.2 288 208S280.8 224 272 224z"
                    />
                  </Svg>
                  <Text style={[stylesPDF.titleWeight, { marginRight: 2 }]}>
                    {t("userSkills.pasteAnswer")}:{" "}
                  </Text>
                  <Text>
                    {result.results.paste_answer
                      ? result.results.paste_answer
                      : 0}{" "}
                    /{" "}
                    {result.results.paste_answer_porc
                      ? result.results.paste_answer_porc
                      : 0}{" "}
                    %{" "}
                  </Text>{" "}
                </View>
                <View style={stylesPDF.answerInfo}>
                  <Svg
                    width={9}
                    style={{ marginRight: 4 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <Path
                      fill="#d1d5db"
                      d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"
                    />
                  </Svg>
                  <Text style={stylesPDF.titleWeight}>
                    {t("userSkills.copyAnswer")}:{" "}
                  </Text>
                  <Text>
                    {result.results.copy_answer
                      ? result.results.copy_answer
                      : 0}{" "}
                    /{" "}
                    {result.results.copy_answer_porc
                      ? result.results.copy_answer_porc
                      : 0}{" "}
                    %{" "}
                  </Text>{" "}
                </View>
                <View style={stylesPDF.answerInfo}>
                  <Svg
                    width={9}
                    style={{ marginRight: 4 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <Path
                      fill="#f87171"
                      d="M16 288c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h112v-64zm489-183L407.1 7c-4.5-4.5-10.6-7-17-7H384v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H152c-13.3 0-24 10.7-24 24v264h128v-65.2c0-14.3 17.3-21.4 27.4-11.3L379 308c6.6 6.7 6.6 17.4 0 24l-95.7 96.4c-10.1 10.1-27.4 3-27.4-11.3V352H128v136c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H376c-13.2 0-24-10.8-24-24z"
                    />
                  </Svg>
                  <Text style={stylesPDF.titleWeight}>
                    {t("userSkills.copyQuestion")}:{" "}
                  </Text>
                  <Text>
                    {result.results.copy_question
                      ? result.results.copy_question
                      : 0}{" "}
                    /{" "}
                    {result.results.copy_question_porc
                      ? result.results.copy_question_porc
                      : 0}{" "}
                    %{" "}
                  </Text>{" "}
                </View>
              </View>
              <View style={stylesPDF.testInfoColumn}>
                {Object.values(result.results.likert_user).map((val, index) => (
                  <View key={index} style={stylesPDF.flexWrap}>
                    <Text style={[stylesPDF.titleWeight, { marginRight: 2 }]}>
                      {val.scale === "S"
                        ? t("userSkills.outstanding")
                        : val.scale === "E"
                        ? t("userSkills.excelent")
                        : val.scale === "R"
                        ? t("userSkills.regular")
                        : val.scale === "D"
                        ? t("userSkills.deficient")
                        : val.scale === "M"
                        ? t("userSkills.badd")
                        : t("userSkills.failEvaluate")}
                      :{" "}
                    </Text>
                    <Text style={{ marginBottom: 4 }}>
                      {val.count_scale ? val.count_scale : 0} /{" "}
                      {val.count_scale
                        ? (
                            (val.count_scale * 100) /
                            result.results.count_question
                          ).toFixed(2)
                        : 0}{" "}
                      %
                    </Text>
                  </View>
                ))}
                <View style={stylesPDF.testResult}>
                  {result.results.points <= 100 &&
                  result.results.points >= 81 ? (
                    <Text
                      style={[
                        stylesPDF.testResultText,
                        stylesPDF.testOutstanding,
                      ]}
                    >
                      {t("userSkills.sobresal")}
                    </Text>
                  ) : result.results.points <= 80 &&
                    result.results.points >= 61 ? (
                    <Text
                      style={[
                        stylesPDF.testResultText,
                        stylesPDF.testExcellent,
                      ]}
                    >
                      {t("userSkills.excellent")}
                    </Text>
                  ) : result.results.points <= 60 &&
                    result.results.points >= 41 ? (
                    <Text
                      style={[stylesPDF.testResultText, stylesPDF.testRegular]}
                    >
                      {t("userSkills.regular")}
                    </Text>
                  ) : result.results.points <= 40 &&
                    result.results.points >= 21 ? (
                    <Text
                      style={[stylesPDF.testResultText, stylesPDF.testRegular]}
                    >
                      {t("userSkills.deficent")}
                    </Text>
                  ) : (
                    <Text style={[stylesPDF.testResultText, stylesPDF.testBad]}>
                      {t("userSkills.bad")}
                    </Text>
                  )}
                </View>
                <View style={[stylesPDF.pointsRow, { marginTop: 4 }]}>
                  <Text style={stylesPDF.titleWeight}>
                    {t("userSkills.points")}:{" "}
                  </Text>
                  <Text>
                    {result.results.points ? result.results.points : 0} pts
                  </Text>
                </View>
                <View style={stylesPDF.pointsRow}>
                  <Text style={stylesPDF.titleWeight}>
                    {t("userSkills.average")}:{" "}
                  </Text>
                  <Text>
                    {result.results.points
                      ? (
                          result.results.points / result.results.count_question
                        ).toFixed(2)
                      : 0}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={[stylesPDF.flexWrap, { marginHorizontal: 8 }]}>
            {Object.values(result.results.user_test).map((item, index) => (
              <View key={index}>
                <View
                  break
                  style={[
                    stylesPDF.flexSpaceBetween,
                    stylesPDF.questionContainer,
                  ]}
                >
                  <View
                    style={[stylesPDF.flexWrap, { alignItems: "flex-start" }]}
                  >
                    <View style={stylesPDF.questionInfo}>
                      <Text style={stylesPDF.titleWeight}>
                        {item.questions}
                      </Text>
                      {/* {item.image && (
                        <Image
                          source={{
                            uri:
                              `${process.env.REACT_APP_BASE_URL}` +
                              item.image,
                            method: "GET",
                            headers: {
                              "Access-Control-Allow-Origin": "*",
                              crossOrigin: "anonymous",
                              Pragma: "no-cache",
                              "Cache-Control": "no-cache",
                            },
                          }}
                          cache={false}
                          allowDangerousPaths={true}
                        />
                      )} */}
                    </View>
                    <View style={stylesPDF.answerContainer}>
                      {item.question_type === "select" ? (
                        Object.entries(item.answers).map(
                          ([key, item2], index) => (
                            <View
                              key={index}
                              style={[
                                stylesPDF.answerTypeSelect,
                                {
                                  color:
                                    item2.id === item.answer
                                      ? item2.result
                                        ? "#10b981"
                                        : "#ce4c4c"
                                      : "#000",
                                },
                              ]}
                            >
                              <Text>
                                {parseInt(key) + 1}.- {item2.answer}
                              </Text>
                            </View>
                          )
                        )
                      ) : (
                        <Text>{item.annotation}</Text>
                      )}
                    </View>
                  </View>

                  <View style={stylesPDF.questionColumn}>
                    <View style={stylesPDF.questionIconRow}>
                      <Svg
                        width={8}
                        style={{ marginRight: 4 }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <Path
                          fill="#6b7280"
                          d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"
                        />
                      </Svg>
                      <Text>{item.time}</Text>
                    </View>
                  </View>

                  <View
                    style={[
                      stylesPDF.questionIconRow,
                      { width: "8.33%", alignItems: "flex-start" },
                    ]}
                  >
                    {item.copy_answer === 1 && (
                      <Svg
                        width={9}
                        style={stylesPDF.questionIcon}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <Path
                          fill="#d1d5db"
                          d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"
                        />
                      </Svg>
                    )}
                    {item.copy_question === 1 && (
                      <Svg
                        width={9}
                        style={stylesPDF.questionIcon}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <Path
                          fill="#f87171"
                          d="M16 288c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h112v-64zm489-183L407.1 7c-4.5-4.5-10.6-7-17-7H384v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H152c-13.3 0-24 10.7-24 24v264h128v-65.2c0-14.3 17.3-21.4 27.4-11.3L379 308c6.6 6.7 6.6 17.4 0 24l-95.7 96.4c-10.1 10.1-27.4 3-27.4-11.3V352H128v136c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H376c-13.2 0-24-10.8-24-24z"
                        />
                      </Svg>
                    )}
                    {item.paste_answer === 1 && (
                      <Svg
                        width={8}
                        style={stylesPDF.questionIcon}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <Path
                          fill="#fcd34d"
                          d="M336 64h-53.88C268.9 26.8 233.7 0 192 0S115.1 26.8 101.9 64H48C21.5 64 0 85.48 0 112v352C0 490.5 21.5 512 48 512h288c26.5 0 48-21.48 48-48v-352C384 85.48 362.5 64 336 64zM192 64c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S160 113.7 160 96C160 78.33 174.3 64 192 64zM272 224h-160C103.2 224 96 216.8 96 208C96 199.2 103.2 192 112 192h160C280.8 192 288 199.2 288 208S280.8 224 272 224z"
                        />
                      </Svg>
                    )}
                  </View>

                  <View style={stylesPDF.questionColumn}>
                    <View style={stylesPDF.questionIconRow}>
                      <Svg
                        width={8}
                        style={{ marginRight: 4 }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <Path
                          fill="#6b7280"
                          d="M298.028 214.267L285.793 96H328c13.255 0 24-10.745 24-24V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v48c0 13.255 10.745 24 24 24h42.207L85.972 214.267C37.465 236.82 0 277.261 0 328c0 13.255 10.745 24 24 24h136v104.007c0 1.242.289 2.467.845 3.578l24 48c2.941 5.882 11.364 5.893 14.311 0l24-48a8.008 8.008 0 0 0 .845-3.578V352h136c13.255 0 24-10.745 24-24-.001-51.183-37.983-91.42-85.973-113.733z"
                        />
                      </Svg>
                      <Text style={stylesPDF.titleWeight}>
                        {item.likert === "S"
                          ? t("userSkills.outstanding")
                          : item.likert === "E"
                          ? t("userSkills.excelent")
                          : item.likert === "R"
                          ? t("userSkills.regular")
                          : item.likert === "D"
                          ? t("userSkills.deficient")
                          : item.likert === "M"
                          ? t("userSkills.badd")
                          : t("userSkills.failEvaluate")}
                      </Text>
                    </View>
                  </View>

                  <View style={stylesPDF.pointsText}>
                    {item.likert === "S" ? (
                      <Text
                        style={[
                          stylesPDF.testResultText,
                          stylesPDF.testOutstanding,
                        ]}
                      >
                        {item.point_likert} pts
                      </Text>
                    ) : item.likert === "E" ? (
                      <Text
                        style={[
                          stylesPDF.testResultText,
                          stylesPDF.testExcellent,
                        ]}
                      >
                        {item.point_likert} pts
                      </Text>
                    ) : item.likert === "R" ? (
                      <Text
                        style={[
                          stylesPDF.testResultText,
                          stylesPDF.testRegular,
                        ]}
                      >
                        {item.point_likert} pts
                      </Text>
                    ) : (
                      <Text
                        style={[stylesPDF.testResultText, stylesPDF.testBad]}
                      >
                        {item.point_likert} pts
                      </Text>
                    )}
                  </View>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View fixed style={stylesPDF.pageNumberView}>
          <Text
            style={stylesPDF.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  );
}

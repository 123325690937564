import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import {
   hiredAvalaible,
} from "../../../redux/actions/userActions";
export default function UserDetail(props) {
   const { t } = useTranslation('common');
   const dispatch = useDispatch();
   const { records } = props;
   const { aditional } = props;
   const [data, setData] = useState({
      uuid: records.uuid,
   });

   useEffect(() => {
     dispatch(hiredAvalaible(data));
   }, [data,dispatch]);

   const handleChange = (text) => {
      setData({
         ...data,
         [text.target.name]: (text.target.checked===true)?'Y':'N',
      });
	};

   const styles = {
      container: "w-full flex mb-1",
      title: "w-3/12 bg-blue-50 p-2 font-semibold text-gray-600 border-t border-b border-blue-100",
      content: "w-9/12 p-2 border-b border-t",
      links: "text-blue-400 hover:text-blue-800 break-words"
   }

   return (
      <div className="flex flex-col w-10/12 mx-auto">
         <div className="flex justify-end">
               <Checkbox 
                  name="avalaible_strut" 
                  onChange={handleChange} 
                  size="small"
                  color="primary" 
                  defaultChecked={(aditional[0].avalaible_strut==='Y') ? true : false}
                  /> 
               <label className="mt-3 mr-5 font-semibold text-sm">{t('list.avalaible')}</label>

               <Checkbox 
                  name="hired_strut" 
                  onChange={handleChange} 
                  size="small" 
                  color="primary"
                  defaultChecked={(aditional[0].hired_strut==='Y') ? true : false}
               /> 
               <label className="mt-3 font-semibold text-sm">{t('list.hired')}</label>
         </div>
         <div className={styles.container}>            
            <div className={styles.title}>{t('personal.personal_phone')}:</div>
            <div className={styles.content}>
               <a className={styles.links} href={`tel:${records.personal_phone}`}>
                  {records.personal_phone}
               </a>
            </div>
         </div>
         <div className={styles.container}>
            <div className={styles.title}>{t('personal.home_phone')}:</div>
            <div className={styles.content}>
               <a className={styles.links} href={`tel:${records.home_phone}`}>
                  {records.home_phone}
               </a>
            </div>
         </div>
         <div className={styles.container}>
            <div className={styles.title}>{t('personal.dob')}:</div>
            <div className={styles.content}>{records.date_of_birth}</div>
         </div>

         <div className={styles.container}>
            <div className={styles.title}>{t('personal.address')}:</div>
            <div className={styles.content}>{records.address}</div>
         </div>
         <div className={styles.container}>
            <div className={styles.title}>{t('personal.website')}:</div>
            <div className={styles.content}>
               <a
                  href={records.website}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.links}
               >
                  <i className="fa fa-link"></i> {t('portfolio.goto')}
               </a>
            </div>
         </div>
         <div className={styles.container}>            
            <div className={styles.title}>{t('portfolio.aboutme')}:</div>
            <div className={styles.content}>{records.about_me}</div>
         </div>
      </div>
   );
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { saveWorkExperience } from "../../redux/actions/workExpActions";
import { handleErrorMessage } from "../../utils/functions";
import Button from "../common/Button";

export default function FormAdd(props) {
  const dispatch = useDispatch();
  const wexp = useSelector((state) => state.workExpReducers);
  let { status, closeFormAdd } = props;
  const { t } = useTranslation("common");
  const [enableDate, setEnableDate] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      title: "",
      company: "",
      location: "",
      website: "",
      from: "",
      time_experience: "A",
      to: "",
      description: "",
    },
  });

  const onSubmitData = (data) => {
    dispatch(saveWorkExperience(data));
    closeFormAdd();
  };

  useEffect(() => {
    reset({
      title: "",
      company: "",
      location: "",
      website: "",
      from: "",
      time_experience: "A",
      to: "",
      description: "",
    });
  }, [reset, closeFormAdd]);

  useEffect(() => {
    setEnableDate(false);

    register("time_experience", {
      required: true,
    });
  }, [register]);

  return (
    <>
      {status && (
        <form
          action=""
          className="w-full animate__animated animate__fadeIn animate__faster"
          onSubmit={handleSubmit(onSubmitData)}
        >
          <div className="w-full md:flex md:mb-5">
            <div className="py-2 md:py-0 md:w-1/2 mx-2">
              <Controller
                name="title"
                id="title"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("experience.error.title_required"),
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#]{3,}$/,
                    message: t("experience.error.title_invalid"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="title"
                    name="title"
                    label={t("experience.titleform")}
                    variant="outlined"
                    className="w-full"
                    error={errors.title ? true : false}
                    helperText={t(handleErrorMessage(wexp, "title"))}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <p className="mt-1 text-sm text-red-500">{message}</p>
                )}
              />
            </div>
            <div className="py-2 md:py-0 md:w-1/2 mx-2">
              <Controller
                name="company"
                id="company"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("experience.error.company_required"),
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#]{3,}$/,
                    message: t("experience.error.company_invalid"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="company"
                    name="company"
                    label={t("experience.company")}
                    variant="outlined"
                    className="w-full"
                    error={errors.company ? true : false}
                    helperText={t(handleErrorMessage(wexp, "company"))}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="company"
                render={({ message }) => (
                  <p className="mt-1 text-sm text-red-500">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="w-full md:flex mb-5">
            <div className="py-2 md:py-0 md:w-1/2 mx-2">
              <Controller
                name="location"
                id="location"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("experience.error.location_required"),
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#-]{3,}$/,
                    message: t("experience.error.location_invalid"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="location"
                    name="location"
                    label={t("experience.location")}
                    variant="outlined"
                    className="w-full"
                    error={errors.location ? true : false}
                    helperText={t(handleErrorMessage(wexp, "location"))}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="location"
                render={({ message }) => (
                  <p className="mt-1 text-sm text-red-500">{message}</p>
                )}
              />
            </div>
            <div className="py-2 md:py-0 md:w-1/2 mx-2">
              <Controller
                name="website"
                id="website"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("experience.error.website_url"),
                  },
                  pattern: {
                    value: /^https?:\/\/[\w]+(\.[\w]+)+[/#?]?.*$/,
                    message: t("experience.error.website_invalid"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="website"
                    name="website"
                    label={t("experience.website")}
                    variant="outlined"
                    className="w-full"
                    error={errors.website ? true : false}
                    helperText={t(handleErrorMessage(wexp, "website"))}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="website"
                render={({ message }) => (
                  <p className="mt-1 text-sm text-red-500">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="w-full md:flex mb-5">
            <div className="w-full md:flex">
              <div className="py-2 md:py-0 md:w-1/2 mx-2">
                <Controller
                  name="from"
                  id="from"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t("experience.error.from_date"),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      type="date"
                      id="from"
                      name="from"
                      label={t("experience.from")}
                      variant="outlined"
                      className="w-full"
                      error={errors.from ? true : false}
                      helperText={t(handleErrorMessage(wexp, "from"))}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: new Date().toISOString().slice(0, 10),
                      }}
                      {...field}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="from"
                  render={({ message }) => (
                    <p className="mt-1 text-sm text-red-500">{message}</p>
                  )}
                />
              </div>
              <div className="py-2 md:py-0 md:w-1/2 mx-2 w-full">
                <InputLabel htmlFor="time_experience">
                  {t("experience.to")}
                </InputLabel>
                <Select
                  id="time_experience"
                  name="time_experience"
                  className="w-full md:my-1"
                  native
                  onChange={(event) => {
                    event.target.value === "P"
                      ? setEnableDate(true)
                      : setEnableDate(false);
                    setValue("time_experience", event.target.value);
                  }}
                  inputProps={{
                    name: "time_experience",
                    id: "time_experience",
                  }}
                >
                  <option value="A">{t("experience.actually")}</option>
                  <option value="P">{t("experience.dateNormal")}</option>
                </Select>
              </div>
              {enableDate && (
                <div className="py-2 md:py-0 md:w-1/2 mx-2">
                  <Controller
                    name="to"
                    id="to"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t("experience.error.to_date"),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        type="date"
                        id="to"
                        name="to"
                        label={t("experience.to")}
                        variant="outlined"
                        className="w-full"
                        error={errors.to ? true : false}
                        helperText={t(handleErrorMessage(wexp, "to"))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: new Date().toISOString().slice(0, 10),
                        }}
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="to"
                    render={({ message }) => (
                      <p className="mt-1 text-sm text-red-500">{message}</p>
                    )}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="md:flex items-center justify-between">
            <div className="md:w-10/12 flex mb-5 mx-2">
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="description"
                    name="description"
                    label={t("experience.description")}
                    multiline
                    minRows={4}
                    variant="outlined"
                    className="w-full"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="md:w-2/12 flex justify-center">
              <Button
                status={wexp.button}
                text={t("experience.addbtn")}
                icon="fa fa-check"
              />
            </div>
          </div>
        </form>
      )}
    </>
  );
}

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../common/Loading";

export default function RouteLogin(props) {
  const main = useSelector((state) => state.mainReducers);

  if (main.mode === "allowed" && main.modules.length) {
    if (props.path === "/dashboard/admin") return <Route {...props} />;

    const visible_user = main.modules.find(({ route, visible_user }) =>
      props.path.includes(route) && visible_user ? true : false
    );

    if (visible_user) return <Route {...props} />;
    return <Redirect to="/dashboard/admin" />;
  } else if (main.mode === "denied") return <Redirect to="/" />;
  else return <Loading />;
}

import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import SkillsStore from "./SkillStore";
import SkillsMain from "./SkillsMain";
import SkillsEdit from "./SkillsEdit";
import SkillsDelete from "./SkillsDelete";
import AdminRoutes from "../../routes/AdminRoutes";

export default function Tests() {
  let { path } = useRouteMatch();

  return (
    <div className="w-11/12 mx-auto">
      <Switch>
        <AdminRoutes exact path={`${path}`} component={SkillsMain} />
        <AdminRoutes path={`${path}/add-skill`} component={SkillsStore} />
        <AdminRoutes path={`${path}/edit/:uuid`} component={SkillsEdit} />
        <AdminRoutes path={`${path}/delete/:uuid`} component={SkillsDelete} />
      </Switch>
    </div>
  );
}

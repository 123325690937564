import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { deleteUser } from "../../redux/actions/admin/usersActions";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";

import { ErrorMessage } from "@hookform/error-message";

export default function ModalDeleteUser(props) {
  const { uuid } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("common");

  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const handleSubmitModal = ({ reason }) => {
    //en base al uuid se envía a eliminar ese usuario
    dispatch(deleteUser(uuid, reason));
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: "USERS_RELOAD",
      })
    }
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-start text-sm">
        <button
          onClick={handleClickOpen}
          className="my-1 h-10 border-2 border-red-500  hover:bg-red-500 text-red-500 hover:text-white font-bold mx-1 py-2 px-2 rounded-lg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </button>
      </div>
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle
          className="border-b text-center"
          style={{ backgroundColor: "#CE4C4C" }}
        >
          <div className="flex justify-end w-full mt-0">
            <svg
              onClick={handleClose}
              xmlns="http://www.w3.org/2000/svg"
              className="cursor-pointer h-5 w-5 border-2 rounded-md text-gray-200 hover:bg-gray-200 hover:text-gray-800"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <span className="font-bold font-poppins text-white">
            {t("users.delete_title")}
          </span>
        </DialogTitle>
        <DialogContent>
          <div className="mt-5 flex justify-end"></div>
          <form
            onSubmit={handleSubmit(handleSubmitModal)}
            className="w-full mx-auto"
          >
            <div className="w-full mx-2 my-5">
              <Controller
                name="reason"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("users.error.reason_elimination"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    id="reason"
                    name="reason"
                    label={t("users.reason_elimination")}
                    multiline
                    minRows={4}
                    variant="outlined"
                    className="w-full"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="reason"
                render={({ message }) => (
                  <p className="mt-1 text-sm text-red-500 font-poppins">{message}</p>
                )}
              />
            </div>
            <div className="flex justify-center w-full mt-7">
              <button
                type="submit"
                className="fa fa-check bg-gray-800 hover:bg-green-600 text-white py-2 px-4 my-4 rounded inline-flex items-center"
              >
                <span className="mx-2 font-poppins font-normal text-sm">
                  {t("users.delete_user")}
                </span>
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

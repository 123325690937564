import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../admin/footer";

export default function QuestionsFreq() {
    const { t } = useTranslation('common');
    return (
        <>
  <main>
    <div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 lg:flex-row">
        <div className="mb-5 w-full lg:mb-0 lg:mr-10">
            <h2 className="relative mb-4 mt-2 text-left font-poppins lg:text-6xl sm:text-5xl md:text-6xl  font-bold tracking-tight text-gray-900 leading-normal">
              <span className="relative inline-block">
                <svg viewBox="0 0 52 24" fill="currentColor" className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                  <defs>
                    <pattern id="6bfa0e57-faa2-4bb2-ac0e-310782e5eb2d" x="0" y="0" width=".135" height=".30">
                      <circle cx="1" cy="1" r=".7"></circle>
                    </pattern>
                  </defs>
                  <rect fill="url(#6bfa0e57-faa2-4bb2-ac0e-310782e5eb2d)" width="52" height="24"></rect>
                </svg>
                <span className="relative">{t('termsconditions.title')} 
                </span>
              </span>
               
            </h2>
        </div> 

        <p className="text-justify text-sm mb-10">
          {t('termsconditions.information')} 
        </p> 

        <h1 id="item1" className="sm:text-xl lg:text-normal font-bold title-font mb-2 text-gray-900">
        {t('termsconditions.website_title')} 
        </h1>
        <p className="text-justify text-sm mb-4">
        { t('termsconditions.information')}
        </p>
        <h1 id="item1" className="sm:text-xl lg:text-normal font-bold title-font mb-2 text-gray-900">
          {t('termsconditions.material_title')} 
        </h1>
        <p className="text-justify text-sm mb-4">
          {t('termsconditions.information')}
        </p>
        <h1 id="item1" className="sm:text-xl lg:text-normal font-bold title-font mb-2 text-gray-900">
          {t('termsconditions.autorized_title')} 
        </h1>
        <p className="text-justify text-sm mb-4">
          {t('termsconditions.information')}
        </p>
        <h1 id="item1" className="sm:text-xl lg:text-normal font-bold title-font mb-2 text-gray-900">
          {t('termsconditions.prohibited_title')} 
        </h1>
        <p className="text-justify text-sm mb-4">
          {t('termsconditions.information')}
        </p>
        <div className="pb-4 mb-4 border-b">
          <h6 className="mb-2 font-semibold leading-5">
            {t('termsconditions.finalities_title')} 
          </h6>
          <ol className="list-decimal text-md text-gray-900">
            <li>Design, implement, and manage enterprise desktop architecture and processes </li>
            <li>Plan, implement, and manage core image processes </li>
            <li>Plan, implement, and manage remote software distribution processes</li>
            <li>Plan, implement, and manage anti-virus policy and processes </li>
            <li>Forecast, rationalize, plan, and coordinate patches and upgrades </li>
            <li>Perform lifecycle management for core desktop services and applications </li>
            <li>Develop and maintain support documentation </li>
            <li>Participate in developing, implementing, and managing a desktop/client virtualization strategy and processes </li>
            <li>Interface with Service Desk to resolve issues and coordinate Problem Management processes </li>
          </ol>
        </div> 
        <h1 id="item1" className="sm:text-xl lg:text-normal font-bold title-font mb-2 text-gray-900">
          {t('termsconditions.resposabilitie_title')}
        </h1>
        <p className="text-justify text-sm mb-4">
          {t('termsconditions.information')}
        </p>
        <h1 id="item1" className="sm:text-xl lg:text-normal font-bold title-font mb-2 text-gray-900">
          {t('termsconditions.register_title')}
        </h1>
        <p className="text-justify text-sm mb-4">
          {t('termsconditions.information')}
        </p>
        <h1 id="item1" className="sm:text-xl lg:text-normal font-bold title-font mb-2 text-gray-900">
        {t('termsconditions.database_title')}
        </h1>
        <p className="text-justify text-sm mb-4">
          {t('termsconditions.information')} 
        </p>
        <h1 id="item1" className="sm:text-xl lg:text-normal font-bold title-font mb-2 text-gray-900">
        {t('termsconditions.terms_title')} 
        </h1>
        <p className="text-justify text-sm mb-4">
          {t('termsconditions.information')}
        </p>

        <polygon strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23">
        </polygon>
      </div> 
    </div>     
  </main>

         <Footer />
        </>
    )
}

export const showLoading = () => async (dispatch) => {
  dispatch({
    type: "SHOW_LOADING",
    payload: true,
  });
};

export const hiddenLoading = () => async (dispatch) => {
  dispatch({
    type: "HIDDEN_LOADING",
    payload: false,
  });
};

import { useTranslation } from "react-i18next";
import { verifyLogin } from "../../../utils/functions";
import { Link } from "react-router-dom";

export default function DetailSelected({
  jobSelected,
  handleApply,
  statusJobs,
  jobs,
  apply = [],
  setStatusJobs,
}) {
  const { t } = useTranslation("common");
  const isLogged = verifyLogin();
  return (
    <>
      <div className="shadow-lg pt-4 ml-2 mr-2 rounded-lg">
        <div className="block bg-white py-3 px-10 border rounded-t-md pb-4">
          <div className="px-4 py-2 flex  justify-between">
            <h2 className="font-poppins relative font-bold text-2xl py-2">
              {jobSelected.name_job}
            </h2>
          </div>
          <p className="font-poppins px-4 text-normal text-lg font-normal text-gray-700">
            {t("vacancies." + jobSelected.location)} {jobSelected.ubication}
          </p>
          {isLogged ? (            
            <button
              disabled={statusJobs.disable }
              onClick={() => handleApply(jobSelected.uuid)}
              className={`className="font-poppins border-2 border-red-500 bg-red-500 hover:bg-red-600 text-white hover:text-white  inline-flex items-center text-xs lg:text-base font-normal py-2 px-4 mt-8 mb-2 rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 ${
                !statusJobs.disable
                  ? "bg-red-500 hover:bg-red-600 text-white hover:text-white"
                  : "opacity-50 cursor-default"
              }`}
            >
              <span className="font-poppins">{t("vacancies.applyJob")}</span>
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>
          ) : (
            <Link
              to={`/register/vacancies/${jobSelected.uuid}`}
              className="font-poppins border-2 border-red-500 bg-red-500 hover:bg-red-600 text-white hover:text-white inline-flex items-center text-xs lg:text-base font-normal py-2 px-4 mt-8 mb-2 rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            >
              <span className="font-poppins">
                {t("vacancies.continueApply")}
              </span>
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </Link>
          )}
          <button
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/info/vacancies/detail/${jobSelected.uuid}`
              );
              setStatusJobs({
                ...statusJobs,
                copy: true,
              });
            }}
            className="inline-flex items-center justify-center w-8 h-8 mx-7 border-2 border-blue-500 rounded-full text-blue-500 focus:shadow-outline hover:bg-blue-100 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
          >
            <i className="fas fa-share-alt"></i>            
          </button>
          {statusJobs.copy && (
            <label className="w-full font-poppins bg-blue-100 rounded-full ml-2 px-2 py-1 border-blue-500 text-blue-800 font-semibold italic">
              {t("vacancies.linkCopy")}
            </label>
          )}

          {isLogged && statusJobs.applyJob && (
            <div>
              <label className="w-full font-poppins bg-blue-100 rounded-full border-blue-500 text-blue-800 font-semibold italic">
                {apply.length > 0 &&
                  apply[0].apply_job === "new_apply" &&
                  apply[0].uuid === jobSelected.uuid &&
                  t("vacancies.applyYour")}
                {apply.length > 0 &&
                  apply[0].apply_job === "old_apply" &&
                  apply[0].uuid === jobSelected.uuid &&
                  t("vacancies.applyOld")}
              </label>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

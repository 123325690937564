import React, { useState } from "react";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function CustomMenu() {
  const [menuIsOpen, setMenuIsOpen] = useState(null);
  const main = useSelector((state) => state.mainReducers);
  const contact = useSelector((state) => state.contactReducers);
  const { t } = useTranslation("common");

  // const handleClick = (event) => {
  //   dispatch(getUnread());
  //   setMenuIsOpen(event.currentTarget);
  // };

  const handleClose = () => {
    setMenuIsOpen(null);
  };
  if (main.mode === "denied") return null;
  else if (main.mode === "allowed") {
    return (
      <>
        {/* <button
               color="inherit"
               className="font-poppins mx-6 lg:visible invisible pb-8  pt-2 text-sm font-normal lg:mt-0 text-white hover:text-red-500"
               aria-controls="admin-menu"
               aria-haspopup="true"
               onClick={handleClick}
            >
               <i className="fa fa-lock mr-1"></i> {t('menu_profile.adminPanel')}
            </button> */}
        <Link
          className="font-poppins mx-6 lg:visible invisible text-sm font-normal lg:mt-0 text-white hover:text-red-500"
          aria-controls="admin-menu"
          aria-haspopup="true"
          to="/dashboard/admin"
        >
          <i className="fas fa-tachometer-alt hover:text-red-500 mr-1"></i>{" "}
          {t("menu_profile.dashboard")}
        </Link>
        <Menu
          id="admin-menu"
          anchorEl={menuIsOpen}
          keepMounted
          open={Boolean(menuIsOpen)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <Link className="font-poppins" to="/admin/users/">
              <i className="fa fa-users"></i> {t("menu_profile.users")}
            </Link>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <Link className="font-poppins" to="/tests/admin">
              <i className="fa fa-file-alt"></i> {t("menu_profile.tests")}
            </Link>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <Link className="font-poppins" to="/skills/admin">
              <i className="fa fa-brain"></i> {t("menu_profile.skills")}
            </Link>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <Link className="font-poppins" to="/contact/admin">
              <i className="fa fa-comments"></i>{" "}
              {t("contactus.FormPlaceholderMessage")}
              <span className="rounded-full text-white bg-black px-2 py-1 text-sm font-semibold w-8 ml-20 pop-message">
                +{contact.countMessages ? contact.countMessages : 0}
              </span>
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link className="font-poppins" to="/vacancies/admin">
              <i className="fa fa-briefcase"></i> {t("menu.vacancies")}
            </Link>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <Link className="font-poppins" to="/clients/admin">
              <i className="fas fa-user-friends"></i> {t("menu.clients")}
            </Link>
          </MenuItem>
        </Menu>
      </>
    );
  } else {
    return null;
  }
}

import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../admin/footer";

export default function QuestionsFreq() {
    const { t } = useTranslation('common');
    return (
        <>
<div>
    <div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 lg:flex-row">
        <div className="text-gray-600 body-font">
          <div className="container flex md:flex-row items-start flex-col-reverse">

            <div className="md:w-3/4 md:pr-12 md:p-8 md:border-r md:border-b-0 mb-10 md:mb-0 pb-10 sm:mt-14 lg:mt-2 border-b border-gray-200">
                <h2 className="relative mb-4 mt-2 text-left font-poppins lg:text-6xl text-3xl md:text-6xl  font-bold tracking-tight text-gray-900 leading-normal">
                  <span className="relative inline-block">
                    <svg viewBox="0 0 52 24" fill="currentColor" className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                      <defs>
                        <pattern id="6bfa0e57-faa2-4bb2-ac0e-310782e5eb2d" x="0" y="0" width=".135" height=".30">
                          <circle cx="1" cy="1" r=".7"></circle>
                        </pattern>
                      </defs>
                      <rect fill="url(#6bfa0e57-faa2-4bb2-ac0e-310782e5eb2d)" width="52" height="24"></rect>
                    </svg>
                    <span className="relative"> 
                    {t('privacy.title')} 
                    </span>
                  </span>

                </h2>
                <p className="text-justify mb-4 text-sm">
                    {t('privacy.policy')}
                </p>
                <h1 id="item1" className="sm:text-xl lg:text-normal font-bold title-font mb-2 text-gray-900">
                    {t('privacy.treatments_title')}
                </h1>
                <p className="text-justify text-sm mb-4">
                    {t('privacy.treatments')}
                </p>
                <h1 id="item2" className="sm:text-xl lg:text-normal font-bold title-font mb-2 text-gray-900">
                    {t('privacy.general_purposes_title')}
                </h1>
                <p className="text-justify text-sm mb-4">
                    {t('privacy.general_purposes')}
                </p>
                <h1 id="item3" className="sm:text-xl lg:text-normal font-bold title-font mb-2 text-gray-900">
                    {t('privacy.specific_purposes_title')}
                </h1>
                <p className="text-justify text-sm mb-4">
                    {t('privacy.specific_purposes')}
                </p>
                <h1 id="item4" className="sm:text-xl lg:text-normal font-bold title-font mb-2 text-gray-900">
                   {t('privacy.data_title')}
                </h1>
                <p className="text-justify text-sm mb-4">
                    {t('privacy.data')}
                </p>    
                <h1 id="item5" className="sm:text-xl lg:text-normal font-bold title-font mb-2 text-gray-900">
                    {t('privacy.rights_title')}
                </h1>
                <p className="text-justify text-sm mb-4">
                    {t('privacy.rights')}
                </p>
                <h1 id="item6" className="sm:text-xl lg:text-normal font-bold title-font mb-2 text-gray-900">
                    {t('privacy.consultation_title')} 
                </h1>
                <p className="text-justify text-sm mb-4">
                    {t('privacy.consultation')} 
                </p>
                <h1 id="item7" className="sm:text-xl lg:text-normal font-bold title-font mb-2 text-gray-900">
                    {t('privacy.responsible_title')} 
                </h1>
                <p className="text-justify text-sm mb-4">
                    {t('privacy.responsible')} 
                </p>
                <h1 id="item8" className="sm:text-xl lg:text-normal font-bold title-font mb-2 text-gray-900">
                   {t('privacy.annexed_title')}
                </h1>
                <p className="text-justify text-sm mb-4">
                    {t('privacy.annexed')}
                </p>
            </div>

            <nav className="flex flex-col lg:w-1/4 md:px-6 overflow-y-scroll lg:h-screen h-64 overflow-hidden">
                <div className="items-center justify-between">
                  <h2 className="title-font font-semibold text-gray-800 tracking-wider lg:text-sm text-lg mb-3">CATEGORIES</h2>
                  <div className="space-y-2">
                    <div className="border-b">
                      <a href="#item1" type="button" aria-label="Open item" title="Open item" className="flex items-center justify-between w-full py-2 focus:outline-none">
                        <p className="text-gray-600 hover:text-gray-800"> {t('privacy.treatments_title')}</p>
                      </a>
                    </div>
                    <div className="border-b">
                      <a href="#item2" type="button" aria-label="Open item" title="Open item" className="flex items-center justify-between w-full py-2 focus:outline-none">
                        <p className="text-gray-600 hover:text-gray-800">{t('privacy.general_purposes_title')}</p>
                      </a>
                    </div>
                    <div className="border-b">
                      <a href="#item3" type="button" aria-label="Open item" title="Open item" className="flex items-center justify-between w-full py-2 focus:outline-none">
                        <p className="text-gray-600 hover:text-gray-800">{t('privacy.specific_purposes_title')}</p>
                      </a>
                    </div>
                    <div className="border-b">
                      <a href="#item4" type="button" aria-label="Open item" title="Open item" className="flex items-center justify-between w-full py-2 focus:outline-none">
                        <p className="text-gray-600 hover:text-gray-800">{t('privacy.data_title')}</p>
                      </a>
                    </div>
                    <div className="border-b">
                      <a href="#item5" type="button" aria-label="Open item" title="Open item" className="flex items-center justify-between w-full py-2 focus:outline-none">
                        <p className="text-gray-600 hover:text-gray-800">{t('privacy.rights_title')}</p>
                      </a>
                    </div>
                    <div className="border-b">
                      <a href="#item6" type="button" aria-label="Open item" title="Open item" className="flex items-center justify-between w-full py-2 focus:outline-none">
                        <p className="text-gray-600 hover:text-gray-800">{t('privacy.consultation_title')}</p>
                      </a>
                    </div>
                    <div className="border-b">
                      <a href="#item7" type="button" aria-label="Open item" title="Open item" className="flex items-center justify-between w-full py-2 focus:outline-none">
                        <p className="text-gray-600 hover:text-gray-800">{t('privacy.responsible_title')}</p>
                      </a>
                    </div>
                    <div className="border-b">
                      <a href="#item8" aria-label="Open item" title="Open item" className="flex items-center justify-between w-full py-2 focus:outline-none">
                        <p className="text-gray-600 hover:text-gray-800">{t('privacy.annexed_title')}</p>
                      </a>
                    </div>
                    <div className="border-b">
                      <a href="#item9" aria-label="Open item" title="Open item" className="flex items-center justify-between w-full py-2 focus:outline-none">
                        <p className="text-gray-600 hover:text-gray-800">{t('privacy.annexed_title')}</p>
                      </a>
                    </div>
                    <div className="border-b">
                      <a href="#item10" aria-label="Open item" title="Open item" className="flex items-center justify-between w-full py-2 focus:outline-none">
                        <p className="text-gray-600 hover:text-gray-800">{t('privacy.annexed_title')}</p>
                      </a>
                    </div>
                    <div className="border-b">
                      <a href="#item11" aria-label="Open item" title="Open item" className="flex items-center justify-between w-full py-2 focus:outline-none">
                        <p className="text-gray-600 hover:text-gray-800">{t('privacy.annexed_title')}</p>
                      </a>
                    </div>
                    <div className="border-b">
                      <a href="#item12" aria-label="Open item" title="Open item" className="flex items-center justify-between w-full py-2 focus:outline-none">
                        <p className="text-gray-600 hover:text-gray-800">{t('privacy.annexed_title')}</p>
                      </a>
                    </div>
                  </div>
                </div>
            </nav>

          <polygon strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23">
          </polygon>

          </div>     
        </div>  
      </div> 
    </div>    
 </div>

         <Footer />
        </>
    )
} 

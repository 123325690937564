import React, { useState, useMemo, useEffect, useCallback } from "react";

// library
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
// redux
import { useDispatch, useSelector } from "react-redux";
import { getJobs, getApplyJobs } from "../../redux/actions/jobActions";
import { getPersonalInfo } from "../../redux/actions/personalActions";
import { emailApplicant } from "../../redux/actions/emailActions";
// components
import { CardOne, Pagination, DefaultColumnFilter, NotResult } from "../common";
import JobDetail from "./JobDetail";
import Footer from "../admin/footer";
import { COLUMNS } from "../../data/jobs";

import DetailSelected from "../common/jobs/DetailSelected";

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export default function Jobs(props) {
  const { t, i18n } = useTranslation("common");
  let query = useQuery();
  let name_job = query.get("name_job");
  let ubication = query.get("ubication");
  const dispatch = useDispatch();
  const personal = useSelector((state) => state.personalReducers);
  const token = localStorage.getItem("access_token");

  const [statusJobs, setStatusJobs] = useState({
    applyJob: false,
    disable: false,
    copy: false,
  });
  const [jobSelected, setJobSelected] = useState({});
  // functions
  useEffect(() => {
    if (!personal.loaded && token) dispatch(getPersonalInfo());
  }, [personal.loaded, token, dispatch]);

  const handleApply = (uuid) => {
    dispatch(getApplyJobs(uuid, personal.records[0].id));
    dispatch(
      emailApplicant({
        lang: i18n.language,
        stage: "apply",
        name: personal.records[0].name,
        email: personal.records[0].email,
        job: jobSelected.name_job,
        greeting: t("notification.greeting"),
        vacant: t("notification.vacant"),
        description: t("notification.description"),
        noreply: t("notification.noreply"),
      })
    );
    setStatusJobs({
      applyJob: true,
      disable: true,
    });
  };

  const handlePage = (payload) => {
    setStatusJobs({
      copy: false,
      disable: false,
    });
    setJobSelected({ ...payload });
  };

  // dispacth
  const { data, loaded, apply } = useSelector((state) => state.jobReducers);

  const jobs = useMemo(() => data, [data]);
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const columns = useMemo(() => COLUMNS, []);

  const {
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    globalFilter,
    setPageSize,
    setGlobalFilter,
    setFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: jobs,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
    useGlobalFilter,
    useFilters,
    usePagination
  );

  const [value, setValue] = useState(globalFilter);
  const [locationValue, setLocationValue] = useState(globalFilter);

  const onChangeInputSearch = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const handleSearchGlobalByName = useCallback(
    (value) => {
      setValue(value || undefined);
      onChangeInputSearch(value || undefined);
    },
    [onChangeInputSearch]
  );

  const handleSearchGlobalByLocation = (value) => {
    setLocationValue(value || undefined);
    onChangeInputSearch(value || undefined);
  };

  // useEffects
  useEffect(() => {
    if (!loaded) dispatch(getJobs(i18n.language));
    if (jobs.length > 0) {
      handleSearchGlobalByName(name_job);
      setFilter("ubication", ubication || undefined);
    }
  }, [
    dispatch,
    handleSearchGlobalByName,
    setFilter,
    loaded,
    i18n.language,
    jobs.length,
    name_job,
    ubication
  ]);

  useEffect(() => {
    if (page.length > 0) {
      setJobSelected({ ...page[0].original });
    }
  }, [page]);

  useEffect(() => {
    dispatch({
      type: "JOB_SEARCH_RELOAD",
    });
  }, [dispatch, i18n.language]);

  return (
    <>
      <section>
        <div className="container px-5 pt-16 pb-8 mx-auto">
          <div className="flex flex-col text-center w-full mb-2"></div>
          <div className="flex lg:w-5/6 w-full sm:flex-row flex-col mx-auto px-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
            <div className="relative flex-grow w-full">
              <label
                htmlFor="full-name"
                className="leading-7 text-sm font-poppins text-gray-600"
              >
                {" "}
                {t("vacancies.whatJob")}{" "}
              </label>
              <div className="relative text-gray-500 focus-within:text-gray-400">
                <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <i className="fas fa-search"></i>
                </span>
                <input
                  type="text"
                  value={value || ""}
                  onChange={(e) => handleSearchGlobalByName(e.target.value)}
                  placeholder={t("vacancies.jobposition")}
                  className="font-poppins w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-red-500 focus:bg-transparent focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div className="relative flex-grow w-full">
              <label
                htmlFor="ubication"
                className="leading-7 text-sm font-poppins text-gray-600"
              >
                {" "}
                {t("vacancies.whereJob")}{" "}
              </label>
              <div className="relative text-gray-500 focus-within:text-gray-400">
                <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <i className="fas fa-street-view"></i>
                </span>
                <input
                  type="text"
                  value={locationValue || ""}
                  onChange={(e) => {
                    handleSearchGlobalByLocation(e.target.value || undefined);
                  }}
                  placeholder={t("vacancies.city")}
                  className="font-poppins w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-red-500 focus:bg-transparent focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div>
              <Link
                to={`/jobs/${value || ""}/${locationValue || ""}`}
                className="font-poppins bg-gradient-to-r from-gray-700 to-red-500 hover:from-red-500 hover:to-red-600 text-white font-semibold inline-flex items-center text-xs lg:text-base py-2 px-10 mt-8 rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
              >
                {t("vacancies.find")}
              </Link>
            </div>
          </div>
        </div>
        <hr />

        {!loaded && (
          <div className="my-24 justify-content-center">
            <div className="text-center my-5 p-4 overflow-x-auto">
              <i className="fa fa-spin fa-spinner fa-2x"></i>
            </div>
          </div>
        )}

        {page.length > 0 && loaded ? (
          <section className="text-gray-600 body-font ">
            <main className="flex justify-center bg-gray-50">
              <div className="flex flex-col w-2/3 lg:w-1/3">
                <div className="pt-8 pb-10 ">
                  <h6 className="mb-2 mx-4 font-semibold leading-5 text-xs md:text-sm font-poppins">
                    <Link
                      to="/register/vacancies/"
                      className="mb-2 font-semibold leading-5 text-xs md:text-sm text-blue-500"
                    >
                      {t("vacancies.publishCV")}
                    </Link>
                    {t("vacancies.applyto_job")}
                  </h6>
                  <hr className="my-8 text-gray-800" />
                  {page.map((item, key) => {
                    prepareRow(item);
                    return (
                      <CardOne
                        item={item.original}
                        handlePage={handlePage}
                        cardSelected={jobSelected}
                        key={item.original.uuid}
                      />
                    );
                  })}
                  <div className="bg-gray-50 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                    <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <div>
                        <Pagination
                          previousPage={previousPage}
                          canPreviousPage={canPreviousPage}
                          nextPage={nextPage}
                          canNextPage={canNextPage}
                          pageIndex={pageIndex}
                          pageOptions={pageOptions}
                          pageSize={pageSize}
                          setPageSize={setPageSize}
                          showSageSize={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:flex lg:flex-col lg:pt-10 lg:pb-20 lg:w-1/2 lg:overflow-hidden bg-gray-50 hidden">
                {jobSelected && page.length > 0 && (
                  <div className="flex flex-col pb-10">
                    <DetailSelected
                      jobSelected={jobSelected}
                      handleApply={handleApply}
                      statusJobs={statusJobs}
                      apply={apply}
                      jobs={jobs}
                      setStatusJobs={setStatusJobs}
                    />
                    <div
                      id="cardDetail"
                      className="shadow-lg ml-2 mr-2 overflow-y-auto h-screen rounded-b-lg"
                    >
                      <div className="block bg-white py-3 border-t pb-4">
                        <div className="pt-8 pb-4">
                          <div className="flex flex-col justify-center mx-10 lg:mx-20">
                            <JobDetail
                              jobsSpecific={jobSelected}
                              loaded={loaded}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </main>
          </section>
        ) : (
          loaded && (
            <div className="flex flex-col w-full mb-8 ">
              <NotResult
                title={"There are no results that match your search"}
                subtitle=" Please try adjusting your search keywords or filters."
              />
            </div>
          )
        )}
        <Footer />
      </section>
    </>
  );
}

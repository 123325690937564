import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateReferences } from "../../redux/actions/referencesActions";
import { useTranslation } from "react-i18next";
import { handleErrorMessage, handleErrorInput } from "../../utils/functions";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Button from "../common/Button";

import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

export default function FormEdit(props) {
  const dispatch = useDispatch();
  const ref = useSelector((state) => state.referencesReducers);
  let { uuid, status, closeModal } = props;
  const [hidden, setHidden] = useState(false);
  const [filterRecords, setFilterRecords] = useState(undefined);
  const effectBG = status ? "fadeIn" : "fadeOut";
  const effectModal = status ? "fadeInDown" : "fadeOutUp";
  const { t } = useTranslation("common");
  const [data, setData] = useState({});

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  useEffect(() => {
    reset({}); //blanquea los valores del formulario
  }, [isSubmitSuccessful, reset]);

  useEffect(() => {
    if (status) {
      var filter = ref.records.filter((item) => item.uuid === uuid);
      setFilterRecords(filter[0]);
    }
  }, [status, uuid, ref.records]);

  useEffect(() => {
    if (status) {
      setHidden(true);
    } else {
      setTimeout(() => {
        setHidden(false);
      }, 200);
    }
  }, [status]);

  useEffect(() => {
    if (ref.success) closeModal();
    else reset({});
  }, [reset, ref.success, closeModal]);

  const handleChange = (text) => {
    setData({
      ...data,
      [text.target.name]: text.target.value,
    });
  };

  const onSubmitData = (data) => {
    var type = document.getElementById("type").value;
    data["type"] = type;
    dispatch(updateReferences(data, uuid));
    setTimeout(() => {
      closeModal();
    }, 200);
  };
  
  return (
    <>
      {hidden && (
        <>
          <div
            className={`fixed bg-black w-full h-full top-0 left-0 z-20 animate__animated animate__${effectBG} animate__faster`}
            style={{ backgroundColor: "rgba(0,0,0,.5)" }}
            onClick={closeModal}
          ></div>
          <div className="flex justify-center">
            <div
              className={`rounded-lg fixed top-0 -left-28 ml-40 mt-48 w-auto items-center center-11-12 bg-white z-30 animate__animated animate__${effectModal} animate__faster`}
            >
              <div className="rounded-md">
                <div className="border-gray-700 bg-gray-700 text-white font-bold">
                  <div className="flex justify-between p-3">
                    <label>{t("references.edit")}</label>
                    <svg
                      onClick={closeModal}
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5  border-2 rounded-md hover:bg-white hover:text-gray-800"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                </div>
                <div className="mt-4 mb-6 px-10 pt-10 pb-2">
                  <form
                    action=""
                    className="w-full animate__animated animate__fadeIn animate__faster"
                    onSubmit={handleSubmit(onSubmitData)}
                  >
                    <div className="w-full md:flex md:mb-5">
                      <div className="py-2 md:py-0 md:w-1/2 mx-2">
                        <Controller
                          name="fullname"
                          id="fullname"
                          control={control}
                          defaultValue={filterRecords.fullname}
                          rules={{
                            required: {
                              value: true,
                              message: t("references.error.fullname_required"),
                            },
                            pattern: {
                              value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#]{3,}$/,
                              message: t("references.error.fullname_invalid"),
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              type="text"
                              id="fullname"
                              name="fullname"
                              label={t("references.fullname")}
                              variant="outlined"
                              onChange={handleChange}
                              className="w-full"
                              error={errors.fullname ? true : false}
                              helperText={t(
                                handleErrorMessage(ref, "fullname")
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: new Date().toISOString().slice(0, 10),
                              }}
                              {...field}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="fullname"
                          render={({ message }) => (
                            <p className="mt-1 text-sm text-red-500">
                              {message}
                            </p>
                          )}
                        />
                      </div>
                      <div className="py-2 md:py-0 md:w-1/2 mx-2">
                        <Controller
                          name="address"
                          id="address"
                          control={control}
                          defaultValue={filterRecords.address || ""}
                          rules={{
                            required: {
                              value: true,
                              message: t("references.error.address_required"),
                            },
                            pattern: {
                              value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#-/]{3,}$/,
                              message: t("references.error.address_invalid"),
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              type="text"
                              id="address"
                              name="address"
                              label={t("references.address")}
                              variant="outlined"
                              onChange={handleChange}
                              className="w-full"
                              error={errors.address ? true : false}
                              helperText={t(handleErrorMessage(ref, "address"))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: new Date().toISOString().slice(0, 10),
                              }}
                              {...field}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="address"
                          render={({ message }) => (
                            <p className="mt-1 text-sm text-red-500">
                              {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="w-full md:flex md:mb-5">
                      <div className="py-2 md:py-0 md:w-5/12 mx-2">
                        <Controller
                          name="phone"
                          id="phone"
                          control={control}
                          defaultValue={filterRecords.phone}
                          rules={{
                            required: {
                              value: true,
                              message: t("references.error.phone_required"),
                            },
                            pattern: {
                              //value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#]{3,}$/,
                              value: /^([0-9]{5})+((-{1})*)+([0-9]{6})$/,
                              message: t("references.error.phone_invalid"),
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              type="text"
                              id="phone"
                              name="phone"
                              label={t("references.phone")}
                              variant="outlined"
                              onChange={handleChange}
                              className="w-full"
                              error={errors.phone ? true : false}
                              helperText={t(handleErrorMessage(ref, "phone"))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: new Date().toISOString().slice(0, 10),
                              }}
                              {...field}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="phone"
                          render={({ message }) => (
                            <p className="mt-1 text-sm text-red-500">
                              {message}
                            </p>
                          )}
                        />
                      </div>
                      <div className="py-2 md:py-0 md:w-5/12 mx-2">
                        <Controller
                          name="relation"
                          id="relation"
                          control={control}
                          defaultValue={filterRecords.relation}
                          render={({ field }) => (
                            <TextField
                              type="text"
                              id="relation"
                              name="relation"
                              label={t("references.relation")}
                              variant="outlined"
                              onChange={handleChange}
                              className="w-full"
                              error={handleErrorInput(ref, "relation")}
                              helperText={t(
                                handleErrorMessage(ref, "relation")
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: new Date().toISOString().slice(0, 10),
                              }}
                              {...field}
                            />
                          )}
                        />
                      </div>
                      <div className="py-2 md:py-0 md:w-5/12 mx-2">
                        <FormControl variant="outlined" className="w-full">
                          <InputLabel htmlFor="type">
                            {t("references.type")}
                          </InputLabel>
                          <Select
                            native
                            value={data.type}
                            onChange={handleChange}
                            defaultValue={filterRecords.type}
                            label={t("references.type")}
                            inputProps={{
                              name: "type",
                              id: "type",
                            }}
                          >
                            <option value="reference">
                              {t("references.reference")}
                            </option>
                            <option value="emergency">
                              {t("references.emergency")}
                            </option>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="flex justify-center md:w-2/12">
                        <Button
                          status={ref.button}
                          text={t("modal.edit")}
                          icon="fa fa-check"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

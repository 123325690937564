import React  from 'react';
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import { NavLink } from "react-router-dom";
import AdminMenuDrawer from "../../admin/AdminMenuDrawer";
import { logoutAction } from "../../../redux/actions/loginActions";
import { useTranslation } from "react-i18next";
import Lang from './DrawerLang'
/* import Lang from "./Lang"; */

const useStyles = makeStyles({
   list: {
      width: 250,
   },
   fullList: {
      width: "auto",
   },
});


export default function MenuDrawer( props ) {
	const { items, drawer, logged, dispatch, isOpen } = props;
	const classes = useStyles();
	const { t } = useTranslation("common");

   return (
      <Drawer
         anchor="left"
         open={isOpen}
         onClose={drawer("left", false)}
      >
         <div
            className={clsx(classes.list)}
            role="presentation"
            onClick={drawer("left", false)}
            onKeyDown={drawer("left", false)}
         >
            <List>
               {items.map((item) => (
                  <ListItem button key={item.id}>
                     <ListItemIcon>
                        <i className={item.icon}></i>
                     </ListItemIcon>
                     <NavLink exact={item.exact} to={item.url}>
                        <ListItemText primary={t(item.name)} />
                     </NavLink>
                  </ListItem>
               ))}

               {logged ? (
                  <>
                     <ListItem button key={2021}>
                        <ListItemIcon>
                           <i className="fa fa-address-card"></i>
                        </ListItemIcon>
                        <NavLink to="/profile">
                           <ListItemText primary={t('menu.profile')} />
                        </NavLink>
                     </ListItem>
                     <AdminMenuDrawer />

                     <ListItem button key={2022}>
                        <ListItemIcon>
                           <i className="fa fa-sign-out-alt"></i>
                        </ListItemIcon>

                        <ListItemText
                           onClick={() => dispatch(logoutAction())}
                           primary={t('menu.logout')}
                        />
                     </ListItem>
                  </>
               ) : (
                  <ListItem button key={2023}>
                     <ListItemIcon>
                        <i className="fa fa-sign-in-alt"></i>
                     </ListItemIcon>
                     <NavLink to="/login">
                        <ListItemText primary={t('menu.login')} />
                     </NavLink>
                  </ListItem>
               )}
					 <Lang /> 
            </List>
         </div>
      </Drawer>
   );
}

import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import { handleErrorMessage, handleErrorInput } from "../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { sendComment } from "../../redux/actions/loginActions";
import Success from "../common/Success";
import Footer from "../admin/footer";

export default function ContactUs() {
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const rMain = useSelector((state) => state.mainReducers);
  const initData = useMemo(() => {
    return {
      fullname: "",
      email: "",
      phone: "",
      message: "",
    };
  }, []);
  const [data, setData] = useState(initData);
  const [mapUrl, setMapUrl] = useState("");

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(sendComment(data));
  };

  useEffect(() => {
    if (rMain.comment_sent) {
      setData(initData);
    }
  }, [rMain.comment_sent, initData]);

  useEffect(() => {
    return () => {
      dispatch({
        type: "SENT_COMMENT_OFF",
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (i18n.language === "es")
      setMapUrl(
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d830.8303288375346!2d-72.50242597853105!3d7.882788757646163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e6645740ec4af5b%3A0x495541b6778e4f83!2sBE%20BETTER%20DEVELOPERS%20S.A.S!5e0!3m2!1ses!2sve!4v1650043955979!5m2!1ses!2sve"
      );
    else
      setMapUrl(
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3041.71180530364!2d-111.72939438460688!3d40.32655317937575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x874d84ef12d24a8d%3A0x9d0a9ab7fdda7857!2sS%20500%20W%2C%20Lindon%2C%20UT%2084042%2C%20EE.%20UU.!5e0!3m2!1ses!2sco!4v1649797008877!5m2!1ses!2sco"
      );
  }, [i18n.language]);

  return (
    <>
      <main className="flex mx-2 lg:mx-28  mt-10 mb-4 xl:pb-48">
        <div className="px-4 pt-2 pb-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20 lg:pb-2">
          <div className="max-w-xl mb-4 md:mx-auto sm:text-center lg:max-w-2xl md:mb-6">
            <h2 className="max-w-lg font-poppins mb-4 text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="07690130-d013-42bc-83f4-90de7ac68f76"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7"></circle>
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#07690130-d013-42bc-83f4-90de7ac68f76)"
                    width="50"
                    height="24"
                  ></rect>
                </svg>
                <span className="relative font-poppins">
                  {" "}
                  {t("menu.contactus")}
                </span>
              </span>
            </h2>
          </div>
          <section className="text-gray-600 font-poppins mb-10 body-font relative">
            <div className="container px-5 py-6 mx-auto flex sm:flex-nowrap flex-wrap">
              <div className="filter ring-offset-gray-400 lg:w-2/3 md:w-1/2 w-80 bg-gray-300 opacity-75 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                <iframe
                  className="w-full absolute inset-0 font-poppins"
                  frameBorder="0"
                  title="map"
                  marginHeight="0"
                  marginWidth="0"
                  scrolling="no"
                  src={`${mapUrl}`}
                  width="600"
                  height="550"
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
                <div className="bg-white relative filter-none flex flex-wrap py-6 rounded shadow-md">
                  <div className="lg:w-1/2 px-6">
                    <h2 className="title-font font-poppins font-bold text-gray-900 tracking-widest text-sm uppercase">
                      {t("personal.address")}
                    </h2>
                    <p className="mt-1 font-poppins">
                      {t("contactus.direction_line1")}
                      {t("contactus.direction_line2")}
                    </p>
                    <p className="py-2 uppercase font-poppins text-gray-600 text-xs font-bold">
                      {t("contactus.company")}
                    </p>
                    <p className="py-2 uppercase font-poppins text-gray-400 text-xs">
                      {t("contactus.other_company")}
                    </p>
                  </div>
                  <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                    <h2 className="title-font font-poppins font-bold text-gray-900 tracking-widest text-sm uppercase">
                      {t("personal.email")}
                    </h2>
                    <label className="text-red-500 font-poppins cursor-pointer leading-relaxed">
                      {t("contactus.email")}
                    </label>
                    <h2 className="title-font font-bold font-poppins  text-gray-900 tracking-widest text-sm uppercase mt-6">
                      {t("contactus.FormPlaceholderPhone")}
                    </h2>
                    <p className="leading-relaxed font-poppins">
                      {t("contactus.phone")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
                <h2 className="text-gray-900 font-poppins text-lg font-semibold mb-4 lg:mb-8 title-font">
                  {t("contactus.yourMess")}
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className="relative mb-6">
                    <Success
                      status={rMain.message_status}
                      styleParent="mb-5"
                      message={rMain.message}
                      closer="MESSAGE_OFF"
                    />
                    <TextField
                      type="text"
                      name="fullname"
                      id="fullname"
                      placeholder={t("contactus.FormPlaceholderName")}
                      value={data.fullname}
                      error={handleErrorInput(rMain, "fullname")}
                      helperText={t(handleErrorMessage(rMain, "fullname"))}
                      onChange={handleChange}
                      className="w-full bg-white font-poppinsrounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                  <div className="relative mb-6">
                    <TextField
                      type="email"
                      name="email"
                      id="email"
                      placeholder={t("contactus.FormPlaceholderEmail")}
                      value={data.email}
                      error={handleErrorInput(rMain, "email")}
                      helperText={t(handleErrorMessage(rMain, "email"))}
                      onChange={handleChange}
                      className="w-full bg-white font-poppins rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                  <div className="relative mb-6 font-poppins">
                    <TextField
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder={t("contactus.FormPlaceholderPhone")}
                      value={data.phone}
                      onChange={handleChange}
                      className="w-full bg-white font-poppins rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                  <div className="relative mb-6">
                    <TextField
                      type="text"
                      name="message"
                      id="message"
                      placeholder={t("contactus.FormPlaceholderMessage")}
                      multiline
                      minRows={4}
                      error={handleErrorInput(rMain, "message")}
                      helperText={t(handleErrorMessage(rMain, "message"))}
                      value={data.message}
                      onChange={handleChange}
                      className="w-full bg-white font-poppins rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 duration-200 ease-in-out"
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full mt-2 lg:mt-8 font-poppins text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg"
                  >
                    {t("contactus.FormButtom")}
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </main>
      {/*<div className="xl:fixed w-full xl:bottom-0">*/}
      <Footer />
      {/*</div>*/}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { editWorkExperience } from "../../redux/actions/workExpActions";
import { handleErrorMessage, handleErrorInput } from "../../utils/functions";
import Button from "../common/Button";

export default function FromEdit({
  uuid,
  preloadedValues,
  status,
  closeModal,
}) {
  const dispatch = useDispatch();
  const wexp = useSelector((state) => state.workExpReducers);
  const [hidden, setHidden] = useState(false);
  const effectBG = status ? "fadeIn" : "fadeOut";
  const effectModal = status ? "fadeInDown" : "fadeOutUp";
  const { t } = useTranslation("common");
  const [dates, setDates] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: preloadedValues,
  });

  useEffect(() => {
    if (status) {
      setHidden(true);
    } else {
      setTimeout(() => {
        setHidden(false);
      }, 200);
    }
  }, [status]);

  const onSubmitData = (data) => {
    if (data.from > data.to) {
      setShowModalError(true);
      return;
    } else setShowModalError(false);

    dispatch(editWorkExperience(data, uuid));
    setTimeout(() => {
      closeModal();
    }, 200);
  };

  useEffect(() => {
    register("time_experience", {
      required: true,
    });
  }, [register]);

  return (
    <>
      {hidden && (
        <>
          <div
            className={`fixed bg-black w-full h-full top-0 left-0 z-20 animate__animated animate__${effectBG} animate__faster`}
            style={{ backgroundColor: "rgba(0,0,0,.5)" }}
            onClick={closeModal}
          ></div>
          <div className="flex justify-center">
            <div
              className={`rounded-lg fixed top-0 lg:-left-40 lg-0 ml-40 sm:mx-2 sm:my-2 lg:mt-24 w-auto items-center center-11-12 bg-white z-30 animate__animated animate__${effectModal} animate__faster`}
            >
              <div className="rounded">
                <div className="border-gray-700 bg-gray-700 text-white font-bold">
                  <div className="flex justify-between p-3">
                    <label>{t("experience.editexpe")}</label>
                    <svg
                      onClick={closeModal}
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5  border-2 rounded-md hover:bg-gray-200 hover:text-gray-800"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                </div>
                <div className="mt-4 mb-2 px-10 pt-10 pb-0">
                  <form
                    action=""
                    className="w-full animate__animated animate__fadeIn animate__faster"
                    onSubmit={handleSubmit(onSubmitData)}
                  >
                    {}
                    <div className="w-full md:flex md:mb-5">
                      <div className="py-2 md:py-0 md:w-1/2 mx-2">
                        <Controller
                          name="title"
                          id="title"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: t("experience.error.title_required"),
                            },
                            pattern: {
                              value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#]{3,}$/,
                              message: t("experience.error.title_invalid"),
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              type="text"
                              id="title"
                              name="title"
                              label={t("experience.titleform")}
                              variant="outlined"
                              className="w-full"
                              error={handleErrorInput(wexp, "title")}
                              helperText={t(handleErrorMessage(wexp, "title"))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...field}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="title"
                          render={({ message }) => (
                            <p className="mt-1 text-sm text-red-500">
                              {message}
                            </p>
                          )}
                        />
                      </div>
                      <div className="py-2 md:py-0 md:w-1/2 mx-2">
                        <Controller
                          name="company"
                          id="company"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: t("experience.error.company_required"),
                            },
                            pattern: {
                              value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#]{3,}$/,
                              message: t("experience.error.company_invalid"),
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              type="text"
                              id="company"
                              name="company"
                              label={t("experience.company")}
                              variant="outlined"
                              className="w-full"
                              error={handleErrorInput(wexp, "company")}
                              helperText={t(
                                handleErrorMessage(wexp, "company")
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...field}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="company"
                          render={({ message }) => (
                            <p className="mt-1 text-sm text-red-500">
                              {message}
                            </p>
                          )}
                        />
                      </div>
                      <div className="py-2 md:py-0 md:w-1/2 mx-2">
                        <Controller
                          name="location"
                          id="location"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: t("experience.error.location_required"),
                            },
                            pattern: {
                              value: /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ:.,*#-]{3,}$/,
                              message: t("experience.error.location_invalid"),
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              type="text"
                              id="location"
                              name="location"
                              label={t("experience.location")}
                              variant="outlined"
                              className="w-full"
                              error={handleErrorInput(wexp, "location")}
                              helperText={t(
                                handleErrorMessage(wexp, "location")
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...field}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="location"
                          render={({ message }) => (
                            <p className="mt-1 text-sm text-red-500">
                              {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    {}
                    <div className="w-full md:flex mb-5">
                      <div className="py-2 md:py-0 md:w-1/2 mx-2">
                        <Controller
                          name="website"
                          id="website"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: t("experience.error.website_url"),
                            },
                            pattern: {
                              value: /^https?:\/\/[\w]+(\.[\w]+)+[/#?]?.*$/,
                              message: t("experience.error.website_invalid"),
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              type="text"
                              id="website"
                              name="website"
                              label={t("experience.website")}
                              variant="outlined"
                              className="w-full"
                              error={handleErrorInput(wexp, "website")}
                              helperText={t(
                                handleErrorMessage(wexp, "website")
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...field}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="website"
                          render={({ message }) => (
                            <p className="mt-1 text-sm text-red-500">
                              {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>

                    <div className="md:flex items-center justify-between mb-5">
                      <div className="py-2 md:py-0 md:w-1/2 mx-2">
                        <Controller
                          name="from"
                          id="from"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: t("experience.error.from_date"),
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              type="date"
                              id="from"
                              name="from"
                              label={t("experience.from")}
                              variant="outlined"
                              className="w-full"
                              error={handleErrorInput(wexp, "from")}
                              helperText={t(handleErrorMessage(wexp, "from"))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: new Date().toISOString().slice(0, 10),
                              }}
                              {...field}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="from"
                          render={({ message }) => (
                            <p className="mt-1 text-sm text-red-500">
                              {message}
                            </p>
                          )}
                        />
                      </div>
                      <div className="py-2 md:py-0 md:w-1/2 mx-2 w-full">
                        <InputLabel htmlFor="time_experience">
                          {t("experience.to")}
                        </InputLabel>
                        <Select
                          id="time_experience"
                          name="time_experience"
                          className="w-full md:my-1"
                          native
                          defaultValue={preloadedValues.time_experience}
                          onChange={(event) => {
                            event.target.value === "P"
                              ? setDates(true)
                              : setDates(false);
                            setValue("time_experience", event.target.value);
                          }}
                          inputProps={{
                            name: "time_experience",
                            id: "time_experience",
                          }}
                        >
                          <option value="P">
                            {t("experience.dateNormal")}
                          </option>
                          <option value="A">{t("experience.actually")}</option>
                        </Select>
                      </div>
                      {dates && (
                        <div className="py-2 md:py-0 md:w-1/2 mx-2">
                          <Controller
                            name="to"
                            id="to"
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: t("experience.error.to_date"),
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                type="date"
                                id="to"
                                name="to"
                                label={t("experience.to")}
                                variant="outlined"
                                className="w-full"
                                error={handleErrorInput(wexp, "to")}
                                helperText={t(handleErrorMessage(wexp, "to"))}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  max: new Date().toISOString().slice(0, 10),
                                }}
                                {...field}
                              />
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="to"
                            render={({ message }) => (
                              <p className="mt-1 text-sm text-red-500">
                                {message}
                              </p>
                            )}
                          />
                        </div>
                      )}
                    </div>
                    <div className="md:flex items-center justify-between">
                      <div className="md:w-10/12 flex mb-5 mx-2">
                        <TextField
                          id="description"
                          name="description"
                          label={t("experience.description")}
                          multiline
                          minRows={4}
                          variant="outlined"
                          className="w-full"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>
                    {showModalError ? (
                      <div className="md:w-11/12 mb-5 flex justify-center mx-2">
                        <label className="text-xs font-semibold	text-red-500">
                          {t("experience.error.to_after")}
                        </label>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="flex justify-end pt-2 pb-4 py-0">
                      <Button
                        status={wexp.button}
                        text={t("modal.edit")}
                        icon="fa fa-check"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import RolesMain from "./RolesMain";
import AdminRoutes from "../../routes/AdminRoutes";

export default function Roles() {
  let { path } = useRouteMatch();

  return (
    <div className="w-11/12 mx-auto">
      <Switch>
        <AdminRoutes exact path={`${path}`} component={RolesMain} />
      </Switch>
    </div>
  );
}

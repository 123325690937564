import api from "../../utils/api";

export const getProfessional = (uuid) => async (dispatch) => {
   try {
      const response = await api.get("/professional");
      if (response.status === 200) {
         dispatch({
            type: "PROF_RECORDS",
            payload: response.data,
         });
      }
   } catch (e) {
      dispatch({
         type: "PROF_ERROR",
         payload: e.response.data.errors,
      });
   }
};

export const saveProfessional = (data) => async (dispatch) => {
	dispatch({ type: 'PROF_BUTTON' })
   try {
      const response = await api.post("/professional", data);
      if (response.status === 200) {
         dispatch({
            type: "PROF_SUCCESS"
         });
      }
   } catch (e) {
      dispatch({
         type: "PROF_ERROR",
         payload: e.response.data.errors,
      });
   }
};

export const deleteProfessional = (uuid) => async dispatch => {
	const response = await api.delete(`/professional/${uuid}`);
	if (response.status === 200) {
		dispatch({
			type: 'PROF_SUCCESS'
		})
	}
}
export const selectProfesional = (uuid) => async (dispatch) => {
   try {
      const response = await api.get(`/professional/${uuid}`);
      if (response.status === 200) {
         dispatch({
            type: "PROF_RECORDS",
            payload: response.data,
         });
      }
   } catch (e) {
      dispatch({
         type: "PROF_ERROR",
         payload: e.response.data.errors,
      });
   }
};
export const updateProfessional = (data,uuid) => async (dispatch) => {
   try {
      await api.put(`/updateProfessional/${uuid}`, data);    
   } catch (e) {
      console.log(e)
   }
};
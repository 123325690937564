import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Success, SingleError } from "../common";
import { isUserIncomplete } from "../../utils/functions";
import { useTranslation } from "react-i18next";
import PersonalForm from "./PersonalForm";

export default function Personal({ setIsProfileIncomplete }) {
  const personal = useSelector((state) => state.personalReducers);
  const [data, setData] = useState({});
  const { t } = useTranslation("common");
  const [closeAlert, setCloseAlert] = useState(false);

  useEffect(() => {
    if (personal.loaded && personal.records.length > 0) {
      var filter = Object.values(personal.records).filter(
        (item) => item.uuid === personal.records[0].uuid
      );
      var detail = filter[0].user_detail;

      setData({
        name: filter[0].name,
        lastname: filter[0].lastname || "",
        email: filter[0].email,
        personal_phone:
          detail && detail.personal_phone ? detail.personal_phone : "",
        date_of_birth:
          detail && detail.date_of_birth ? detail.date_of_birth : "",
        address: detail && detail.address ? detail.address : "",
        about_me: detail && detail.about_me ? detail.about_me : "",

        address2: detail && detail.address2 ? detail.address2 : "",
        apartment: detail && detail.apartment ? detail.apartment : "",
        state: detail && detail.state ? detail.state : "",
        country: detail && detail.country ? detail.country : "",
        zip_code: detail && detail.zip_code ? detail.zip_code : "",
      });

      const incomplete = isUserIncomplete(personal.records[0]);
      setIsProfileIncomplete(incomplete);
      setCloseAlert(incomplete);
    }
  }, [personal.loaded, personal.records, setIsProfileIncomplete]);

  useEffect(() => {
    if (personal.success) {
      setIsProfileIncomplete(false);
      setCloseAlert(false);
    }
  }, [personal.success, setIsProfileIncomplete]);

  return (
    <>
      {Object.values(data).length > 0 && (
        <>
          <h1 className="font-bold font-poppins border-b">
            {t("personal.title")}
          </h1>

          {/*<Error
                  status={personal.error}
                  styleParent="mt-5 w-1/2 mx-auto"
                  errors={personal.error_message}
                  closer="PERSONAL_ERROR_OFF"
              />*/}
          <Success
            status={personal.success}
            styleParent="mt-5 w-full md:w-1/2 mx-auto"
            message={t("personal.success")}
            closer="PERSONAL_SUCCESS_OFF"
          />
          <div className="mb-10 w-full md:w-4/5 md:mx-auto mt-10">
            <SingleError
              status={closeAlert}
              styleParent="mb-5 w-full md:w-1/2 mx-auto"
              errors={t("personal.profile_incomplete")}
              closer={() => setCloseAlert(false)}
            />
            {data && <PersonalForm preloadedValues={data} />}
          </div>
        </>
      )}
    </>
  );
}

import axios from "axios";
import { api, errorInterceptor, updateHeaderInterceptor } from "./functions";
import { showLoading, hiddenLoading } from "../redux/actions/loadingActions";
import store from "../redux/store";
const { dispatch } = store;

axios.defaults.baseURL = api.url;
axios.defaults.headers.common["Authorization"] = api.type + " " + api.token;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
const apiInstance = axios.create();

updateHeaderInterceptor(apiInstance, hiddenLoading, showLoading, dispatch);

errorInterceptor(apiInstance, hiddenLoading, dispatch);

export default apiInstance;

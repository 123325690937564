import React from "react";
import { verifyLogin } from "../../utils/functions";
import { useDispatch } from "react-redux";

import MenuDrawer from "./drawer_menu/MenuDrawer";
import Menu from './menu/Menu'


const menu_1 = [
   // {
   //    url: "/",
   //    name: "menu.home",
   //    icon: "fa fa-home",
   //    id: 0,
	// 	exact: true,
   // },
   {
      url: "/hiring/process",
      name: "menu.recriutment",
      icon: "fa fa-shoe-prints",
      id: 0,
      exact: false,
   },
   {
      url: "/register/vacancies",
      name: "menu.register",
      icon: "fa fa-folder-open",
      id: 1,
      exact: false,
   },
   {
      url: "/",
      name: "menu.searchJob",
      icon: "fa fa-search",
      id: 2,
      exact: false,
   },
   {
      url: "/aboutus",
      name: "menu.aboutus",
      icon: "fa fa-building",
      id: 3,
      exact: false,
   },

   // {
   //    url: "/info/vacancies",
   //    name: "menu.vacancies",
   //    icon: "fa fa-shoe-prints",
   //    id: 3,
   //    exact: false,
   // },
   // {
   //    url: "/contactus",
   //    name: "menu.contactus",
   //    icon: "fa fa-phone",
   //    id: 4,
	// 	exact: false,
   // },
];

export default function Main(props) {
   
   const { children } = props;
   const isLogged = verifyLogin();
   const dispatch = useDispatch();
	const [state, setState] = React.useState({
      top: false,
      left: false,
      bottom: false,
      right: false,
   });

   const toggleDrawer = (anchor, open) => (event) => {
      if (
         event.type === "keydown" &&
         (event.key === "Tab" || event.key === "Shift")
      ) {
         return;
      }
      setState({ ...state, [anchor]: open });
   };

   return (
      <>
         <div id="modal_results"></div>
         <Menu items={menu_1} logged={isLogged} dispatch={dispatch} drawer={toggleDrawer} />
			<MenuDrawer items={menu_1} drawer={toggleDrawer} logged={isLogged} dispatch={dispatch} isOpen={state['left']} />
         <div className="w-full font-poppins">{children}</div>
      </>
   );
}

import React from "react";
import { useTranslation } from "react-i18next";


export default function UserExperience(props) {
   const { t } = useTranslation('common');
   const { records } = props;

   return (
      <table className="w-full userInfoTable font-poppins text-sm">
         <thead>
            <tr>
               <th>{t('experience.titleform')}</th>
               <th>{t('experience.company')} ({t('experience.location')})</th>
               <th>{t('experience.description')}</th>
               <th>{t('experience.website')}</th>
               <th>{t('professional.from')} / {t('professional.to')}</th>
            </tr>
         </thead>
         <tbody>
            {records.length > 0 ? Object.entries(records).map(([key, item]) => (
               <tr key={key}>
                  <td>{item.title}</td>
                  <td>
                     {item.company}{" "}
                     {item.location ? `(${item.location})` : null}
                  </td>
                  <td>{item.description}</td>
                  <td>
                     <a
                        href={item.website}
                        target="_blank"
                        rel="noreferrer"
                     >
                        <i className="fa fa-link"></i> Go to
                     </a>
                  </td>
                  <td>
                     {item.from} / {item.to}
                  </td>
               </tr>
            ))
               :
               <tr>
                  <td colSpan="8" style={{ textAlign: 'center' }}>{t('portfolio.norecords')}</td>
               </tr>
            }
         </tbody>
      </table>
   );
}

import api from "../../utils/api";

export const getJobs = (language) => async (dispatch) => {
  try {
    const response = await api.get(`/jobs/list/${language}`);
    dispatch({
      type: "JOBS_RECORDS",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getJobsLimit = (limit) => async (dispatch) => {
  try {
    const response = await api.get(`/jobs/list-job/${JSON.stringify(limit)}`);
    dispatch({
      type: "JOBS_RECORDS",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getVacant = (uuid) => async (dispatch) => {
  try {
    const response = await api.get(`/jobs/edit/${uuid}`);
    dispatch({
      type: "JOBS_RECORDS",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "JOBS_ERROR",
      payload: e.response.data.errors,
    });
  }
};

export const findJobs = (data) => async (dispatch) => {
  try {
    const response = await api.get(`/jobs/find/${JSON.stringify(data)}`);
    dispatch({
      type: "JOBS_RECORDS",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "JOBS_ERROR",
      payload: e.response.data.errors,
    });
  }
};
export const getJob = (uuid) => async (dispatch) => {
  try {
    const response = await api.get(`/jobs/edit/${uuid}`);
    dispatch({
      type: "JOBS_ADITIONAL",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "JOBS_ERROR",
      payload: e.response.data.errors,
    });
  }
};
export const getApplyJobs =
  (uuid, user_id) =>
  async (dispatch) => {
    try {
      const response = await api.post(`/jobs/apply/${uuid}/${user_id}`);
      dispatch({
        type: "JOBS_APPLY",
        payload: response.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
export const getApplyJobsAdmin =
  (uuid, userid = 0) =>
  async (dispatch) => {
    try {
      const response = await api.post(`/jobs/applyAdmin/${uuid}/${userid}`);
      dispatch({
        type: "JOBS_APPLY_ADMIN",
        payload: response.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
export const saveComment = (data) => async (dispatch) => {
  try {
    const response = await api.post("/comments/save", data);
    dispatch({
      type: "JOBS_COMMENTS",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "JOBS_ERROR",
      payload: e.response.data.errors,
    });
  }
};
export const getComment = (data) => async (dispatch) => {
  try {
    const response = await api.post("/comments/get", data);
    dispatch({
      type: "JOBS_MODAL",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "JOBS_ERROR",
      payload: e.response.data.errors,
    });
  }
};

export const editComment = (data, uuid) => async (dispatch) => {
  try {
    const response = await api.put(`/comments/edit/${uuid}`, data);
    dispatch({
      type: "COMMENT_EDIT",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const deleteComment = (uuid) => async (dispatch) => {
  try {
    await api.delete(`/comments/delete/${uuid}`);
    dispatch({
      type: "RESTART_MODAL_JOBS",
    });
  } catch (e) {
    dispatch({
      type: "COMMENT_ERROR",
      payload: e.response.data.errors,
    });
  }
};

//función que trae el perfil completo del aspirante
export const getProfile = (data) => async (dispatch) => {
  try {
    const response = await api.post("/comments/get/profile", data);
    dispatch({
      type: "PROFILE_GET",
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: "PROFILE_ERROR",
      payload: e.response.data.errors,
    });
  }
};

const initialState = {
	records: [],
	loaded: false
}

export default function contactReducers( state = initialState, action ) {
	switch(action.type) {
		case 'CONTACT_RECORDS': {
			return {
				...state,
				records: action.payload,
				loaded: true
			}
		}
		case 'CONTACT_LOADED_OFF': {
			return {
				...state,
				loaded: false
			}
		}
		case 'CONTACT_MESS': {
			return {
				...state,
				countMessages: action.payload
			}
		}
		case 'COUNTS_ALL': {
			return {
				...state,
				records: action.payload
			}
		}
		default: return state;
	}
}
import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { deleteCurrency } from "../../../redux/actions/admin/currencyActions";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

export default function CurrenciesDelete({ open, handleClose, uuid }) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const destroy = () => {
    dispatch(deleteCurrency(uuid));
    handleClose("delete");
  };

  return (
    <Dialog open={open} onClose={() => handleClose("delete")} fullWidth={true}>
      <DialogTitle
        className="border-b text-center"
        style={{ backgroundColor: "#CE4C4C" }}
      >
        <span className="font-bold font-poppins text-white">
          {t("role.delete")}
        </span>
      </DialogTitle>
      <DialogContent>
        <DialogContent>
          <DialogContentText className="text-center">
            <span className="mt-2">{t("role.message_delete")}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose("delete")}>
            {t("board.close_action")}
          </Button>
          <Button onClick={destroy}>{t("board.send_action")}</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import imgerror from "../../views/assets/img/forgotpass.png";
import Footer from "../admin/footer";
import { forgotEmail } from "../../redux/actions/emailActions";

export default function Forgot_password() {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.emailReducers);
  const { t } = useTranslation("common");
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
    lang: localStorage.getItem("lang"),
    url: window.location.origin,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoad(true);
    dispatch(forgotEmail(data));
  };
  return (
    <>
      <div className="flex p-6 bg-black dark:bg-gray-900 pt-6 pb-24 xl:pt-32 xl:pb-60">
        <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
          <div className="flex flex-col overflow-y-auto md:flex-row">
            <div className="">
              <img className="object-cover sm:h-96" src={imgerror} alt="" />
            </div>
            <div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
              <div className="w-full">
                <h1 className="my-8 text-xl font-semibold text-gray-700 dark:text-gray-200">
                  {t("menu.forgotPass")}
                </h1>
                <form action="" onSubmit={handleSubmit}>
                  <label className="block text-sm">
                    <span className="text-gray-700 dark:text-gray-400">
                      Email
                    </span>
                    <input
                      type="email"
                      required
                      className="block w-full mt-1 border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring-1 focus:ring-red-400 focus:border-transparent"
                      placeholder="your@email.com"
                      onChange={(e) =>
                        setData({ ...data, email: e.target.value })
                      }
                    />
                  </label>

                  <button
                    className="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
                    type="submit"
                  >
                    {t("menu.recoveryPassword")}
                  </button>
                </form>

                {load === true && email.data.length === 0 && (
                  <div className="text-center mt-6">
                    <i className="fa fa-spin fa-spinner fa-2x mr-3"></i>
                  </div>
                )}

                {load === true && email.data.user < 1 && (
                  <div
                    className="text-xs mt-6 bg-red-100 border-l-4 border-red-500 text-red-700 p-4"
                    role="alert"
                  >
                    <svg
                      className="inline flex-shrink-0 mr-3 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>{t("contactus.emailNoValid")}</span>
                  </div>
                )}

                {load === true && email.data.user === 1 && (
                  <div
                    className="text-xs mt-6 bg-green-100 border-l-4 border-green-500 text-green-700 p-4"
                    role="alert"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="inline flex-shrink-0 mr-3 w-5 h-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span>{t("contactus.emailSuccess")}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

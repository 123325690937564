import React, { useState } from "react";
import Register from "./Register";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../admin/footer";

export default function Home() {
  const [register, setRegister] = useState(false);
  const [effect,setEffect] = useState("fadeIn");
  const { t } = useTranslation("common");

  const moveRegister = () => {
    setEffect("fadeOutLeft");
    setTimeout(() => {
      setRegister(true);
    }, 300);
  };

  return (
    <>
      {!register && (
        <main>
          {/* start */}
          {/*  className=" absolute z-10  w-full  3xl:w-96 "start */}
          <div className={`relative flex items-center justify-center w-full h-screen overflow-hidden ${effect}`}>
          <div className="mb-40">
          <div className="relative z-30 p-5 lg:mx-60 text-xl my-2 text-center sm:text-4xl sm:text-center md:text-5xl md:text-center lg:text-5xl uppercase font-semibold font-sans text-gray-300 bg-black bg-opacity-50 border-2 border-red-500 rounded">
              {t("home.message")}
            </div>
          </div>
              <video
                autoPlay
                loop
                muted
                className="object-cover absolute z-10 w-auto min-w-full min-h-full max-w-none "
              >
                <source
                  src="https://apiemployment.bebettertest.net/videos/prueba_banner.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
           {/* start */}
          <section className="pb-0 bg-black opacity sm:pt-10 lg:pt-8 xl:pt-12">
            {/* start */}
            <div className="container mx-auto px-4">
               {/* start */}
              <div className="flex flex-wrap">
                {/* <div className="lg:pt-12 text-white pt-6 w-full md:w-4/12 px-4 text-center"> */}
                <div className="w-full md:w-4/12 px-4 text-center pt-10">
                <a href="/aboutus">
                  <div className="relative flex flex-col min-w-0 break-words bg-black w-full lg:pl-20 mb-8 shadow-lg rounded-lg">
                    <div className="px-4 py-2  bg-black border-2 border-white hover:border-red-500 border-opacity-50 rounded transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                      <div className="text-white p-3 text-center text-xl inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-500 bg-opacity-90">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          className="h-6 w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                      </div>
                      <h6 className="text-xl text-white font-semibold">
                        {t("home.agency")}
                      </h6>
                      <p className="mt-2 mb-4 text-white font-light">
                        {t("home.specialty")}
                      </p>
                    </div>
                  </div>
                  </a>
                </div>
                <div className="w-full md:w-4/12 px-4 text-center">
                  <div className="relative flex flex-col min-w-0 break-words bg-black w-full lg:px-10 mb-8 shadow-lg rounded-lg">
                  <a href="/hiring/process">
                    <div className="px-4 py-2  bg-black border-2 border-white hover:border-red-500 border-opacity-50 rounded transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                      <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-500 bg-opacity-90">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          className="h-6 w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                      </div>
                      <h6 className="text-xl text-white font-semibold">
                        {t("home.remoteJob")}
                      </h6>
                      <p className="mt-2 mb-4 text-white font-light">
                        {t("home.bestIdea")}
                      </p>
                    </div>
                    </a>
                  </div>
                </div>
                <div className="w-full md:w-4/12 px-4 text-center md:pt-10">
                  <div className=" relative flex flex-col min-w-0 break-words bg-black w-full lg:pr-20 mb-8 shadow-lg rounded-lg ">
                    <a href="/contactus">
                    <div  className="px-2 py-2 bg-black border-2 border-white hover:border-red-500 border-opacity-50 flex-auto rounded transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110  ...">
                      <div className=" text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-500 bg-opacity-90">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          className="h-6 w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"
                          />
                        </svg>
                      </div>
                      <h6 className="text-xl text-white font-semibold">
                        {t("home.bestAliance")}
                      </h6>
                      <p className="mt-2 mb-4 text-white font-light">
                        {t("home.better")} <a className="font-semibold" href="/contactus">Be Better Developers.</a>
                      </p>
                    </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap items-center mt-10 lg:mt-16">
                <div className="w-full md:w-5/12 px-4 pb-8 mr-auto ml-auto">
                  <h3 className="text-3xl mb-2 font-semibold leading-normal text-white">
                    {t("home.joinTeam")}
                  </h3>
                  <p className="text-xl  font-light leading-relaxed mt-0 mb-4 text-white ">
                    {t("home.findDevelopers")}
                  </p>
                  {localStorage.getItem("access_token") ? (
                    <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                      <Link
                        className=" bg-red-500 text-white active:bg-emerald-700 font-bold uppercase text-md px-4 py-2 rounded shadow hover:bg-red-500 hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                        to="/profile"
                      >
                        <i className="fa fa-flag-checkered"></i>{" "}
                        {t("home.StartButtom")}
                      </Link>
                    </div>
                  ) : (
                    <button
                      className=" bg-red-500 text-white active:bg-emerald-700 font-bold uppercase text-md px-4 py-2 rounded shadow hover:bg-red-500 hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                      onClick={moveRegister}
                    >
                      <i className="fa fa-flag-checkered"></i>{" "}
                      {t("home.recriutment")}
                    </button>
                  )}
                </div>
                <div className="w-full md:w-4/12 px-4 mr-auto pb-0 mb-0 ml-auto">
                  <div className=" relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-black">
                    <video
                      autoPlay
                      loop
                      muted
                      className=" z-10 w-auto min-w-auto min-h-full max-w-none bg-black opacity-80 rounded-t-2xl"
                    >
                      <source
                        src="https://apiemployment.bebettertest.net/videos/video-tecnology.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                    <blockquote className="relative p-8 mb-4 bg-black opacity-90 rounded-b-lg">
                          <svg
                              preserveAspectRatio="none"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 583 95"
                              className="absolute left-0 w-full block"
                              style={{ height: "95px", top:"-94px"}}  
                          >
                                    <polygon
                                       points="-30,95 583,95 583,65"
                                       className="text-black fill-current">
                                    </polygon>
                          </svg> 
                      <h4 className="text-xl font-bold text-white">
                        {t("home.wantPlace")}
                      </h4>
                      </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </section>
           {/* start */}
          <section className="relative py-20">
           {/* start */}
            <div className=" bg-black bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden mt-0 h-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
                className="absolute bottom-0 overflow-hidden"
              >
                <polygon
                  points="2560 0 2560 100 0 100"
                  className="text-white fill-current"
                ></polygon>
              </svg>
            </div>
            {/* start */}
            <div className="container mx-auto px-4">
              <div className="items-center flex flex-wrap">
                <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                  <video
                    autoPlay
                    loop
                    muted
                    className=" z-10 max-w-full rounded-lg shadow-xl"
                  >
                    <source
                      src="https://apiemployment.bebettertest.net/videos/video_code.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="w-full md:w-5/12 ml-auto mr-auto mt-4 lg:mt-0 px-4">
                  <div className="md:pr-12">                   
                   <p className="mt-4 text-xl font-light text-justify leading-relaxed mb-4 text-gray-900">
                      {t("home.jobStrut")}
                    </p>                  
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}

      {register && (
        <Register
          register={() => setRegister(false)}
          initialEffect="fadeInRight"
          finalEffect={() => setEffect("fadeInLeft")}
        />
      )}
      <Footer />
    </>
  );
}

import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Link, Redirect, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionByUuid } from "../../../redux/actions/admin/questionActions";
import { useTranslation } from "react-i18next";

import QuestionEditForm from "./QuestionEditForm";

export default function QuestionEdit() {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const question = useSelector((state) => state.questionReducers);
  let { uuid } = useParams();
  const [editData, setEditData] = useState(undefined);

  useEffect(() => {
    if (!question.record_loaded) {
      dispatch(getQuestionByUuid(uuid));
    } else {
      setEditData({
        ...question.record,
        skills_uuid: question.record.question_skills.map(({ skill }) => skill),
      });
    }
  }, [dispatch, uuid, question.record, question.record_loaded]);

  useEffect(() => {
    return () => {
      dispatch({
        type: "QUESTION_RECORD_OFF",
      });
    };
  }, [dispatch]);

  return (
    <>
      {question.redirect && <Redirect to="/tests/admin" />}

      <div className="mt-5 flex justify-end">
        <Link to="/tests/admin">
          <Button className="focus:outline-none" variant="outlined">
            <i className="fa fa-arrow-left mr-2"></i> {t("personal.back")}
          </Button>
        </Link>
      </div>
      {editData !== undefined ? (
        <QuestionEditForm uuid={uuid} preloadedValues={editData} />
      ) : (
        <div className="justify-center">
          <div className="text-center mt-56 p-4 overflow-x-auto">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        </div>
      )}
    </>
  );
}

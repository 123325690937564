import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
} from "@material-ui/core";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import Autocomplete from "@material-ui/lab/Autocomplete";
import { getPostulates, getDetail, removeElement, setElement } from "../redux/actions/admin/userJobsActions";
import VacantPerson from "./admin/vacancies/VacantPerson";
import { useTranslation } from "react-i18next";
import { InputSearch } from "./common";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { emailApplicant } from "../redux/actions/emailActions";
import { getTemplatesByStage } from "../redux/actions/admin/notificationActions";
import EmailCustom from "./admin/emails/EmailCustom";
import { Success } from "./common";


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination, boardSource, boardDestiny, id, boardComplete) => {
    let result = {};
    result = [boardSource, boardDestiny];
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    delete boardSource['elements'];
    delete boardDestiny['elements'];
    destClone.splice(droppableDestination.index, 0, removed);

    result[droppableSource.droppableId] = { elements: [...sourceClone], ...boardSource, id: id };
    if (result[droppableSource.droppableId].hasOwnProperty('elements') && sourceClone.length === 0) {
        result[droppableSource.droppableId] = { elements: [], ...boardSource, id: id };
    }
    result[droppableDestination.droppableId] = { elements: [...destClone], ...boardDestiny, id: id };
    return result;
};

export default function DropDrag() {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation('common');
    const userJobs = useSelector((state) => state.userJobsReducers);
    const notification = useSelector((state) => state.notificationReducers);
    //const [changeInput, setChangeInput] = useState(false);
    const [load, setLoad] = useState(false);
    // const [newName, setNewName] = useState({
    //     target: ""
    // });
    const [userId, setUserId] = useState(false);
    const [user, setUser] = useState(false);
    const [position, setPosition] = useState(undefined);
    const [uuid_board, setUuidboard] = useState(false);
    const [job_id, setJobId] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [value, setValue] = useState("");
    const [notifyModal, setNotifyModal] = useState(false);
    const [dataPostulate, setDataPostulate] = useState({});
    const [template, setTemplate] = useState("");
    const [templateForm, setTemplateForm] = useState(false);
    const [sender, setSender] = useState("");
    const [interviewDate, setInterviewDate] = useState(null);
    const [interviewFrom, setInterviewFrom] = useState(null);
    const [interviewTo, setInterviewTo] = useState(null);
    const [interviewError, setInterviewError] = useState(false);
    const date = useRef();
    const from = useRef();
    const to = useRef();

    const styleBoard = [
        'rounded bg-gray-100 border-2 border-gray-500 flex-no-shrink w-80 p-2 mr-3 border-l-4 border-l-4-red-600 overflow-y-auto max-h-screen',
        'rounded bg-blue-50 border-2 border-blue-400 flex-no-shrink w-80 p-2 mr-3 border-l-4 border-l-4-red-600 overflow-y-auto max-h-screen',
        'rounded bg-purple-50 border-2 border-purple-500 flex-no-shrink w-80 p-2 mr-3 border-l-4 border-l-4-red-600 overflow-y-auto max-h-screen',
        'rounded bg-yellow-50 border-2 border-yellow-500 flex-no-shrink w-80 p-2 mr-3 border-l-4 border-l-4-red-600 overflow-y-auto max-h-screen',
        'rounded bg-green-50 border-2 border-green-500 flex-no-shrink w-80 p-2 mr-3 border-l-4 border-l-4-red-600 overflow-y-auto max-h-screen',
        'rounded bg-red-50 border-2 border-red-500 flex-no-shrink w-80 p-2 mr-3 border-l-4 border-l-4-red-600 overflow-y-auto max-h-screen',
    ];
    const styleComments = [
        'bg-gray-100 border-2 border-gray-500 border-l-4 border-l-4-red-600',
        'bg-blue-50 border-2 border-blue-400 border-l-4 border-l-4-red-600',
        'bg-purple-50 border-2 border-purple-500 border-l-4 border-l-4-red-600',
        'bg-yellow-50 border-2 border-yellow-500 border-l-4 border-l-4-red-600',
        'bg-green-50 border-2 border-green-500 border-l-4 border-l-4-red-600',
        'bg-red-50 border-2 border-red-500 border-l-4 border-l-4-red-600',
    ];
    const styleLetter = [
        'bg-gray-500',
        'bg-blue-600',
        'bg-purple-600',
        'bg-yellow-500', 
        'bg-green-600',
        'bg-red-600'
    ]

    const proccessPhases = [
        { name: "apply", description: t("board.email.apply") },
        { name: "makeTest", description: t("board.email.makeTest") },
        { name: "firstInterview", description: t("board.email.firstInterview") },
        { name: "secondInterview", description: t("board.email.secondInterview") },
        { name: "approved", description: t("board.email.approved") },
        { name: "reject", description: t("board.email.reject") },
    ];

    let { id } = useParams();

    function onDragEnd(result) {
        const { source, destination, draggableId } = result;
        setLoad(false)
        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;
        if (sInd === dInd) {
            const items = reorder(userJobs.data[sInd].elements, source.index, destination.index);
            const newState = [...userJobs.data];
            newState[sInd] = items;
            // setState(newState);
        } else {
            const result = move(userJobs.data[sInd].elements, userJobs.data[dInd].elements, source, destination, userJobs.data[sInd], userJobs.data[dInd], id, userJobs.data);
            const newState = [...userJobs.data];
            newState[dInd] = result[dInd]
            newState[sInd] = result[sInd]
            userJobs.data = [...newState]
            dispatch(setElement(result[dInd]));

            setNotifyModal(true);

            const { user: { name, email }, vacant_id } = result[dInd].elements.find(({ uuid_element }) => uuid_element === draggableId);

            setDataPostulate({
                lang: i18n.language,
                stage: result[dInd].name_board,
                name,
                email,
                job: userJobs.detail[0].name_job,
                vacant_id: vacant_id,
                greeting: t('notification.greeting'),
                vacant: t('notification.vacant'),
                description: t('notification.description'),
                reply: t('notification.reply'),
                noreply: t('notification.noreply'),
            });
        }

    }
    useEffect(() => {
        setLoad(true)
        dispatch(getPostulates(id));
        dispatch(getDetail(id));
        setLoad(false)
    }, [dispatch, userJobs.loaded, load, id]);

    const handleModal = (id, position, uuid_board, job_id, user) => {
        setUserId(id);
        setPosition(position);
        setUuidboard(uuid_board);
        setUser(user);
        setJobId(job_id);

        dispatch({
            type: 'HANDLE_MODAL',
            payload: true
        });        
        setTimeout(() => {
            setShowModal(true);    
        }, 300);
    };

    const closeModal = () => {
        setShowModal(false);
        dispatch({ type: "CLEAR_MODAL" });
        dispatch({
            type: 'HANDLE_MODAL',
            payload: false
        })
        //dispatch({ type: "CLEAR_MODAL" });
        //dispatch({ type: 'RESTART_MODAL_JOBS' })
    };

    const handleSearch = () => {
        dispatch(getPostulates(id, value));
    };

    const handleModalNotify = () => {
        setNotifyModal(false);
        setTemplateForm(false);
        dispatch({
            type: "NOTIFICATION_TEMPLATES_OFF"
        })
    };

    useEffect(() => {
        if (!notification.templates_loaded && notifyModal && dataPostulate?.stage) {
            dispatch(getTemplatesByStage({ stage: dataPostulate?.stage, language: i18n.language }));
        }

        if (notification.templates_loaded) {
            setTemplate(notification.templates.find((item) => item.default === 1)?.uuid);
            if (dataPostulate?.stage.includes("Interview")) {
                setInterviewDate(null);
                setInterviewFrom(null);
                setInterviewTo(null);
                setSender("");
            }
        }
    }, [dispatch, notifyModal, notification, dataPostulate, i18n.language])

    const formatDate = (date) => new Date(date.toString()).toLocaleDateString(i18n.language === 'en' ? 'en-US' : 'es-CO');

    const formatTime = (time) => {
        const parsedTime = new Date(time.toString()).toLocaleTimeString("en-US");
        return parsedTime;
    }

    const sendNotification = () => {
        const isInterview = dataPostulate?.stage.includes("Interview");
        if (isInterview) {
            if (!interviewDate || !interviewFrom || !interviewTo || !sender) {
                setInterviewError(true);
                setTimeout(() => setInterviewError(false), 4000);
                return;
            }
        }

        dispatch(emailApplicant({
            ...dataPostulate, 
            interview_data: isInterview ? {
                date: formatDate(interviewDate),
                from: formatTime(interviewFrom),
                to: formatTime(interviewTo),
                sender
            } : "",
            template: { uuid: template }
        }));
        
        setNotifyModal(false);
        setTemplateForm(false);
    };

    const setDataFromModal = (userDetail) => {
        const { user: { user: { name, email }, vacant_id }, position } = userDetail;

        setDataPostulate({ 
            lang: i18n.language,
            stage: proccessPhases[position].name,
            name,
            email,
            job: userJobs.detail[0].name_job,
            vacant_id,
            greeting: t('notification.greeting'),
            vacant: t('notification.vacant'),
            description: t('notification.description'),
            reply: t('notification.reply'),
            noreply: t('notification.noreply')
        })

        setNotifyModal(true);
        setShowModal(false);
    };

    const templateHandleChange = (value) => {
        setTemplate(value);

        if (value === "other") {
            setTemplateForm(true);
        } else {
            setTemplateForm(false);
        }
    };

    return (
        <>
            <Success
                status={notification.send_success}
                styleParent="mt-10 w-full md:w-1/2 mx-auto"
                message={t("notification.send_success")}
                closer="NOTIFICATION_SEND_SUCCESS_OFF"
            />
            <div className="w-14/16 mx-auto mt-5 animate__animated animate__fadeIn mb-50">
            {/*          <div className="flex p-2 bg-gray-200 items-center justify-end">
                <button
                    className="text-white bg-gray-700 hover:bg-gray-900 focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    type="button"
                    onClick={() => {
                        // setState([...userJobs.data, []]);
                    }}
                >
                    Nueva lista de proceso
                </button>
            </div> */}
            <br/>
            <Link to={`/vacancies/admin`} className="ml-5">
                <Button
                    className="focus:outline-none outline font-poppins"
                    variant="outlined"
                >
                    <i className="fa fa-arrow-left mr-2 font-poppins"></i>{" "}
                    {t("personal.back")}
                </Button>
            </Link>

            <div className="flex p-4 items-center justify-between">
                <div className="flex">
                    <h3 className="text-gray-800 mr-4">{t("board.candidateProcess")}</h3>
                    {userJobs && userJobs.detail[0] &&
                        <div className="list-reset text-gray-800 hidden md:flex">
                            <span className="font-bold text-lg px-2">☆</span>
                            <span className="border-l border-blue-lighter px-2 text lg:text-lg font-bold">{userJobs.detail[0].name_job}</span>
                                <span className="rounded-lg bg-blue-300 text-xs px-2 py-1">{t('list.avalaible')} {userJobs.detail[0].cant_vacant} {t('vacancies.vacancies')}</span>
                            <span className="mx-4 rounded-lg bg-red-300 text-xs px-2 py-1">{userJobs.data[0] && userJobs.data[0].elements.length > 0 ? userJobs.data[0].elements.length : 0} {t('board.newCandidates')}</span>
                        </div>
                    }
                </div>
                <InputSearch setValue={setValue} value={value} handleSearch={handleSearch} />
            </div>
            <div style={{ display: "flex" }}
                className="flex px-4 pt-10 pb-8 items-start overflow-x-scroll w-screen">
                <div className="flex">
                    <DragDropContext onDragEnd={onDragEnd}>
                        {!load && userJobs.loaded && userJobs.data.length > 0 && userJobs.data.map((el, ind) => (
                            <Droppable key={ind} droppableId={`${ind}`}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        className={`${ind === 0 ? styleBoard[0]
                                            : (ind === 1 ? styleBoard[1]
                                                : (ind === 2 ? styleBoard[2]
                                                    : (ind === 3 ? styleBoard[3]
                                                        : (ind === 4 ? styleBoard[4]
                                                            : styleBoard[5]))))}`}
                                    >
                                        <div className="flex justify-between py-1"
                                        //  onClick={() => { setChangeInput(true) }} 
                                        >
                                            {/* {changeInput ? */}
                                                {/* <input type="text"
                                                    id="name_target"
                                                    name="name_target"
                                                    // onChange={(nameNew) => {
                                                    //     setNewName({
                                                    //         target: nameNew.target.value,
                                                    //     });
                                                    // }}
                                                    // onKeyDown={(e) => {
                                                    //     if (e.keyCode === 13) {
                                                    //         setChangeInput(false)
                                                    //     }
                                                    // }}
                                                    // onBlur={() => {
                                                    //     setChangeInput(false);
                                                    // }}
                                                    defaultValue={el.name_board ? el.name_board : "Nueva Tarjeta"}
                                                />
                                                : */}
                                                <div>
                                                    <h3 className="text-sm">{el.name_board ? t("board." + el.name_board) : "Nueva Tarjeta"}</h3>
                                                    {/* <svg className="h-4 fill-current text-grey-dark cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 10a1.999 1.999 0 1 0 0 4 1.999 1.999 0 1 0 0-4zm7 0a1.999 1.999 0 1 0 0 4 1.999 1.999 0 1 0 0-4zm7 0a1.999 1.999 0 1 0 0 4 1.999 1.999 0 1 0 0-4z" /></svg> */}
                                                </div>
                                            {/* } */}
                                        </div>
                                        {userJobs.data[ind].hasOwnProperty('elements') && userJobs.data[ind].elements.map((item, index) => (
                                            <Draggable
                                                key={item.uuid_element}
                                                draggableId={item.uuid_element}
                                                index={index}

                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        className="block text-sm h-52 py-5 mt-2 px-2 bg-white rounded border-b-2 border-grey-100 cursor-pointer hover:bg-gray-200 overflow-hidden"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}

                                                    >
                                                        <div className="px-4 flex  justify-start">
                                                            <button
                                                                className="justify-end"
                                                                type="button"
                                                                onClick={() => {
                                                                    // const newState = [...userJobs.data];
                                                                    // newState[ind].splice(index, 1);
                                                                    // setState(
                                                                    //     newState.filter(group => group.length)
                                                                    // );
                                                                }}
                                                            >
                                                            </button>
                                                            <div className="flex-grow">
                                                                <div className="flex">
                                                                    <div className={`flex-none text-center rounded-full pt-1 w-10 h-10 align-middle text-white text-2xl content-center 
                                                                            ${ind === 0 ? styleLetter[0]
                                                                            : (ind === 1 ? styleLetter[1]
                                                                                : (ind === 2 ? styleLetter[2]
                                                                                    : (ind === 3 ? styleLetter[3]
                                                                                        : (ind === 4 ? styleLetter[4]
                                                                                            : styleLetter[5]))))}`}>
                                                                        {(item.user.name).charAt(0)}
                                                                    </div>
                                                                    <div className="flex-col w-44">
                                                                        {/*  {item.user.hired_strut === "Y" &&
                                                                            <span className="inline-block px-2 mx-4 py-1 font-semibold leading-tight text-xs text-green-700 bg-green-100 rounded-md font-poppins">
                                                                                Contratado
                                                                            </span>
                                                                        } */}
                                                                        {/*                                                                 <span className="inline-block px-2 mx-4 py-1 font-semibold leading-tight text-xs text-red-700 bg-red-100 rounded-md font-poppins">
                                                                  Deficiente
                                                                </span> */}
                                                                        <h1 className="text-gray-900 lg:text-sm title-font text-xl font-semibold mx-4 font-poppins">{item.user.name}</h1>
                                                                        <p className="truncate text-xs ml-4 mb-4 text-gray-600">
                                                                            {item.user.email}
                                                                        </p>
                                                                        <span className="pt-1 text-base font-bold flex items-center justify-center lg:justify-start">
                                                                            {t('menu_profile.skills_mobile')}
                                                                        </span>
                                                                        <div>
                                                                            <i className=" ml-4 mr-4 far fa-chart-bar"></i>
                                                                            <span className="mt-2 mr-6 space-x-2 text-gray-500 text-xs font-poppins">{item.skills_check ? item.skills_check : 0}{"/"}{item.skills ? item.skills : 0} {t('board.skillsGenerals')}</span>
                                                                        </div>
                                                                        <div>
                                                                            <i className=" ml-4 mr-4 fas fa-list-ul"></i>
                                                                            <span className="mt-2 mr-6 space-x-2 text-gray-500 text-xs font-poppins">{item.skills_test ? item.skills_test : 0}{"/"}{item.skills_specific ? item.skills_specific : 0} {t('board.skillsSpecifics')}</span>
                                                                        </div>
                                                                        <div>
                                                                            <i className="ml-4 mr-2 far fa-clock"></i>
                                                                            <span className="mt-1 ml-2 space-x-2 text-gray-500 text-xs font-poppins">{t('board.public').replace(/%d/, item.dias)}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                {/* <p> ID: {item.content} </p>  */}
                                                                <div className="flex justify-end">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleModal(item.user_id, ind, userJobs.data[ind]["uuid_board"], el.job_id, item)} className="mx-1 p-1 h-8 w-8 flex rounded-full text-white bg-gray-600 uppercase px-2 py-1 text-xs font-bold mr-3 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                                                                    </svg>

                                                                    {/*  <svg xmlns="http://www.w3.org/2000/svg" className="p-1 h-7 w-7 text-white bg-red-600 border-2 border-red-500 rounded-md" viewBox="0 0 20 20" fill="currentColor">
                                                                        <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                                                                    </svg> */}
                                                                </div>
                                                            </div>
                                                            <div className="flex-row space-x-2">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        const newState = [...userJobs.data];
                                                                        const removeUuid = newState[ind]['elements'][index].uuid_element;
                                                                        userJobs.data[ind]['elements'].splice(index, 1);
                                                                        setLoad(true)
                                                                        dispatch(removeElement(removeUuid));
                                                                    }}
                                                                >
                                                                    <i className="text-gray-500 fas fa-times"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        ))}
                    </DragDropContext>
                </div>
                {load &&
                    <div style={{ textAlign: 'center' }}>
                        <i className="text-center fa fa-spin fa-spinner fa-2x"></i>
                    </div>
                }
            </div>
            {notification.templates_loaded && notifyModal && (
                <Dialog open={notifyModal} fullWidth={true}>
                    <div className="flex justify-end pt-2 pr-2" style={{ backgroundColor: '#61a5fa' }}>
                        <button onClick={handleModalNotify}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="cursor-pointer h-5 w-5 border-2 text-gray-200 rounded-md hover:bg-gray-200 hover:text-gray-800"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                        </button>
                    </div>
                    <DialogTitle className="text-center" style={{ backgroundColor: '#61a5fa' }}>
                        <span className="text-white font-poppins">{t('board.notification_title')}</span>
                    </DialogTitle>
                    <DialogContent>
                        <div className="flex flex-col items-center mb-3">
                            <span className="mt-2 font-poppins">{t('board.notification_message')}</span>
                            <div className="w-2/3">
                                <Autocomplete
                                    id="board_name"
                                    name="board_name"
                                    className="h-1/4 w-full my-6 pr-3"
                                    options={[...notification.templates, { uuid: "other", subject: t("notification.other") }]}
                                    getOptionLabel={(option) => option.subject}
                                    style={{ width: 380 }}
                                    onChange={(event, value) => templateHandleChange(value?.uuid)}
                                    getOptionSelected={(option, value) => option.subject === value.subject}
                                    defaultValue={notification.templates.find((item) => item.default === 1)}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("notification.select_template")}
                                        variant="outlined"
                                        className="w-full"
                                    />
                                    )}
                                />
                                
                                {dataPostulate?.stage?.includes("Interview") && (
                                    <>
                                        <TextField
                                            id="sender"
                                            name="sender"
                                            type="email"
                                            label={t("notification.sender")}
                                            value={sender}
                                            onChange={(event) => setSender(event.target.value)}
                                            variant="outlined"
                                            className="w-full"
                                        />
                                        <div className="mt-5">
                                            <div className="w-1/2">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        ref={date}
                                                        label={t("notification.variables.interview.legend")}
                                                        value={interviewDate}
                                                        onChange={(value) => {
                                                            setInterviewDate(value)
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} />
                                                        )}
                                                        inputFormat={t("notification.date_format")}
                                                        minDate={new Date()}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                        <div className="flex mt-3">
                                            <div className="w-1/2 mr-8">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <TimePicker
                                                        ref={from}
                                                        label={t("notification.variables.interview.from")}
                                                        value={interviewFrom}
                                                        onChange={(value) => {
                                                            setInterviewFrom(value)
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                            <div className="w-1/2">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <TimePicker
                                                        ref={to}
                                                        label={t("notification.variables.interview.to")}
                                                        value={interviewTo}
                                                        onChange={(value) => {
                                                            setInterviewTo(value)
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                        {interviewError && (
                                            <p className="mt-3 text-sm text-red-500 font-poppins">{t("notification.error.interview")}</p>
                                        )}
                                    </>
                                )}
                            </div>
                            {templateForm && (
                                <EmailCustom 
                                    dataPostulate={dataPostulate} 
                                    dateRef={date} 
                                    fromRef={from} 
                                    toRef={to} 
                                    setNotifyModal={setNotifyModal}
                                    setError={setInterviewError}
                                    closeForm={setTemplateForm} 
                                />
                            )}
                        </div>
                    </DialogContent>
                    {!templateForm && (
                        <DialogActions>
                            <Button onClick={handleModalNotify}>{t('board.close_action')}</Button>
                            <Button onClick={() => sendNotification()}>{t('board.send_action')}</Button>
                        </DialogActions>
                    )}
                </Dialog>
            )}
            {userJobs.open_modal && position >= 0 &&
                <VacantPerson
                    status={showModal} 
                    id={userId} 
                    position={position} 
                    closeModal={closeModal} 
                    uuid_board={uuid_board} 
                    job_id={job_id} 
                    user={user} 
                    styleLetter={styleComments} 
                    jobsDetail={userJobs.detail[0]}
                    prepareNotification={setDataFromModal}
                />
            }
        </div>
        </>
        
    );
}

import api from "../../../utils/api";

export const getUsers = () => async (dispatch) => {
  try {
    const { data: payload } = await api.get("/users");
    dispatch({
      type: "USERS_RECORDS",
      payload,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getUserProfile = (uuid) => async (dispatch) => {
  try {
    const response = await api.get(`/users/${uuid}`);
    dispatch({
      type: "USERS_RECORD",
      payload: response.data[0],
    });
  } catch (e) {
    console.log(e);
  }
};
export const getdataskill = (data) => async (dispatch) => {
  try {
    const response = await api.get(`/getdataskill/test/${data}`);
    dispatch({
      type: "DATASKILL",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getResults = (user_skill_uuid) => async (dispatch) => {
  try {
    const response = await api.get(`/user-skills/${user_skill_uuid}`);
    dispatch({ type: "USER_RESULTS", payload: response.data[0] });
  } catch (e) {
    console.log(e);
  }
};
export const evaluatiodev = (data) => async (dispatch) => {
  try {
    const response = await api.put("/evaluar/test", data);

    dispatch({
      type: "USER_RESULTS_REV",
      payload: response.data[0],
    });
  } catch (e) {
    console.log(e);
  }
};

export const getListUsersSkills = () => async (dispatch) => {
  try {
    const response = await api.get(`/list/users/skills`);
    dispatch({ type: "USER_SKILLS_LIST", payload: response.data });
  } catch (e) {
    console.log(e);
  }
};

export const filterUsers = (data) => async (dispatch) => {
  try {
    const response = await api.post(`/users/filter`, data);
    dispatch({ type: "USERS_FIND", payload: response.data });
  } catch (e) {
    console.log(e);
  }
};

export const findUser = (skill_id) => async (dispatch) => {
  try {
    const response = await api.get(`/users/finds/${skill_id}`);
    dispatch({ type: "USERS_FIND", payload: response.data });
  } catch (e) {
    console.log(e);
  }
};
export const findUserType = (type) => async (dispatch) => {
  try {
    const response = await api.get(`/users/findstype/${type}`);
    dispatch({ type: "USERS_FIND", payload: response.data });
  } catch (e) {
    console.log(e);
  }
};
export const deleteRecordsSkills = (idSkill, uuidUser) => async (dispatch) => {
  var data = {
    idskill: idSkill,
    uuidUser: uuidUser,
  };
  try {
    const response = await api.post("/skillsuser/deleteskill", data);
    dispatch({ type: "DELETE_SKILL", payload: response.data });
  } catch (e) {
    console.log(e);
  }
};
export const deleteUser = (uuid, reason) => async (dispatch) => {
  var data = {
    uuid: uuid,
    reason: reason,
  };
  try {
    await api.post("/users/deleteuser", data);
    dispatch({
      type: "USERS_RECORDS_DELETE",
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateUserRole = (data) => async (dispatch) => {
  try {
    await api.post(`/roles/user`, data);
    dispatch({
      type: "USER_RELOAD",
    });
  } catch (e) {
    console.log(e);
  }
};

const initialState = {
  loaded: false,
  records: [],
  record_loaded: false,
  record: undefined,
  search: [],
  button: false,
  error: false,
  error_message: [],
  success: false,
  redirect: false,
  success_message: "",
  go_to_edit_on: "",
  questions_list: [],
};

export default function questionReducers(state = initialState, action) {
  switch (action.type) {
    case "QUESTION_RESTART": {
      return {
        ...state,
        button: false,
        error: false,
        error_message: false,
        redirect: false,
      };
    }
    case "QUESTION_BUTTON": {
      return {
        ...state,
        button: true,
      };
    }
    case "QUESTION_RECORDS": {
      return {
        ...state,
        loaded: true,
        records: action.payload,
      };
    }
    case "QUESTION_RECORD": {
      return {
        ...state,
        record_loaded: true,
        record: action.payload,
      };
    }
    case "QUESTION_SEARCH": {
      return {
        ...state,
        loaded: true,
        search: action.payload,
      };
    }
    case "QUESTION_LOADED_OFF": {
      return {
        ...state,
        loaded: false,
      };
    }
    case "QUESTION_RECORD_OFF": {
      return {
        ...state,
        record_loaded: false,
        loaded: false
      };
    }
    case "QUESTION_STORE": {
      return {
        ...state,
        error: false,
        error_message: [],
        records: state.records.concat(action.payload),
        go_to_edit_on: action.payload.uuid,
        success: true,
        redirect: true,
        button: false,
        success_message: `${action.payload.question} was added successfully`,
      };
    }
    case "QUESTION_UPDATE": {
      let indice = state.records.findIndex(
        (item) => item.uuid === action.payload.uuid
      );
      const copy_array = [...state.records];
      copy_array[indice] = action.payload;
      return {
        ...state,
        error: false,
        error_message: [],
        success: true,
        redirect: true,
        button: false,
        records: copy_array,
        success_message: `${action.payload.question} was modified successfully`,
      };
    }
    case "QUESTION_DELETE": {
      const arreglo = state.records.filter(
        (item) => item.uuid !== action.payload.uuid
      );
      return {
        ...state,
        error: false,
        error_message: [],
        success: true,
        redirect: true,
        button: false,
        records: arreglo,
        success_message: `${action.payload.question} was deleted successfully`,
      };
    }
    case "QUESTION_ERROR": {
      return {
        ...state,
        error: true,
        error_message: action.payload,
        button: false,
      };
    }
    case "QUESTION_SUCCESS_OFF": {
      return {
        ...state,
        success: false,
        success_message: "",
        redirect: false,
      };
    }
    case "QUESTION_LIST": {
      return {
        ...state,
        questions_list: action.payload,
      };
    }
    case "QUESTION_RELOAD": {
      return {
        ...state,
        loaded: false,
      };
    }
    default:
      return state;
  }
}
